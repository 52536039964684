import React, { FC, useState, useEffect } from "react";
import SVG from "react-inlinesvg";
import { toast } from "react-toastify";
import { Card, CardBody, CardHeader } from "../../../partials/controls";
import { SizePerPage } from "../../../common/table/filters/SizePerPage";
import { Table } from "../../../common/table";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import {
  getBranchDetailsForSearch,
  getCustomerPackages,
} from "../../../service/carService";
import { useHistory, useParams } from "react-router-dom";
import {
  toAbsoluteUrl,
  headerSortingClasses,
  sortCaret,
} from "../../../helpers";
import moment from "moment";
import { sortServerString } from "../../../common/table/sorter";
import { PaginationLinks } from "../../../common/pagination/PaginationLinks";
import { PaginationToolbar } from "../../../common/pagination/PaginationToolbar";
import AddCustomerPackage from "./customerPackageEdit";
import { FilterQuery } from "../../../common/table/filters/FilterQuery";
import CustomerNewPackageModal from "./CustomerPurchasePackage";

const CustomerPackages = ({ customerName }) => {
  const [query, setQuery] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [totalData, setTotalData] = useState<number>(1);
  const [show, setShow] = useState(false);
  const [entities, setEntities] = useState<any>([]);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [type, setType] = useState<string>("0$created");
  const [sortingType, setSortingType] = useState<any>("");
  const [branchListFilter, setBranchListFilter] = useState([]);
  const [branch, setBranch] = useState<any>("");
  const [selectedBranch, setSelectedBranch] = useState<any>(null);
  const [packages, setPackages] = useState([]);
  const [branchList, setBranchList] = useState<any>([]);
  const [isEditModal, setIsEditModal] = useState(false);
  const [modalData, setModalData] = useState<any>({});
  const params: { id: string } = useParams();
  //  @ts-ignore
  const id: any = params.id;

  const getCustomerPackageFunction = () => {
    let packageList: any = [];
    if (id) {
      getCustomerPackages(id, page, branch, type, query, sizePerPage).then(
        (res: any) => {
          setTotalData(res?.data?.total);
          setPage(res?.data?.page);
          res?.data?.docs.map((data, index) => {
            const Obj = {
              // index: ++index + (page - 1) * sizePerPage,
              _id: data._id,
              package: data?.product?.name,
              quota: data?.quotaLeft + "/" + data?.quota,
              quotaLeft: data?.quotaLeft,
              createdAt: data?.created_at,
              amountPayable: data?.amountPayable,
              expireData: data?.expireAt,
            };
            packageList.push(Obj);
          });
          setPackages(packageList);
        }
      );
    }
  };

  const DateColumnFormatter = (date: any, row) => {
    if (date) {
      return (
        <span
          onClick={() => {
            history.push(`/customer/packagerecord/${params.id}/${row._id}`);
          }}
        >
          {moment(date).format("DD-MM-YYYY")}
        </span>
      );
    }
  };

  // const TimeColumnFormatter = (date:any) => {
  //   if(date){
  //     return `${moment(date.start).format("HH:mm")}-${moment(date.end).format("HH:mm")}`
  //   }
  // }

  // const openBookDetailPage = (rowData) => {
  //   localStorage.setItem("status" , rowData.status);
  //   window.open(`/booking/edit/${rowData._id}`, '_blank')

  // };

  console.log(customerName, "asdfadfasdfafdasf");

  const ActionEdit = (cellContent, row, rowIndex) => {
    return (
      <div
        title="Edit customer"
        className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3 zindex-modal"
        onClick={(e) => {
          e.stopPropagation();
          setIsEditModal(true);
          setModalData(row);
        }}
      >
        <span className="svg-icon svg-icon-md svg-icon-primary">
          <SVG
            src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
          />
        </span>
      </div>
    );
  };
  const formateUser = (cellContent, row, rowIndex) => {
    return (
      <>
        <div className="text-left">
          <div className="user-name-booking">{row.created_by.name}</div>
          <div className="phone-number">{row.created_by.phone}</div>
        </div>
      </>
    );
  };

  const PriceFormatter = (e, row) => {
    return (
      <span
        onClick={() => {
          history.push(`/customer/packagerecord/${params.id}/${row._id}`);
        }}
      >
        {`$${e.toLocaleString("en-US")}`}
      </span>
    );
  };

  const PackageFormatter = (cellContent, row, rowIndex) => {
    return (
      <div className="text-left">
        <div
          onClick={() => {
            history.push(`/customer/packagerecord/${params.id}/${row._id}`);
          }}
          className="package"
        >
          {row.package}
        </div>
      </div>
    );
  };

  const QuotaFormatter = (a, b, c) => {
    return (
      <div
        onClick={() =>
          history.push(`/customer/packagerecord/${params.id}/${b._id}`)
        }
      >
        {b.quota}
      </div>
    );
  };

  const columns = [
    {
      dataField: "package",
      text: "Package",
      sort: false,
      formatter: PackageFormatter,
    },
    {
      dataField: "quota",
      text: "Quota",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: QuotaFormatter,
      onSort: (field: string, order: string) =>
        sortServerString(field, order, setSortingType),
      // formatter: DateColumnFormatter,
    },
    {
      dataField: "createdAt",
      text: "Purchase Date",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      onSort: (field: string, order: string) =>
        sortServerString(field, order, setSortingType),
      formatter: DateColumnFormatter,
    },
    {
      dataField: "expireData",
      text: "Expiry Date",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      onSort: (field: string, order: string) =>
        sortServerString(field, order, setSortingType),
      formatter: DateColumnFormatter,
    },
    {
      dataField: "amountPayable",
      text: "Price",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: PriceFormatter,
    },
    {
      dataField: "action",
      text: "Action",
      formatter: ActionEdit,
    },
  ];

  const history = useHistory();

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      console.log(row);
      history.push(`/customer/packagerecord/${params.id}/${row._id}`);
    },
  };

  const getBranchsDetailsForSearch = () => {
    setLoading(true);
    getBranchDetailsForSearch()
      .then((res: any) => {
        setLoading(false);
        const branchData = res.data.docs.map((branch) => {
          return { value: branch.id, label: branch.name };
        });
        branchData.unshift({ value: "", label: "All" });
        setBranchListFilter(branchData);
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || "Something went wrong.");
        setLoading(false);
      });
  };

  const customTotal = (from: any, to: any, size: any) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing rows {totalData === 0 ? 0 : (page - 1) * sizePerPage + 1} to {to}{" "}
      of {totalData}
    </span>
  );

  const onTableChange = async (type: any, props: any, paginationProps: any) => {
    if (type !== "sort") {
      setSizePerPage(props.sizePerPage);
      if (props.sizePerPage > paginationProps.totalSize) {
        setPage(1);
      } else {
        setPage(props.page);
      }
    }
  };

  const paginationOptions = {
    custom: true,
    totalSize: totalData,
    hideSizePerPage: true,
    showTotal: true,
    page,
    sizePerPage,
    paginationTotalRenderer: customTotal,
  };

  const getSelectRow = {
    mode: "radio",
    clickToSelect: true,
    hideSelectColumn: true,
    // onSelect: (data: ArticleEntities) => history.push(`/booking/edit`),
  };
  const handleSizePerPage = (
    { page, onSizePerPageChange }: any,
    newSizePerPage: any
  ) => {
    onSizePerPageChange(newSizePerPage, page);
  };

  useEffect(() => {
    getBranchsDetailsForSearch();
    getCustomerPackageFunction();
  }, [query, type, sortingType, branch, page, sizePerPage, isEditModal, show]);

  return (
    <Card>
      <CardHeader title="Packages"></CardHeader>
      <CardBody>
        <PaginationProvider pagination={paginationFactory(paginationOptions)}>
          {({ paginationProps, paginationTableProps }) => (
            <>
              <div className="d-flex justify-content-between">
                <div className="d-flex flex-column flex-md-row flex-sm-column mb-3">
                  <AddCustomerPackage
                    show={isEditModal}
                    modalData={modalData}
                    onHide={() => setIsEditModal(false)}
                  />
                  <FilterQuery
                    query={query}
                    setQuery={(e) => {
                      setQuery(e);
                      setPage(1);
                    }}
                  />
                </div>
                <div className="mr-3">
                  <button
                    className="btn btn-primary "
                    onClick={() => setShow(true)}
                  >
                    New Package
                  </button>
                </div>
              </div>
              <Table
                getSelectRow={getSelectRow}
                paginationProps={paginationProps}
                paginationOptions={paginationOptions}
                paginationTableProps={paginationTableProps}
                data={packages}
                columns={columns}
                rowEvents={rowEvents}
                remote
                onTableChange={(type: any, props: any) =>
                  onTableChange(type, props, paginationProps)
                }
              />
              <div className="d-flex justify-content-between align-items-center flex-wrap">
                <div>
                  <PaginationLinks paginationProps={paginationProps} />
                </div>
                <div className="d-flex">
                  <SizePerPage
                    paginationProps={paginationProps}
                    handleSizePerPage={handleSizePerPage}
                    entities={entities}
                  />
                  <PaginationToolbar paginationProps={paginationProps} />
                </div>
              </div>
            </>
          )}
        </PaginationProvider>
        <CustomerNewPackageModal
          customerName={customerName}
          show={show}
          onHide={() => setShow(false)}
        />
      </CardBody>
    </Card>
  );
};

export default CustomerPackages;
