import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { updateArrivedBranchDetail } from "../../../service/carService";
import Loader from "../../../common/loader";

import { toast } from "react-toastify";

const ChangeStatusToAbsentModal = ({
  show,
  onHide,
  onMainHide,
  id,
  getCalenderLatestData,
}) => {
  const [loading, setLoading] = useState<boolean>(false);

  const updateStatus = () => {
    updateArrivedBranchDetail(id, "absent")
      .then((res: any) => {
        onHide(true);
        getCalenderLatestData();
        toast.success("Status is updated");
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || "Something went wrong.");
      });
  };

  return (
    <div>
      {loading && <Loader />}
      <Modal
        centered
        show={show}
        onHide={() => {
          onHide();
          onMainHide(true);
        }}
        // size="sm"
        dialogClassName="modal-90w"
        aria-labelledby="example-modal-sizes-title-sm"
        className="service-modal"
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">Confirm</Modal.Title>
        </Modal.Header>
        <Modal.Body className="show-grid">
          <div>Please confirm mark Absent for this booking</div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex flex-wrap align-items-center justify-content-between">
            <div className="mt-4 mt-sm-0">
              <button
                className="btn btn-secondary mr-2 mx-sm-2"
                onClick={() => {
                  onHide();
                  onMainHide(true);
                }}
              >
                Back
              </button>
              <button
                className="btn btn-danger mr-2 mx-sm-2"
                onClick={() => updateStatus()}
              >
                Confirm
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ChangeStatusToAbsentModal;
