import React, { FC, useEffect, useState } from "react";
import SVG from "react-inlinesvg";
import { headerSortingClasses, sortCaret } from "../../../helpers";
import { Card, CardBody, CardHeader } from "../../../partials/controls";
import { SizePerPage } from "../../../common/table/filters/SizePerPage";
import { Table } from "../../../common/table";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { FilterQuery } from "../../../common/table/filters/FilterQuery";
import { StatusFilter } from "../../../common/table/filters/StatusFilter";
import { useHistory } from "react-router-dom";
import { sortString, sortServerString } from "../../../common/table/sorter";
import { PaginationLinks } from "../../../common/pagination/PaginationLinks";
import { PaginationToolbar } from "../../../common/pagination/PaginationToolbar";
import { getPushNotificationHistoryApi } from "../../../service/carService";
import moment from "moment";
import { toAbsoluteUrl } from "../../../helpers";
import AddNotification from "./addnotification";
import Loader from "../../../common/loader";
import { toast } from "react-toastify";

const PushNotification: FC = () => {
  const history = useHistory();
  const [query, setQuery] = useState<string>("");
  const [totalData, setTotalData] = useState<number>(1);
  const [entities, setEntities] = useState<any>([]);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [type, setType] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [notification, setnotification] = useState<boolean>(false);
  const [sortingType, setSortingType] = useState<any>("");

  const columns = [
    {
      dataField: "subject",
      text: "Title",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      onSort: (field: string, order: string) =>
        sortServerString(field, order, setSortingType),
    },
    {
      dataField: "labels",
      text: "Customer Tag",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      onSort: (field: string, order: string) =>
        sortServerString(field, order, setSortingType),
    },
    {
      dataField: "transport",
      text: "Push Type",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      onSort: (field: string, order: string) =>
        sortServerString(field, order, setSortingType),
    },
    {
      dataField: "content",
      text: "Content",
      sort: false,
    },
  ];

  const customTotal = (from: any, to: any, size: any) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing rows {totalData === 0 ? 0 : (page - 1) * sizePerPage + 1} to {to}{" "}
      of {totalData}
    </span>
  );

  const handleSizePerPage = (
    { page, onSizePerPageChange }: any,
    newSizePerPage: any
  ) => {
    onSizePerPageChange(newSizePerPage, page);
  };

  const onTableChange = async (type: any, props: any, paginationProps: any) => {
    if (type !== "sort") {
      setSizePerPage(props.sizePerPage);
      if (props.sizePerPage > paginationProps.totalSize) {
        setPage(1);
        getPushNotificationHistory(1, props.sizePerPage);
        setPage(1);
      } else {
        setPage(props.page);
        getPushNotificationHistory(props.page, props.sizePerPage);
      }
    }
  };

  const paginationOptions = {
    custom: true,
    totalSize: totalData,
    hideSizePerPage: true,
    showTotal: true,
    page,
    sizePerPage,
    paginationTotalRenderer: customTotal,
  };

  const getSelectRow = {
    mode: "radio",
    clickToSelect: true,
    hideSelectColumn: true,
    onSelect: (data: ArticleEntities) =>
      history.push(`/pushNotification/history/${data.id}`),
  };

  const getPushNotificationHistory = (
    page = 1,
    sizePerPage = 10,
    showLoading = true
  ) => {
    const isSortColumn =
      sortingType !== "" ? `&${sortingType}` : `&sort={"created_at": -1}`;
    const isStatus = type !== "" ? `&filter={"transport":"${type}"}` : "";
    if (showLoading) {
      setLoading(true);
    }
    getPushNotificationHistoryApi(
      page,
      sizePerPage,
      query,
      isStatus,
      isSortColumn
    )
      .then((res: any) => {
        setLoading(false);
        let news: any = [];
        res?.data?.docs.map((data, index) => {
          const Obj = {
            index: ++index + (page - 1) * sizePerPage,
            id: data._id,
            subject: data.subject,
            transport: data.transport,
            content: data.content,
            labels: data.labels.toString(),
            created_at: moment(data.created_at).format("DD/MM/YYYY HH:mm"),
          };
          news.push(Obj);
        });
        setEntities(news);
        setTotalData(res?.data?.total);
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || "Something went wrong.");
        setLoading(false);
      });
  };

  // useEffect(() => {
  //   getPushNotificationHistory();
  // }, [sortingType]);

  useEffect(() => {
    getPushNotificationHistory(1, 10, false);
  }, [query, type, sortingType]);

  const openAddNotification = (id?) => {
    setnotification(true);
  };

  const resetPage = () => {
    setnotification(false);
    setPage(1);
    setSizePerPage(10);
    getPushNotificationHistory();
  };

  return (
    <Card>
      {loading && <Loader />}
      <CardHeader title="Push History">
        <div className="d-flex align-items-center">
          <a
            title="Edit customer"
            className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
            role="button"
            onClick={() => {
              getPushNotificationHistory();
            }}
          >
            <span className="svg-icon svg-icon-md svg-icon-primary">
              <SVG src={toAbsoluteUrl("/media/svg/icons/reload.svg")} />
            </span>
          </a>
        </div>
      </CardHeader>
      <CardBody>
        <PaginationProvider pagination={paginationFactory(paginationOptions)}>
          {({ paginationProps, paginationTableProps }) => (
            <>
              <div className="d-flex ">
                <div className="d-flex flex-column flex-md-row flex-sm-column mb-10">
                  <StatusFilter
                    status={type}
                    setStatus={(e) => {
                      setType(e);
                      setPage(1);
                    }}
                    options={[
                      { label: "All", value: "" },
                      { label: "Push", value: "push" },
                      { label: "SMS", value: "sms" },
                    ]}
                  />
                  <FilterQuery
                    query={query}
                    setQuery={(e) => {
                      setQuery(e);
                      setPage(1);
                    }}
                  />
                </div>
                <div className="mt-4">
                  <button
                    className="btn btn-primary"
                    onClick={openAddNotification}
                  >
                    Push Notification
                  </button>
                </div>
              </div>
              <Table
                getSelectRow={getSelectRow}
                paginationProps={paginationProps}
                paginationOptions={paginationOptions}
                paginationTableProps={paginationTableProps}
                data={entities}
                columns={columns}
                remote
                onTableChange={(type: any, props: any) =>
                  onTableChange(type, props, paginationProps)
                }
              />
              <div className="d-flex justify-content-between align-items-center flex-wrap">
                <div>
                  <PaginationLinks paginationProps={paginationProps} />
                </div>
                <div className="d-flex">
                  <SizePerPage
                    paginationProps={paginationProps}
                    handleSizePerPage={handleSizePerPage}
                    entities={entities}
                  />
                  <PaginationToolbar paginationProps={paginationProps} />
                </div>
              </div>
            </>
          )}
        </PaginationProvider>
      </CardBody>
      {notification && (
        <AddNotification
          show={notification}
          onHide={() => setnotification(false)}
          resetPage={resetPage}
        />
      )}
    </Card>
  );
};

export default PushNotification;
