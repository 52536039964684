import React, { useEffect, useState } from "react";
import "react-quill/dist/quill.snow.css";
import { useFormik } from "formik";
import {
  getOneService,
  addServices,
  updateServices,
} from "../../../service/carService";
import { toast } from "react-toastify";
import { useHistory, useParams } from "react-router";
import Loader from "../../../common/loader";
import * as Yup from "yup";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
} from "../../../partials/controls";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import DragFileUpload from "../../../common/components/DragFileUpload";
import { Link } from "react-router-dom";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";

interface Values {
  enabled: boolean;
  discountedPrice?: number;
  listedPrice?: number;
  name: string;
  uiTag: string;
  tagColor: string;
  descriptionCN: string;
  descriptionEN: string;
  detailsCN: string;
  detailsEN: string;
  featuredImage: string;
  images: string[];
}
interface Params {
  id?: string;
}
const AddListService = ({ id, show, onHide, getServiceDetails }) => {
  const history = useHistory();
  const [loader, setLoader] = useState<boolean>(false);
  const [service, setService] = useState<any>({});
  const [isSwitchOn, setIsSwitchOn] = useState(true);
  const params: Params = useParams();
  const [imageSrc, setImageSrc] = useState<string[]>([]);

  const onUpdate = (values: Values) => {
    const newDiscription = {
      zh: formik.values.descriptionCN,
      en: formik.values.descriptionEN,
    };

    const newDetails = {
      zh: formik.values.detailsCN,
      en: formik.values.detailsEN,
    };
    const body = {
      enabled: isSwitchOn,
      name: values.name,
      listedPrice: values.listedPrice,
      discountedPrice: values.discountedPrice,
      description: JSON.stringify(newDiscription),
      images: values.images,
      featuredImage: values.featuredImage,
      metadata: {
        uiTag: values.uiTag,
        details: JSON.stringify(newDetails),
        tag: "soft",
        tagColor: values.tagColor,
      },
    };
    setLoader(true);
    updateServices(params.id, body)
      .then((res: any) => {
        setLoader(false);
        if (res?.status === 200) {
          toast.success("Service Update Successful");
          history.push("/service");
          onHide();
          getServiceDetails();
        }
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || "Something went wrong.");
        setLoader(false);
      });
  };

  const getSingleService = () => {
    setLoader(true);
    getOneService(params.id)
      .then((res: any) => {
        setLoader(false);
        setService(res.data);
        setIsSwitchOn(res?.data?.enabled);
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || "Something went wrong.");
        setLoader(false);
      });
  };

  useEffect(() => {
    if (params.id) {
      getSingleService();
    }
  }, []);

  const onsubmitData = (values: Values) => {
    const newDiscription = {
      zh: formik.values.descriptionCN,
      en: formik.values.descriptionEN,
    };

    const newDetails = {
      zh: formik.values.detailsCN,
      en: formik.values.detailsEN,
    };
    setLoader(true);
    const body = {
      enabled: isSwitchOn,
      name: values.name,
      listedPrice: values.listedPrice,
      discountedPrice: values.discountedPrice,
      description: JSON.stringify(newDiscription),
      images: values.images,
      featuredImage: values.featuredImage,
      metadata: {
        uiTag: values.uiTag,
        details: JSON.stringify(newDetails),
        tag: "soft",
        tagColor: values.tagColor,
      },
    };
    addServices(body)
      .then((res: any) => {
        setLoader(false);
        if (res?.status === 200) {
          toast.success("Service Add Successful");
          history.push("/service");
          onHide();
          getServiceDetails();
        }
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || "Something went wrong.");
        setLoader(false);
      });
  };
  const getImagePreview = (id: any) => {
    setImageSrc(id);
  };

  const defaultDescription =
    params.id && service?.description?.includes("{")
      ? JSON.parse(service?.description)
      : null;

  const defaultDetails =
    params.id && service.metadata?.details?.includes("{")
      ? JSON.parse(service.metadata?.details)
      : null;

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: params.id ? service?.name : "",
      listedPrice: params.id ? service?.listedPrice : "",
      enabled: params.id ? service?.enabled : true,
      descriptionCN: params.id
        ? defaultDescription
          ? defaultDescription?.zh
          : service?.description
        : "",
      descriptionEN: params.id
        ? defaultDescription
          ? defaultDescription?.en
          : service?.description
        : "",
      uiTag: params.id ? service?.metadata?.uiTag : "",
      tagColor: params.id ? service?.metadata?.tagColor : "",
      detailsCN: params.id
        ? defaultDetails
          ? defaultDetails?.zh
          : service?.metadata?.details
        : "",
      detailsEN: params.id
        ? defaultDetails
          ? defaultDetails?.en
          : service?.metadata?.details
        : "",
      discountedPrice: params.id ? service?.discountedPrice : "",
      featuredImage: params.id ? service?.featuredImage : "",
      images: params.id ? service?.images : [],
    },
    validationSchema: Yup.object({
      discountedPrice: Yup.number().required("Discount Price is required"),
      listedPrice: Yup.number().required("Price is required"),
      name: Yup.string().required("Name is required"),
      uiTag: Yup.string().required("Tag is required"),
      tagColor: Yup.string().required("Tag color is required"),
      detailsCN: Yup.string().required("Details is required"),
      detailsEN: Yup.string().required("Details is required"),
      descriptionCN: Yup.string().required("Description is required"),
      descriptionEN: Yup.string().required("Description is required"),
      featuredImage: Yup.string().required("Please select at least one image"),
    }),
    onSubmit: (values: Values) => {
      params.id ? onUpdate(values) : onsubmitData(values);
    },
  });

  const onSwitchAction = () => {
    setIsSwitchOn(!isSwitchOn);
  };

  useEffect(() => {
    if (params.id && formik.values.images) {
      getImagePreview(formik.values.images);
    }
  }, [formik.values.images]);

  const manageImageIds = (id: any) => {
    formik.setFieldValue("featuredImage", id[0]);
    formik.setFieldValue("images", id);
  };
  return (
    <div>
      {loader && <Loader />}
      <Card>
        {loader && <Loader />}
        <CardHeader
          title={params.id ? "Edit Service" : "Create Service"}
        ></CardHeader>
        <CardBody>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="col-lg-4 ws-nowrap">Service Name</div>
              <div className="col-lg-8">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.name && formik.errors.name ? (
                    <div className="text-danger mt-1 ml-1">
                      {formik.errors.name}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="col-lg-4 ws-nowrap">Tag</div>
              <div className="col-lg-8">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    id="uiTag"
                    name="uiTag"
                    value={formik.values.uiTag}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.uiTag && formik.errors.uiTag ? (
                    <div className="text-danger mt-1 ml-1">
                      {formik.errors.uiTag}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="col-lg-4 ws-nowrap">Tag Color</div>
              <div className="col-lg-8">
                <div className="input-group relative d-flex align-items-center">
                  <select
                    name="tagColor"
                    className="form-control"
                    value={formik.values.tagColor}
                    onChange={formik.handleChange}
                  >
                    <option disabled selected value="">
                      Select Tag Color
                    </option>
                    <option value="#000000">Black</option>
                    <option value="#D70000">Red</option>
                  </select>
                  <ExpandMoreRoundedIcon
                    style={{
                      position: "absolute",
                      right: "1rem",
                      // zIndex: 10,
                    }}
                  />
                </div>
                {formik.touched.tagColor && formik.errors.tagColor ? (
                  <div className="text-danger mt-1 ml-1">
                    {formik.errors.tagColor}
                  </div>
                ) : null}
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="col-lg-4 ws-nowrap">Price</div>
              <div className="col-lg-8">
                <div className="form-group">
                  <input
                    type="number"
                    className="form-control"
                    id="listedPrice"
                    name="listedPrice"
                    value={formik.values.listedPrice}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.listedPrice && formik.errors.listedPrice ? (
                    <div className="text-danger mt-1 ml-1">
                      {formik.errors.listedPrice}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="col-lg-4 ws-nowrap">Price Discount</div>
              <div className="col-lg-8 ">
                <div className="form-group">
                  <input
                    type="number"
                    className="form-control"
                    id="discountedPrice"
                    name="discountedPrice"
                    min={0}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.discountedPrice}
                  />
                  {formik.touched.discountedPrice &&
                  formik.errors.discountedPrice ? (
                    <div className="text-danger mt-1 ml-1">
                      {formik.errors.discountedPrice}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>

          <div className="">
            <div className="col-12 col-sm-2 mb-4 mb-sm-0  ws-nowrap">
              Description (Chinese)
            </div>
            <div className="col-10">
              <div className="form-group">
                <textarea
                  className="form-control"
                  id="description"
                  name="descriptionCN"
                  value={formik.values.descriptionCN}
                  onChange={formik.handleChange}
                />
                {formik.touched.descriptionCN && formik.errors.descriptionCN ? (
                  <div className="text-danger mt-1 ml-1">
                    {formik.errors.descriptionCN}
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          <div className="">
            <div className="col-12 col-sm-2 mb-4 mb-sm-0  ws-nowrap">
              Description (English)
            </div>
            <div className="col-10">
              <div className="form-group">
                <textarea
                  className="form-control"
                  id="description"
                  name="descriptionEN"
                  value={formik.values.descriptionEN}
                  onChange={formik.handleChange}
                />
                {formik.touched.descriptionEN && formik.errors.descriptionEN ? (
                  <div className="text-danger mt-1 ml-1">
                    {formik.errors.descriptionEN}
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          <div className="">
            <div className="col-12 col-sm-2 mb-4 mb-sm-0  ws-nowrap">
              Detail (Chinese)
            </div>
            <div className="col-10">
              <div className="form-group">
                <textarea
                  className="form-control"
                  id="details"
                  name="detailsCN"
                  value={formik.values.detailsCN}
                  onChange={formik.handleChange}
                />
                {formik.touched.detailsCN && formik.errors.detailsCN ? (
                  <div className="text-danger mt-1 ml-1">
                    {formik.errors.detailsCN}
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          <div className="">
            <div className="col-12 col-sm-2 mb-4 mb-sm-0  ws-nowrap">
              Detail (English)
            </div>
            <div className="col-10">
              <div className="form-group">
                <textarea
                  className="form-control"
                  id="details"
                  name="detailsEN"
                  value={formik.values.detailsEN}
                  onChange={formik.handleChange}
                />
                {formik.touched.detailsEN && formik.errors.detailsEN ? (
                  <div className="text-danger mt-1 ml-1">
                    {formik.errors.detailsEN}
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-10 col-md-10 col-sm-12">
              <div className="col-lg-4 ws-nowrap">Upload Photo</div>
              <div className="col-lg-8">
                {" "}
                <DragFileUpload
                  isMultipleFileUpload={true}
                  updateFileList={(fileId: any) => manageImageIds(fileId)}
                  imageSrc={imageSrc}
                  showRemoveIcon={false}
                />
                {formik.touched.featuredImage ? (
                  <div className="text-danger mt-1 ml-1">
                    {formik.errors.featuredImage}
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          <div className="row" style={{ marginLeft: "0.5px" }}>
            <div className="mt-4 col-12 col-sm-3 mb-4 mb-sm-0 ws-nowrap d-flex justify-content-between align-items-center">
              <span>Status</span>
              <FormControlLabel
                control={
                  <Switch
                    checked={isSwitchOn}
                    onChange={onSwitchAction}
                    color="primary"
                    name="status"
                  />
                }
                label={isSwitchOn ? "Active" : "Inactive"}
                className="mb-0"
              />
            </div>
          </div>
        </CardBody>

        <CardFooter>
          <div className="row">
            <div className="col-lg-10">
              <div className="d-flex flex-wrap align-items-center justify-content-end">
                <div className="mt-4 mt-sm-0">
                  <button
                    className="btn btn-success mr-2 mx-sm-2"
                    onClick={() => {
                      formik.handleSubmit();
                      formik.setFieldTouched("featuredImage");
                    }}
                  >
                    Submit
                  </button>
                </div>
                <div className="mt-4 mt-sm-0">
                  <Link className="btn btn-car-back" to="/service">
                    Back
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </CardFooter>
      </Card>
    </div>
  );
};

export default AddListService;
