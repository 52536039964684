import React, { useEffect, useState } from "react";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import "react-quill/dist/quill.snow.css";
import { Button, Modal } from "react-bootstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  pushNotificationTags,
  sendPushNotification,
} from "../../../service/carService";
import { toast } from "react-toastify";
import Loader from "../../../common/loader";
import Select from "react-select";

interface Values {
  content: string;
  enabled?: string;
  type?: string;
  expires_at?: any;
  tag: string[];
  title: string;
}

const AddNotification = ({ show, onHide, resetPage }) => {
  const [loader, setLoader] = useState<boolean>(false);
  const [modalShow, setModalShow] = useState(false);
  const [count, setCount] = useState();
  const [tags, setTags] = useState([]);
  const [quary, setQuery] = useState("");

  const getTags = () => {
    pushNotificationTags(quary)
      .then((res: any) => {
        setLoader(false);
        res.data = res.data.map((tagData) => {
          return { value: tagData, label: tagData };
        });
        setTags(res.data);
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || "Something went wrong.");
        setLoader(false);
      });
  };

  const getNotificationCount = (values) => {
    setLoader(true);
    const body = {
      transport: values.type,
      subject: values.title,
      content: values.content,
      to_labels: values.tag.map((tagData) => tagData.value),
      commit: false,
    };
    sendPushNotification(body)
      .then((res: any) => {
        setLoader(true);
        if (res.status === 200) {
          setModalShow(true);
          setCount(res.data.count);
          setLoader(true);
        }
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || "Something went wrong.");
        setLoader(false);
      });
  };

  const handleTagChange = (selectedOption) => {
    if (selectedOption) {
      formik.setFieldValue("tag", selectedOption);
    } else {
      formik.setFieldValue("tag", []);
    }
  };

  const onSend = (values) => {
    const body = {
      transport: values.type,
      subject: values.title,
      content: values.content,
      to_labels: values.tag.map((tagData) => tagData.value),
      commit: true,
    };
    sendPushNotification(body)
      .then((res: any) => {
        setLoader(false);
        if (res.status === 200) {
          onHide();
          toast.success("Send Notification Successful");
          resetPage();
        }
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || "Something went wrong.");
        setLoader(false);
      });
  };

  useEffect(() => getTags(), [quary]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      content: "",
      enabled: "",
      type: "",
      tag: [],
      title: "",
    },
    validationSchema: Yup.object({
      type: Yup.string().required("Type is required"),
      tag: Yup.array()
        .nullable()
        .required("Tag is required")
        .min(1, "Atleast choose one tag"),
      title: Yup.string().when("type", {
        is: "push",
        then: Yup.string().required("Title is required"),
      }),
      content: Yup.string().required("Description is required"),
    }),
    onSubmit: (values: Values) => {
      getNotificationCount(values);
    },
  });

  const closeConfirmDialog = () => setModalShow(false);

  return (
    <div>
      {loader && <Loader />}
      <Modal
        centered
        show={show}
        onHide={onHide}
        aria-labelledby="example-modal-sizes-title-lg"
        className="service-modal"
      >
        <Modal.Body>
          <div className="d-flex justify-content-between">
            <div className="modal-header-text px-lg-12">Push Notification</div>
            <div className="modal-close" onClick={onHide}>
              x
            </div>
          </div>
          <div className="row justify-content-center mt-10">
            <div className="col-lg-10">
              {" "}
              <p className="purchase-edit-lable">App</p>
              <div className="input-group mt-2 relative d-flex align-items-center">
                <select
                  className="form-control top-select rounded"
                  style={{ paddingRight: "3rem", appearance: "button" }}
                  name="type"
                  value={formik.values.type}
                  onChange={formik.handleChange}
                >
                  <option value="" disabled>
                    type
                  </option>
                  <option value={"sms"}>SMS</option>
                  <option value={"push"}>APP</option>
                </select>
              </div>
              {formik.touched.type && formik.errors.type ? (
                <div className="text-danger mt-1 ml-1">
                  {formik.errors.type}
                </div>
              ) : null}
            </div>
            {formik.values.type === "push" && (
              <div className="col-lg-10 ">
                <p className="purchase-edit-lable">Title</p>
                <input
                  type="text"
                  className="form-control mt-2"
                  placeholder="Title"
                  name="title"
                  value={formik.values.title}
                  onChange={formik.handleChange}
                />
                {formik.touched.title && formik.errors.title ? (
                  <div className="text-danger mt-1 ml-1">
                    {formik.errors.title}
                  </div>
                ) : null}
              </div>
            )}
            <div className="col-lg-10 mt-4">
              <p className="purchase-edit-lable">Tag</p>
              <div className="input-group relative d-flex align-items-center">
                <Select
                  isMulti
                  name="tag"
                  options={tags}
                  closeMenuOnSelect={false}
                  onChange={handleTagChange}
                  onInputChange={(e) => setQuery(e)}
                  value={formik.values.tag}
                  className="basic-multi-select w-100 mt-2"
                  classNamePrefix="select"
                  placeholder="Select Tags"
                />
              </div>
              {formik.touched.tag && formik.errors.tag ? (
                <div className="text-danger mt-1 ml-1">{formik.errors.tag}</div>
              ) : null}
            </div>
            <div className="col-lg-10 mt-4">
              {quary}
              <p className="purchase-edit-lable">Description</p>
              <textarea
                className="form-control mt-2"
                placeholder="Description"
                name="content"
                rows={5}
                cols={5}
                value={formik.values.content}
                onChange={formik.handleChange}
              />
              {formik.touched.content && formik.errors.content ? (
                <div className="text-danger mt-1 ml-1">
                  {formik.errors.content}
                </div>
              ) : null}
            </div>

            <div className="col-lg-10 mt-4 mb-4">
              <button
                type="submit"
                className="btn btn-car-primary "
                onClick={() => formik.handleSubmit()}
              >
                Send
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={modalShow} onHide={closeConfirmDialog} centered size="sm">
        <Modal.Header closeButton>
          <Modal.Title>Notification Confirm</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {formik.values.type == "sms"
            ? `Please confirm send SMS to ${count} users with the tag ${formik.values.tag
                .map((tagData: any) => tagData.value)
                .join(", ")}.`
            : `Please confirm push the message to ${count} users with the tag ${formik.values.tag
                .map((tagData: any) => tagData.value)
                .join(", ")}`}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeConfirmDialog}>
            Close
          </Button>
          <Button variant="primary" onClick={() => onSend(formik.values)}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AddNotification;
