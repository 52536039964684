import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import {
  addBranch,
  getBranchsDetails,
  updateBooking,
} from "../../../service/carService";
import Loader from "../../../common/loader";
import Select from "react-select";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import * as Yup from "yup";

const ChangeRemarksModal = ({
  show,
  onHide,
  onMainHide,
  //   serviceProduct,
  id,
  getCalenderLatestData,
}) => {
  const [loading, setLoading] = useState<boolean>(false);

  const remarksUpdate = () => {
    const body = {
      remarks: formik.values.remarks,
    };

    updateBooking(body, id)
      .then((res: any) => {
        setLoading(false);
        if (res.data.status) {
          toast.success("Remarks Updated successfully");
          onHide(true);
          onMainHide(true);
          getCalenderLatestData();
        }
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || "Something went wrong.");
        setLoading(false);
      });
  };

  const changeBranchSchema = Yup.object().shape({
    remarks: Yup.string().required("remarks is required"),
  });

  const formik = useFormik({
    initialValues: {
      remarks: "",
    },

    validationSchema: changeBranchSchema,

    onSubmit: () => {
      remarksUpdate();
    },
  });

  return (
    <div>
      {loading && <Loader />}
      <Modal
        centered
        show={show}
        onHide={() => {
          onHide();
          onMainHide(true);
        }}
        dialogClassName="modal-90w"
        // size="sm"
        aria-labelledby="example-modal-sizes-title-sm"
        className="service-modal"
      >
        <Modal.Body className="show-grid">
          <div className="d-flex justify-content-between">
            <div className="modal-header-text">Change Remarks</div>
            <div
              className="modal-close"
              onClick={() => {
                onHide();
                onMainHide(true);
              }}
            >
              x
            </div>
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="ws-nowrap">Remarks</div>
            <div>
              <textarea
                className="form-control resize-none"
                name="remarks"
                value={formik.values.remarks}
                onChange={formik.handleChange}
              />
            </div>
            {formik.touched.remarks && formik.errors.remarks ? (
              <div className="text-danger mt-1 ml-1">
                {formik.errors.remarks}
              </div>
            ) : null}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex flex-wrap align-items-center justify-content-between">
            <div className="mt-4 mt-sm-0">
              <button
                className="btn btn-success mr-2 mx-sm-2"
                onClick={() => formik.handleSubmit()}
              >
                Save
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ChangeRemarksModal;
