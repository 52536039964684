import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { updateBooking } from "../../../service/carService";
import Loader from "../../../common/loader";

import { toast } from "react-toastify";

const ChangeStatusToLateModal = ({
  show,
  onHide,
  onMainHide,
  id,
  getCalenderLatestData,
}) => {
  const [loading, setLoading] = useState<boolean>(false);

  const updateStatus = () => {
    const body = {
      late: true,
    };

    updateBooking(body, id)
      .then((res: any) => {
        setLoading(false);
        if (res.data.status) {
          toast.success("status Updated successfully");
          onHide(true);
          onMainHide(true);
          getCalenderLatestData();
        }
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || "Something went wrong.");
        setLoading(false);
      });
  };

  return (
    <div>
      {loading && <Loader />}
      <Modal
        centered
        show={show}
        onHide={() => {
          onHide();
          onMainHide(true);
        }}
        dialogClassName="modal-90w"
        // size="sm"
        aria-labelledby="example-modal-sizes-title-sm"
        className="service-modal"
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">Confirm</Modal.Title>
        </Modal.Header>
        <Modal.Body className="show-grid">
          <div>Please confirm mark late for this booking</div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex flex-wrap align-items-center justify-content-between">
            <div className="mt-4 mt-sm-0">
              <button
                className="btn btn-secondary mr-2 mx-sm-2"
                onClick={() => {
                  onHide();
                  onMainHide(true);
                }}
              >
                Back
              </button>
              <button
                className="btn btn-primary mr-2 mx-sm-2"
                onClick={() => updateStatus()}
              >
                confirm
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ChangeStatusToLateModal;
