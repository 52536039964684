import React, { FC, useState, useEffect } from "react";
import SVG from "react-inlinesvg";
import {
  toAbsoluteUrl,
  headerSortingClasses,
  sortCaret,
} from "../../../helpers";
import { Card, CardBody, CardHeader } from "../../../partials/controls";
import { SizePerPage } from "../../../common/table/filters/SizePerPage";
import { Table } from "../../../common/table";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { FilterQuery } from "../../../common/table/filters/FilterQuery";
import Loader from "../../../common/loader";
import { getBlacklist, deleteBlackListData } from "../../../service/carService";
import { toast } from "react-toastify";
import { sortServerString } from "../../../common/table/sorter";
import { PaginationLinks } from "../../../common/pagination/PaginationLinks";
import { PaginationToolbar } from "../../../common/pagination/PaginationToolbar";
import Addblacklist from "./addblacklist";

const Blacklist: FC = () => {
  const [query, setQuery] = useState<string>("");
  const [totalData, setTotalData] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [entities, setEntities] = useState<any>([]);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [deletedata, setDeleteData] = useState(false);
  const [black, setBlack] = useState(false);
  const [sortingType, setSortingType] = useState<any>("");

  const columns = [
    // {
    //   dataField: "index",
    //   text: "Number",
    //   sort: true,
    //   sortCaret: sortCaret,
    //   headerSortingClasses,
    //   onSort: (field: string, order: string) =>
    //     sortServerString(field, order, setSortingType),
    // },

    {
      dataField: "phone",
      text: "Phone Number",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      onSort: (field: string, order: string) =>
        sortServerString(field, order, setSortingType),
    },

    {
      dataField: "action",
      text: "Action",
      sort: true,
    },
  ];

  const deleteBlackList = (e, data) => {
    e.preventDefault();
    e.stopPropagation();
    setDeleteData(true);
    deleteBlackListData(data._id)
      .then((res: any) => {
        if (res.data) {
          toast.success("BlackList Delete Successful");
          getBlackLists();
          setDeleteData(false);
        }
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || "Something went wrong.");
        console.log("err");
      });
  };

  const getBlackLists = (page = 1, sizePerPage = 10, showLoading = true) => {
    if (showLoading) {
      setLoading(true);
    }
    const isSortColumn =
      sortingType !== "" ? `&${sortingType}` : `&sort={"created_at":-1} `;

    getBlacklist(page, sizePerPage, query, isSortColumn)
      .then((res: any) => {
        setLoading(false);
        let news: any = [];
        res?.data?.docs.map((data, index) => {
          const Obj = {
            index: ++index + (page - 1) * sizePerPage,
            _id: data._id,
            phone: data.phone,
            name: data.name,
            homeDistrict: data.homeDistrict,
            action: (
              <div onClick={(e) => deleteBlackList(e, data)}>
                <img
                  alt="Logo"
                  className="max-h-30px"
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Communication/trash.svg"
                  )}
                />
              </div>
            ),
          };
          news.push(Obj);
        });
        setEntities(news);
        setTotalData(res?.data?.total);
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || "Something went wrong.");
        setLoading(false);
      });
  };

  // useEffect(() => {
  //   getBlackLists(1, 10, false);

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [sortingType]);

  const customTotal = (from: any, to: any, size: any) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing rows {totalData === 0 ? 0 : (page - 1) * sizePerPage + 1} to {to}{" "}
      of {totalData}
    </span>
  );

  const onTableChange = async (type: any, props: any, paginationProps: any) => {
    if (type !== "sort") {
      setSizePerPage(props.sizePerPage);
      if (props.sizePerPage > paginationProps.totalSize) {
        setPage(1);
        getBlackLists(1, props.sizePerPage);
        setPage(1);
      } else {
        setPage(props.page);
        getBlackLists(props.page, props.sizePerPage);
      }
    }
  };

  const handleSizePerPage = (
    { page, onSizePerPageChange }: any,
    newSizePerPage: any
  ) => {
    onSizePerPageChange(newSizePerPage, page);
  };

  const paginationOptions = {
    custom: true,
    totalSize: totalData,
    hideSizePerPage: true,
    showTotal: true,
    page,
    sizePerPage,
    paginationTotalRenderer: customTotal,
  };

  const getSelectRow = {
    mode: "radio",
    clickToSelect: true,
    hideSelectColumn: true,
    // onSelect: (data: ArticleEntities) =>
    //   deletedata === false ? history.push(`/blacklist/edit`) : "",
  };

  useEffect(() => {
    getBlackLists(1, 10, false);
  }, [query, sortingType]);

  const onAddList = () => {
    setBlack(true);
  };

  return (
    <Card>
      {loading && <Loader />}
      <CardHeader title="Blacklist">
        <div className="d-flex align-items-center">
          <a
            title="Edit customer"
            className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
            role="button"
            onClick={() => {
              getBlackLists();
            }}
          >
            <span className="svg-icon svg-icon-md svg-icon-primary">
              <SVG src={toAbsoluteUrl("/media/svg/icons/reload.svg")} />
            </span>
          </a>
        </div>
      </CardHeader>
      <CardBody>
        <PaginationProvider pagination={paginationFactory(paginationOptions)}>
          {({ paginationProps, paginationTableProps }) => (
            <>
              <div className="d-flex ">
                <div className="d-flex flex-column flex-md-row flex-sm-column mb-10">
                  <FilterQuery
                    query={query}
                    setQuery={(e) => {
                      setQuery(e);
                      setPage(1);
                    }}
                  />
                </div>
                <div className="mt-4">
                  <button className="btn btn-primary" onClick={onAddList}>
                    New Blacklist
                  </button>
                </div>
              </div>
              <Table
                getSelectRow={getSelectRow}
                paginationProps={paginationProps}
                paginationOptions={paginationOptions}
                paginationTableProps={paginationTableProps}
                data={entities}
                columns={columns}
                remote
                onTableChange={(type: any, props: any) =>
                  onTableChange(type, props, paginationProps)
                }
              />
              <div className="d-flex justify-content-between align-items-center flex-wrap">
                <div>
                  <PaginationLinks paginationProps={paginationProps} />
                </div>
                <div className="d-flex">
                  <SizePerPage
                    paginationProps={paginationProps}
                    handleSizePerPage={handleSizePerPage}
                    entities={entities}
                  />
                  <PaginationToolbar paginationProps={paginationProps} />
                </div>
              </div>
            </>
          )}
        </PaginationProvider>
      </CardBody>
      {black && (
        <Addblacklist
          show={black}
          onHide={() => setBlack(false)}
          id={""}
          getBlackLists={getBlackLists}
        />
      )}
    </Card>
  );
};

export default Blacklist;
