import React, { useState, useEffect } from "react";
import "react-quill/dist/quill.snow.css";
import { CKModules } from "../../../common/editor/ckModules";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useFormik } from "formik";
import {
  addLatestNews,
  getOneNews,
  updateNews,
} from "../../../service/carService";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useHistory } from "react-router";
import { Form, Modal } from "react-bootstrap";
import moment from "moment";
import Loader from "../../../common/loader";
import { makeStyles } from "@material-ui/core/styles";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Switch from "@material-ui/core/Switch";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";

interface Values {
  subject: string;
  content: string;
  enabled?: string;
  type?: string;
  expires_at?: any;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  formControl: {
    margin: theme.spacing(3),
  },
  group: {
    margin: theme.spacing(1, 0),
  },
}));

const AddNews = ({ id, show, onHide, getLatestNews }) => {
  const history = useHistory();
  const [loader, setLoader] = useState<boolean>(false);
  const [news, setNews] = useState<any>({});
  const [isSwitchOn, setIsSwitchOn] = useState(true);

  const classes = useStyles();

  useEffect(() => {
    if (id) {
      getOneNewsDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getOneNewsDetails = () => {
    setLoader(true);
    getOneNews(id)
      .then((res: any) => {
        setLoader(false);
        setNews(res?.data);
        setIsSwitchOn(res?.data?.enabled);
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || "Something went wrong.");
        setLoader(false);
      });
  };

  const onUpdate = (value: Values) => {
    const body = {
      subject: value.subject,
      content: value.content,
      enabled: isSwitchOn,
      type: value.type,
      expires_at: moment(value.expires_at).toISOString(),
    };
    setLoader(true);
    updateNews(id, body)
      .then((res: any) => {
        setLoader(false);
        if (res.status === 200) {
          toast.success("Latest News Edit Successful");
          history.push("/latestNews");
          onHide(true);
          getLatestNews();
        }
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || "Something went wrong.");
        setLoader(false);
      });
  };

  const onSubmitData = (value: Values) => {
    const body = {
      subject: value.subject,
      content: value.content,
      enabled: isSwitchOn,
      type: value.type,
      expires_at: moment(value.expires_at).toISOString(),
    };
    setLoader(true);
    addLatestNews(body)
      .then((res: any) => {
        setLoader(false);
        if (res.status === 200) {
          toast.success("Latest News Add Successful");
          history.push("/latestNews");
          onHide(true);
          getLatestNews();
        }
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || "Something went wrong.");
        setLoader(false);
      });
  };

  const handleChange = (e, editor) => {
    const data = editor.getData();
    formik.setFieldValue("content", data);
  };

  const LatestSchema = Yup.object().shape({
    enabled: Yup.string().required("status is required"),
  });
  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: LatestSchema,
    initialValues: {
      subject: id ? (news?.subject as string) : "",
      content: id ? (news?.content as string) : "",
      enabled: id ? (news?.enabled as string) : "",
      type: id ? (news?.type as string) : "",
      expires_at: id ? news?.expires_at : null,
    },
    onSubmit: (values: Values) => {
      id ? onUpdate(values) : onSubmitData(values);
    },
  });

  const onSwitchAction = () => {
    setIsSwitchOn(!isSwitchOn);
  };

  const handleDateChange = (dateValue) => {
    formik.setFieldValue("expires_at", dateValue);
  };

  return (
    <div>
      {loader && <Loader />}
      <Modal
        centered
        show={show}
        onHide={onHide}
        aria-labelledby="example-modal-sizes-title-lg"
        className="service-modal"
      >
        <Modal.Body>
          <div className="d-flex justify-content-between px-10">
            <div className="modal-header-text px-lg-5">
              {id ? "Edit " : "Create"} News
            </div>
            <div className="modal-close" onClick={() => onHide(false)}>
              x
            </div>
          </div>
          <div className="row justify-content-center mt-10">
            <div className="col-lg-10">
              {" "}
              <div className="form-group">
                <div className="text-muted">Title</div>
                <input
                  type="text"
                  className="form-control border-0"
                  id="title"
                  placeholder="Title"
                  name="subject"
                  value={formik.values.subject}
                  onChange={formik.handleChange}
                />
              </div>
            </div>
            <div className="col-lg-10 mt-2">
              {" "}
              <div className="">
                <div className="">
                  <div>
                    <div className="text-muted">Content </div>
                    <CKEditor
                      editor={ClassicEditor}
                      config={{ CKModules, placeholder: "Content" }}
                      className="ck"
                      placeHolder="Content"
                      style={{ border: "0" }}
                      onChange={handleChange}
                      data={formik.values.content || ""}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-10 mt-4 d-flex">
              <div className="form-group">
                <div className="text-muted">Deadline</div>
                <Datetime
                  inputProps={{
                    name: "expires_at",
                    placeholder: "dd/MM/yyyy",
                  }}
                  value={moment(formik.values.expires_at)}
                  timeFormat={false}
                  dateFormat={"D/M/YYYY"}
                  onChange={handleDateChange}
                  closeOnSelect={true}
                  className="border-0"
                />
              </div>
            </div>
            <div className="col-lg-10 mt-3">
              {" "}
              <div className="d-flex">
                <RadioGroup
                  row
                  name="type"
                  className={classes.group}
                  value={formik.values.type}
                  onChange={formik.handleChange}
                >
                  <FormControlLabel
                    value="news"
                    control={<Radio color="primary" />}
                    label="Normal"
                    checked={formik.values.type === "news" ? true : false}
                  />
                  <FormControlLabel
                    value="announcement"
                    control={<Radio color="primary" />}
                    checked={
                      formik.values.type === "announcement" ? true : false
                    }
                    label="Announcement"
                  />
                </RadioGroup>
              </div>
            </div>
            <div className="col-lg-10 mt-4">
              {" "}
              <FormControlLabel
                control={
                  <Switch
                    onChange={onSwitchAction}
                    color="primary"
                    id="custom-switch"
                    checked={isSwitchOn}
                  />
                }
                label={isSwitchOn ? "Active" : "Inactive"}
              />
            </div>
            <div className="col-lg-10 mt-7 mb-4">
              <button
                type="submit"
                className="btn btn-car-primary"
                onClick={
                  id !== undefined
                    ? () => onUpdate(formik.values)
                    : () => onSubmitData(formik.values)
                }
              >
                {id ? "Save" : "Create"}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AddNews;
