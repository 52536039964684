import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import moment from "moment";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
} from "../../../partials/controls";
import { Table } from "react-bootstrap";
import { TimePicker } from "antd";
import Datetime from "react-datetime";
import "react-quill/dist/quill.snow.css";
import {
  computeAmountPayable,
  getDownloadImage,
  getOneOrderDetail,
  getPackageSpecified,
  getServiceProductDetails,
} from "./../../../service/carService";
import { toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Loader from "../../../common/loader";
import { numberFormater } from "../../../common/thousandSeperator/defaultFunction";

function sumArray(array: any = []) {
  let sum = 0;
  for (let i = 0; i < array.length; i += 1) {
    sum += array[i];
  }

  return sum;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  formControl: {
    margin: theme.spacing(3),
  },
  group: {
    margin: theme.spacing(1, 0),
  },
}));

const OrderEdit = () => {
  const location = useLocation();
  const [loader, setLoader] = useState<boolean>(false);
  const [id, setId] = useState("");
  const [type, setType] = useState("");
  const [hairLength, setHairLength] = useState<any>();
  const [orderDetail, setOrderDetail] = useState<any>({});
  const [radio, setRadio] = useState(false);
  const [amount, setAmount] = useState<any>([]);
  const [imageSrc, setImageSrc] = useState<string>();
  const [serviceProductData, setServiceProductData] = useState<any>();
  const [discount, setDiscount] = useState(0);
  const [serviceProductAmount, setServiceProductAmount] = useState<number>();
  const [adminDiscount, setAdminDiscount] = useState<any>(100);

  const classes = useStyles();

  useEffect(() => {
    let data: any = location.state;
    let type = data.type;
    let id = data.id;

    setId(id);
    setType(type);
  }, [location]);

  const getOrderDetail = () => {
    setLoader(true);
    getOneOrderDetail(id, type)
      .then((res: any) => {
        let details: any = {};
        const booking = res.data;
        const discountVal = booking.otherDiscount?.percentageDiscount;
        setRadio(discountVal !== 0 ? true : false || true);
        setAdminDiscount(res?.data?.adminDeduct || 0);
        if (type == "service") {
          //  let usePackage

          let status = "";
          if (res?.data.status === "99$completed") {
            status = "Completed";
          } else if (res?.data.status === "98$absent") {
            status = "Absent";
          } else if (res?.data.status === "97$cancelled") {
            status = "Cancel";
          } else if (res?.data.status === "1$arrived") {
            status = "Arrived Branch";
          } else if (res?.data.status === "0$created") {
            status = "Booked";
          }

          let packageSpecified = res?.data.serviceDetails.packageSpecified;

          details = {
            id: id || "",
            bookingDate: res?.data.time.start || "",
            branch: res?.data.serviceDetails?.masterSpecified?.name || "",
            userName: res?.data.created_by?.name || "",
            plateNumber: res?.data.carPlate || "",
            status: status,
            usePackage: packageSpecified ? "yes" : "no",
            discount: res?.data?.otherDiscount?.percentageDiscount * 100 || "",
            product:
              res?.data.purchasedProducts &&
              res?.data.purchasedProducts.length > 0
                ? res?.data.purchasedProducts
                : null,
            totalAmount: res?.data.amountPayable || "",
            remark: res?.data.remarks || "",
            paymentMethod: res.data.paymentMethod || "",
          };

          if (packageSpecified) {
            getPackageSpecified(packageSpecified).then(
              (packageResponse: any) => {
                details["package"] = packageResponse?.data;
                setOrderDetail(details);
                setDiscount(details?.discount);
                if (res?.data?.serviceDetails?.serviceProduct) {
                  setServiceProductData(
                    res?.data?.serviceDetails?.serviceProduct
                  );
                } else {
                  getServiceProductData(
                    res?.data?.serviceDetails?.serviceProduct ||
                      "6458b3f73daa878c690cdd76"
                  );
                }
                // getServiceProductData(
                //   res?.data?.serviceDetails?.serviceProduct
                // );
                // getServiceProductData("6458b3f73daa878c690cdd76");
                getCompletedServiceProductAmount(res?.data);
              }
            );
          } else {
            setOrderDetail(details);
            setDiscount(details?.discount);
            if (res?.data?.serviceDetails?.serviceProduct) {
              setServiceProductData(res?.data?.serviceDetails?.serviceProduct);
            } else {
              getServiceProductData(
                res?.data?.serviceDetails?.serviceProduct ||
                  "6458b3f73daa878c690cdd76"
              );
            }
            // getServiceProductData(res?.data?.serviceDetails?.serviceProduct);
            // getServiceProductData("6458b3f73daa878c690cdd76");
            getCompletedServiceProductAmount(res?.data);
          }
        } else {
          let imgId =
            res?.data.payment?.gateway_result?.clientInfo?.attachments;

          getDownloadImage(imgId)
            .then((res: any) => {
              setImageSrc(res?.config?.url);
            })
            .catch((err) => {
              setImageSrc("/media/image/image-not-found.jpeg");
            });

          details = {
            id: id || "",
            packageName: `${res?.data.product.name} * 10` || "",
            userName: res?.data.created_by.name || "",
            service: res?.data.product.name || "",
            phone: res?.data.created_by.phone || "",
            amount: res?.data.amountPayable || "",
            paymentMedhod:
              res?.data?.payment.gateway === "manual"
                ? "FPS / Bank Transfer"
                : "Visa / MasterCard",
          };
          setOrderDetail(details);
          setDiscount(details?.discount);
        }
        setLoader(false);
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || "Something went wrong.");
        setLoader(false);
      });
  };

  useEffect(() => {
    if (id !== "") getOrderDetail();
  }, [id, type, imageSrc]);

  const getServiceProductData = (id) => {
    getServiceProductDetails(id)
      .then((res: any) => {
        setServiceProductData(res?.data);
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || "Something went wrong.");
      });
  };

  const getCompletedServiceProductAmount = (bookingDetail) => {
    let body: any = {
      serviceDetails: {
        serviceProduct: "6458b3f73daa878c690cdd76", //bookingDetail?.serviceDetails?.serviceProduct,
        packageSpecified: bookingDetail?.serviceDetails?.packageSpecified,
        masterSpecified: bookingDetail?.serviceDetails?.masterSpecified.id,
        isRedo: false,
        needHairCut: false,
        needExtraFeeForMasterSpecified: false,
      },
      adminDeduct: Number(adminDiscount || 0),
    };

    if (
      bookingDetail?.otherDiscount &&
      Object.keys(bookingDetail?.otherDiscount).length &&
      bookingDetail?.otherDiscount.percentageDiscount !== 0 &&
      bookingDetail?.otherDiscount.percentageDiscount !== 1
    ) {
      body = {
        ...body,
        otherDiscount: bookingDetail?.otherDiscount,
      };
    }

    computeAmountPayable(body)
      .then((res: any) => {
        setAmount(res?.data);
        setHairLength(res?.data?.differentHairLength);
        setServiceProductAmount(res?.data?.amountPayable);
      })
      .catch((err: any) => {
        toast.error(err.response?.data?.message || "Something went wrong.");
      });
  };

  const serviceAmounts = orderDetail?.product?.map(
    (x: any) => x?.amountPayable
  );

  const subTotalAmountValue: any =
    Number(
      hairLength && Object.keys(hairLength).length && radio === false
        ? hairLength.fee > 0
          ? numberFormater(hairLength.fee * hairLength.multiplier)
          : 0
        : numberFormater(serviceProductData?.listedPrice)
    ) + sumArray(serviceAmounts);

  const discountAmount =
    (Number(
      hairLength && Object.keys(hairLength).length && radio === false
        ? hairLength.fee > 0
          ? numberFormater(hairLength.fee * hairLength.multiplier)
          : 0
        : numberFormater(serviceProductData?.listedPrice)
    ) /
      100) *
    Number(discount / 100);

  const defaultBeranchName = orderDetail?.branch?.includes("{")
    ? JSON.parse(orderDetail?.branch)
    : null;
  return (
    <div className={type === "package" ? "d-flex justify-content-center" : ""}>
      {id !== "" && (
        <div className={type === "package" ? "w-50" : ""}>
          <Card>
            {loader && <Loader />}
            <CardHeader title={"Order Info"}></CardHeader>
            <CardBody>
              {type === "package" ? (
                <>
                  <div className="row my-4 ">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <b className="col-lg-4 ws-nowrap">Package Info</b>
                    </div>
                  </div>
                  <div className="row justify-content-between px-10">
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="col-lg-4 ws-nowrap">Package ID</div>
                        <div className="col-lg-12">
                          {" "}
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              id="id"
                              value={orderDetail.id}
                              readOnly
                              name="id"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="col-lg-4 ws-nowrap">Package Name</div>
                        <div className="col-lg-12">
                          {" "}
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              id="packageName"
                              name="packageName"
                              value={orderDetail.packageName}
                              readOnly
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="col-lg-4 ws-nowrap">Username</div>
                        <div className="col-lg-12">
                          {" "}
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              id="userName"
                              name="userName"
                              value={orderDetail.userName}
                              readOnly
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="col-lg-4 ws-nowrap">Phone Number</div>
                        <div className="col-lg-12">
                          {" "}
                          <div className="form-group">
                            <input
                              type="string"
                              className="form-control"
                              id="phone"
                              value={orderDetail.phone}
                              name="phone"
                              readOnly
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="col-lg-4 ws-nowrap">Reciept</div>

                        <div className="col-lg-12">
                          <div
                            style={{
                              width: "160px",
                              borderRadius: "10px",
                              overflow: "hidden",
                            }}
                          >
                            <img
                              alt="package"
                              className="w-100 h-100 max-h-150px"
                              src={imageSrc}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="col-lg-12 ws-nowrap">
                          Payment Method
                        </div>
                        <div className="col-lg-12">
                          {" "}
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              id="service"
                              name="service"
                              value={orderDetail.paymentMedhod}
                              readOnly
                            />
                          </div>
                        </div>

                        <div className=" mt-4">
                          <div className="col-12 col-sm-2 mb-4 mb-sm-0  ws-nowrap">
                            Amount
                          </div>
                          <div className="col-12">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                id="reason"
                                name="reason"
                                value={`$${numberFormater(orderDetail.amount)}`}
                                readOnly
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="row my-4 ">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <b className="col-lg-4 ws-nowrap">Booking Info</b>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div className="col-lg-4 ws-nowrap">Booking Id</div>
                      <div className="col-lg-10">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            id="title"
                            name="title"
                            value={orderDetail.id}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div className="col-lg-4 ws-nowrap">Username</div>
                      <div className="col-lg-10">
                        {" "}
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            id="username"
                            name="username"
                            value={orderDetail.userName}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div className="col-lg-4 ws-nowrap">Service Date</div>
                      <div className="col-lg-10">
                        <div className="form-group">
                          <input
                            disabled
                            type="text"
                            className="form-control"
                            id="date"
                            name="date"
                            value={moment(orderDetail.bookingDate).format(
                              "DD-MM-YYYY"
                            )}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div className="col-lg-4 ws-nowrap">Service Time</div>
                      <div className="col-lg-10">
                        <div className="form-group">
                          <input
                            disabled
                            type="text"
                            className="form-control"
                            id="date"
                            name="date"
                            value={moment(orderDetail.bookingDate).format(
                              "HH:mm"
                            )}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div className="col-lg-4 ws-nowrap">Plate Number</div>
                      <div className="col-lg-10">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            id="plateNo"
                            name="plateNo"
                            value={orderDetail.plateNumber}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div className="col-lg-4 ws-nowrap">Branch</div>
                      <div className="col-lg-10">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            id="branch"
                            name="branch"
                            value={
                              defaultBeranchName
                                ? defaultBeranchName?.en
                                : orderDetail.branch
                            }
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* NEW SERVICE */}
                  <div className="row my-4 ">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <b className="col-lg-4 ws-nowrap">Service Bill </b>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div className="col-lg-4 ws-nowrap">Service</div>
                      <div className="col-lg-10">
                        <div className="form-group">
                          <input
                            disabled
                            className="form-control top-select rounded"
                            name="productName"
                            value={serviceProductData?.name}
                            style={{ paddingRight: "3rem" }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div className="col-lg-4 ws-nowrap">Amount</div>
                      <div className="col-lg-10">
                        <input
                          disabled
                          type="text"
                          min={0}
                          className="form-control"
                          id="amount"
                          name="productPrice"
                          value={
                            hairLength &&
                            Object.keys(hairLength).length &&
                            radio === false
                              ? hairLength.fee > 0
                                ? `$${numberFormater(
                                    hairLength.fee * hairLength.multiplier
                                  )}`
                                : `$0`
                              : `$${numberFormater(
                                  serviceProductData?.listedPrice
                                )}`
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div className="col-lg-4 ws-nowrap">Use package</div>
                      <div className="col-lg-10">
                        <div className="">
                          <RadioGroup
                            row
                            name="radio"
                            className={classes.group}
                            value={orderDetail.usePackage}
                          >
                            <FormControlLabel
                              value={"yes"}
                              control={<Radio color="primary" />}
                              label={"Yes"}
                              checked={radio === false}
                              onClick={() => {
                                setRadio(false);
                              }}
                              disabled
                            />
                            <FormControlLabel
                              value={"no"}
                              control={<Radio color="primary" />}
                              label={"No"}
                              checked={radio === true}
                              onClick={() => {
                                setRadio(true);
                              }}
                              disabled
                            />
                          </RadioGroup>
                        </div>
                      </div>
                    </div>
                    {radio === false ? (
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="col-lg-4 ws-nowrap">Package</div>
                        <div className="col-lg-10">
                          {" "}
                          <div className="input-group relative d-flex align-items-center">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                id="package"
                                name="package"
                                value={orderDetail.package?.product?.name}
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="col-lg-4 ws-nowrap">Discount Off</div>
                        <div className="col-lg-6">
                          <div className="form-group d-flex align-items-baseline">
                            <input
                              type="number"
                              className="form-control"
                              id="discount"
                              name="discount"
                              min={1}
                              max={100}
                              value={orderDetail.discount / 100}
                              disabled
                            />
                            <h4>%</h4>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="row my-4 ">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <b className="col-lg-4 ws-nowrap">Product Bill</b>
                    </div>
                  </div>

                  {orderDetail?.product?.length ? (
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="col-lg-4 ws-nowrap">Product</div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12 d-flex">
                        <div className="col-lg-4 col-md-6 col-sm-6 col-6 ws-nowrap">
                          <div className="">Quantity</div>
                        </div>
                        <div className="col-lg-4 col-md-5 col-sm-6 col-5 ws-nowrap">
                          <div className="">Amount</div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="col-lg-4 ws-nowrap">No Product</div>
                      </div>
                    </div>
                  )}

                  {orderDetail.product !== null &&
                    orderDetail.product &&
                    orderDetail.product.length > 0 &&
                    orderDetail?.product.map((prod, index) => {
                      return (
                        <div className="row" key={index}>
                          <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="col-lg-10">
                              <div className="input-group relative d-flex align-items-center">
                                <div className="form-group w-100">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="product"
                                    name={`product`}
                                    value={prod.product.name}
                                    disabled
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-12 d-flex">
                            <div className="col-lg-4 col-md-6 col-sm-5 col-6  ws-nowrap ">
                              <div className="">
                                <div className="form-group">
                                  <input
                                    type="number"
                                    className="form-control"
                                    id="quantity"
                                    name={`quantity`}
                                    value={prod.qty}
                                    disabled
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="col-lg-6  col-md-6 col-sm-6 col-6 ws-nowrap">
                              <div className="form-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="amount"
                                  name={`amount`}
                                  value={`$${numberFormater(
                                    prod.amountPayable
                                  )}`}
                                  disabled
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  <div className="row flex-end d-flex">
                    <div className="col-lg-6 col-md-6 col-sm-12"></div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <Table
                        className="col-lg-10 justify-content-end mt-3 "
                        bordered={false}
                        variant="light"
                        style={{ backgroundColor: "#ffffff" }}
                      >
                        <tbody>
                          <tr>
                            <th>Subtotal:</th>
                            <td style={{ textAlign: "right" }}>
                              ${numberFormater(subTotalAmountValue)}
                            </td>
                          </tr>
                          <tr>
                            <th>Discount (Service):</th>
                            <td style={{ textAlign: "right" }}>
                              $
                              {discount && discount != 0
                                ? numberFormater(discountAmount)
                                : 0}
                            </td>
                          </tr>

                          <tr>
                            <th>Cash Discount:</th>
                            <td
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                alignItems: "center",
                                textAlign: "right",
                              }}
                            >
                              $
                              <input
                                disabled
                                type="number"
                                style={{
                                  textAlign: "right",
                                  maxWidth: 100,
                                  marginLeft: 8,
                                }}
                                className="form-control pr-0"
                                id="id"
                                name="id"
                                value={adminDiscount}
                                onChange={(e: any) => {
                                  setAdminDiscount(e.target.value);
                                }}
                              />
                            </td>
                          </tr>

                          <tr>
                            <th>Total:</th>
                            <td style={{ textAlign: "right" }}>
                              ${numberFormater(orderDetail?.totalAmount) || 0}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div className="col-lg-10 pb-6">
                        <label>Payment Method</label>
                        <input
                          disabled
                          type="text"
                          min={0}
                          className="form-control"
                          id="amountPayable"
                          value={orderDetail?.paymentMethod || ""}
                          // readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div className="col-lg-4 ws-nowrap">Remarks</div>
                    </div>
                    <div className="col-lg-12">
                      <div className="col-lg-11">
                        <div className="form-group">
                          <input
                            type="text"
                            value={orderDetail.remark}
                            className="form-control"
                            id="remark"
                            name="remark"
                            style={{ height: "50px" }}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </CardBody>

            <CardFooter className="hide_wprint">
              <div className="row">
                <div className="col-lg-11">
                  <div className="d-flex flex-wrap align-items-center justify-content-end">
                    <div className="mt-4 mt-sm-0">
                      <Link className="btn btn-car-back" to="/order">
                        Back
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </CardFooter>
          </Card>
        </div>
      )}
    </div>
  );
};

export default OrderEdit;
