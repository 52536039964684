import React, { FC, useEffect, useState } from "react";
import SVG from "react-inlinesvg";
import {
  toAbsoluteUrl,
  headerSortingClasses,
  sortCaret,
} from "../../../helpers";
import { Card, CardBody, CardHeader } from "../../../partials/controls";
import { SizePerPage } from "../../../common/table/filters/SizePerPage";
import { Table } from "../../../common/table";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { toast } from "react-toastify";
import { FilterQuery } from "../../../common/table/filters/FilterQuery";
import { StatusFilter } from "../../../common/table/filters/StatusFilter";
import { useHistory } from "react-router-dom";
import { sortServerString } from "../../../common/table/sorter";
import { PaginationLinks } from "../../../common/pagination/PaginationLinks";
import { PaginationToolbar } from "../../../common/pagination/PaginationToolbar";
import {
  getBranchDetailsForSearch,
  getOrderListByType,
  getOrderTransactionList,
} from "../../../service/carService";
import Loader from "../../../common/loader";
import { numberFormater } from "../../../common/thousandSeperator/defaultFunction";
import moment from "moment";
import Select from "react-select";

const Order: FC = () => {
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(false);
  const [query, setQuery] = useState<string>("");
  const [totalData, setTotalData] = useState<number>(1);
  const [entities, setEntities] = useState<any>([]);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [type, setType] = useState<string>("service");
  const [sortingType, setSortingType] = useState<any>("");
  const [branchListFilter, setBranchListFilter] = useState([]);
  const [branch, setBranch] = useState<any>("");
  const [selectedBranch, setSelectedBranch] = useState<any>(null);

  const DateColumnFormatter = (date: any) => {
    if (date) {
      return moment(date).format("DD/MM/YYYY");
    }
  };
  const TimeColumnFormatter = (time: any) => {
    if (time) {
      return moment(time).format("HH:mm");
    }
  };
  const columns = [
    {
      dataField: "created_at",
      text: "Transaction Date",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      onSort: (field: string, order: string) =>
        sortServerString(field, order, setSortingType),
      formatter: DateColumnFormatter,
    },
    {
      dataField: "created_at",
      text: "Transaction Time",
      sort: false,
      formatter: TimeColumnFormatter,
    },
    {
      dataField: "owner.name",
      text: "Username",
      sort: false,
    },
    {
      dataField: "owner.phone",
      text: "Phone Number",
      sort: false,
    },
    {
      dataField: "amount",
      text: "Amount",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      onSort: (field: string, order: string) =>
        sortServerString(field, order, setSortingType),
      formatter: (field: string) => {
        console.log(field);
        const amount =
          parseInt(field) > 0 ? `-$${field}` : "$" + field.replace("-", "");
        return amount;
      },
    },
  ];

  const getOrderList = (page = 1, sizePerPage = 10, showLoading = true) => {
    // const isStatus = type == "" ? "service" : "package";
    const isSortColumn =
      sortingType !== "" ? `&${sortingType}` : `&sort={"created_at":-1}`;

    if (showLoading) {
      setLoading(true);
    }
    getOrderTransactionList(
      page,
      sizePerPage,
      query,
      type,
      isSortColumn,
      branch
    )
      .then((res: any) => {
        setLoading(false);
        let order: any = [];
        res?.data?.docs.map((data, index) => {
          console.log(data._id, "diddddddjhsdfasljdfh");
          const Obj1 = {
            _id: data._id,
            metadata: data?.metadata,
            created_at: data.created_at,
            createdAtTime: data.created_at,
            created_by: data.created_by,
            owner: data?.owner,
            amount: `${numberFormater(data.amount?.value)}`,
            index: ++index + (page - 1) * sizePerPage,
          };
          order.push(Obj1);
          console.log(Obj1.amount, "ammount");
        });
        setEntities(order);
        setTotalData(res?.data?.total);
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || "Something went wrong.");
        setLoading(false);
      });
  };

  const customTotal = (from: any, to: any, size: any) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing rows {totalData === 0 ? 0 : (page - 1) * sizePerPage + 1} to {to}{" "}
      of {totalData}
    </span>
  );

  const onTableChange = async (type: any, props: any, paginationProps: any) => {
    if (type !== "sort") {
      setSizePerPage(props.sizePerPage);
      if (props.sizePerPage > paginationProps.totalSize) {
        setPage(1);
        getOrderList(1, props.sizePerPage);
        setPage(1);
      } else {
        setPage(props.page);
        getOrderList(props.page, props.sizePerPage);
      }
    }
  };

  const handleSizePerPage = (
    { page, onSizePerPageChange }: any,
    newSizePerPage: any
  ) => {
    onSizePerPageChange(newSizePerPage, page);
  };

  const paginationOptions = {
    custom: true,
    totalSize: totalData,
    hideSizePerPage: true,
    showTotal: true,
    page,
    sizePerPage,
    paginationTotalRenderer: customTotal,
  };

  useEffect(() => {
    getOrderList(1, 10, false);
  }, [query, type, sortingType, branch]);

  // const handleQuery = (e) => {
  //   setPage(1);
  //   setQuery(e);
  // };

  useEffect(() => {
    getBranchsDetailsForSearch();
  }, []);

  const getBranchsDetailsForSearch = () => {
    setLoading(true);
    getBranchDetailsForSearch()
      .then((res: any) => {
        setLoading(false);
        const branchData = res.data.docs.map((branch) => {
          const defaultName = branch.name?.includes("{")
            ? JSON.parse(branch.name)
            : null;
          return {
            value: branch.id,
            label: defaultName ? defaultName?.en : branch.name,
          };
        });
        branchData.unshift({ value: "", label: "All" });
        setBranchListFilter(branchData);
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || "Something went wrong.");
        setLoading(false);
      });
  };

  const handleBranchChange = (selectedOption) => {
    if (selectedOption) {
      setSelectedBranch(selectedOption);
      setBranch(selectedOption.value);
    } else {
    }
  };

  const rowEvents = {
    onClick: (a, row, c) => {
      console.log(typeof row?.metadata.BNBooking, "mertadafd");
      return history.push({
        pathname: `/order/edit/${row?._id}`,
        state: {
          id:
            type === "service"
              ? typeof row?.metadata.BNBooking === "object"
                ? row?.metadata?.BNBooking._id
                : row?.metadata?.BNBooking
              : row?.metadata?.BNTicketsPackage?._id,
          type: type,
        },
      });
    },
  };
  return (
    <Card>
      {loading && <Loader />}
      <CardHeader title="Order">
        <div className="d-flex align-items-center">
          <div
            title="Edit customer"
            className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
            role="button"
            onClick={() => {
              getOrderList();
            }}
          >
            <span className="svg-icon svg-icon-md svg-icon-primary">
              <SVG src={toAbsoluteUrl("/media/svg/icons/reload.svg")} />
            </span>
          </div>
        </div>
      </CardHeader>
      <CardBody>
        <PaginationProvider pagination={paginationFactory(paginationOptions)}>
          {({ paginationProps, paginationTableProps }) => (
            <>
              <div className="d-flex ">
                <div className="d-flex flex-column flex-md-row flex-sm-column mb-10">
                  <div className="mt-4 mr-3">
                    <div
                      className={`d-flex align-items-center top-select`}
                      style={{ position: "relative", minWidth: "15rem" }}
                    >
                      <Select
                        isDisabled={Boolean(type === "package")}
                        name="branch"
                        options={branchListFilter}
                        closeMenuOnSelect={true}
                        onChange={handleBranchChange}
                        value={type === "package" ? null : selectedBranch}
                        className="basic-multi-select w-100"
                        defaultValue={branchListFilter}
                        classNamePrefix="select"
                        placeholder="-- Select Branch --"
                      />
                    </div>
                    <small className="form-text text-muted">
                      <b>Filter</b> by Branch
                    </small>
                  </div>
                  <div className="mt-4 mr-3">
                    <div
                      className={`d-flex align-items-center top-select`}
                      style={{ position: "relative", minWidth: "12rem" }}
                    >
                      <select
                        className="form-control rounded custom-select"
                        name="Status"
                        onChange={(e) => {
                          setType(e.target.value);
                          setPage(1);
                        }}
                        value={type}
                        style={{ paddingRight: "3rem" }}
                      >
                        <option defaultValue="service">Service</option>
                        <option value="package">Package</option>
                      </select>
                    </div>
                    <small className="form-text text-muted">
                      <b>Filter</b> by Type
                    </small>
                  </div>

                  <FilterQuery
                    query={query}
                    setQuery={(e) => {
                      setQuery(e);
                      setPage(1);
                    }}
                  />
                </div>
              </div>
              <Table
                rowEvents={rowEvents}
                paginationProps={paginationProps}
                paginationOptions={paginationOptions}
                paginationTableProps={paginationTableProps}
                data={entities}
                columns={columns}
                remote
                onTableChange={(type: any, props: any) =>
                  onTableChange(type, props, paginationProps)
                }
              />
              <div className="d-flex justify-content-between align-items-center flex-wrap">
                <div>
                  <PaginationLinks paginationProps={paginationProps} />
                </div>
                <div className="d-flex">
                  <SizePerPage
                    paginationProps={paginationProps}
                    handleSizePerPage={handleSizePerPage}
                    entities={entities}
                  />
                  <PaginationToolbar paginationProps={paginationProps} />
                </div>
              </div>
            </>
          )}
        </PaginationProvider>
      </CardBody>
    </Card>
  );
};

export default Order;
