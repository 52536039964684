export const DevURL: string = process.env.REACT_APP_API_URL as string;
export const imageURL = "https://ad.uat.fletrix.hk";

export const LOCAL_STORAGE_KEYS = {
  ACCESS_TOKEN: "ACCESS_TOKEN",
};

export const GetToken = () => {
  let token = localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);
  return token;
};
