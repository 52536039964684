/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Card, CardBody, CardHeader } from "../../../partials/controls";
import { SizePerPage } from "../../../common/table/filters/SizePerPage";
import { Table } from "../../../common/table";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import {
  getBranchDetailsForSearch,
  getCustomerPackagesRecords,
} from "../../../service/carService";
import { useHistory, useParams } from "react-router-dom";
import { headerSortingClasses, sortCaret } from "../../../helpers";
import moment from "moment";
import { sortServerString } from "../../../common/table/sorter";
import { PaginationLinks } from "../../../common/pagination/PaginationLinks";
import { PaginationToolbar } from "../../../common/pagination/PaginationToolbar";
import AddCustomerPackage from "./customerPackageEdit";
import { FilterQuery } from "../../../common/table/filters/FilterQuery";
import Select from "react-select";
import { toast } from "react-toastify";

const CustomerPackageRecord = ({ customerName }) => {
  const [query, setQuery] = useState<string>("");
  const [totalData, setTotalData] = useState<number>(1);
  const [entities, setEntities] = useState<any>([]);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [type, setType] = useState<string>("0$created");
  const [sortingType, setSortingType] = useState<any>("");
  const [branchListFilter, setBranchListFilter] = useState([]);
  const [branch, setBranch] = useState<any>("");
  const [selectedBranch, setSelectedBranch] = useState<any>(null);
  const [packages, setPackages] = useState([]);
  const [isEditModal, setIsEditModal] = useState(false);
  const [modalData, setModalData] = useState<any>({});
  const params: { pid: string; cid: string } = useParams();
  const history = useHistory();
  //  @ts-ignore
  const getCustomerPackageFunction = () => {
    const pid = params.pid;
    const id = params.cid;
    getCustomerPackagesRecords(id, pid, page, branch, type, query, sizePerPage)
      .then((res: any) => {
        let packageList: any = [];
        setTotalData(res?.data?.total);
        setPage(res?.data?.page);
        console.log(res?.data?.docs, "Responce Data");
        res?.data?.docs.map((data, index) => {
          const obj = {
            id: data?._id,
            index: index,
            branch: data?.serviceDetails.masterSpecified.name,
            service: data?.serviceDetails.serviceProduct.name,
            serviceDate: data?.time.start,
            serviceTime: {
              start: data?._timetableTime.start,
              end: data?._timetableTime.end,
            },
            price: data?.amountPayable,
          };
          packageList.push(obj);
        });
        setPackages(packageList);
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || "Something went wrong.");
      });
  };
  useEffect(() => {
    getCustomerPackageFunction();
  }, [branch]);

  const DateColumnFormatter = (date: any) => {
    if (date) {
      return moment(date).format("DD-MM-YYYY");
    }
  };

  const TimeColumnFormatter = (date: any) => {
    if (date) {
      console.log(date.start, date.end);
      return `${moment(date.start).format("HH:mm")}-${moment(date.end).format(
        "HH:mm"
      )}`;
    }
  };

  const PriceFormatter = (e, row) => {
    return e ? `$${e.toLocaleString("en-US")}` : "";
  };

  const columns = [
    {
      dataField: "branch",
      text: "Branch",
      sort: false,
      formatter: (cell: string) => {
        const defaultName = cell?.includes("{") ? JSON.parse(cell) : null;
        return defaultName ? defaultName?.en : cell;
      },
    },
    {
      dataField: "service",
      text: "Service",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      onSort: (field: string, order: string) =>
        sortServerString(field, order, setSortingType),
    },
    {
      dataField: "serviceDate",
      text: "Service Date",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      onSort: (field: string, order: string) =>
        sortServerString(field, order, setSortingType),
      formatter: DateColumnFormatter,
    },
    {
      dataField: "serviceTime",
      text: "Service Time ",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: TimeColumnFormatter,
    },
    {
      dataField: "price",
      text: "Price",
      formatter: PriceFormatter,
    },
  ];

  useEffect(() => {}, [packages]);
  const getBranchsDetailsForSearch = () => {
    getBranchDetailsForSearch()
      .then((res: any) => {
        const branchData = res.data.docs.map((branch) => {
          const defaultName = branch.name?.includes("{")
            ? JSON.parse(branch.name)
            : null;
          return {
            value: branch.id,
            label: defaultName ? defaultName?.en : branch.name,
          };
        });
        branchData.unshift({ value: "", label: "All" });
        setBranchListFilter(branchData);
      })
      .catch((err) => {});
  };

  const customTotal = (from: any, to: any, size: any) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing rows {totalData === 0 ? 0 : (page - 1) * sizePerPage + 1} to {to}{" "}
      of {totalData}
    </span>
  );

  const onTableChange = async (type: any, props: any, paginationProps: any) => {
    if (type !== "sort") {
      setSizePerPage(props.sizePerPage);
      if (props.sizePerPage > paginationProps.totalSize) {
        setPage(1);
      } else {
        setPage(props.page);
      }
    }
  };

  const paginationOptions = {
    custom: true,
    totalSize: totalData,
    hideSizePerPage: true,
    showTotal: true,
    page,
    sizePerPage,
    paginationTotalRenderer: customTotal,
  };

  const handleBranchChange = (selectedOption) => {
    if (selectedOption) {
      setSelectedBranch(selectedOption);
      setBranch(selectedOption.value);
    } else {
    }
  };

  const openBookDetailPage = (rowData) => {
    localStorage.setItem("status", rowData.status);
    window.open(`/booking/edit/${rowData.id}`, "_blank");
  };

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      console.log(e, row, rowIndex);
      openBookDetailPage(row);
    },
  };

  const getSelectRow = {
    mode: "radio",
    clickToSelect: true,
    hideSelectColumn: true,
    // onSelect: (data: ArticleEntities) => history.push(`/booking/edit`),
  };
  const handleSizePerPage = (
    { page, onSizePerPageChange }: any,
    newSizePerPage: any
  ) => {
    onSizePerPageChange(newSizePerPage, page);
  };

  useEffect(() => {
    getBranchsDetailsForSearch();
    getCustomerPackageFunction();
  }, [
    query,
    type,
    sortingType,
    branch,
    page,
    sizePerPage,
    isEditModal,
    branch,
  ]);

  return (
    <Card>
      <CardHeader title="Package Usage Record"></CardHeader>
      <CardBody>
        <PaginationProvider pagination={paginationFactory(paginationOptions)}>
          {({ paginationProps, paginationTableProps }) => (
            <>
              <div className="d-flex justify-content-between">
                <div className="d-flex flex-column flex-md-row flex-sm-column mb-3">
                  <AddCustomerPackage
                    show={isEditModal}
                    modalData={modalData}
                    onHide={() => setIsEditModal(false)}
                  />
                  <div className="mt-4 mr-3">
                    <div
                      className={`d-flex align-items-center top-select`}
                      style={{ position: "relative", minWidth: "15rem" }}
                    >
                      <Select
                        name="branch"
                        options={branchListFilter}
                        closeMenuOnSelect={true}
                        onChange={handleBranchChange}
                        value={selectedBranch}
                        className="basic-multi-select w-100"
                        defaultValue={branchListFilter}
                        classNamePrefix="select"
                        placeholder="-- Select Branch --"
                      />
                    </div>
                    <small className="form-text text-muted">
                      <b>Filter</b> by Branch
                    </small>
                  </div>

                  <FilterQuery
                    query={query}
                    setQuery={(e) => {
                      setQuery(e);
                      setPage(1);
                    }}
                  />
                </div>
                <div className="mr-3">
                  <button
                    className="btn btn-secondary "
                    onClick={() => history.goBack()}
                  >
                    Back
                  </button>
                </div>
              </div>
              <Table
                getSelectRow={getSelectRow}
                paginationProps={paginationProps}
                paginationOptions={paginationOptions}
                paginationTableProps={paginationTableProps}
                data={packages}
                columns={columns}
                rowEvents={rowEvents}
                remote
                onTableChange={(type: any, props: any) =>
                  onTableChange(type, props, paginationProps)
                }
              />
              <div className="d-flex justify-content-between align-items-center flex-wrap">
                <div>
                  <PaginationLinks paginationProps={paginationProps} />
                </div>
                <div className="d-flex">
                  <SizePerPage
                    paginationProps={paginationProps}
                    handleSizePerPage={handleSizePerPage}
                    entities={entities}
                  />
                  <PaginationToolbar paginationProps={paginationProps} />
                </div>
              </div>
            </>
          )}
        </PaginationProvider>
      </CardBody>
    </Card>
  );
};

export default CustomerPackageRecord;
