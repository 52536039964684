import React, { useEffect, useState } from "react";
import { CardBody, CardFooter } from "../../../partials/controls";
import "react-quill/dist/quill.snow.css";
import { Modal } from "react-bootstrap";
import Datetime from "react-datetime";
import moment from "moment";
import { updatePackageWithId } from "../../../service/carService";
import { toast } from "react-toastify";

interface Values {
  package: any;
  purchaseDate: any;
  expiryDate: any;
  quata: any;
}
interface Params {
  id: string;
}
const AddCustomerPackage = ({ show, onHide, modalData }: any) => {
  const [packageName, setPackageName] = useState("");
  const [quata, setQuata] = useState<number>(0);
  const [purchaseDate, setPurchaseDate] = useState("");
  const [expiryDate, setExpiryDate] = useState("");

  useEffect(() => {
    if (modalData) {
      const {
        createdAt,
        expireData,
        package: packageName,
        quotaLeft,
      } = modalData;
      setPackageName(packageName);
      setQuata(quotaLeft);
      setExpiryDate(expireData);
      setPurchaseDate(createdAt);
    }
  }, [modalData]);

  const handleSubmit = () => {
    const body = {
      quotaLeft: quata,
      created_at: purchaseDate,
      expireAt: expiryDate,
    };

    updatePackageWithId(modalData._id, body)
      .then((res) => {
        toast.success("Updated successfully");
        onHide();
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || "Something went wrong.");
      });
  };

  const onChangeTime = (date) => {
    const DateToPass = new Date(date).toUTCString();
    setExpiryDate(DateToPass);
  };

  const onChangePurchase = (date) => {
    const DateToPass = new Date(date).toUTCString();
    setPurchaseDate(DateToPass);
  };

  return (
    <div>
      <Modal
        centered
        show={show}
        onHide={onHide}
        dialogClassName="w-100"
        aria-labelledby="example-modal-sizes-title-xl"
        className="w-100"
      >
        <header className="package-header">Edit Package</header>
        <CardBody>
          <div className="row mt-3">
            <div className="col-lg-12 col-md-6 col-sm-12">
              <div className="col-lg-12 ws-nowrap">Package Name</div>
              <div className="col-lg-12">
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  value={packageName}
                  disabled
                  onChange={(e) => setPackageName(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-lg-12 col-md-6 col-sm-12">
              <div className="col-lg-12 ws-nowrap">Purchase Date</div>
              <div className="col-lg-12">
                <Datetime
                  inputProps={{
                    name: "date",
                    placeholder: "dd/MM/yyyy",
                  }}
                  value={moment(purchaseDate).format("DD-MM-YYYY")}
                  timeFormat={false}
                  dateFormat={"DD-MM-YYYY"}
                  onChange={onChangePurchase}
                  closeOnSelect={true}
                />
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-lg-12 col-md-6 col-sm-12">
              <div className="col-lg-12 ws-nowrap">Expiry Date</div>
              <div className="col-lg-12">
                <Datetime
                  inputProps={{
                    name: "date",
                    placeholder: "DD-MM-YYYY",
                  }}
                  value={moment(expiryDate).format("DD-MM-YYYY")}
                  timeFormat={false}
                  dateFormat={"DD-MM-YYYY"}
                  onChange={onChangeTime}
                  closeOnSelect={true}
                />
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-lg-12 col-md-6 col-sm-12">
              <div className="col-lg-12 ws-nowrap">Qauta Left</div>
              <div className="col-lg-12">
                <input
                  type="number"
                  className="form-control"
                  id="name"
                  name="name"
                  value={quata}
                  onChange={(e) => setQuata(parseInt(e.target.value))}
                />
              </div>
            </div>
          </div>
        </CardBody>
        <CardFooter>
          <div className="row ">
            <div className="col-lg-12">
              <div className="d-flex flex-wrap align-items-center justify-content-end">
                <div className="mt-sm-0">
                  <button
                    className="btn btn-success mr-2 mx-sm-2"
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </CardFooter>
      </Modal>
    </div>
  );
};

export default AddCustomerPackage;
