import React, { FC, useEffect, useState } from "react";
import {
  toAbsoluteUrl,
  headerSortingClasses,
  sortCaret,
} from "../../../helpers";
import { Card, CardBody, CardHeader } from "../../../partials/controls";
import { toast } from "react-toastify";
import { SizePerPage } from "../../../common/table/filters/SizePerPage";
import { Table } from "../../../common/table";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import SVG from "react-inlinesvg";
import { FilterQuery } from "../../../common/table/filters/FilterQuery";
import { StatusFilter } from "../../../common/table/filters/StatusFilter";
import { sortServerString } from "../../../common/table/sorter";
import { PaginationLinks } from "../../../common/pagination/PaginationLinks";
import { PaginationToolbar } from "../../../common/pagination/PaginationToolbar";
import Loader from "../../../common/loader";
import { getPurchaseApprovalApi } from "../../../service/carService";
import moment from "moment";
import Addapproval from "./addpapproval";
import Editpapproval from "./editpapproval";
import { numberFormater } from "../../../common/thousandSeperator/defaultFunction";

const PurchaseApproval: FC = () => {
  const [query, setQuery] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [totalData, setTotalData] = useState<number>(1);
  const [approval, setapproval] = useState<any>(false);
  const [editapproval, seteditapproval] = useState<any>(false);
  const [approvalId, setAprovalId] = useState<any>("");
  const [entities, setEntities] = useState<any>([]);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [type, setType] = useState<string>("pending");
  const [sortingType, setSortingType] = useState<any>("");

  const statusMap: any = {
    paid: {
      label: "Approved",
      class: "info",
    },
    fail: {
      label: "Rejected",
      class: "danger",
    },
    pending: {
      label: "Pending",
      class: "warning",
    },
  };

  const StatusColumnFormatter = (status: any) => {
    if (status) {
      return (
        <span
          className={`label label-lg label-light-${statusMap[status]["class"]} label-inline font-weight-normal font-size-11`}
        >
          {statusMap[status]["label"]}
        </span>
      );
    }
  };
  const ActionEdit = (cellContent, row, rowIndex) => {
    return (
      <a
        title="Edit customer"
        className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
        onClick={(e) => oneditModal(row._id)}
      >
        <span className="svg-icon svg-icon-md svg-icon-primary">
          <SVG
            src={toAbsoluteUrl("/media/svg/icons/Communication/arrow.svg")}
          />
        </span>
      </a>
    );
  };

  const rowEvents = {
    onClick: (a, row, c) => {
      oneditModal(row._id);
    },
  };
  const columns = [
    // {
    //   dataField: "_id",
    //   text: "Application ID",
    //   sort: true,
    //   sortCaret: sortCaret,
    //   headerSortingClasses,
    //   onSort: (field: string, order: string) =>
    //     sortServerString(field, order, setSortingType),
    // },
    {
      dataField: "product.name",
      text: "Package",
      sort: false,
    },
    {
      dataField: "created_by.name",
      text: "Username",
      sort: false,
    },
    {
      dataField: "quota",
      text: "Quota",
      sort: false,
    },
    {
      dataField: "created_by.phone",
      text: "Phone Number",
      sort: false,
    },
    {
      dataField: "amountPayable",
      text: "Amount",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      onSort: (field: string, order: string) =>
        sortServerString(field, order, setSortingType),
    },
    {
      dataField: "created_at",
      text: "Created Date",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      onSort: (field: string, order: string) =>
        sortServerString(field, order, setSortingType),
    },
    {
      dataField: "paymentStatus",
      text: "Status",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: StatusColumnFormatter,
      onSort: (field: string, order: string) =>
        sortServerString(field, order, setSortingType),
    },
    // {
    //   dataField: "action",
    //   text: "Action",
    //   formatter: ActionEdit,
    // },
  ];

  const customTotal = (from: any, to: any, size: any) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing rows {totalData === 0 ? 0 : (page - 1) * sizePerPage + 1} to {to}{" "}
      of {totalData}
    </span>
  );
  const handleSizePerPage = (
    { page, onSizePerPageChange }: any,
    newSizePerPage: any
  ) => {
    onSizePerPageChange(newSizePerPage, page);
  };
  const onTableChange = async (type: any, props: any, paginationProps: any) => {
    if (type !== "sort") {
      setSizePerPage(props.sizePerPage);
      if (props.sizePerPage > paginationProps.totalSize) {
        setPage(1);
        getPurchaseApproval(1, props.sizePerPage);
        setPage(1);
      } else {
        setPage(props.page);
        getPurchaseApproval(props.page, props.sizePerPage);
      }
    }
  };

  const openAddApproval = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setapproval(true);
    // setnewId(id);
  };

  const oneditModal = (id) => {
    // e.preventDefault();
    // e.stopPropagation();
    seteditapproval(true);
    setAprovalId(id);
  };

  const paginationOptions = {
    custom: true,
    totalSize: totalData,
    hideSizePerPage: true,
    showTotal: true,
    page,
    sizePerPage,
    paginationTotalRenderer: customTotal,
  };

  const getSelectRow = {
    mode: "radio",
    clickToSelect: true,
    hideSelectColumn: true,
    // onSelect: (data: ArticleEntities) => oneditModal(data),
  };

  useEffect(() => {
    getPurchaseApproval(1, 10, false);
  }, [query, type]);

  const getPurchaseApproval = (
    page = 1,
    sizePerPage = 10,
    showLoading = true
  ) => {
    const isStatus =
      type !== ""
        ? `&filter={"paymentMethod":"manual","paymentStatus":"${type}"}&populate=["created_by","product"]`
        : `&filter={"paymentMethod":"manual"}&populate=["created_by","product"]`;
    const isSortColumn =
      sortingType !== "" ? `&${sortingType}` : `&sort={"created_at":-1}`;

    if (showLoading) {
      setLoading(true);
    }
    getPurchaseApprovalApi(page, sizePerPage, query, isStatus, isSortColumn)
      .then((res: any) => {
        setLoading(false);
        let news: any = [];
        res?.data?.docs.map((data, index) => {
          const Obj = {
            index: ++index + (page - 1) * sizePerPage,
            _id: data._id,
            title: data.subject,
            created_by: data?.created_by,
            product: data?.product,
            amountPayable: `$${numberFormater(data?.amountPayable)}`,
            created_at: moment(data.created_at).format("DD/MM/YYYY HH:mm"),
            paymentStatus: data.paymentStatus,
            quota: `${data?.quotaLeft}/${data?.quota}`,
          };
          news.push(Obj);
        });
        setEntities(news);
        setTotalData(res?.data?.total);
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || "Something went wrong.");
        setLoading(false);
      });
  };

  useEffect(() => {
    getPurchaseApproval();
  }, [sortingType]);

  const resetPageAndGetPurchase = () => {
    setPage(1);
    setSizePerPage(10);
    getPurchaseApproval();
  };

  return (
    <Card>
      {loading && <Loader />}
      <CardHeader title="Purchase Approval">
        <div className="d-flex align-items-center">
          <a
            title="Edit customer"
            className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
            role="button"
            onClick={() => {
              getPurchaseApproval();
            }}
          >
            <span className="svg-icon svg-icon-md svg-icon-primary">
              <SVG src={toAbsoluteUrl("/media/svg/icons/reload.svg")} />
            </span>
          </a>
        </div>
      </CardHeader>
      <CardBody>
        <PaginationProvider pagination={paginationFactory(paginationOptions)}>
          {({ paginationProps, paginationTableProps }) => (
            <>
              <div className="d-flex ">
                <div className="d-flex flex-column flex-md-row flex-sm-column mb-10">
                  <StatusFilter
                    status={type}
                    setStatus={(e) => {
                      setType(e);
                      setPage(1);
                    }}
                    options={[
                      { label: "All", value: "" },
                      { label: "Pending", value: "pending" },
                      { label: "Rejected", value: "fail" },
                      { label: "Approved", value: "paid" },
                    ]}
                  />
                  <FilterQuery
                    query={query}
                    setQuery={(e) => {
                      setQuery(e);
                      setPage(1);
                    }}
                  />
                </div>
                <div className="mt-4">
                  <button
                    className="btn btn-primary"
                    onClick={(e) => openAddApproval(e)}
                  >
                    New Purchase
                  </button>
                </div>
              </div>
              <Table
                rowEvents={rowEvents}
                getSelectRow={getSelectRow}
                paginationProps={paginationProps}
                paginationOptions={paginationOptions}
                paginationTableProps={paginationTableProps}
                data={entities}
                columns={columns}
                remote
                onTableChange={(type: any, props: any) =>
                  onTableChange(type, props, paginationProps)
                }
              />
              <div className="d-flex justify-content-between align-items-center flex-wrap">
                <div>
                  <PaginationLinks paginationProps={paginationProps} />
                </div>
                <div className="d-flex">
                  <SizePerPage
                    paginationProps={paginationProps}
                    handleSizePerPage={handleSizePerPage}
                    entities={entities}
                  />
                  <PaginationToolbar paginationProps={paginationProps} />
                </div>
              </div>
            </>
          )}
        </PaginationProvider>
      </CardBody>
      {approval && (
        <Addapproval
          show={approval}
          onHide={() => setapproval(false)}
          getPurchaseApproval={resetPageAndGetPurchase}
        />
      )}
      {editapproval && (
        <Editpapproval
          show={editapproval}
          onHide={() => seteditapproval(false)}
          id={approvalId}
          getPurchaseApproval={resetPageAndGetPurchase}
        />
      )}
    </Card>
  );
};

export default PurchaseApproval;
