import React, { useEffect, useState } from "react";
import "react-quill/dist/quill.snow.css";
import Loader from "../../../common/loader";
import { Form, Modal } from "react-bootstrap";
import {
  AddAdminData,
  getElementById,
  resetAdminPassword,
  updateAdmin,
} from "../../../service/carService";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
interface Values {
  name: string;
  pw: string;
  role?: string;
  username: string;
}

const AddAdmin = ({ id, show, onHide }) => {
  const [loader, setLoader] = useState<boolean>(false);
  const [admin, setAdmin] = useState<any>([]);
  const [isSwitchOn, setSwitchOn] = useState<boolean>(true);
  const [isActive, setIsActive] = useState<boolean>(true);

  useEffect(() => {
    if (id) {
      getAdminDetail();
    }
  }, [id]);

  const getAdminDetail = () => {
    setLoader(true);
    getElementById(id)
      .then((res: any) => {
        setLoader(false);
        setAdmin(res?.data);
        setIsActive(res.data.enabled);
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || "Something went wrong.");
        setLoader(false);
      });
  };

  const onSubmitData = (values) => {
    setLoader(true);
    values.role = isSwitchOn ? "admin" : "bn.cashier";
    AddAdminData(values)
      .then((res: any) => {
        setLoader(false);
        if (res.status === 200) {
          toast.success("Admin Type Add Successful");
          onHide(true);
        }
      })
      .catch((err) => {
        // toast.error("Username already exist");
        toast.error(err.response?.data?.message || "Something went wrong.");
        onHide(true);
        setLoader(false);
      });
  };

  const onUpdate = (values) => {
    setLoader(true);
    const body = {
      newPw: values.pw,
      pwIsNull: false,
    };
    resetAdminPassword(admin?.user?.id, body)
      .then((res: any) => {
        setLoader(false);
        if (res.status === 200) {
          toast.success("Admin Type update Sucessfull");
          onHide(true);
        }
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || "Something went wrong.");
        setLoader(false);
      });
    const Resbody = {
      enabled: isActive,
    };
    updateAdmin(admin.id, Resbody);
  };
  const formik = useFormik({
    enableReinitialize: true,

    initialValues: {
      name: id ? admin.name : "",
      username: "",
      pw: "",
    },
    onSubmit: (values: Values) => {
      id
        ? onUpdate((values.username = values.name) && values)
        : onSubmitData((values.username = values.name) && values);
    },
  });
  return (
    <div>
      {loader && <Loader />}
      <Modal
        centered
        show={show}
        onHide={onHide}
        aria-labelledby="example-modal-sizes-title-lg"
        className="service-modal"
      >
        <Modal.Body>
          <div className="d-flex justify-content-between px-10">
            <div className="modal-header-text ml-2">
              {id ? "Update " : "Create "}Admin
            </div>
            <div className="modal-close" onClick={() => onHide(false)}>
              x
            </div>
          </div>
          <div className="row justify-content-center mt-5">
            <div className="col-lg-10">
              {" "}
              <div className="input-group relative d-flex align-items-center">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Admin Name"
                  name="name"
                  readOnly={admin.id}
                  value={formik.values.name}
                  onChange={formik.handleChange}
                />
              </div>
              <div className="input-group relative d-flex align-items-center mt-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="password"
                  name="pw"
                  value={formik.values.pw}
                  onChange={formik.handleChange}
                />
              </div>
            </div>
            {!admin.id && (
              <div className="col-lg-10 mt-4">
                {" "}
                <div className="input-group relative d-flex align-items-center">
                  <FormControlLabel
                    control={
                      <Switch
                        checked={isSwitchOn}
                        onChange={() => setSwitchOn(!isSwitchOn)}
                        color="primary"
                        name="status"
                      />
                    }
                    label={isSwitchOn ? "Super Admin" : "Cashier"}
                  />
                </div>
              </div>
            )}
            {admin.id && (
              <div className="col-lg-10 mt-4">
                <div className="input-group relative d-flex align-items-center">
                  <FormControlLabel
                    control={
                      <Switch
                        checked={isActive}
                        onChange={() => setIsActive(!isActive)}
                        color="primary"
                        name="active"
                      />
                    }
                    label={isActive ? "Active" : "Inactive"}
                  />
                </div>
              </div>
            )}
            <div className="col-lg-10 mt-7 mb-4">
              <button
                className="btn btn-car-primary "
                onClick={() => formik.handleSubmit()}
              >
                {id ? "Update" : "Create "}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AddAdmin;
