import React, { useEffect, useMemo, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
} from "../../../partials/controls";
import { TimePicker } from "antd";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import "react-quill/dist/quill.snow.css";
import Loader from "../../../common/loader";
import {
  ErrorMessage,
  FieldArray,
  Formik,
  FormikProvider,
  useFormik,
} from "formik";
import * as Yup from "yup";
import { toAbsoluteUrl } from "../../../helpers";
import {
  completeBooking,
  computeAmountPayable,
  getOneBookingDetail,
  getOneOrderDetail,
  getPackageListForBooking,
  getPackageSpecified,
  getProductListForBooking,
  getCustomersDetails,
  getServiceProductDetails,
  saveBookingDetails,
  updateArrivedBookingDetail,
  getBranchsDetails,
  voidBooking,
  updateBooking,
  getServiceList,
} from "../../../service/carService";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import { toast } from "react-toastify";
import { numberFormater } from "../../../common/thousandSeperator/defaultFunction";
import Datetime from "react-datetime";
import Select from "react-select";
import { Button, Switch } from "@material-ui/core";
import { Table, Modal } from "react-bootstrap";
import CommonModal from "./CommonModal";
import { set } from "lodash";
let pushToAddProduct = () => {};
function sumArray(array: any = []) {
  let sum = 0;
  for (let i = 0; i < array.length; i += 1) {
    sum += array[i];
  }

  return sum;
}

interface Values {
  id: string;
  date: any;
  time: any;
  branch: string;
  username: string;
  plateNo: string;
  status: string;
  radio: string;
  serviceRadio: string;
  package: string;
  discount?: any;
  remark: string;
  addProductData: any;
}
interface Params {
  id: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  formControl: {
    margin: theme.spacing(2, 3),
  },
  group: {
    margin: theme.spacing(0, 0),
  },
}));

const BookingEdit = () => {
  const history = useHistory();
  const params: Params = useParams();
  const [customerList, setCustomerList] = useState<any>([]);
  const [branchList, setBranchList] = useState<any>([]);
  const [loader, setLoader] = useState<boolean>(false);
  const [booking, setBooking] = useState<any>([]);
  const [bookingDate, setBookingDate] = useState<string>("");
  const [productList, setProductList] = useState<any>([]);
  const [serviceList, setServiceList] = useState([]);
  const [selectedService, setSelectedService] = useState<any>(null);
  const [packageList, setPackageList] = useState<any>([]);
  const [amount, setAmount] = useState<any>([]);
  const [serviceProductData, setServiceProductData] = useState<any>();
  const [hairLength, setHairLength] = useState<any>();
  const [serviceProductAmount, setServiceProductAmount] = useState<number>();
  const [isLate, setIsLate] = useState(false);
  const [isVoiding, setIsVoiding] = useState(false);
  const [isDetailsSaved, setIsDetailsSaved] = useState<boolean>(false);
  const [isVoided, setIsVoided] = useState(false);
  const [isBack, setIsBack] = useState<boolean>(false);
  const [voidData, setVoidData] = useState<any>({ voidedAt: "", voidedBy: "" });
  const [isConfirmCancel, setIsComfirmCancel] = useState<boolean>(false);
  const [isConfirmAbsent, setIsComfirmAbsent] = useState<boolean>(false);
  const [showpaymodal, setShowPayModal] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState<any>(null);
  const [isCompleted, setIsCompleted] = useState<boolean>(false);
  const [adminDiscount, setAdminDiscount] = useState<any>(0);

  const statusMap: any = {
    "0$created": "Booked",
    "97$cancelled": "Cancel",
    "98$absent": "Absent",
    "1$arrived": "Arrived Branch",
    "99$completed": "Completed",
  };

  const getBookingDetail = () => {
    setLoader(true);
    getOneBookingDetail(params.id)
      .then((res: any) => {
        setLoader(false);
        setBooking(res?.data);
        setSelectedService(
          res.data?.serviceDetails?.serviceProduct?._id ||
            "6458b3f73daa878c690cdd76"
        );
        setAdminDiscount(res?.data?.adminDeduct || 0);
        // setBookingDate(
        //   moment(res?.data?.time?.start).format("DD/MM/YYYY HH:mm")
        // );
        if (res?.data?.serviceDetails?.serviceProduct) {
          setServiceProductData(res?.data?.serviceDetails?.serviceProduct);
        } else {
          getServiceProductData(
            res?.data?.serviceDetails?.serviceProduct?._id ||
              "6458b3f73daa878c690cdd76"
          );
        }
        // getServiceProductData("6458b3f73daa878c690cdd76");
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || "Something went wrong.");
        setLoader(false);
      });
  };

  const getCustomerDetails = () => {
    setLoader(true);
    getCustomersDetails()
      .then((res: any) => {
        setLoader(false);
        const customerData = res.data.docs.map((customer) => {
          return {
            value: customer._id,
            label: `${customer.phone} - ${customer.name}`,
          };
        });
        setCustomerList(customerData);
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || "Something went wrong.");
        setLoader(false);
      });
  };

  const getBranchDetails = () => {
    setLoader(true);
    getBranchsDetails()
      .then((res: any) => {
        setLoader(false);
        const branchData = res.data.docs.map((branch) => {
          const defaultName = branch.name?.includes("{")
            ? JSON.parse(branch.name)
            : null;
          return {
            value: branch._id,
            label: defaultName ? defaultName?.en : branch.name,
          };
        });
        setBranchList(branchData);
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || "Something went wrong.");
        setLoader(false);
      });
  };

  const getCompletedBookingDetail = () => {
    setLoader(true);
    getOneOrderDetail(params.id, "product")
      .then((res: any) => {
        setLoader(false);
        let packageSpecified = res?.data.serviceDetails.packageSpecified;
        res.data.radio = packageSpecified ? "yes" : "no";
        if (packageSpecified) {
          getPackageSpecified(packageSpecified).then((packageResponse: any) => {
            res.data.package = packageResponse?.data;
            setBooking(res?.data);
            setBookingDate(
              moment(res?.data?.time?.start).format("DD/MM/YYYY HH:mm")
            );
            getServiceProductData(res?.data?.serviceDetails?.serviceProduct);
            getCompletedServiceProductAmount(res?.data);
          });
        } else {
          setBooking(res?.data);
          setBookingDate(
            moment(res?.data?.time?.start).format("DD/MM/YYYY HH:mm")
          );
          getServiceProductData(res?.data?.serviceDetails?.serviceProduct);
          getCompletedServiceProductAmount(res?.data);
        }
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || "Something went wrong.");
        setLoader(false);
      });
  };

  const getServiceProductData = (id) => {
    getServiceProductDetails(id)
      .then((res: any) => {
        setServiceProductData(res?.data);
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || "Something went wrong.");
      });
  };

  const getAllProductList = () => {
    getProductListForBooking()
      .then((res: any) => {
        let newData: any = [];
        res?.data?.docs.map((data, index) => {
          const obj = {
            label: data.name,
            value: data._id,
          };
          newData.push(obj);
        });
        setProductList(newData);
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || "Something went wrong.");
        toast.error(err);
      });
  };

  const getAllPackageList = () => {
    getPackageListForBooking(booking?.created_by?.id).then((res: any) => {
      let newPackageData: any = [];
      res?.data?.docs.map((data, index) => {
        const obj = {
          name: data?.product?.name,
          id: data?._id,
        };
        newPackageData.push(obj);
      });
      setPackageList(newPackageData);
    });
  };

  const getAllServices = () => {
    getServiceList()
      .then((res: any) => {
        let newServiceData: any = [];
        res?.data?.docs.map((data, index) => {
          const obj = {
            label: data?.name,
            value: data?._id,
          };
          newServiceData.push(obj);
        });
        setServiceList(newServiceData);
      })
      .catch((err: any) => {});
  };

  const arrivedBranchSubmit = (values: any) => {
    const body = {
      amountPayable:
        amount && amount.amountPayable >= 0
          ? amount.amountPayable
          : +serviceProductData?.listedPrice,
      otherDiscount: {
        name: `${values.discount}%`,
        percentageDiscount: (100 - values?.discount) / 100,
      },
      remarks: values.remark,
      purchasedProducts: amount.purchasedProducts,
      serviceDetails: {
        serviceProduct: booking?.serviceDetails?.serviceProduct,
        packageSpecified: values.package === "" ? null : values.package,
        masterSpecified: booking?.serviceDetails?.masterSpecified.id,
        isRedo: false,
        needHairCut: false,
        needExtraFeeForMasterSpecified: false,
      },
    };
    const reqBody = {
      creditDeduct: 0,
      creditGain: 0,
      paymentMethod: "Cash",
    };
    setLoader(true);
    updateArrivedBookingDetail(params.id, body).then((res) => {
      completeBooking(params.id, reqBody)
        .then((res: any) => {
          setLoader(false);
          if (res.status === 200) {
            toast.success("Data add Sucessfull");
            history.push("/booking");
          }
        })
        .catch((err) => {
          toast.error("Can't complete the future booking");
          setLoader(false);
        });
    });
  };

  useEffect(() => {
    getAllProductList();
    getAllServices();
    if (params.id) {
      const locationState: any = localStorage.getItem("status");
      locationState.status === "99$completed"
        ? getCompletedBookingDetail()
        : getBookingDetail();
    }
  }, [localStorage]);

  useEffect(() => {
    setIsLate(booking.late);
    if (booking?.isVoided) {
      setIsVoided(true);
      setVoidData({ voidedBy: booking.voidedBy, voidedAt: booking.voidedAt });
    }
  }, [booking]);

  const saveBookingData = () => {
    if (formik.values.radio === "yes" && !formik.values.package) {
      toast.error(`Please select one package or choose “No” use package`);
    } else {
      const id = booking.id;
      const currentTime = moment(formik.values.date).toISOString();
      const afterTime = moment(formik.values.date)
        .add(30, "minutes")
        .toISOString();
      const body = {
        serviceDetails: {
          serviceProduct: selectedService, // booking?.serviceDetails?.serviceProduct
          packageSpecified:
            formik.values.package !== "" ? formik.values.package : null,
          masterSpecified: formik.values.branch,
        },
        adminDeduct: adminDiscount,
        otherDiscount: {
          name: formik.values.discount ? formik.values.discount : "",
          percentageDiscount: formik.values.discount
            ? formik.values.discount
            : 0,
        },
        status: formik.values.status,
        time: {
          start: currentTime,
          end: afterTime,
          duration: 1800000,
        },
        carPlate: formik.values.plateNo,
        created_by: formik.values.username,
        _timetableTime: {
          start: currentTime,
          end: afterTime,
          duration: 1800000,
        },
        late: isLate,
        remarks: formik.values.remark,
        purchasedProducts: formik.values.addProductData.map((x) => {
          return {
            ...x,
            qty: parseInt(x?.qty),
          };
        }),
        amountPayable: amount?.amountPayable,
      };

      saveBookingDetails(id, body, booking?._timetable?._id)
        .then((res) => {
          toast.success("Data Saved Successfully...");
          setIsDetailsSaved(true);
        })
        .catch((err) => {
          toast.error(err.response?.data?.message || "Something went wrong.");
        });
    }
  };

  const seletedProducts =
    (params?.id
      ? booking?.purchasedProducts
      : [
          {
            product: "",
            qty: "1",
            amountPayable: "",
            selectedProductObject: undefined,
          },
        ]) || [];

  const discountVal = booking.otherDiscount?.percentageDiscount;
  const formik = useFormik({
    onSubmit: (values: Values) => {
      if (!isDetailsSaved) {
        saveBookingData();
      }
    },
    enableReinitialize: true,
    initialValues: {
      id: params.id ? booking.id : "",
      date: params.id ? booking?.time?.start : "",
      time: params.id ? booking?.time?.start : "",
      branch: params.id ? booking?.serviceDetails?.masterSpecified?.id : "",
      radio: params.id ? (discountVal !== 0 ? "no" : "yes") : "yes",
      serviceRadio: "yes",
      username: params.id ? booking?.created_by?._id : "",
      plateNo: params.id ? booking.carPlate : "",
      status: params.id ? booking.status : "",
      package: params.id ? booking?.serviceDetails?.packageSpecified : "",
      discount: params.id ? booking?.otherDiscount?.percentageDiscount : 0,
      remark: params.id ? booking.remarks : "",
      addProductData: seletedProducts,
    },
    validationSchema: Yup.object({
      id: Yup.string().required("Id is required"),
      date: Yup.string().required("Date is required"),
      time: Yup.string().required("Time is Required"),
      branch: Yup.string().required("Branch is required"),
      radio: Yup.string().when("status", {
        is: "1$arrived",
        then: Yup.string().required("Required"),
      }),
      username: Yup.string().required("Username is required"),
      plateNo: Yup.string().required("Plate Number is required"),
      status: Yup.string().required("Status is required"),
      package: Yup.string().when(["radio", "status"], {
        is: (radio: string, status: string) => {
          return radio === "yes" && status === "1$arrived";
        },
        then: Yup.string().required("Package is required"),
      }),
      discount: Yup.number().when("status", {
        is: "1$arrived",
        then: Yup.number()
          .integer("Discount is must be in decimal")
          .min(1, "Discount is must be in bettween 1 to 100")
          .max(100, "Discount is must be in bettween 1 to 100"),
      }),

      addProductData: Yup.array().when("status", {
        is: "1$arrived",
        then: Yup.array()
          .of(
            Yup.object().shape({
              product: Yup.string().required("Required"),
              qty: Yup.number()
                .required("Required")
                .min(1, "Quantity must be atleast 1"),
              amountPayable: Yup.string(),
            })
          )
          .test("unique", "Product must be unique", function(list) {
            const mapper = (x) => x.product;
            const set = [...(new Set(list?.map(mapper)) as any)];
            const isUnique = list?.length === set.length;
            if (isUnique) {
              return true;
            }
            const idx = list?.findIndex((l, i) => mapper(l) !== set[i]);
            return this.createError({
              path: `addProductData.${idx}.product`,
              message: "Product must be unique",
            });
          }),
      }),
    }),
  });

  useEffect(() => {
    if (formik.values.radio) {
      if (selectedService) {
        totalAmountPayable();
      }
      if (formik.values.radio === "yes" && booking?.created_by?.id) {
        getAllPackageList();
      }
    }
  }, [formik.values.radio, booking?.created_by?.id]);

  useEffect(() => {
    if (
      formik.values.package ||
      formik.values.discount === "" ||
      formik.values.discount ||
      adminDiscount !== null
    ) {
      if (selectedService) {
        totalAmountPayable();
      }
    }
  }, [
    formik.values.package,
    formik.values.discount,
    adminDiscount,
    selectedService,
  ]);

  const updateBookingDetails = () => {
    setLoader(true);
    const currentTime = moment(formik.values.date).toISOString();
    const afterTime = moment(formik.values.date)
      .add(30, "minutes")
      .toISOString();
    const body = {
      time: {
        start: currentTime,
        end: afterTime,
        duration: 1800000,
      },
      _timetableTime: {
        start: currentTime,
        end: afterTime,
        duration: 1800000,
      },
    };

    updateArrivedBookingDetail(params.id, body)
      .then((res: any) => {
        setLoader(false);
        if (res.status === 200) {
          toast.success("Data Updated Sucessfull");
        }
      })
      .catch((err) => {
        toast.error("Can't update the booking");
        setLoader(false);
      });
  };

  const onChangeTime = (date) => {
    formik.setFieldValue("date", date);
  };

  const totalAmountPayable = () => {
    const values: any = formik.values;

    const purchasedProducts = values.addProductData
      .map(({ qty, product }) => {
        if (qty !== "" && product !== "") {
          return { qty, product };
        }
      })
      .filter(Boolean);

    let body: any = {
      paymentMethod: "Cash",
      serviceDetails: {
        serviceProduct: selectedService, // "6458b3f73daa878c690cdd76", // booking?.serviceDetails?.serviceProduct,
        packageSpecified: formik.values.radio === "yes" ? values.package : null,
        masterSpecified: booking?.serviceDetails?.masterSpecified.id,
        isRedo: false,
        needHairCut: false,
        needExtraFeeForMasterSpecified: false,
      },
      purchasedProducts: purchasedProducts,
      creditDeduct: 0,
      adminDeduct: Number(adminDiscount || 0),
    };

    if (
      values.discount === "" ||
      values.discount === undefined ||
      values.discount === 0 ||
      values.discount === 100
    ) {
      body = {
        ...body,
      };
    } else {
      body = {
        ...body,
        otherDiscount: {
          name: `${values.discount}%`,
          percentageDiscount: (100 - values?.discount) / 100,
        },
      };
    }

    let checkQuantity = body.purchasedProducts.some((chk) => chk.qty === 0);
    if (!checkQuantity) {
      computeAmountPayable(body)
        .then((res: any) => {
          setAmount(res?.data);
          setHairLength(res?.data?.differentHairLength);
          for (const [
            index,
            product,
          ] of formik.values.addProductData.entries()) {
            const compareId = res?.data?.purchasedProducts.find(
              (p_id) => p_id.product._id === product.product
            );
            if (compareId) {
              formik.setFieldValue(
                `addProductData[${index}]amountPayable`,
                compareId.amountPayable
              );
            }
          }
        })
        .catch((err: any) => {
          toast.error(err.response?.data?.message || "Something went wrong.");
        });
    }
  };

  useEffect(() => {
    if (isDetailsSaved) {
      setIsDetailsSaved(false);
    }
  }, [formik.values]);

  useEffect(() => {
    let filedValidate = formik.values.addProductData.map(
      (product: any) => product.product === "" || product.qty === ""
    );
    if (selectedService && !filedValidate.includes(true)) {
      totalAmountPayable();
    }
  }, [formik.values.addProductData]);

  const getCompletedServiceProductAmount = (bookingDetail) => {
    let body: any = {
      serviceDetails: {
        serviceProduct: bookingDetail?.serviceDetails?.serviceProduct,
        packageSpecified: bookingDetail?.serviceDetails?.packageSpecified,
        masterSpecified: bookingDetail?.serviceDetails?.masterSpecified.id,
        isRedo: false,
        needHairCut: false,
        needExtraFeeForMasterSpecified: false,
      },
    };

    if (
      bookingDetail?.otherDiscount &&
      Object.keys(bookingDetail?.otherDiscount).length &&
      bookingDetail?.otherDiscount.percentageDiscount !== 0 &&
      bookingDetail?.otherDiscount.percentageDiscount !== 1
    ) {
      body = {
        ...body,
        otherDiscount: bookingDetail?.otherDiscount,
      };
    }

    computeAmountPayable(body)
      .then((res: any) => {
        setServiceProductAmount(res?.data?.amountPayable);
      })
      .catch((err: any) => {
        toast.error(err.response?.data?.message || "Something went wrong.");
      });
  };

  const handleProductChange = (selectedOption, index) => {
    if (selectedOption) {
      formik.setFieldValue(
        `addProductData[${index}].product`,
        selectedOption.value
      );
      formik.setFieldValue(
        `addProductData[${index}].selectedProductObject`,
        selectedOption
      );
    }
  };

  const statusBtnStyles = {
    active: {
      background: "#1BC5BD",
      borderRadius: 0,
      fontWeight: 400,
      color: "white",
    },
    inActive: {
      background: "#E5EAEE",
      borderRadius: 0,
      fontWeight: 400,
      color: "black",
    },
    resume: {
      position: "absolute",
      top: "1.5%",
      right: "77%",
      background: "#1BC5BD",
      color: "white",
      border: "none",
      outline: "none",
      borderRadius: "0.3rem",
      height: "40px",
    },
  };
  const toResume = ["97$cancelled", "98$absent", "99$completed"];

  const handleStatus = (value: string) => {
    updateStatus(value);
  };

  const completeStatus = () => {
    setLoader(true);
    const reqBody = {
      adminDeduct: adminDiscount,
      creditDeduct: 0,
      creditGain: 0,
      paymentMethod: paymentMethod,
    };
    const BookingId = booking.id;
    completeBooking(BookingId, reqBody)
      .then((res: any) => {
        setLoader(false);
        if (res.status === 200) {
          toast.success("Data add Sucessfull");
          history.push("/booking");
        }
      })
      .catch((err) => {
        toast.error("Can't complete the future booking");
        setLoader(false);
      });
  };

  const updateStatus = (status: string) => {
    const body = {
      status: status,
    };
    const BookingId = booking.id;
    updateBooking(body, BookingId)
      .then((res: any) => {
        if (res.data.status) {
          toast.success("status Updated successfully");
          formik.setFieldValue("status", body.status);
          if (body.status === "99$completed") {
            window.location.reload();
          }
        }
      })
      .catch((err) => {
        toast.error("Can Not Update Status");
      });
  };

  const handleLateChange = (e: any) => {
    const bookingId = booking.id;
    updateBooking(
      e.target.checked ? { late: true } : { late: false },
      bookingId
    )
      .then((res: any) => {
        toast.success("Status Updated Successfully");
        setIsLate((state) => !state);
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || "Something went wrong.");
      });
  };

  if (toResume.includes(formik.values.status))
    statusBtnStyles.active.background = "rgb(167 167 167)";

  useEffect(() => {
    getCustomerDetails();
    getBranchDetails();
    // eslint-disable-next-line
  }, []);

  const handleVoid = () => {
    voidBooking(booking.id)
      .then((res: any) => {
        setIsVoided(true);
        setIsVoiding(false);
        toast.success("booking has been voided successfully.");
        const voidedBy = res?.data?.voidedBy;
        const voidedAt = res?.data?.voidedAt;
        setVoidData({ voidedBy, voidedAt });
        getBookingDetail();
      })
      .catch((e) => {
        toast.warn("Error occured");
        setIsVoiding(false);
      });
  };

  const serviceAmounts = amount?.purchasedProducts?.map(
    (x: any) => x?.amountPayable
  );

  // hairLength &&
  //   Object.keys(hairLength).length &&
  //   formik.values.radio === "yes"
  //   ? hairLength.fee > 0
  //     ? numberFormater(hairLength.fee * hairLength.multiplier)
  //     : 0
  //   : numberFormater(serviceProductData?.listedPrice)

  const subTotalAmountValue: any =
    Number(
      formik.values.radio === "yes"
        ? hairLength && Object.keys(hairLength).length
          ? hairLength.fee > 0
            ? numberFormater(hairLength.fee * hairLength.multiplier)
            : 0
          : 0
        : numberFormater(amount?.singleTreatment?.listedPrice || 0)
    ) + sumArray(serviceAmounts);

  // hairLength &&
  //   Object.keys(hairLength).length &&
  //   formik.values.radio === "yes"
  //   ? hairLength.fee > 0
  //     ? numberFormater(hairLength.fee * hairLength.multiplier)
  //     : 0
  //   : numberFormater(serviceProductData?.listedPrice)

  const discountAmount =
    (Number(
      formik.values.radio === "yes"
        ? hairLength && Object.keys(hairLength).length
          ? hairLength.fee > 0
            ? numberFormater(hairLength.fee * hairLength.multiplier)
            : 0
          : 0
        : numberFormater(amount?.singleTreatment?.listedPrice || 0)
    ) /
      100) *
    Number(formik.values.discount);

  const totalAmountToShow = subTotalAmountValue - discountAmount;
  return (
    <div>
      <Modal show={showpaymodal} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Payment Method</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Credit Card</p>
          <ul style={{ paddingInlineStart: 0 }}>
            {["AE", "Visa", "Master"].map((item: any, index: any) => {
              return (
                <button
                  key={index}
                  onClick={() => {
                    setPaymentMethod(item);
                  }}
                  style={{ borderRadius: 10 }}
                  className={
                    paymentMethod === item
                      ? "btn btn-success mr-3 mb-3"
                      : "btn btn-secondary mr-3 mb-3"
                  }
                >
                  {item}
                </button>
              );
            })}
          </ul>
          <p>Other</p>
          <ul style={{ paddingInlineStart: 0 }}>
            {[
              "Cash",
              "Octopus Card",
              "Union Pay",
              "Payme",
              "Paypal",
              "Alipay HK",
              "Bank Transfer",
              "Cheque",
            ].map((item: any, index: any) => {
              return (
                <button
                  key={index}
                  onClick={() => {
                    setPaymentMethod(item);
                  }}
                  style={{ borderRadius: 10 }}
                  className={
                    paymentMethod === item
                      ? "btn btn-success mr-3 mb-3"
                      : "btn btn-secondary mr-3 mb-3"
                  }
                >
                  {item}
                </button>
              );
            })}
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn btn-secondary mr-3"
            onClick={() => setShowPayModal(false)}
          >
            Back
          </Button>
          <Button
            className="btn btn-success"
            onClick={() => {
              if (paymentMethod) {
                setIsCompleted(true);
                setShowPayModal(false);
              } else {
                toast.error("Please Select Payment Method");
              }
            }}
          >
            Complete
          </Button>
        </Modal.Footer>
      </Modal>
      <CommonModal
        show={isVoiding}
        onHide={setIsVoiding}
        functionToExecute={handleVoid}
        message="Do you confirm to void?"
      />
      <Card>
        {loader && <Loader />}
        <CardHeader title={"Booking Details"}>
          {toResume.includes(formik.values.status) &&
            booking?.status !== "99$completed" && (
              <button
                className="hide_wprint"
                style={{
                  position: "absolute",
                  top: "1.5%",
                  right: "77%",
                  background: "#1BC5BD",
                  color: "white",
                  border: "none",
                  outline: "none",
                  borderRadius: "0.3rem",
                  height: "40px",
                }}
                onClick={() => handleStatus("0$created")}
              >
                Resume
              </button>
            )}
          <div
            className="hide_wprint"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginRight: "10%",
            }}
          >
            {booking.status === "99$completed" ? (
              <>
                {isVoided ? (
                  <button
                    className="btn btn-outline-danger m-2 text-capitalised"
                    disabled
                  >
                    <span className="text-capitalize font-weight-bold">
                      Void By:
                    </span>
                    <span className="text-capitalize">
                      {voidData.voidedBy.name + " " || "Anonymous"}
                    </span>
                    <span className="text-capitalize font-weight-bold">
                      Void at:{" "}
                    </span>
                    {moment(voidData.voidedAt).format(
                      "DD/MM/YYYY (ddd) HH:mm"
                    ) || "00/00/0000"}
                  </button>
                ) : (
                  <button
                    className="btn btn-danger m-3 hide_wprint"
                    onClick={() => {
                      setIsVoiding(true);
                    }}
                  >
                    Void
                  </button>
                )}
                <button
                  className={
                    isVoided
                      ? "btn btn-outline-danger hide_wprint"
                      : "btn btn-outline-success hide_wprint"
                  }
                  disabled
                >
                  Completed
                </button>
              </>
            ) : (
              <>
                <div className="mr-4 fs-4 hide_wprint">
                  <Switch
                    checked={isLate}
                    onChange={handleLateChange}
                    disabled={toResume.includes(formik.values.status)}
                  />
                  Late
                </div>
                <Button
                  className="text-capitalize hide_wprint"
                  style={
                    formik.values.status === "0$created"
                      ? statusBtnStyles.active
                      : statusBtnStyles.inActive
                  }
                  onClick={() => handleStatus("0$created")}
                  disabled={toResume.includes(formik.values.status)}
                >
                  Booked
                </Button>
                <Button
                  className="text-capitalize hide_wprint"
                  style={
                    formik.values.status === "1$arrived"
                      ? statusBtnStyles.active
                      : statusBtnStyles.inActive
                  }
                  onClick={() => handleStatus("1$arrived")}
                  disabled={toResume.includes(formik.values.status)}
                >
                  Arrived
                </Button>
                <CommonModal
                  show={isConfirmCancel}
                  onHide={setIsComfirmCancel}
                  functionToExecute={updateStatus}
                  funcVal={"97$cancelled"}
                  message="Do you confirm to update the status to 'Cancelled' of this booking ?"
                />
                <Button
                  className="text-capitalize hide_wprint"
                  style={
                    formik.values.status === "97$cancelled"
                      ? statusBtnStyles.active
                      : statusBtnStyles.inActive
                  }
                  onClick={() => setIsComfirmCancel(true)}
                  disabled={toResume.includes(formik.values.status)}
                >
                  Cancelled
                </Button>
                <CommonModal
                  show={isConfirmAbsent}
                  onHide={setIsComfirmAbsent}
                  functionToExecute={updateStatus}
                  funcVal={"98$absent"}
                  message="Do you confirm to update the status to 'Absent' of this booking ?"
                />
                <Button
                  className="text-capitalize hide_wprint"
                  style={
                    formik.values.status === "98$absent"
                      ? statusBtnStyles.active
                      : statusBtnStyles.inActive
                  }
                  onClick={() => setIsComfirmAbsent(true)}
                  disabled={toResume.includes(formik.values.status)}
                >
                  Absent
                </Button>
              </>
            )}
          </div>
        </CardHeader>

        <CardBody>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="col-lg-4 ws-nowrap">Booking Id</div>
              <div className="col-lg-10">
                <div className="form-group">
                  <input
                    disabled
                    type="text"
                    className="form-control"
                    id="id"
                    name="id"
                    value={formik.values.id}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.id && formik.errors.id ? (
                    <div className="text-danger mt-1 ml-1">
                      {formik.errors.id}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="col-lg-4 ws-nowrap">Username</div>
              <div
                className={
                  Boolean(toResume.includes(formik.values.status))
                    ? "col-lg-10 select-disabled"
                    : "col-lg-10"
                }
              >
                <Select
                  name="username"
                  isDisabled={Boolean(toResume.includes(formik.values.status))}
                  options={customerList}
                  closeMenuOnSelect={true}
                  onChange={(opt: any) => {
                    formik.setFieldValue("username", opt.value);
                  }}
                  value={
                    customerList.filter(
                      (x: any) => x.value === formik.values.username
                    )[0]
                  }
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="col-lg-4 ws-nowrap">Service Date</div>
              <div className="col-lg-10">
                {" "}
                <div className="form-group">
                  {formik.values.status === "0$created" ||
                  formik.values.status === "1$arrived" ? (
                    <Datetime
                      inputProps={{
                        name: "date",
                        placeholder: "dd/MM/yyyy",
                      }}
                      value={moment(formik.values.date).format("DD-MM-YYYY")}
                      timeFormat={false}
                      dateFormat={"D/M/YYYY"}
                      onChange={onChangeTime}
                      closeOnSelect={true}
                    />
                  ) : (
                    <input
                      disabled={toResume.includes(formik.values.status)}
                      type="text"
                      className="form-control"
                      id="date"
                      name="date"
                      value={moment(formik.values.date).format("DD-MM-YYYY")}
                      onChange={formik.handleChange}
                    />
                  )}
                  {formik.touched.date && formik.errors.date ? (
                    <div className="text-danger mt-1 ml-1">
                      {formik.errors.date}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="col-lg-4 ws-nowrap">Service Time</div>
              <div className="col-lg-10">
                {" "}
                <div className="form-group">
                  {formik.values.status === "0$created" ||
                  formik.values.status === "1$arrived" ? (
                    <TimePicker
                      format={"HH:mm"}
                      className="time_picker"
                      value={moment(formik.values.date)}
                      onChange={(val) => {
                        formik.setFieldValue("date", moment(val).format());
                      }}
                    />
                  ) : (
                    <input
                      disabled={toResume.includes(formik.values.status)}
                      type="text"
                      className="form-control"
                      id="date"
                      name="date"
                      value={moment(formik.values.date).format("HH:mm")}
                      onChange={formik.handleChange}
                    />
                  )}
                  {formik.touched.date && formik.errors.date ? (
                    <div className="text-danger mt-1 ml-1">
                      {formik.errors.date}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="col-lg-4 ws-nowrap">Plate Number</div>
              <div className="col-lg-10">
                <div className="form-group">
                  <input
                    disabled={toResume.includes(formik.values.status)}
                    type="text"
                    className="form-control"
                    id="plateNo"
                    name="plateNo"
                    value={formik.values.plateNo}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.plateNo && formik.errors.plateNo ? (
                    <div className="text-danger mt-1 ml-1">
                      {formik.errors.plateNo}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="col-lg-4 ws-nowrap">Branch</div>
              <div className="col-lg-10">
                <div
                  className={
                    Boolean(toResume.includes(formik.values.status))
                      ? "form-group select-disabled"
                      : "form-group"
                  }
                >
                  <Select
                    isDisabled={Boolean(
                      toResume.includes(formik.values.status)
                    )}
                    name="branch"
                    options={branchList}
                    closeMenuOnSelect={true}
                    onChange={(opt: any) => {
                      formik.setFieldValue("branch", opt.value);
                    }}
                    value={
                      branchList.filter(
                        (x: any) => x.value === formik.values.branch
                      )[0]
                    }
                    className="basic-multi-select w-100"
                    classNamePrefix="select"
                    placeholder="-- Select Branch --"
                  />

                  {formik.touched.branch && formik.errors.branch ? (
                    <div className="text-danger mt-1 ml-1">
                      {formik.errors.branch}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>

          {/* NEW SERVICE */}
          <div className="row my-4 ">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <b className="col-lg-4 ws-nowrap">Service Bill </b>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="col-lg-4 ws-nowrap">Service</div>
              <div className="col-lg-10">
                <div className="form-group">
                  {formik.values.status === "0$created" ||
                  formik.values.status === "1$arrived" ? (
                    <Select
                      name="service"
                      options={serviceList}
                      closeMenuOnSelect={true}
                      onChange={(opt: any) => {
                        setSelectedService(opt.value);
                      }}
                      value={
                        serviceList.filter(
                          (x: any) => x.value === selectedService
                        )[0]
                      }
                      className="basic-multi-select w-100"
                      classNamePrefix="select"
                      placeholder="-- Select Service --"
                    />
                  ) : (
                    <input
                      disabled
                      className="form-control top-select rounded"
                      name="productName"
                      value={serviceProductData?.name}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      style={{ paddingRight: "3rem" }}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="col-lg-4 ws-nowrap">Amount</div>
              <div className="col-lg-10">
                <div className="form-group">
                  <input
                    disabled
                    type="text"
                    min={0}
                    className="form-control"
                    id="amount"
                    name="productPrice"
                    // value={
                    //   hairLength &&
                    //   Object.keys(hairLength).length &&
                    //   formik.values.radio === "yes"
                    //     ? hairLength.fee >= 0
                    //       ? `$${numberFormater(
                    //           hairLength.fee * hairLength.multiplier
                    //         )}`
                    //       : `$0`
                    //     : `$${numberFormater(
                    //         amount?.singleTreatment?.listedPrice || 0
                    //       )}` // `$${numberFormater(serviceProductData?.listedPrice)}`
                    // }
                    value={
                      formik.values.radio === "yes"
                        ? hairLength && Object.keys(hairLength).length
                          ? hairLength.fee > 0
                            ? `$${numberFormater(
                                hairLength.fee * hairLength.multiplier
                              )}`
                            : "$0"
                          : "$0"
                        : `$${numberFormater(
                            amount?.singleTreatment?.listedPrice || 0
                          )}` // `$${numberFormater(serviceProductData?.listedPrice)}`
                    }
                    onChange={formik.handleChange}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="col-lg-4 ws-nowrap" aria-disabled>
                Use package
              </div>
              <div className="col-lg-8">
                <div className="d-flex">
                  <RadioGroup
                    row
                    name={
                      toResume.includes(formik.values.status)
                        ? "disabled"
                        : "radio"
                    }
                    value={formik.values.radio}
                    onChange={(e) => {
                      formik.handleChange(e);
                      if (e.target.value === "yes") {
                        formik.setFieldValue("discount", 0);
                      } else {
                        formik.setFieldValue("package", null);
                      }
                    }}
                  >
                    <FormControlLabel
                      value="yes"
                      control={<Radio color="primary" />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio color="primary" />}
                      label="No"
                    />
                  </RadioGroup>
                </div>
                {formik.touched.radio && formik.errors.radio ? (
                  <div className="text-danger mt-n3">{formik.errors.radio}</div>
                ) : null}
              </div>
            </div>
            {formik.values.radio === "yes" ? (
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="col-lg-4 ws-nowrap">Package</div>
                <div className="col-lg-10">
                  <div className="input-group relative d-flex align-items-center">
                    <select
                      className="form-control top-select rounded"
                      name="package"
                      value={formik.values.package}
                      disabled={toResume.includes(formik.values.status)}
                      onChange={formik.handleChange}
                      style={{ paddingRight: "3rem" }}
                    >
                      <option
                        value=""
                        disabled={toResume.includes(formik.values.status)}
                      >
                        -- Select --
                      </option>
                      {packageList.map((data) => (
                        <option value={data.id}>{data.name}</option>
                      ))}
                    </select>
                    <ExpandMoreRoundedIcon
                      style={{
                        position: "absolute",
                        right: "1rem",
                        // zIndex: 10,
                      }}
                    />
                  </div>
                  {formik.touched.package && formik.errors.package ? (
                    <div className="text-danger mt-1 ml-1">
                      {formik.errors.package}
                    </div>
                  ) : null}
                </div>
              </div>
            ) : (
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="col-lg-10 col-sm-8 col-md-8">
                  <div>Discount Off</div>
                  <div className="form-group d-flex align-items-baseline">
                    <input
                      disabled={toResume.includes(formik.values.status)}
                      type="number"
                      className="form-control"
                      id="discount"
                      name="discount"
                      min={1}
                      max={100}
                      value={formik.values.discount}
                      onChange={formik.handleChange}
                    />
                    <h6>%</h6>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="row my-4 ">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <b className="col-lg-4 ws-nowrap">Product Bill</b>
            </div>
          </div>
          <div className="row align-items-center d-flex  mt-3 mb-3">
            <div className="col-6 d-flex">
              <div className="col-lg-5 col-md-5 col-sm-6 col-5 ws-nowrap">
                {!(
                  toResume.includes(formik.values.status) ||
                  formik.values.status === "99$completed"
                ) || formik.values.addProductData.length ? (
                  <div className="">
                    Product
                    {!(
                      toResume.includes(formik.values.status) ||
                      formik.values.status === "99$completed"
                    ) ? (
                      <span>
                        <button
                          className="btn btn-outline-dark mx-3 hide_wprint"
                          onClick={() => pushToAddProduct()}
                        >
                          Add Other Product
                        </button>
                      </span>
                    ) : null}
                  </div>
                ) : (
                  "No Product"
                )}
              </div>
            </div>
            {formik.values.addProductData.length > 0 ? (
              <>
                <div className="col-2 d-flex">
                  <div className="col-lg-5 col-md-5 col-sm-6 col-5 ws-nowrap">
                    <div className="">Quantity</div>
                  </div>
                </div>
                <div className="col-2 d-flex">
                  <div className="col-lg-5 col-md-5 col-sm-6 col-5 ws-nowrap">
                    <div className="">Amount</div>
                  </div>
                </div>
              </>
            ) : null}
          </div>
          <FormikProvider value={formik}>
            <FieldArray name="addProductData">
              {({ remove, push }) => {
                pushToAddProduct = () =>
                  push({
                    product: "",
                    qty: "1",
                    amountPayable: "",
                  });
                return (
                  <div>
                    {formik.values.addProductData.length > 0 &&
                      formik.values.addProductData.map(
                        (data: any, index: number) => (
                          <div className="row" key={index}>
                            <div className="col-lg-6 col-md-6 col-sm-6">
                              <div className="col-lg-10 col-sm-8 col-md-8">
                                <div className="input-group relative d-flex align-items-center">
                                  <Select
                                    name={`addProductData.${index}.product`}
                                    isDisabled={Boolean(
                                      toResume.includes(formik.values.status) ||
                                        formik.values.status === "99$completed"
                                    )}
                                    options={productList}
                                    closeMenuOnSelect={true}
                                    onChange={(productData) =>
                                      handleProductChange(productData, index)
                                    }
                                    value={
                                      params?.id
                                        ? productList.filter(
                                            (x) =>
                                              x.value ===
                                              formik.values.addProductData[
                                                index
                                              ]?.product
                                          )[0]
                                        : formik.values.addProductData[index]
                                            ?.selectedProductObject
                                    }
                                    className="basic-multi-select w-100"
                                    classNamePrefix="select"
                                    placeholder="-- Select Product --"
                                  />
                                </div>
                                <ErrorMessage
                                  name={`addProductData.${index}.product`}
                                  component="div"
                                  render={(errorMsg: any) => (
                                    <div className="text-danger">
                                      {errorMsg}
                                    </div>
                                  )}
                                ></ErrorMessage>
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 d-flex">
                              <div className="col-lg-4 col-md-4 col-sm-5 col-4  ws-nowrap ">
                                <div className="">
                                  <div className="form-group">
                                    <input
                                      disabled={toResume.includes(
                                        formik.values.status
                                      )}
                                      type="number"
                                      min={1}
                                      className="form-control"
                                      id="qty"
                                      name={`addProductData.${index}.qty`}
                                      value={
                                        formik.values.addProductData[index]
                                          .product
                                          ? formik.values.addProductData[index]
                                              .qty
                                            ? formik.values.addProductData[
                                                index
                                              ].qty
                                            : 1
                                          : undefined
                                      }
                                      onChange={formik.handleChange}
                                    />
                                    <ErrorMessage
                                      name={`addProductData.${index}.qty`}
                                      component="div"
                                      className="text-danger"
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-5 col-md-5 col-sm-6 col-5 ws-nowrap">
                                <div className="form-group">
                                  <input
                                    disabled
                                    type="text"
                                    min={0}
                                    className="form-control"
                                    id="amountPayable"
                                    name={`addProductData.${index}.amountPayable`}
                                    value={`$${numberFormater(
                                      formik.values.addProductData[index]
                                        .amountPayable
                                    )}`}
                                    // readOnly
                                    onChange={formik.handleChange}
                                  />
                                  <ErrorMessage
                                    name={`addProductData.${index}.amountPayable`}
                                    component="div"
                                    className="text-danger"
                                  />
                                </div>
                              </div>
                              {!toResume.includes(formik.values.status) &&
                                formik.values.status !== "99$completed" && (
                                  <img
                                    alt="Logo"
                                    className="align-self-baseline h-3 w-3 cursor-pointer"
                                    src={toAbsoluteUrl(
                                      "/media/svg/icons/common/delete.svg"
                                    )}
                                    onClick={() => {
                                      remove(index);
                                    }}
                                  />
                                )}
                            </div>
                          </div>
                        )
                      )}
                    {/* <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-12"></div>
                      <div className="col-lg-5 col-md-5 col-sm-12">
                        <div className=" d-flex justify-content-end sm:justify-content-end">
                        </div>
                      </div>
                    </div> */}
                  </div>
                );
              }}
            </FieldArray>
          </FormikProvider>
          <div className="row">
            <div className="col-lg-2 col-md-5 col-sm-12"></div>
            <div className="col-lg-1 col-md-5 col-sm-12"></div>
          </div>
          <div className="row flex-end d-flex">
            <div className="col-lg-6 col-md-6 col-sm-12"></div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <Table
                className="col-lg-10 justify-content-end mt-3 "
                bordered={false}
                variant="light"
                style={{ backgroundColor: "#ffffff" }}
              >
                <tbody>
                  <tr>
                    <th>Subtotal:</th>
                    <td style={{ textAlign: "right" }}>
                      ${numberFormater(subTotalAmountValue)}
                    </td>
                  </tr>
                  <tr>
                    <th>Discount (Service):</th>
                    <td style={{ textAlign: "right" }}>
                      $
                      {formik.values.discount && formik.values.discount != 0
                        ? numberFormater(discountAmount)
                        : 0}
                    </td>
                  </tr>

                  <tr>
                    <th>Cash Discount:</th>
                    <td
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        textAlign: "right",
                      }}
                    >
                      $
                      <input
                        disabled={toResume.includes(booking?.status)}
                        type="number"
                        style={{
                          textAlign: "right",
                          maxWidth: 100,
                          marginLeft: 8,
                        }}
                        className="form-control pr-0"
                        id="id"
                        name="id"
                        value={adminDiscount}
                        onChange={(e: any) => {
                          setAdminDiscount(e.target.value);
                        }}
                      />
                    </td>
                  </tr>

                  <tr>
                    <th>Total:</th>
                    <td style={{ textAlign: "right" }}>
                      $
                      {amount && amount.amountPayable >= 0
                        ? numberFormater(amount.amountPayable)
                        : 0}
                      {/* numberFormater(+serviceProductData?.listedPrice) */}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
          {booking?.status === "99$completed" ? (
            <div className="mt-4">
              <div
                className="col-lg-6 col-md-5 col-sm-12"
                style={{ display: "flex", alignItems: "flex-end" }}
              >
                <div className="row" style={{ width: "100%" }}>
                  <div className="col-lg-10 col-md-10 col-sm-12 pb-6">
                    <label>Payment Method</label>
                    <input
                      disabled
                      type="text"
                      min={0}
                      className="form-control"
                      id="amountPayable"
                      value={booking?.paymentMethod || ""}
                      // readOnly
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          <div className="row mt-4">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="col-lg-4 ws-nowrap">Remarks</div>
            </div>
            <div className="col-lg-12">
              <div className="col-lg-11">
                <div className="form-group">
                  <input
                    disabled={toResume.includes(formik.values.status)}
                    type="text"
                    value={formik.values.remark}
                    onChange={formik.handleChange}
                    className="form-control"
                    id="remark"
                    name="remark"
                    style={{ height: "50px" }}
                  />
                  {formik.touched.remark && formik.errors.remark ? (
                    <div className="text-danger mt-1 ml-1">
                      {formik.errors.remark}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </CardBody>

        <CardFooter className="hide_wprint">
          <div className="row">
            <div className="col-lg-5 col-md-6 col-sm-12">
              <div className="d-flex flex-wrap align-items-center justify-content-start">
                <div className="mt-4 mt-sm-0">
                  <CommonModal
                    show={isBack}
                    onHide={setIsBack}
                    functionToExecute={() => history.push("/booking")}
                    funcVal={false}
                    message="All the data will not be saved"
                  />
                  <div
                    className="btn btn-car-back"
                    onClick={() => {
                      // setIsBack(true)
                      if (isDetailsSaved) {
                        history.push("/booking");
                      }
                      setIsBack(true);
                    }}
                  >
                    Back
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="d-flex flex-wrap align-items-center justify-content-end">
                {formik.values.status === "0$created" ||
                formik.values.status === "1$arrived" ? (
                  <div className="mt-4 mt-sm-0">
                    <CommonModal
                      show={isCompleted}
                      onHide={setIsCompleted}
                      functionToExecute={completeStatus}
                      funcVal={"99$completed"}
                      message="Once Completed you can not edit"
                    />
                    <button
                      className={
                        !isDetailsSaved || formik.values.status === "0$created"
                          ? "btn btn-secondary mr-2 mx-sm-2"
                          : "btn btn-success mr-2 mx-sm-2"
                      }
                      disabled={
                        !isDetailsSaved || formik.values.status === "0$created"
                      }
                      onClick={() => setShowPayModal(true)}
                    >
                      Complete
                    </button>
                  </div>
                ) : null}
                {!toResume.includes(formik.values.status) && (
                  <div className="mt-4 mt-sm-0 mr-3">
                    <button
                      className="btn mr-2 mx-sm-2"
                      style={{ backgroundColor: "#D86B3C", color: "#ffffff" }}
                      type="submit"
                      onClick={() => saveBookingData()}
                    >
                      Compute & Save
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </CardFooter>
      </Card>
    </div>
  );
};

export default BookingEdit;
