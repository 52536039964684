import React, { useState, useRef, useEffect } from "react";
import { uploadAttachment } from "../../service/carService";
import SortableList, { SortableItem } from 'react-easy-sort'
import arrayMove from 'array-move'
import CloseRounded from "@material-ui/icons/CloseRounded";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    display: "flex",
    userSelect: "none"
  },
  item: {
    position: "relative",
    flexShrink: 0,
    display: "flex",
    margin: 8,
    cursor: "grab",
    userSelect: "none",
  },
  image: {
    pointerEvents: "none"
  },
  dragged: {
    boxShadow:
      "0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12)",
    "& button": {
      opacity: 0
    }
  }
});

const DragFileUpload = ({ isMultipleFileUpload, updateFileList, imageSrc, showRemoveIcon }) => {
  const classes = useStyles();
  const [isErrorMessage, setIsErrorMessage] = useState<string>("");
  const [AllImageID, setAllImageID] = useState<any>(imageSrc);
  const [SingleImage, setSingleImage] = useState<any>(imageSrc);

  useEffect(() => {
    setAllImageID(imageSrc);
    setSingleImage(imageSrc);
  }, [imageSrc]);

  const onSortEnd = (oldIndex: number, newIndex: number) => {
    setAllImageID((array) => arrayMove(array, oldIndex, newIndex))
  }

  useEffect(() => {
    if (AllImageID) {
      updateFileList(AllImageID)
    }
  }, [AllImageID])

  const inputFile = useRef<HTMLInputElement | null>(null);
  const mimeType: string[] = ["image/png", "image/jpeg"];

  const dropHandler = (ev) => {
    ev.preventDefault();
    setIsErrorMessage("");
    if (ev.dataTransfer.items) {
      for (let i = 0; i < ev.dataTransfer.items.length; i++) {
        if (ev.dataTransfer.items[i].kind === "file") {
          let files = ev.dataTransfer.items[i].getAsFile();
          if (files) {
            try {
              if (mimeType.includes(files.type)) {
                const formData = new FormData();
                formData.append("file", files);
                uploadAttachment(formData).then((res: any) => {
                  if (res && res.data && res.data._id) {
                    if (isMultipleFileUpload) {

                      AllImageID.push(res.data._id)
                      updateFileList(AllImageID);
                    } else {

                      updateFileList(res.data._id)
                    }
                  }
                });
              } else {
                setIsErrorMessage("Please select image file type only");
              }
            } catch (e) {
              setIsErrorMessage("Something went to wrong");
            }
          } else {
            setIsErrorMessage("Something went to wrong");
          }
        }
      }
    }
  };

  const dragOverHandler = (ev) => {
    // Prevent default behavior (Prevent file from being opened)
    ev.preventDefault();
  };


  const handleFile = (event: any) => {
    setIsErrorMessage("");
    const files = event.target.files;
    for (let i = 0; i < files.length; i++) {
      if (files[i]) {
        try {
          if (mimeType.includes(files[i].type)) {
            const formData = new FormData();
            formData.append("file", files[i]);
            uploadAttachment(formData).then((res: any) => {
              if (res && res.data && res.data._id) {
                if (isMultipleFileUpload) {
                  AllImageID.push(res.data._id)
                  updateFileList(AllImageID);
                } else {
                  updateFileList(res.data._id)
                }
              }
            });
          } else {
            setIsErrorMessage("Please select image file type only");
          }
        } catch (e) {
          setIsErrorMessage("Something went to wrong");
        }
      } else {
        setIsErrorMessage("Something went to wrong");
      }
    }
  };

  const onUploadFileButtonClick = () => {
    if (inputFile.current) inputFile.current.value = '';

    const fileUploadElement: any = document.getElementById("fileUpload");
    if (fileUploadElement) {
      fileUploadElement.click();
    }
  };

  const removeImageItemList = (removeImageId) => {
    let removeImageIds = AllImageID.filter((f) => f !== removeImageId)
    setAllImageID(removeImageIds)
    setSingleImage(null)
    updateFileList(removeImageIds)
  }

  const removeSingleImage = () => {
    setSingleImage(null)
    updateFileList(null)
  }

  return (
    <>
      <div
        style={{
          padding: "2rem",
          textAlign: "center",
          background: "#FFF",
          borderRadius: "0.5rem",
          borderStyle: "dashed",
          borderColor : "rgb(165, 165, 165)"
        }}
        onDrop={(e) => dropHandler(e)}
        onDragOver={(e) => dragOverHandler(e)}
        onClick={() => onUploadFileButtonClick()}
      >
        <p>Drop files here or click to upload</p>
        <input
          type="file"
          id="fileUpload"
          name="fileUpload"
          accept={mimeType.join(",")}
          onChange={handleFile}
          ref={inputFile}
          style={{ display: "none" }}
          multiple={isMultipleFileUpload}

        />
      </div>
      <b style={{ color: "red" }}>{isErrorMessage && isErrorMessage}</b>
      {
        AllImageID && AllImageID.length > 0 && isMultipleFileUpload ?
          (
            <div className="container mt-4 border">
              <div
                className="row pt-1 pb-2 "
                style={{ maxHeight: "250px", overflowX: "auto" }}
              >
                <SortableList onSortEnd={onSortEnd}
                  className={classes.root}
                  draggedItemClassName={classes.dragged}>
                  {AllImageID.map((item, index) => (
                    <div>
                      {index === 0 ? <p className="text-danger font-weight-bold" >Feature</p> : <p className="text-light">Images</p>}
                      <SortableItem key={item}>
                        <div className={classes.item}>
                          <CloseRounded className="position-absolute bg-secondary" onClick={(e) => removeImageItemList(item)} />
                          <img
                            className={classes.image}
                            src={`https://ad.uat.fletrix.hk/api/attachments/${item}/download`}
                            alt={`alt-11`}
                            width={150}
                            height={100}
                          />
                        </div>
                      </SortableItem>
                    </div>
                  ))}
                </SortableList>
              </div>
            </div>

          )
          :
          SingleImage && SingleImage.length > 0 &&
          <div className="mt-3">
            {showRemoveIcon && <CloseRounded className="position-absolute bg-secondary" onClick={(e) => removeSingleImage()} />}
            <img
              src={`https://ad.uat.fletrix.hk/api/attachments/${SingleImage}/download`}
              alt={`alt-11`}
              width={150}
              height={100} />
          </div>
      }
    </>
  );
};

export default DragFileUpload;
