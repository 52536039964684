import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
} from "../../../partials/controls";
import "react-quill/dist/quill.snow.css";
import { TimePicker } from "antd";
import moment from "moment";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  addBranch,
  getOneBranches,
  updateBranch,
} from "../../../service/carService";
import { toast } from "react-toastify";
import { useHistory, useParams } from "react-router";
import Loader from "../../../common/loader";
import { Button, Form } from "react-bootstrap";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
interface Values {
  nameCN: string;
  nameEN: string;
  qty: string;
  start: string;
  end: string;
  restTimeStart: string;
  restTimeEnd: string;
  addressCN: string;
  addressEN: string;
  avatar: any;
  status: string;
  phone: any;
}

interface Params {
  id?: string;
}

const AddBranch = () => {
  // const inputFile = useRef<HTMLInputElement | null>(null);
  const history = useHistory();
  // const [imgLoaded, setImgLoaded] = useState<boolean>(false);
  const [branch, setBranch] = useState<any>({});
  const [loader, setLoader] = useState<boolean>(false);
  const params: Params = useParams();
  const [isSwitchOn, setIsSwitchOn] = useState(true);
  const [workStart, setWorkStart] = useState<any>();
  const [workEnd, setWorkEnd] = useState<any>();
  const [haveRestTime, setHaveRestTime] = useState("Yes");
  const [restStart, setRestStart] = useState<any>();
  const [restEnd, setRestEnd] = useState<any>();

  const format = "HH:mm";

  const timeConvertMilliseconds = (time) => {
    const timeSplit = time.format("HH:mm").split(":");
    const timeInMilliseconds =
      Number(timeSplit[0]) * (60000 * 60) + Number(timeSplit[1]) * 60000;
    return timeInMilliseconds;
  };

  // const uploadImage = async (e: ChangeEvent) => {
  //   e.preventDefault();
  //   if (inputFile.current?.files) {
  //     setLoader(true);
  //     const formData = new FormData();
  //     formData.append("file", inputFile.current.files[0]);
  //     uploadImages(formData)
  //       .then((res: any) => {
  //         const fileURL = process.env.REACT_APP_IMAGE_URL + res.data.href;
  //         formik.setFieldValue("avatar", fileURL);
  //         toast.success("Image uploaded");
  //         setLoader(false);
  //       })
  //       .catch((err) => {
  //         setLoader(false);
  //         toast.error("banner not uploaded, please try again");
  //       });
  //   }
  // };

  useEffect(() => {
    if (params.id) {
      getOneBranchDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getOneBranchDetails = () => {
    setLoader(true);
    getOneBranches(params?.id)
      .then((res: any) => {
        setLoader(false);
        setBranch(res?.data);
        setIsSwitchOn(res?.data?.enabled);
        if (
          res &&
          res.data &&
          res.data.openingHours &&
          Object.keys(res.data.openingHours).length
        ) {
          setWorkStart(
            moment(
              moment.utc(res?.data?.openingHours?.start).format("HH:mm"),
              "HH:mm"
            )
          );
          setWorkEnd(
            moment(
              moment.utc(res?.data?.openingHours?.end).format("HH:mm"),
              "HH:mm"
            )
          );
        }

        if (
          res &&
          res.data &&
          res.data.restHours &&
          Object.keys(res.data.restHours).length
        ) {
          setHaveRestTime("Yes");
          setRestStart(
            moment(
              moment.utc(res?.data?.restHours?.start).format("HH:mm"),
              "HH:mm"
            )
          );
          setRestEnd(
            moment(
              moment.utc(res?.data?.restHours?.end).format("HH:mm"),
              "HH:mm"
            )
          );
        } else {
          setHaveRestTime("No");
        }
      })
      .catch((err) => {
        setLoader(false);
        toast.error(err.response?.data?.message || "Something went wrong.");
      });
  };

  const onUpdate = (value: Values) => {
    const newAddress = {
      zh: formik.values.addressCN,
      en: formik.values.addressEN,
    };
    const newName = {
      zh: formik.values.nameCN,
      en: formik.values.nameEN,
    };

    setLoader(true);
    const body = {
      name: JSON.stringify(newName),
      servicingCapacity: value.qty,
      openingHours: {
        start: timeConvertMilliseconds(workStart),
        end: timeConvertMilliseconds(workEnd),
      },
      restHours:
        haveRestTime === "Yes"
          ? {
              start: timeConvertMilliseconds(restStart),
              end: timeConvertMilliseconds(restEnd),
            }
          : {},
      address: JSON.stringify(newAddress),
      enabled: isSwitchOn,
      phone: value.phone,
    };
    updateBranch(params.id, body)
      .then((res: any) => {
        setLoader(false);
        if (res.data.status) {
          toast.success("Branch update Successful");
          history.push("/branches");
        }
      })
      .catch((err) => {
        setLoader(false);
        toast.error(err.response?.data?.message || "Something went wrong.");
      });
  };

  const onSubmit = (value: Values) => {
    setLoader(true);
    // let startTime = value.start.substring(0, 2);
    // let endTime = value.end.substring(0, 2);

    const newAddress = {
      zh: formik.values.addressCN,
      en: formik.values.addressEN,
    };
    const newName = {
      zh: formik.values.nameCN,
      en: formik.values.nameEN,
    };

    const body = {
      name: JSON.stringify(newName),
      servicingCapacity: value.qty,
      openingHours: {
        start: timeConvertMilliseconds(workStart),
        end: timeConvertMilliseconds(workEnd),
      },
      restHours:
        haveRestTime === "Yes"
          ? {
              start: timeConvertMilliseconds(restStart),
              end: timeConvertMilliseconds(restEnd),
            }
          : {},
      address: JSON.stringify(newAddress),
      enabled: isSwitchOn,
      phone: value.phone,
    };
    addBranch(body)
      .then((res: any) => {
        setLoader(false);
        if (res.data.status) {
          toast.success("Branch Add Successful");
          history.push("/branches");
        }
      })
      .catch((err) => {
        setLoader(false);
        toast.error(err.response?.data?.message || "Something went wrong.");
      });
  };

  const BranchSchema = Yup.object().shape({
    // status: Yup.string().required("status is required"),
    phone: Yup.number(),
  });

  const defaultAddress =
    params.id && branch?.address?.includes("{")
      ? JSON.parse(branch?.address)
      : null;

  const defaultName =
    params.id && branch?.name?.includes("{") ? JSON.parse(branch?.name) : null;

  const formik = useFormik({
    initialValues: {
      nameEN: params.id ? (defaultName ? defaultName?.en : branch.name) : "",
      nameCN: params.id ? (defaultName ? defaultName?.zh : branch.name) : "",
      qty: params.id ? branch?.servicingCapacity : "",
      start: params.id && workStart ? workStart : null,
      end: params.id && workEnd ? workEnd : null,
      restTimeStart: params.id && restStart ? restStart : null,
      restTimeEnd: params.id && restEnd ? restEnd : null,
      addressCN: params.id
        ? defaultAddress
          ? defaultAddress?.zh
          : branch?.address
        : "",
      addressEN: params.id
        ? defaultAddress
          ? defaultAddress?.en
          : branch?.address
        : "",
      avatar: "",
      status: params.id ? branch?.status : "",
      phone: params.id ? branch?.phone : "",
    },
    validationSchema: BranchSchema,
    enableReinitialize: true,
    onSubmit: (values: Values) => {
      params.id ? onUpdate(values) : onSubmit(values);
    },
  });

  const onSwitchAction = () => {
    setIsSwitchOn(!isSwitchOn);
  };

  const onChangeWorkstart = (time, timeString) => {
    formik.setFieldValue("start", time);
    setWorkStart(time);
  };

  const onChangeWorkEnd = (time) => {
    formik.setFieldValue("end", time);
    setWorkEnd(time);
  };
  const onChangeReststart = (time) => {
    formik.setFieldValue("restTimeStart", time);
    setRestStart(time);
  };
  const onChangeRestEnd = (time) => {
    formik.setFieldValue("restTimeEnd", time);
    setRestEnd(time);
  };
  return (
    <div>
      <Card>
        {loader && <Loader />}
        <CardHeader
          title={
            <>
              Branches &nbsp;&nbsp;
              {params.id ? (
                <Button
                  onClick={() => {
                    window.open(
                      `/branches/holiday/${params.id}/${branch?.name}`
                    );
                  }}
                >
                  Holidays
                </Button>
              ) : null}
            </>
          }
        ></CardHeader>
        <CardBody>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="col-lg-4 ws-nowrap">Branch Name (Chinese)</div>
              <div className="col-lg-8">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    id="title"
                    name="nameCN"
                    value={formik.values.nameCN}
                    onChange={formik.handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="col-lg-4 ws-nowrap">Branch Name (English)</div>
              <div className="col-lg-8">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    id="title"
                    name="nameEN"
                    value={formik.values.nameEN}
                    onChange={formik.handleChange}
                  />
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="col-lg-4 ws-nowrap">Capacity</div>
              <div className="col-lg-8">
                <div className="form-group">
                  <input
                    type="number"
                    className="form-control"
                    id="title"
                    name="qty"
                    value={formik.values.qty}
                    onChange={formik.handleChange}
                  />
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="col-lg-4 ws-nowrap">Phone Number</div>
              <div className="col-lg-8">
                {" "}
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    id="phone"
                    name="phone"
                    value={formik.values.phone}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.phone && formik.errors.phone ? (
                    <div className="text-danger mt-1 ml-1">
                      please enter valid number
                    </div>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="col-lg-4 ws-nowrap">Working Hours (Start)</div>
              <div className="col-lg-8">
                <div className="form-group">
                  <TimePicker
                    format={format}
                    className="time_picker"
                    onChange={onChangeWorkstart}
                    value={moment(
                      formik.values.start || moment("00:00", "HH:mm")
                    )}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="col-lg-4 ws-nowrap ">Working Hours (End)</div>
              <div className="col-lg-8">
                <div className="form-group">
                  <TimePicker
                    format={format}
                    className="time_picker"
                    onChange={onChangeWorkEnd}
                    value={moment(
                      formik.values.end || moment("00:00", "HH:mm")
                    )}
                  />
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="col-lg-4 ws-nowrap">Have Rest Time?</div>
              <div className="col-lg-8">
                <div className="form-group">
                  <select
                    className="form-control custom-select"
                    value={haveRestTime}
                    onChange={(e) => {
                      setHaveRestTime(e.target.value);
                    }}
                  >
                    <option value={"Yes"}>Yes</option>
                    <option value={"No"}>No</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12"></div>
          </div>

          {haveRestTime === "Yes" ? (
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="col-lg-4 ws-nowrap">Rest Time (Start)</div>
                <div className="col-lg-8">
                  <div className="form-group">
                    <TimePicker
                      format={format}
                      className="time_picker"
                      onChange={onChangeReststart}
                      value={moment(
                        formik.values.restTimeStart || moment("00:00", "HH:mm")
                      )}
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="col-lg-4 ws-nowrap">Rest Time (End)</div>

                <div className="col-lg-8">
                  <div className="form-group">
                    <TimePicker
                      format={format}
                      className="time_picker"
                      onChange={onChangeRestEnd}
                      value={moment(
                        formik.values.restTimeEnd || moment("00:00", "HH:mm")
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          <div className="">
            <div className="col-12 col-sm-2 mb-4 mb-sm-0  ws-nowrap">
              Location (Chinese)
            </div>
            <div className="col-10">
              <div className="form-group">
                <textarea
                  className="form-control"
                  id="title"
                  name="addressCN"
                  value={formik.values.addressCN}
                  onChange={formik.handleChange}
                />
              </div>
            </div>
          </div>

          <div className="">
            <div className="col-12 col-sm-2 mb-4 mb-sm-0  ws-nowrap">
              Location (English)
            </div>
            <div className="col-10">
              <div className="form-group">
                <textarea
                  className="form-control"
                  id="title"
                  name="addressEN"
                  value={formik.values.addressEN}
                  onChange={formik.handleChange}
                />
              </div>
            </div>
          </div>

          <div className="row" style={{ marginLeft: "0.5px" }}>
            <div className="col-12 col-sm-3 mb-4 mb-sm-0 ws-nowrap d-flex justify-content-between align-items-center">
              <span>Status</span>
              <FormControlLabel
                control={
                  <Switch
                    checked={isSwitchOn}
                    onChange={onSwitchAction}
                    color="primary"
                    name="status"
                  />
                }
                label={isSwitchOn ? "Active" : "Inactive"}
                className="mb-0"
              />
            </div>
          </div>
        </CardBody>

        <CardFooter>
          <div className="row">
            <div className="col-lg-10">
              <div className="d-flex flex-wrap align-items-center justify-content-end">
                <div className="mt-4 mt-sm-0">
                  <button
                    className="btn btn-success mr-2 mx-sm-2"
                    onClick={() => formik.handleSubmit()}
                  >
                    Submit
                  </button>
                </div>
                <div className="mt-4 mt-sm-0">
                  <Link className="btn btn-car-back" to="/branches">
                    Back
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </CardFooter>
      </Card>
    </div>
  );
};

export default AddBranch;
