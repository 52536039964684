import axios from "axios";
import { DevURL } from "../constant/defalut";
import { LOCAL_STORAGE_KEYS } from "../constant/defalut"

export const Bearer = "Bearer ";

// axios.interceptors.response.use(
//   function (response) {
//     return response;
//   },
//   function (error) {
//     if (
//       error.response.status === 402 ||
//       error.response.status === 500 ||
//       error.response.status === 403
//     ) {
//       localStorage.clear();
//       window.location.assign("/login");
//     } else {
//       return Promise.reject(error);
//     }
//   }
// );

const axoisAPI = {
  post: (url, data?) => {
    return axios.post(DevURL + url, data, { withCredentials: true });
  },

  get: (url) => {
    return axios.get(DevURL + url, { withCredentials: true });
  },

  postwithToken: (url, data?) => {
    //  return axios({
    //    method: "POST",
    //    data,
    //    headers:{
    //     'Content-Type': 'application/json',
    //     'Authorization': localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN) || "",
    //     'withCredentials': true
    //    },
    //    url: DevURL + url,
    //  })
    return axios.post(DevURL + url, data, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        // Authorization:
        //   localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN) || "",
      },
    });
  },

  putwithToken: (url?:any, data?:any) => {
    // return axios({
    //   method: "PATCH",
    //   data,
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization:
    //       localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN) || "",
    //   },
    //   url: DevURL + url,
    // });
    console.log("data=>>>>", data)
    return axios.patch(DevURL + url, data, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        // Authorization:
        //   localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN) || "",
      },
    });
  },

  getwithToken: (url) => {
    // return axios({
    //   method: "get",
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization:
    //       localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN) || "",
    //   },
    //   url: DevURL + url,
    // });
    return axios.get(DevURL + url, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        // Authorization:
        //   localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN) || "",
      },
    });
  },

  deletewithToken: (url) => {
    // return axios({
    //   method: "delete",
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization:
    //       localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN) || "",
    //   },
    //   url: DevURL + url,
    // });
    return axios.delete(DevURL + url, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        // Authorization:
        //   localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN) || "",
      },
    });
  },
};

export default axoisAPI;
