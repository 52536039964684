import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import {
  addBranch,
  getBranchsDetails,
  updateBooking,
} from "../../../service/carService";
import Loader from "../../../common/loader";
import Select from "react-select";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import * as Yup from "yup";

const ChangeBranchModal = ({
  show,
  onHide,
  onMainHide,
  serviceProduct,
  id,
  getCalenderLatestData,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [branchList, setBranchList] = useState<any>([]);
  const [branch, setBranchArr] = useState([]);

  const getBranchList = () => {
    setLoading(true);
    getBranchsDetails()
      .then((res: any) => {
        setLoading(false);
        const branchArr: any = res.data.docs.map((branch) => {
          return { value: branch.id, label: branch.name };
        });
        setBranchArr(branchArr);
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || "Something went wrong.");
        setLoading(false);
      });
  };

  const branchUpdate = () => {
    const body = {
      serviceDetails: {
        serviceProduct: serviceProduct,
        masterSpecified: formik.values.branch,
      },
    };

    updateBooking(body, id)
      .then((res: any) => {
        setLoading(false);
        if (res.data.status) {
          toast.success("Branch Updated successfully");
          onHide(true);
          onMainHide(true);
          getCalenderLatestData();
        }
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || "Something went wrong.");
        setLoading(false);
      });
  };

  useEffect(() => {
    getBranchList();
    const branchArr: any = branchList.map((branch) => {
      const defaultName = branch.name?.includes("{")
        ? JSON.parse(branch.name)
        : null;
      return {
        value: branch.id,
        label: defaultName ? defaultName?.en : branch.name,
      };
    });
    setBranchArr(branchArr);
  }, []);

  const changeBranchSchema = Yup.object().shape({
    branch: Yup.string().required("Branch is required"),
  });

  const formik = useFormik({
    initialValues: {
      branch: "",
    },

    validationSchema: changeBranchSchema,

    onSubmit: () => {
      branchUpdate();
    },
  });

  const handleBranchChange = (selectedBranch) => {
    formik.setFieldValue("branch", selectedBranch.value);
  };

  return (
    <div>
      {loading && <Loader />}
      <Modal
        centered
        show={show}
        onHide={() => {
          onHide();
          onMainHide(true);
        }}
        dialogClassName="modal-90w"
        // size="sm"
        aria-labelledby="example-modal-sizes-title-sm"
        className="service-modal"
      >
        <Modal.Body className="show-grid">
          <div className="d-flex justify-content-between">
            <div className="modal-header-text">Change Branch</div>
            <div
              className="modal-close"
              onClick={() => {
                onHide();
                onMainHide(true);
              }}
            >
              x
            </div>
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="ws-nowrap">Branch</div>
            <div className="">
              <Select
                name="branch"
                options={branch}
                closeMenuOnSelect={true}
                onChange={handleBranchChange}
                className="basic-multi-select w-100"
                classNamePrefix="select"
                placeholder="-- Select Branch --"
              />

              {formik.touched.branch && formik.errors.branch ? (
                <div className="text-danger mt-1 ml-1">
                  {formik.errors.branch}
                </div>
              ) : null}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex flex-wrap align-items-center justify-content-between">
            <div className="mt-4 mt-sm-0">
              <button
                className="btn btn-success mr-2 mx-sm-2"
                onClick={() => formik.handleSubmit()}
              >
                Save
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ChangeBranchModal;
