import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
} from "../../../partials/controls";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import { Link, useHistory } from "react-router-dom";
import {
  createBookings,
  getBranchsDetails,
  getCustomersDetails,
  getDate,
  getTime,
} from "../../../service/carService";
import Loader from "../../../common/loader";
import { useFormik } from "formik";
import moment from "moment";
import { toast } from "react-toastify";
import Datetime from "react-datetime";
import * as Yup from "yup";
import "react-datetime/css/react-datetime.css";
import Select from "react-select";

function CreateBooking() {
  const [loading, setLoading] = useState<boolean>(false);
  const [customerList, setCustomerList] = useState<any>([]);
  const [selectedCustomer, setSelectedCustomer] = useState<any>(null);
  const [branchList, setBranchList] = useState<any>([]);
  const [selectedBranch, setSelectedBranch] = useState<any>(null);
  const [customDates, setCustomDates] = useState<any>();
  const [timeOption, setTimeOption] = useState<any>();
  const [disabledDateInput, setDisabledDateInput] = useState(true);
  const date = moment();
  const yesterday = moment().subtract(1, "day");
  const history = useHistory();
  const datePickersRef = useRef<any>();
  const [{ navigated, viewMode }, setState] = useState({
    navigated: 0,
    viewMode: "",
  });

  const getCustomerDetails = () => {
    setLoading(true);
    getCustomersDetails()
      .then((res: any) => {
        setLoading(false);
        // let customerData: any = [];
        // res?.data?.docs.map((customerDetails) => {
        //   const customerObj = {
        //     name: customerDetails.name,
        //     id: customerDetails._id,
        //     phone: customerDetails.phone,
        //   };
        //   customerData.push(customerObj);
        // });
        const customerData = res.data.docs.map((customer) => {
          return {
            value: customer._id,
            label: `${customer.phone} - ${customer.name}`,
          };
        });
        setCustomerList(customerData);
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || "Something went wrong.");
        setLoading(false);
      });
  };
  const handleCustomerChange = (selectedOption) => {
    if (selectedOption) {
      setSelectedCustomer(selectedOption);
      formik.setFieldValue("customer", selectedOption.value);
    } else {
      formik.setFieldValue("customer", "");
    }
  };

  const handleBranchChange = (selectedOption) => {
    if (selectedOption) {
      setSelectedBranch(selectedOption);
      formik.setFieldValue("branch", selectedOption.value);
    } else {
      formik.setFieldValue("branch", "");
    }
  };

  const getBranchDetails = () => {
    setLoading(true);
    getBranchsDetails()
      .then((res: any) => {
        setLoading(false);
        // let branchData: any = [];
        // res?.data?.docs.map((branchDetails) => {
        //   const branchObj = {
        //     name: branchDetails.name,
        //     id: branchDetails.id,
        //   };
        //   branchData.push(branchObj);
        // });
        const branchData = res.data.docs.map((branch) => {
          const defaultName = branch.name?.includes("{")
            ? JSON.parse(branch.name)
            : null;
          return {
            value: branch._id,
            label: defaultName ? defaultName?.en : branch.name,
          };
        });
        setBranchList(branchData);
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || "Something went wrong.");
        setLoading(false);
      });
  };

  const getDateByRange = () => {
    setLoading(true);
    const dateStart = moment()
      .startOf("month")
      .format("x");
    const dateEnd = moment()
      .endOf("month")
      .format("x");
    const mid = formik.values.branch;

    getDate(mid, dateStart, dateEnd)
      .then((res: any) => {
        setLoading(false);
        let dateData: any = [];
        res.data.histogram.map((data) => {
          data.full && dateData.push(moment(data.date).format("YYYY-MM-DD"));
        });
        setCustomDates(dateData);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const getTimeForSelectedDate = () => {
    setLoading(true);
    const selectedDate = moment(formik.values.date).format("x");
    const mid = formik.values.branch;
    getTime(mid, selectedDate)
      .then((res: any) => {
        setLoading(false);
        setTimeOption(res.data.histogram);
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || "Something went wrong.");
        setLoading(false);
      });
  };

  useEffect(() => {
    getCustomerDetails();
    getBranchDetails();
  }, []);

  const createBookingSchema = Yup.object().shape({
    customer: Yup.string().required("Customer is required"),
    branch: Yup.string().required("Branch is required"),
    carPlateNumber: Yup.string().required("carPlateNumber is required"),
    date: Yup.string().required("Booking date is required"),
    bookingTime: Yup.string().required("Service time is required"),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      customer: "",
      branch: "",
      carPlateNumber: "",
      date: "",
      bookingTime: "",
    },
    validationSchema: createBookingSchema,
    onSubmit: () => {
      createBooking();
    },
  });

  const onChangeDate = (date) => {
    formik.setFieldValue("date", date);
  };

  useEffect(() => {
    if (formik.values.branch != "") {
      getDateByRange();
      setDisabledDateInput(false);
    }
  }, [formik.values.branch]);

  useEffect(() => {
    if (formik.values.date != "") {
      getTimeForSelectedDate();
    }
  }, [formik.values.date]);

  const createBooking = () => {
    // const selectedCustomer: any = formik.values.customer;
    const currentTime = moment(formik.values.bookingTime, "x").toISOString();
    const afterTime = moment(formik.values.bookingTime, "x")
      .add(30, "minutes")
      .toISOString();
    const body = {
      serviceDetails: {
        serviceProduct: "62318678bb5e985de91d14e7",
        masterSpecified: formik.values.branch,
      },
      master: formik.values.branch,
      needCheckCapacity: true,
      status: "0$created",
      time: {
        start: currentTime,
        end: afterTime,
        duration: 1800000,
      },
      created_by: formik.values.customer,
      carPlate: formik.values.carPlateNumber,
      _timetableTime: {
        start: currentTime,
        end: afterTime,
        duration: 1800000,
      },
    };

    createBookings(body)
      .then((res: any) => {
        setLoading(false);
        if (res.data) {
          history.push("/booking");
          toast.success("Booking created successfully");
        }
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || "Something went wrong.");
        setLoading(false);
      });
  };

  const disableCustomDt = (current) => {
    if (customDates && customDates.length) {
      return (
        !customDates.includes(current.format("YYYY-MM-DD")) &&
        current.isAfter(yesterday)
      );
    } else {
      return current.isAfter(yesterday);
    }
  };

  const onChangeBranch = () => {
    formik.setFieldValue("date", "");
  };

  const onNavigate = () =>
    setState((prevState) => ({
      ...prevState,
      navigated: prevState.navigated + 1,
    }));

  const onViewMode = useCallback((localViewMode) => {
    setState((prevState) => ({ ...prevState, viewMode: localViewMode }));
  }, []);

  useLayoutEffect(() => {
    // formik.setFieldValue("date", "")
    if (datePickersRef.current) {
      const viewedDate = datePickersRef.current.querySelector(".rdtSwitch")
        .textContent;
      const [date] = viewedDate.match(/\w{3,9} \d{4}/) ?? []; // matches 'May 2021', 'September 2025'

      if (date) {
        setLoading(true);
        const navigateMonthStartDate = moment(date)
          .startOf("month")
          .format("x");
        const navigateMonthEndDate = moment(date)
          .endOf("month")
          .format("x");

        getDate(
          formik.values.branch,
          navigateMonthStartDate,
          navigateMonthEndDate
        )
          .then((res: any) => {
            setLoading(false);
            let dateData2: any = [];
            res.data.histogram.map((data) => {
              data.full &&
                dateData2.push(moment(data.date).format("YYYY-MM-DD"));
            });
            setCustomDates(dateData2);
          })
          .catch((err) => {
            toast.error(err.response?.data?.message || "Something went wrong.");
            setLoading(false);
          });
      }
    }
  }, [navigated, viewMode]);

  return (
    <Card>
      {loading && <Loader />}
      <CardHeader title={"Create Booking"}></CardHeader>
      <CardBody>
        <div className="row pl-4">
          <div className="col">
            <h4>Booking Info</h4>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className="col-lg-4 ws-nowrap">Customer</div>
            <div className="col-lg-8">
              <div className="input-group relative d-flex align-items-center">
                {/* <select
                  className="form-control top-select rounded"
                  name="customer"
                  value={formik.values.customer}
                  onChange={formik.handleChange}
                  style={{ paddingRight: "3rem" }}
                >
                  <option value="" disabled>
                    -- Select --
                  </option>
                  {customerList.map((customer) => (
                    <option value={customer.id}>
                      {customer.phone} - {customer.name}
                    </option>
                  ))}
                </select>
                <ExpandMoreRoundedIcon
                  style={{
                    position: "absolute",
                    right: "1rem",
                    // zIndex: 10,
                  }}
                /> */}
                <Select
                  name="customer"
                  options={customerList}
                  closeMenuOnSelect={true}
                  onChange={handleCustomerChange}
                  value={selectedCustomer}
                  className="basic-multi-select w-100"
                  classNamePrefix="select"
                  placeholder="-- Select Customer --"
                />
              </div>
              {formik.touched.customer && formik.errors.customer ? (
                <div className="text-danger mt-1 ml-1">
                  {formik.errors.customer}
                </div>
              ) : null}
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className="col-lg-4 ws-nowrap">Branch</div>
            <div className="col-lg-8">
              <div className="input-group relative d-flex align-items-center">
                {/* <select
                  className="form-control top-select rounded"
                  name="branch"
                  value={formik.values.branch}
                  onChange={(e) => {
                    formik.handleChange(e);
                    onChangeBranch();
                  }}
                  style={{ paddingRight: "3rem" }}
                >
                  <option value="" disabled>
                    -- Select --
                  </option>
                  {branchList.map((branch) => (
                    <option value={branch.id}>{branch.name}</option>
                  ))}
                </select>
                <ExpandMoreRoundedIcon
                  style={{
                    position: "absolute",
                    right: "1rem",
                    // zIndex: 10,
                  }}
                /> */}
                <Select
                  name="branch"
                  options={branchList}
                  closeMenuOnSelect={true}
                  onChange={handleBranchChange}
                  value={selectedBranch}
                  className="basic-multi-select w-100"
                  classNamePrefix="select"
                  placeholder="-- Select Branch --"
                />
              </div>
              {formik.touched.branch && formik.errors.branch ? (
                <div className="text-danger mt-1 ml-1">
                  {formik.errors.branch}
                </div>
              ) : null}
            </div>
          </div>
        </div>

        <div className="row mt-5">
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className="col-lg-4 ws-nowrap">Service Date</div>
            <div className="col-lg-8">
              <div
                className="input-group relative d-flex align-items-center"
                ref={datePickersRef}
              >
                <Datetime
                  className="w-100"
                  value={formik.values.date ? moment(formik.values.date) : ""}
                  timeFormat={false}
                  renderInput={(props) => {
                    return (
                      <input
                        {...props}
                        value={formik.values.date != "" ? props.value : ""}
                      />
                    );
                  }}
                  dateFormat={"DD-MM-YYYY"}
                  onChange={onChangeDate}
                  isValidDate={disableCustomDt}
                  closeOnSelect={true}
                  onNavigateBack={() => {
                    onNavigate();
                    onChangeBranch();
                  }}
                  onNavigateForward={() => {
                    onNavigate();
                    onChangeBranch();
                  }}
                  onNavigate={onViewMode}
                  inputProps={{
                    disabled: disabledDateInput,
                    readOnly: disabledDateInput,
                  }}
                />
              </div>
              {formik.touched.date && formik.errors.date ? (
                <div className="text-danger mt-1 ml-1">
                  {formik.errors.date}
                </div>
              ) : null}
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className="col-lg-4 ws-nowrap">Service Time</div>
            <div className="col-lg-8">
              <div className="input-group relative d-flex align-items-center">
                <select
                  className="form-control top-select rounded"
                  name="bookingTime"
                  value={formik.values.bookingTime}
                  onChange={formik.handleChange}
                  style={{
                    paddingRight: "3rem",
                    backgroundColor:
                      formik.values.date && formik.values.branch != ""
                        ? "white"
                        : "#d1d3e0",
                  }}
                  disabled={
                    formik.values.date && formik.values.branch != ""
                      ? false
                      : true
                  }
                >
                  <option value="" disabled>
                    -- Select --
                  </option>
                  {timeOption?.map((time) => (
                    <option disabled={time.full} value={time.time}>
                      {moment(time.time).format("HH:mm")}
                    </option>
                  ))}
                </select>
                <ExpandMoreRoundedIcon
                  style={{
                    position: "absolute",
                    right: "1rem",
                    // zIndex: 10,
                  }}
                />
              </div>
              {formik.touched.bookingTime && formik.errors.bookingTime ? (
                <div className="text-danger mt-1 ml-1">
                  {formik.errors.bookingTime}
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className="col-lg-4 ws-nowrap">Plate Number</div>
            <div className="col-lg-8">
              <div className="form-group">
                <input
                  type="text"
                  value={formik.values.carPlateNumber}
                  onChange={formik.handleChange}
                  className="form-control"
                  id="title"
                  name="carPlateNumber"
                />
                {formik.touched.carPlateNumber &&
                formik.errors.carPlateNumber ? (
                  <div className="text-danger mt-1 ml-1">
                    {formik.errors.carPlateNumber}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </CardBody>

      <CardFooter>
        <div className="row">
          <div className="col-lg-10">
            <div className="d-flex flex-wrap align-items-center justify-content-end">
              <div className="mt-4 mt-sm-0">
                <button
                  className="btn btn-success mr-2 mx-sm-2"
                  onClick={() => formik.handleSubmit()}
                >
                  Submit
                </button>
              </div>
              <div className="mt-4 mt-sm-0">
                <Link className="btn btn-car-back" to="/booking">
                  Back
                </Link>
              </div>
            </div>
          </div>
        </div>
      </CardFooter>
    </Card>
  );
}

export default CreateBooking;
