import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
} from "../../../partials/controls";
import Select from "react-select";
import { Link, useHistory } from "react-router-dom";
import { toAbsoluteUrl } from "../../../helpers";
import { createCustomer } from "../../../service/carService";
import { toast } from "react-toastify";
import { districtList } from "../../../data/livingDistrict";
import ReactTagInput from "@pathofdev/react-tag-input";

function CreateCustomer() {
  const [cars, setCars] = useState([""]);
  const [tags, setTags] = useState([]);
  const [phonenumber, setPhonenumber] = useState("");
  const [username, setUsername] = useState("");
  const [district, setDistrict] = useState("");
  const [districtArr, setDistrictArr] = useState([]);
  const history = useHistory();
  const handleCarPlate = (e: any, index: number) => {
    let newCarValues = [...cars];
    newCarValues.splice(index, 1, e.target.value);
    setCars(newCarValues);
  };
  const handleCarRemove = (index: number) => {
    let newCars = [...cars];
    newCars.splice(index, 1);
    setCars(newCars);
  };
  const handleSubmit = () => {
    if (!tags || !cars[0] || !username || !phonenumber) {
      return toast.warn("All fields are required");
    }
    const labels = tags;

    const body = {
      role: "bn.client",
      name: username,
      phone: "+852" + phonenumber,
      metadata: {
        labels: labels,
        carPlates: cars,
      },
      // @ts-ignore
      homeDistrict: district.label,
    };
    createCustomer(body)
      .then((res) => {
        setUsername("");
        setPhonenumber("");
        // @ts-ignore
        setDistrict("");
        setCars([""]);
        setTags([]);
        toast.success("Customer created successfully");
        history.push("/customer");
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || "Something went wrong.");
      });
  };

  const handleDistrictChange = (selectedOption) => {
    if (selectedOption) {
      setDistrict(selectedOption);
    } else {
      setDistrict("");
    }
  };
  useEffect(() => {
    const districts: any = districtList.map((district) => {
      return { value: district, label: district };
    });
    setDistrictArr(districts);
  }, []);

  return (
    <Card>
      <CardHeader title={"Create Customer"}></CardHeader>
      <CardBody>
        <div className="row pl-4">
          <div className="col"></div>
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className="col-lg-4 ws-nowrap">Username</div>
            <div className="col-lg-8">
              <div className="input-group relative d-flex align-items-center">
                <input
                  type="text"
                  className="form-control"
                  id="username"
                  name="username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className="col-lg-4 ws-nowrap">Phone Number </div>
            <div className="col-lg-8">
              <div className="input-group relative d-flex align-items-center">
                <input
                  type="number"
                  className="form-control"
                  id="plateNo"
                  name="plateNo"
                  value={phonenumber}
                  onChange={(e) => setPhonenumber(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className="col-lg-4 ws-nowrap">Living Disrict</div>
            <div className="col-lg-8">
              <div className="input-group relative d-flex align-items-center">
                <Select
                  name="homeDistrict"
                  options={districtArr}
                  closeMenuOnSelect={true}
                  onChange={handleDistrictChange}
                  value={district}
                  className="basic-multi-select w-100"
                  classNamePrefix="select"
                  placeholder="-- Select District --"
                />
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className="col-lg-4 ws-nowrap">Customer Tag</div>
            <div className="col-lg-8">
              <div className="input-group relative d-flex align-items-center">
                <ReactTagInput
                  tags={tags}
                  // @ts-ignore
                  onChange={(newTags) => setTags(newTags)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className="col-lg-4 ws-nowrap">
              Plate Number
              <span>
                <button
                  className="btn btn-outline-dark mx-4 my-2"
                  onClick={() => setCars((state) => [...state, ""])}
                >
                  Add other car
                </button>
              </span>
            </div>
            {cars.map((value, index) => {
              return (
                <div className="col-lg-8" key={`${value}+${index}`}>
                  <div className="form-group">
                    <span className="d-flex flex-row">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Plate Number"
                        id="plateNo"
                        name="plateNo"
                        autoFocus
                        value={value}
                        onChange={(e) => handleCarPlate(e, index)}
                      />
                      {cars.length > 1 && (
                        <img
                          alt="Logo"
                          className="align-self-baseline h-3 w-3 px-2 cursor-pointer"
                          src={toAbsoluteUrl(
                            "/media/svg/icons/common/delete.svg"
                          )}
                          onClick={() => {
                            handleCarRemove(index);
                          }}
                        />
                      )}
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </CardBody>
      <CardFooter>
        <div className="row">
          <div className="col-lg-10">
            <div className="d-flex flex-wrap align-items-center justify-content-end">
              <div className="mt-4 mt-sm-0">
                <button
                  className="btn btn-success mr-2 mx-sm-2"
                  onClick={() => handleSubmit()}
                  type="submit"
                >
                  Submit
                </button>
              </div>
              <div className="mt-4 mt-sm-0">
                <Link className="btn btn-car-back" to="/customer">
                  Back
                </Link>
              </div>
            </div>
          </div>
        </div>
      </CardFooter>
    </Card>
  );
}

export default CreateCustomer;
