import React, { useState, useEffect } from "react";
import "react-quill/dist/quill.snow.css";
import { toast } from "react-toastify";
import { useHistory } from "react-router";
import { updateCustomer } from "../../../service/carService";
import { getOneCustomer } from "../../../service/carService";
import ReactTagInput from "@pathofdev/react-tag-input";
import { Card } from "react-bootstrap";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import * as Yup from "yup";
import CustomerBookings from "./customerBookings";
import {
  ErrorMessage,
  Field,
  FieldArray,
  FormikProvider,
  useFormik,
} from "formik";
import { toAbsoluteUrl } from "../../../helpers";
import Select from "react-select";
import { districtList } from "../../../data/livingDistrict";
import { useParams } from "react-router";
import CustomerPackages from "./CustomerPackages";
interface Params {
  id?: string;
}

const CustomerEdit = () => {
  const history = useHistory();
  const [id, setId] = useState("");
  const [loader, setLoader] = useState<boolean>(false);
  const [customer, setCustomer] = useState<any>({});
  const [tags, setTags] = React.useState([""]);
  const [isSwitchOn, setIsSwitchOn] = useState(false);
  const [district, setDistrictArr] = useState([]);
  const params = useParams();

  useEffect(() => {
    // @ts-ignore

    setId(params.id);
    if (id) {
      getOneCustomerDetails();
      const districtArr: any = districtList.map((district) => {
        return { value: district, label: district };
      });
      setDistrictArr(districtArr);
    }
  }, [id]);

  const getOneCustomerDetails = () => {
    setLoader(true);
    getOneCustomer(id)
      .then((res: any) => {
        setLoader(false);
        setTags(res?.data?._doc?.metadata?.labels);
        setCustomer(res?.data?._doc);
        setIsSwitchOn(res?.data?._doc.enabled);
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || "Something went wrong.");
        setLoader(false);
      });
  };

  const onUpdate = () => {
    const carPlateNo = formik.values.plateNo.filter((plate) => {
      return plate !== "";
    });
    setLoader(true);
    const phoneNumber = formik.values.phoneNo.replace("+852", "");
    const body = {
      name: formik.values.userName,
      phone: "+852" + phoneNumber,
      homeDistrict: formik.values.homeDistrict.value,
      metadata: {
        labels: tags,
        carPlates: carPlateNo,
      },
      enabled: isSwitchOn,
    };

    updateCustomer(id, body)
      .then((res: any) => {
        setLoader(false);
        if (res.status === 200) {
          toast.success("Customer Update Successful");
          // history.push("/customer");
        }
      })
      .catch((err) => {
        setLoader(false);
        toast.error(err.response?.data?.message || "Something went wrong.");
      });
  };

  const onSwitchAction = () => {
    setIsSwitchOn(!isSwitchOn);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      plateNo: customer?.metadata?.carPlates.length
        ? customer?.metadata?.carPlates
        : [""],
      phoneNo: customer?.phone ? customer.phone : "",
      userName: customer?.name ? customer.name : "",
      homeDistrict: customer?.homeDistrict
        ? { label: customer?.homeDistrict, value: customer?.homeDistrict }
        : { label: "", value: "" },
    },
    validationSchema: Yup.object({
      plateNo: Yup.array().of(Yup.string()),
    }),

    onSubmit: (values) => {
      onUpdate();
    },
  });

  const removeIndex = () => {
    if (formik.values.plateNo.length === 1) {
      formik.setFieldValue("plateNo", [""]);
    }
  };

  const handleDistrictChange = (selectedOption) => {
    if (selectedOption) {
      formik.setFieldValue("homeDistrict", selectedOption);
    } else {
      formik.setFieldValue("homeDistrict", "");
    }
  };

  return (
    <>
      <Card>
        <Card.Header>
          <Card.Title id="example-modal-sizes-title-lg">Customer</Card.Title>
        </Card.Header>
        <Card.Body>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="row">
                <div className="col-lg-4 ws-nowrap">Username</div>
                <div className="col-lg-8">
                  {" "}
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      id="title"
                      name="userName"
                      onChange={formik.handleChange}
                      value={formik.values.userName}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="row">
                <div className="col-lg-4 ws-nowrap">Phone Number</div>
                <div className="col-lg-8">
                  {" "}
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      id="title"
                      name="phoneNo"
                      onChange={formik.handleChange}
                      value={formik.values.phoneNo.replace("+852", "")}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="row">
                <div className="col-lg-4 ws-nowrap">Living District</div>
                <div className="col-lg-8">
                  {" "}
                  <div className="form-group">
                    <Select
                      name="homeDistrict"
                      options={district}
                      closeMenuOnSelect={true}
                      onChange={handleDistrictChange}
                      value={formik.values.homeDistrict}
                      className="basic-multi-select w-100"
                      classNamePrefix="select"
                      placeholder="-- Select District --"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="row">
                <div className="col-lg-4 ws-nowrap">Customer Tag</div>
                <div className="col-lg-8">
                  <div className="form-group">
                    <ReactTagInput
                      tags={tags}
                      onChange={(newTags) => setTags(newTags)}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-6 col-sm-12 mt-4  ">
              <div className="row">
                <div className="col-lg-4 ws-nowrap">Plate Number</div>
                <div className="col-lg-8">
                  <div className="form-group">
                    <FormikProvider value={formik}>
                      <FieldArray
                        name="plateNo"
                        render={({ remove, push }) => (
                          <>
                            {formik.values.plateNo.length > 0 &&
                              formik.values.plateNo.map((friend, index) => (
                                <div className="row" key={index}>
                                  <div className="col-10 form-group">
                                    <Field
                                      className="form-control"
                                      name={`plateNo.${index}`}
                                      type="text"
                                      value={formik.values.plateNo[index]}
                                      onChange={formik.handleChange}
                                      placeholder="Plate Number"
                                    />
                                    <ErrorMessage
                                      name={`plateNo.${index}`}
                                      component="div"
                                      className="text-danger"
                                    />
                                  </div>
                                  <div className="col-2">
                                    <div>
                                      <img
                                        alt="Logo"
                                        className="align-self-baseline h-3 w-3"
                                        src={toAbsoluteUrl(
                                          "/media/svg/icons/common/delete.svg"
                                        )}
                                        onClick={() => {
                                          remove(index);
                                          removeIndex();
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              ))}

                            <div className="row justify-content-end">
                              <div className="col-12">
                                {/* <img
                                alt="logo"
                                src={toAbsoluteUrl(
                                  `/media/svg/icons/common/addMore.svg`
                                )}
                                className="h-3 w-3 mr-2"
                                onClick={() => push("")}
                              /> */}
                                <button
                                  className="btn btn-outline-dark"
                                  onClick={() => push("")}
                                >
                                  Add Other Car
                                </button>
                              </div>
                            </div>
                          </>
                        )}
                      />
                      <br />
                    </FormikProvider>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="row">
                <div className="col-lg-4 ws-nowrap d-flex justify-content-between align-items-center">
                  <span>Status</span>
                  <FormControlLabel
                    control={
                      <Switch
                        onChange={onSwitchAction}
                        color="primary"
                        id="custom-switch"
                        checked={isSwitchOn}
                      />
                    }
                    label={isSwitchOn ? "Active" : "Inactive"}
                    className="mb-0"
                  />
                </div>
              </div>
            </div>
          </div>
        </Card.Body>

        <Card.Footer>
          <div className="d-flex flex-wrap align-items-center justify-content-between">
            <div className="mt-4 mt-sm-0">
              <button
                className="btn btn-success mr-2 mx-sm-2"
                onClick={() => formik.handleSubmit()}
              >
                Save
              </button>
            </div>
            <div className="mt-4 mt-sm-0">
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => history.push("/customer")}
              >
                Close
              </button>
            </div>
          </div>
        </Card.Footer>
      </Card>
      <Card className="mt-8">
        <CustomerBookings />
      </Card>
      <Card className="mt-8">
        <CustomerPackages customerName={customer?.name} />
      </Card>
    </>
  );
};

export default CustomerEdit;
