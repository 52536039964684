import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
} from "../../../partials/controls";
import "react-quill/dist/quill.snow.css";
import { toast } from "react-toastify";
import { useHistory, useParams } from "react-router";
import { useFormik } from "formik";
import Loader from "../../../common/loader";
import {
  AddProductListData,
  getoneProductList,
  updateProductList,
  getAllProductType,
} from "../../../service/carService";
import DragFileUpload from "../../../common/components/DragFileUpload";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Select from "react-select";

interface Values {
  name: string;
  listedPrice: string;
  discountedPrice: string;
  descriptionCN: string;
  descriptionEN: string;
  category: any;
  detailsCN: string;
  detailsEN: string;
  enabled: string;
  uiTag: string;
  tagColor: string;
  featuredImage: string;
  images: string[];
}

interface Params {
  id?: string;
}

const AddProductList = () => {
  const history = useHistory();
  const [isSwitchOn, setIsSwitchOn] = useState(true);
  const [productList, setProductList] = useState<any>([]);
  const [selectedCategory, setSelectedCategory] = useState<any>(null);
  const [product, setProduct] = useState<any>([]);
  const [loader, setLoader] = useState<boolean>(false);
  const [imageSrc, setImageSrc] = useState<string[]>([]);
  const params: Params = useParams();

  const onSubmit = (values: Values) => {
    const newDiscription = {
      zh: formik.values.descriptionCN,
      en: formik.values.descriptionEN,
    };

    const newDetails = {
      zh: formik.values.detailsCN,
      en: formik.values.detailsEN,
    };

    const body = {
      name: values.name,
      category: values.category,
      listedPrice: values.listedPrice,
      discountedPrice: values.discountedPrice,
      description: JSON.stringify(newDiscription),
      images: values.images,
      featuredImage: values.featuredImage,
      enabled: isSwitchOn,
      metadata: {
        uiTag: values.uiTag,
        details: JSON.stringify(newDetails),
        tag: "hard",
        tagColor: values.tagColor,
      },
    };
    setLoader(true);
    AddProductListData(body)
      .then((res: any) => {
        setLoader(false);
        if (res.status === 200) {
          toast.success("Product list add Sucessfull");
          history.push("/product/list");
        }
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || "Something went wrong.");
        setLoader(false);
      });
  };

  const onUpdate = (values: Values) => {
    const newDiscription = {
      zh: formik.values.descriptionCN,
      en: formik.values.descriptionEN,
    };

    const newDetails = {
      zh: formik.values.detailsCN,
      en: formik.values.detailsEN,
    };

    const body = {
      name: values.name,
      category: values.category,
      listedPrice: values.listedPrice,
      discountedPrice: values.discountedPrice,
      description: JSON.stringify(newDiscription),
      images: values.images,
      featuredImage: values.featuredImage,
      enabled: isSwitchOn,
      metadata: {
        uiTag: values.uiTag,
        details: JSON.stringify(newDetails),
        tag: "hard",
        tagColor: values.tagColor,
      },
    };
    setLoader(true);
    updateProductList(params.id, body)
      .then((res: any) => {
        setLoader(false);
        if (res.status === 200) {
          toast.success("Product list update Sucessfull");
          history.push("/product/list");
        }
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || "Something went wrong.");
        setLoader(false);
      });
  };

  useEffect(() => {
    getproductListType();
    // if (params.id) {
    //   getproductListOne();
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getproductListOne = (arr) => {
    setLoader(true);
    getoneProductList(params.id)
      .then((res: any) => {
        setLoader(false);
        setProduct(res.data);
        setIsSwitchOn(res?.data?.enabled);
        const categoryData = arr.find((x) => x.value === res?.data?.category);
        setSelectedCategory({
          value: categoryData.value,
          label: categoryData.label,
        });
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || "Something went wrong.");
        setLoader(false);
      });
  };

  const getproductListType = () => {
    const isStatus = `&filter={"enabled":true}`;
    getAllProductType(1, 1000, isStatus)
      .then((res: any) => {
        const productArr = res.data.docs.map((product) => {
          return { value: product._id, label: product.title };
        });
        setProductList(productArr);
        if (params.id) {
          getproductListOne(productArr);
        }
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || "Something went wrong.");
      });
  };

  const handlePackageChange = (selectedOption) => {
    setSelectedCategory(selectedOption);
    if (selectedOption) {
      formik.setFieldValue("category", selectedOption.value);
    } else {
      formik.setFieldValue("category", "");
    }
  };

  const ProductSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    category: Yup.string().required("Category is required"),
    listedPrice: Yup.number().required("Price is required"),
    discountedPrice: Yup.number().required("Discount Price is required"),
    uiTag: Yup.string().required("Tag is required"),
    tagColor: Yup.string().required("Tag color is required"),
    descriptionCN: Yup.string().required("Description is required"),
    descriptionEN: Yup.string().required("Description is required"),
    detailsCN: Yup.string().required("Details is required"),
    detailsEN: Yup.string().required("Details is required"),
    featuredImage: Yup.string().required("Please select at least one image"),
    enabled: Yup.string().required("Status is required"),
  });

  const onSwitchAction = () => {
    setIsSwitchOn(!isSwitchOn);
  };

  const defaultDescription =
    params.id && product?.description?.includes("{")
      ? JSON.parse(product?.description)
      : null;

  const defaultDetails =
    params.id && product.metadata?.details?.includes("{")
      ? JSON.parse(product.metadata?.details)
      : null;

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: params.id ? product.name : "",
      category: params.id ? product.category : "",
      listedPrice: params.id ? product.listedPrice : "",
      discountedPrice: params.id ? product.discountedPrice : "",
      uiTag: params.id ? product.metadata?.uiTag : "",
      tagColor: params.id ? product.metadata?.tagColor : "",
      descriptionCN: params.id
        ? defaultDescription
          ? defaultDescription?.zh
          : product.description
        : "",
      descriptionEN: params.id
        ? defaultDescription
          ? defaultDescription?.en
          : product.description
        : "",
      detailsCN: params.id
        ? defaultDetails
          ? defaultDetails?.zh
          : product.metadata?.details
        : "",
      detailsEN: params.id
        ? defaultDetails
          ? defaultDetails?.en
          : product.metadata?.details
        : "",
      enabled: params.id ? product.enabled : true,
      featuredImage: params.id ? product?.featuredImage : "",
      images: params.id ? product?.images : [],
    },
    validationSchema: ProductSchema,
    onSubmit: (values: Values) => {
      params.id ? onUpdate(values) : onSubmit(values);
    },
  });

  const getImagePreview = (id: any) => {
    setImageSrc(id);
  };

  useEffect(() => {
    if (params.id && formik.values.images) {
      getImagePreview(formik.values.images);
    }
  }, [formik.values.images]);

  const manageImageIds = (id: any) => {
    formik.setFieldValue("featuredImage", id[0]);
    formik.setFieldValue("images", id);
  };

  return (
    <div>
      <Card>
        {loader && <Loader />}
        <CardHeader
          title={params.id ? "Product Detail" : "Create Product"}
        ></CardHeader>
        <CardBody>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="col-lg-4 ws-nowrap">Product Name</div>
              <div className="col-lg-8">
                {" "}
                <div className="form-group">
                  <input
                    type="text"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    className="form-control"
                    id="title"
                    name="name"
                  />
                  {formik.touched.name && formik.errors.name ? (
                    <div className="text-danger mt-1 ml-1">
                      {formik.errors.name}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="col-lg-4 ws-nowrap">Product Type</div>
              <div className="col-lg-8">
                <div className="input-group relative d-flex align-items-center">
                  {/* <select
                    className="form-control top-select rounded"
                    name="category"
                    value={formik.values.category}
                    onChange={formik.handleChange}
                    style={{ paddingRight: "3rem" }}
                  >
                    <option value="" disabled>
                      -- Select --
                    </option>
                    {productList.map((data) => (
                      <option value={data.id}>{data.name}</option>
                    ))}
                  </select>
                  <ExpandMoreRoundedIcon
                    style={{
                      position: "absolute",
                      right: "1rem",
                      // zIndex: 10,
                    }}
                  /> */}
                  <Select
                    name="category"
                    options={productList}
                    closeMenuOnSelect={true}
                    onChange={handlePackageChange}
                    value={selectedCategory}
                    className="basic-multi-select w-100"
                    classNamePrefix="select"
                    placeholder="-- Select Package Name --"
                  />
                </div>
                {formik.touched.category && formik.errors.category ? (
                  <div className="text-danger mt-1 ml-1">
                    {formik.errors.category}
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="col-lg-4 ws-nowrap">Price</div>
              <div className="col-lg-8">
                {" "}
                <div className="form-group">
                  <input
                    type="number"
                    value={formik.values.listedPrice}
                    onChange={formik.handleChange}
                    className="form-control"
                    id="title"
                    name="listedPrice"
                  />
                  {formik.touched.listedPrice && formik.errors.listedPrice ? (
                    <div className="text-danger mt-1 ml-1">
                      {formik.errors.listedPrice}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="col-lg-4 ws-nowrap">Discounted Price</div>
              <div className="col-lg-8">
                {" "}
                <div className="form-group">
                  <input
                    type="number"
                    value={formik.values.discountedPrice}
                    onChange={formik.handleChange}
                    className="form-control"
                    id="title"
                    name="discountedPrice"
                  />
                  {formik.touched.discountedPrice &&
                  formik.errors.discountedPrice ? (
                    <div className="text-danger mt-1 ml-1">
                      {formik.errors.discountedPrice}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="col-lg-4 ws-nowrap">Tag</div>
              <div className="col-lg-8">
                <div className="form-group">
                  <input
                    type="text"
                    onChange={formik.handleChange}
                    className="form-control"
                    value={formik.values.uiTag}
                    id="uiTag"
                    name="uiTag"
                  />
                  {formik.touched.uiTag && formik.errors.uiTag ? (
                    <div className="text-danger mt-1 ml-1">
                      {formik.errors.uiTag}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="col-lg-4 ws-nowrap">Tag Color</div>
              <div className="col-lg-8">
                <div className="input-group relative d-flex align-items-center">
                  <select
                    name="tagColor"
                    className="form-control"
                    value={formik.values.tagColor}
                    onChange={formik.handleChange}
                  >
                    <option disabled selected value="">
                      Select Tag Color
                    </option>
                    <option value="#000000">Black</option>
                    <option value="#D70000">Red</option>
                  </select>
                  <ExpandMoreRoundedIcon
                    style={{
                      position: "absolute",
                      right: "1rem",
                      // zIndex: 10,
                    }}
                  />
                </div>
                {formik.touched.tagColor && formik.errors.tagColor ? (
                  <div className="text-danger mt-1 ml-1">
                    {formik.errors.tagColor}
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          <div className="mt-4">
            <div className="col-12 col-sm-2 mb-4 mb-sm-0  ws-nowrap">
              Description (Chinese)
            </div>
            <div className="col-10">
              <div className="form-group">
                <textarea
                  className="form-control resize-none"
                  name="descriptionCN"
                  value={formik.values.descriptionCN}
                  onChange={formik.handleChange}
                />
                {formik.touched.descriptionCN && formik.errors.descriptionCN ? (
                  <div className="text-danger mt-1 ml-1">
                    {formik.errors.descriptionCN}
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          <div className="mt-4">
            <div className="col-12 col-sm-2 mb-4 mb-sm-0  ws-nowrap">
              Description (English)
            </div>
            <div className="col-10">
              <div className="form-group">
                <textarea
                  className="form-control resize-none"
                  name="descriptionEN"
                  value={formik.values.descriptionEN}
                  onChange={formik.handleChange}
                />
                {formik.touched.descriptionEN && formik.errors.descriptionEN ? (
                  <div className="text-danger mt-1 ml-1">
                    {formik.errors.descriptionEN}
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          <div className="mt-4">
            <div className="col-12 col-sm-2 mb-4 mb-sm-0  ws-nowrap">
              Detail (Chinese)
            </div>
            <div className="col-10">
              <div className="form-group">
                {/* <input
                  type="text"
                  onChange={formik.handleChange}
                  className="form-control"
                  value={formik.values.details}
                  id="title"
                  name="details"
                  style={{ height: "50px" }}
                /> */}

                <textarea
                  className="form-control resize-none"
                  name="detailsCN"
                  value={formik.values.detailsCN}
                  onChange={formik.handleChange}
                />
                {formik.touched.detailsCN && formik.errors.detailsCN ? (
                  <div className="text-danger mt-1 ml-1">
                    {formik.errors.detailsCN}
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          <div className="mt-4">
            <div className="col-12 col-sm-2 mb-4 mb-sm-0  ws-nowrap">
              Detail (English)
            </div>
            <div className="col-10">
              <div className="form-group">
                {/* <input
                  type="text"
                  onChange={formik.handleChange}
                  className="form-control"
                  value={formik.values.details}
                  id="title"
                  name="details"
                  style={{ height: "50px" }}
                /> */}

                <textarea
                  className="form-control resize-none"
                  name="detailsEN"
                  value={formik.values.detailsEN}
                  onChange={formik.handleChange}
                />
                {formik.touched.detailsEN && formik.errors.detailsEN ? (
                  <div className="text-danger mt-1 ml-1">
                    {formik.errors.detailsEN}
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-10 col-md-10 col-sm-12">
              <div className="col-lg-4 ws-nowrap">Upload Photo</div>
              <div className="col-lg-8">
                {" "}
                <DragFileUpload
                  isMultipleFileUpload={true}
                  updateFileList={(fileId: any) => manageImageIds(fileId)}
                  imageSrc={imageSrc}
                  showRemoveIcon={false}
                />
                {formik.touched.featuredImage ? (
                  <div className="text-danger mt-1 ml-1">
                    {formik.errors.featuredImage}
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          <div className="row" style={{ marginLeft: "0.5px" }}>
            <div className="col-12 col-sm-3 mt-4 mb-sm-0 ws-nowrap d-flex justify-content-between align-items-center">
              <span>Status</span>
              <FormControlLabel
                control={
                  <Switch
                    checked={isSwitchOn}
                    onChange={onSwitchAction}
                    color="primary"
                    name="status"
                  />
                }
                label={isSwitchOn ? "Active" : "Inactive"}
                className="mb-0"
              />
            </div>
          </div>
        </CardBody>

        <CardFooter>
          <div className="row">
            <div className="col-lg-10">
              <div className="d-flex flex-wrap align-items-center justify-content-end">
                <div className="mt-4 mt-sm-0">
                  <button
                    className="btn btn-success mr-2 mx-sm-2"
                    onClick={() => {
                      formik.handleSubmit();
                      formik.setFieldTouched("featuredImage");
                    }}
                  >
                    Submit
                  </button>
                </div>
                <div className="mt-4 mt-sm-0">
                  <Link className="btn btn-car-back" to="/product/list">
                    Back
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </CardFooter>
      </Card>
    </div>
  );
};

export default AddProductList;
