import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
} from "../../../partials/controls";
import "react-quill/dist/quill.snow.css";
import {
  getCompanyAccount,
  editCompanyAccount,
} from "../../../service/carService";
import { toast } from "react-toastify";
import Loader from "../../../common/loader";
import { useFormik } from "formik";

interface Values {
  fpsId: string;
  fpsAccount: string;
  bankName: string;
  bankAccount: string;
  bankNumber: string;
}

const CompanyAccount = () => {
  const [loader, setLoader] = useState<boolean>(false);
  const [account, setAccount] = useState<any>([]);

  useEffect(() => {
    getCompanyAccountDetails();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCompanyAccountDetails = () => {
    setLoader(true);
    getCompanyAccount()
      .then((res: any) => {
        setAccount(res?.data);
        setLoader(false);
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || "Something went wrong.");
        setLoader(false);
      });
  };

  const onUdateDate = (values: Values) => {
    setLoader(true);
    const body = {
      fpsId: values.fpsId,
      fpsAccount: values.fpsAccount,
      bankName: values.bankName,
      bankAccount: values.bankAccount,
      bankNumber: values.bankNumber,
    };
    editCompanyAccount(body)
      .then((res: any) => {
        setLoader(false);
        if (res.status === 200) {
          toast.success("CompanyAccount Update Successful");
        }
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || "Something went wrong.");
        setLoader(false);
      });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      fpsId: account?.fpsId ? (account?.fpsId as any) : "",
      fpsAccount: account?.fpsAccount ? (account.fpsAccount as any) : "",
      bankName: account?.bankName ? (account.bankName as any) : "",
      bankAccount: account?.bankAccount ? (account.bankAccount as any) : "",
      bankNumber: account?.bankNumber ? (account.bankNumber as any) : "",
    },
    onSubmit: (values: Values) => {
      onUdateDate(values);
    },
  });

  return (
    <div className="row justify-content-center mt-20">
      <div className="col-8 ">
        <Card>
          {loader && <Loader />}
          <CardHeader title={"Company Account"}></CardHeader>
          <CardBody>
            <div
              style={{
                color: "#000000",
                fontWeight: "500",
                paddingLeft: "15px",
              }}
            >
              FPS
            </div>
            <div className="row mt-2">
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="col-lg-8 ws-nowrap font-label-color">Type</div>
                <div className="col-lg-8 mt-1">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      value={formik.values.fpsId}
                      onChange={formik.handleChange}
                      name="fpsId"
                    />
                  </div>{" "}
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="col-lg-8 ws-nowrap font-label-color">
                  Account
                </div>
                <div className="col-lg-8 mt-1">
                  {" "}
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      value={formik.values.fpsAccount}
                      onChange={formik.handleChange}
                      name="fpsAccount"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                color: "#000000",
                fontWeight: "500",
                paddingLeft: "14px",
                marginTop: "20px",
              }}
            >
              BANK
            </div>
            <div className="row mt-2">
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="col-lg-8 ws-nowrap font-label-color">Bank</div>
                <div className="col-lg-8 mt-1">
                  {" "}
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      name="bankName"
                      value={formik.values.bankName}
                      onChange={formik.handleChange}
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="col-lg-8 ws-nowrap font-label-color">
                  Bank Account
                </div>
                <div className="col-lg-8 mt-1">
                  {" "}
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      name="bankAccount"
                      value={formik.values.bankAccount}
                      onChange={formik.handleChange}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row ">
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="col-lg-8 ws-nowrap font-label-color">
                  Bank Account Number
                </div>
                <div className="col-lg-8 mt-1">
                  {" "}
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      name="bankNumber"
                      value={formik.values.bankNumber}
                      onChange={formik.handleChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </CardBody>

          <CardFooter>
            <div className="row">
              <div className="col-lg-10">
                <div className="d-flex flex-wrap align-items-center justify-content-end">
                  <div className="mt-4 mt-sm-0">
                    <button
                      className="btn btn-success mr-2 mx-sm-2"
                      onClick={() => formik.handleSubmit()}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </CardFooter>
        </Card>
      </div>
    </div>
  );
};

export default CompanyAccount;
