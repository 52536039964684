import moment from "moment";
import api from "../common/api";

export const getLeasetNews = (
  page: number,
  limit: number,
  query: any,
  type?: any,
  sort?: any
) => {
  let q = "";
  if (query) {
    q = `&search=${query}`;
  }
  return new Promise(async (resolve, reject) => {
    return api
      .getwithToken(
        `notification/system?page=${page}&limit=${limit}${q}${type}${sort}`
      )
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const addLatestNews = (body: any) => {
  return new Promise(async (resolve, reject) => {
    return api
      .postwithToken(`notification/system`, body)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const voidBooking = (id: any) => {
  return new Promise(async (resolve, reject) => {
    return api
      .postwithToken(`bn/admin/booking/void/${id}`)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const getOneNews = (id: any) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getwithToken(`notification/system/${id}`)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const updateNews = (id: any, body) => {
  return new Promise(async (resolve, reject) => {
    return api
      .putwithToken(`notification/system/${id}`, body)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const getBranches = (
  page: number,
  limit: number,
  query: any,
  type?: string,
  sort?: string
) => {
  let q = "";
  if (query) {
    q = `&search=${query}`;
  }
  return new Promise(async (resolve, reject) => {
    return api
      .getwithToken(
        `bn/admin/masterProfiles?page=${page}&limit=${limit}${q}${type}${sort}`
      )
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const getServices = (
  page?: number,
  limit?: number,
  query?: any,
  filter?: any,
  sort?: any
) => {
  let q = "";
  if (query) {
    q = `&search=${query}`;
  }
  return new Promise(async (resolve, reject) => {
    return api
      .getwithToken(
        `bn/admin/PhysicalProducts?page=${page}&limit=${limit}${q}${filter}${sort}`
      )
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const getOneService = (id: any) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getwithToken(`bn/admin/PhysicalProducts/${id}`)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const addServices = (body: any) => {
  return new Promise(async (resolve, reject) => {
    return api
      .postwithToken(`bn/admin/PhysicalProducts`, body)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const updateServices = (id, body: any) => {
  return new Promise(async (resolve, reject) => {
    return api
      .putwithToken(`bn/admin/PhysicalProducts/${id}`, body)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const getPacakges = (
  page?: number,
  limit?: number,
  query?: any,
  filter?: any,
  sort?: any
) => {
  let q = "";
  if (query) {
    q = `&search=${query}`;
  }
  return new Promise(async (resolve, reject) => {
    return api
      .getwithToken(
        `bn/serviceProducts?page=${page}&limit=${limit}${q}${filter}${sort}`
      )
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const updatePackage = (id: any, body: any) => {
  return new Promise(async (resolve, reject) => {
    return api
      .putwithToken(`bn/admin/serviceProducts/${id}`, body)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};
export const AddPackageData = (body: any) => {
  return new Promise(async (resolve, reject) => {
    return api
      .postwithToken(`bn/admin/serviceProducts`, body)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const getServiceList = () => {
  return new Promise(async (resolve, reject) => {
    return api
      .getwithToken(
        `/bn/admin/serviceProducts/?filter={"enabled":true,"discountedPrice10":{"$ne":null}}&sort={"created_at": -1}&limit=-1&enabled=true`
      )
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const getOnePackageDetails = (id: any) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getwithToken(
        `bn/serviceProducts/${id}?filter={"discountedPrice10":{"$ne":null}}`
      )
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const getServiceProductDetails = (id: any) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getwithToken(`bn/serviceProducts/${id}`)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const getCustomer = (
  page?: number,
  limit?: number,
  query?: any,
  type?: any,
  sort?: any
) => {
  let q = "";

  if (query) {
    q = `&search=${query}`;
  }
  return new Promise(async (resolve, reject) => {
    return api
      .getwithToken(
        `bn/admin/ClientProfiles?page=${page}&limit=${limit}${q}${type}${sort}`
      )
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const getActiveCustomer = (
  page?: number,
  limit?: number,
  query?: any,
  type?: any
) => {
  let q = "";

  if (query) {
    q = `&search=${query}`;
  }
  return new Promise(async (resolve, reject) => {
    return api
      .getwithToken(
        `profiles?page=${page}&limit=${limit}${q}${type}&populate=user&filter={"role":"bn.client","enabled": true}`
      )
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const addBranch = (body: any) => {
  return new Promise(async (resolve, reject) => {
    return api
      .postwithToken(`bn/admin/masterProfiles`, body)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const uploadImages = (body) => {
  return new Promise(async (resolve, reject) => {
    return api
      .post(`/attachment/upload`, body)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const getOneBranches = (id: any) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getwithToken(`bn/admin/MasterProfiles/${id}`)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const updateBranch = (id: any, body: any) => {
  return new Promise(async (resolve, reject) => {
    return api
      .putwithToken(`bn/admin/masterProfiles/${id}`, body)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const getHolidays = (
  id: any,
  page?: number,
  limit?: number,
  query?: any
) => {
  let q = "";
  if (query) {
    q = `&search=${query}`;
  }
  return new Promise(async (resolve, reject) => {
    return api
      .getwithToken(
        `bn/admin/timetables?filter={"master":"${id}"}&page=${page}&limit=${limit}${q}`
      )
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const addHolidays = (body: any) => {
  return new Promise(async (resolve, reject) => {
    return api
      .postwithToken(`bn/admin/timetables`, body)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const createCustomer = (body: any) => {
  return new Promise(async (resolve, reject) => {
    return api
      .postwithToken("users", body)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};
export const deleteHolidays = (id: any) => {
  return new Promise(async (resolve, reject) => {
    return api
      .deletewithToken(`bn/admin/timetables/${id}`)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const getProductType = (
  page?: number,
  limit?: number,
  query?: any,
  type?: any,
  sort?: any
) => {
  let q = "";
  if (query) {
    q = `&search=${query}`;
  }
  return new Promise(async (resolve, reject) => {
    return api
      .getwithToken(
        `bn/admin/productCategories?page=${page}&limit=${limit}${q}${type}${sort}`
      )
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const getAllProductType = (
  page?: number,
  limit?: number,
  query?: any,
  type?: any
) => {
  let q = "";
  if (query) {
    q = `&search=${query}`;
  }
  return new Promise(async (resolve, reject) => {
    return api
      .getwithToken(
        `bn/admin/productCategories?page=${page}&limit=${limit}${q}`
      )
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const addProductType = (body: any) => {
  return new Promise(async (resolve, reject) => {
    return api
      .postwithToken(`bn/admin/productCategories`, body)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const agetOneProductType = (id: any) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getwithToken(`bn/admin/productCategories/${id}`)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const updateProductType = (id: any, body) => {
  return new Promise(async (resolve, reject) => {
    return api
      .putwithToken(`bn/admin/productCategories/${id}`, body)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const getOneCustomer = (id: any) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getwithToken(`bn/admin/ClientProfiles/${id}`)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const updateCustomer = (id: any, body: any) => {
  return new Promise(async (resolve, reject) => {
    return api
      .putwithToken(`bn/admin/ClientProfiles/${id}`, body)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const getBlacklist = (page, limit, query, sort) => {
  let q = "";
  if (query) {
    q = `&search=${query}`;
  }
  return new Promise(async (resolve, reject) => {
    return api
      .getwithToken(`blacklist/rules?page=${page}&limit=${limit}${q}${sort}`)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const addBlackListData = (body: any) => {
  return new Promise(async (resolve, reject) => {
    return api
      .postwithToken(`blacklist/rules`, body)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const deleteBlackListData = (id: any) => {
  return new Promise(async (resolve, reject) => {
    return api
      .deletewithToken(`blacklist/rules/${id}`)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const getcontactUs = () => {
  return new Promise(async (resolve, reject) => {
    return api
      .getwithToken(`bn/admin/TextContactUs`)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const UpdatecontactUs = (body: any) => {
  return new Promise(async (resolve, reject) => {
    return api
      .putwithToken(`/bn/admin/TextContactUs/value`, body)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const getTermsandCondition = () => {
  return new Promise(async (resolve, reject) => {
    return api
      .getwithToken(`bn/TextTerms`)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const UpdateTermsandCondition = (body: any) => {
  return new Promise(async (resolve, reject) => {
    return api
      .putwithToken(`bn/admin/TextTerms/value`, body)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const getPrivacyPolicy = () => {
  return new Promise(async (resolve, reject) => {
    return api
      .getwithToken(`bn/TextPrivacy`)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const UpdatePrivacyPolicy = (body: any) => {
  return new Promise(async (resolve, reject) => {
    return api
      .putwithToken(`bn/admin/TextPrivacy/value`, body)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const getBookingApi = (
  page: number,
  limit: number,
  query: any,
  sort?: any,
  isSortBranchStatus?: any
) => {
  let q = "";
  if (query) {
    q = `&search=${query}`;
  }
  return new Promise(async (resolve, reject) => {
    return api
      .getwithToken(
        `bn/admin/bookings?page=${page}&limit=${limit}${q}${sort}${isSortBranchStatus}`
      )
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const getProductList = (page, limit, query, type?, sort?) => {
  let q = "";
  if (query) {
    q = `&search=${query}`;
  }
  return new Promise(async (resolve, reject) => {
    return api
      .getwithToken(
        `/bn/admin/PhysicalProducts?page=${page}&limit=${limit}${q}${type}${sort}`
      )
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const AddProductListData = (body) => {
  return new Promise(async (resolve, reject) => {
    return api
      .postwithToken(`bn/admin/PhysicalProducts`, body)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};
export const getoneProductList = (id) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getwithToken(
        `bn/admin/PhysicalProducts/${id}?filter={"metadata.tag":"hard"}`
      )
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};
export const updateProductList = (id, body) => {
  return new Promise(async (resolve, reject) => {
    return api
      .putwithToken(`bn/admin/PhysicalProducts/${id}`, body)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const getPurchaseApprovalApi = (
  page: number,
  limit: number,
  query: any,
  type?: string,
  sort?: any
) => {
  let q = "";
  if (query) {
    q = `&search=${query}`;
  }
  return new Promise(async (resolve, reject) => {
    return api
      .getwithToken(
        `bn/admin/ticketsPackages?page=${page}&limit=${limit}${q}${type}${sort}`
      )
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const AddRejectPurchaseApproval = (id: string, body: any) => {
  return new Promise(async (resolve, reject) => {
    return api
      .postwithToken(`payment/manual/admin/${id}/reject`, body)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const AddApprovePurchaseApproval = (id: string) => {
  return new Promise(async (resolve, reject) => {
    return api
      .postwithToken(`payment/manual/admin/${id}/approve`, {})
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const getAdminListApi = (
  page: number,
  limit: number,
  query: any,
  type?: string,
  sort?: any
) => {
  let q = "";
  if (query) {
    q = `&search=${query}`;
  }
  return new Promise(async (resolve, reject) => {
    return api
      .getwithToken(
        `profiles?populate=user&page=${page}&limit=${limit}${q}${type}${sort}`
      )
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const getElementById = (id: any) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getwithToken(`profiles/${id}?populate=user`)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const AddAdminData = (body: any) => {
  return new Promise(async (resolve, reject) => {
    return api
      .postwithToken(`users`, body)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};
export const updateAdminData = (id: any, body) => {
  return new Promise(async (resolve, reject) => {
    return api
      .putwithToken(`profiles/${id}`, body)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};
export const resetAdminPassword = (id: string, body: any) => {
  return new Promise(async (resolve, reject) => {
    return api
      .postwithToken(`users/${id}/resetPassword`, body)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};
export const updateAdmin = (id: any, body: any) => {
  return new Promise(async (resolve, reject) => {
    return api
      .putwithToken(`profiles/${id}`, body)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const getPushNotificationHistoryApi = (
  page?: number,
  limit?: number,
  query?: any,
  type?: string,
  sort?: string
) => {
  let q = "";
  if (query) {
    q = `&search=${query}`;
  }
  return new Promise(async (resolve, reject) => {
    return api
      .getwithToken(
        `bn/admin/pushNotifications?page=${page}&limit=${limit}${q}${type}${sort}`
      )
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const getCompanyAccount = () => {
  return new Promise(async (resolve, reject) => {
    return api
      .getwithToken(`bn/TextCompanyAccount`)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const getSocialUrl = () => {
  return new Promise(async (resolve, reject) => {
    return api
      .getwithToken(`bn/TextUiMetadata`)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const editSocialUrlDetails = (body) => {
  return new Promise(async (resolve, reject) => {
    return api
      .putwithToken(`bn/admin/TextUiMetadata/value`, body)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const editCompanyAccount = (body) => {
  return new Promise(async (resolve, reject) => {
    return api
      .putwithToken(`bn/admin/TextCompanyAccount/value`, body)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const addPurchase = (body) => {
  return new Promise(async (resolve, reject) => {
    return api
      .postwithToken(`bn/admin/ticketsPackage/addManualPurchase`, body)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const getOnePushNotificationHistoryApi = (id?: any) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getwithToken(`bn/admin/pushNotifications/${id}`)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const getTableDataById = (
  id?: any,
  page?: number,
  sizePerPage?: number
) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getwithToken(
        `bn/admin/pushNotifications/${id}/records?select=["profile", "read"]&populate={"path": "profile", "select": ["name","phone"]}&page=${page}&limit=${sizePerPage}`
      )
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const getOnePurchaseApprovalApi = (id: any) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getwithToken(
        `bn/admin/ticketsPackages/${id}?populate=["created_by","product"]
      `
      )
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};
export const getOneBookingDetail = (id: string) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getwithToken(
        `bn/admin/bookings/${id}?populate=["serviceDetails.masterSpecified", "serviceDetails.serviceProduct" , "created_by" , "voidedBy", "_timetable"]`
      )
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};
export const updateArrivedBranchDetail = (id: any, status: any) => {
  return new Promise(async (resolve, reject) => {
    return api
      .putwithToken(`bn/admin/booking/${status}/${id}`, "")
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const sendPushNotification = (body) => {
  return new Promise(async (resolve, reject) => {
    return api
      .postwithToken(`bn/admin/pushNotification/send`, body)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const uploadAttachment = (body?: any) => {
  return new Promise(async (resolve, reject) => {
    return api
      .postwithToken(`attachment/upload`, body)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const getDownloadImage = (id: any) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getwithToken(`attachments/${id}/download`)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const getOrderListByType = (
  page?: number,
  limit?: number,
  query?: any,
  // filter?: any,
  type?: any,
  sort?: any,
  branch?: any
) => {
  let q = "";
  if (query) {
    q = `&search=${query}`;
  }

  let url: any;
  if (type === "package" && branch !== "") {
    url = `ticketsPackages?&filter={"paymentStatus":"paid", "serviceDetails.masterSpecified":"${branch}"}&populate=["created_by","product"]`;
  } else if (type !== "package" && branch !== "") {
    url = `ticketsPackages?&filter={"serviceDetails.masterSpecified":"${branch}"}&populate=["created_by","product"]`;
  } else if (type === "package" && branch == "") {
    url = `ticketsPackages?&filter={"paymentStatus":"paid"}&populate=["created_by","product"]`;
  } else {
    url = `bookings?populate=["serviceDetails.masterSpecified","created_by"]&filter={"status": "99$completed"}`;
  }
  return new Promise(async (resolve, reject) => {
    return api
      .getwithToken(`bn/admin/${url}&page=${page}&limit=${limit}${q}${sort}`)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const computeAmountPayable = (body?: any) => {
  return new Promise(async (resolve, reject) => {
    return api
      .postwithToken(`bn/admin/booking/computePayment`, body)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const getProductListForBooking = () => {
  return new Promise(async (resolve, reject) => {
    return api
      .getwithToken(
        `bn/PhysicalProducts?filter={"enabled":true, "metadata.tag":"hard"}&page=1&limit=1000`
      )
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};
export const getPackageListForBooking = (id: string) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getwithToken(
        `bn/admin/ticketsPackages?filter={"paymentStatus": "paid","created_by": "${id}", "expireAt": {"$gt": "${moment().toISOString()}"}, "quotaLeft": {"$gt":0}}&populate=["product"]`
      )
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const updateArrivedBookingDetail = (id: string, body: any) => {
  return new Promise(async (resolve, reject) => {
    return api
      .putwithToken(`bn/admin/bookings/${id}`, body)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const completeBooking = (id: string, body?: any) => {
  return new Promise(async (resolve, reject) => {
    return api
      .postwithToken(`bn/admin/booking/completeBooking/${id}`, body)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};
export const saveBookingDetails = (
  id: string,
  body?: any,
  timetableId?: any
) => {
  return new Promise(async (resolve, reject) => {
    return api
      .putwithToken(`bn/admin/bookings/${id}`, body)
      .then((res: any) => {
        resolve(res);
        const reqBody = {
          time: body.time,
          booking: id,
          master: body.serviceDetails.masterSpecified,
          _client: body.created_by,
        };

        api.putwithToken(
          `/bn/admin/timetables/${timetableId}?filter={isHoliday:true,master:${body.serviceDetails.masterSpecified}}`,
          reqBody
        );
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const getOneOrderDetail = (id: any, type: any) => {
  let url: any;
  if (type === "package") {
    url = `ticketsPackages/${id}?populate=["product", "created_by" , "voidedBy"]`;
  } else {
    url = `bookings/${id}?populate=["serviceDetails.masterSpecified", "serviceDetails.serviceProduct", "created_by","product","purchasedProducts.product", "voidedBy"]&filter={"status": "99$completed"}
    `;
  }
  return new Promise(async (resolve, reject) => {
    return api
      .getwithToken(`bn/admin/${url}`)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};
export const getPackageSpecified = (id: any) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getwithToken(`bn/admin/ticketsPackages/${id}?populate=["product"]`)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const getBranchsList = () => {
  return new Promise(async (resolve, reject) => {
    return api
      .getwithToken(
        `bn/admin/masterProfiles?filter={"enabled": true}&page=1&limit=1000`
      )
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const getOrderTransactionList = (
  page?: number,
  limit?: number,
  query?: any,
  // filter?: any,
  type?: any,
  sort?: any,
  branch?: any
) => {
  let q = "";
  if (query) {
    q = `&search=${query}`;
  }
  let url: any;
  if (type === "package" && branch !== "") {
    url = `ec/transaction?filter={"metadata.BNTicketsPackage":{"$ne":null}}&populate=["created_by","product"]`;
  } else if (type !== "package" && branch !== "") {
    url = `ec/transaction?filter={"metadata.BNBooking.serviceDetails.masterSpecified.id":"${branch}"}&populate=["owner"]`;
  } else if (type === "package" && branch == "") {
    url = `ec/transaction?filter={"metadata.BNTicketsPackage":{"$ne":null}}&populate=["owner"]`;
  } else {
    url = `ec/transaction?filter={"metadata.BNBooking":{"$ne":null}}&populate=["owner"]`;
  }
  return new Promise(async (resolve, reject) => {
    return api
      .getwithToken(`${url}&page=${page}&limit=${limit}${q}${sort}`)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const getBookingDateList = (
  id: string,
  startDate: string,
  endDate: string,
  showAbsent: boolean,
  showCancelled: boolean
) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getwithToken(
        `bn/admin/bookings?filter={"status":${
          showAbsent === true && showCancelled === true
            ? '["0$created","98$absent","1$arrived","99$completed","97$cancelled"]'
            : showAbsent === true && showCancelled === false
            ? '["0$created","98$absent","1$arrived","99$completed"]'
            : showAbsent === false && showCancelled === true
            ? '["0$created","1$arrived","99$completed","97$cancelled"]'
            : '["0$created","1$arrived","99$completed"]'
        },"serviceDetails.masterSpecified":"${id}","time.start":{"$gte":"${startDate}","$lt":"${endDate}"}}&populate=["created_by","_timetable"]&sort={"time.start":1}`
      )
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const pushNotificationTags = (q: string) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getwithToken(
        `bn/ClientProfile/autocompleteLabels?page=1&limit=10000&search=${q}`
      )
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const updateBooking = (body: any, id: any, timetable_id?: any) => {
  const reqBody = {
    time: body?.time,
    booking: id,
    master: body?.master,
    _client: body?.created_by,
  };

  return new Promise(async (resolve, reject) => {
    return api
      .putwithToken(`bn/admin/bookings/${id}`, body)
      .then((res: any) => {
        resolve(res);
        if (timetable_id) {
          api.putwithToken(
            `/bn/admin/timetables/${timetable_id}?filter={isHoliday:true,master:${body?.master}}`,
            reqBody
          );
        }
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const getCustomersDetails = () => {
  return new Promise(async (resolve, reject) => {
    return api
      .getwithToken(
        `/profiles?filter={"role":"bn.client", "enabled": true}&page=1&limit=10000`
      )
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const getBranchsDetails = () => {
  return new Promise(async (resolve, reject) => {
    return api
      .getwithToken(`/bn/admin/masterProfiles?filter={"enabled": true}`)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const createBookings = (body: any) => {
  return new Promise(async (resolve, reject) => {
    // return api
    //   .postwithToken(`bn/admin/bookings`, body)
    //   .then((res: any) => {
    //     resolve(res);
    //     const reqBody = {
    //       time: body.time,
    //       booking: res.data?._id,
    //       master: body.serviceDetails.masterSpecified,
    //       _client: body.created_by,
    //     };
    //     api.postwithToken(
    //       `bn/admin/Timetables?filter={isHoliday:true,master:${body.serviceDetails.masterSpecified}}`,
    //       reqBody
    //     );
    //   })
    //   .catch((err: any) => {
    //     reject(err);
    //   });

    return api
      .postwithToken(`bn/admin/booking/createBookingAndTimetable`, body)
      .then((res: any) => {
        resolve(res);
        const reqBody = {
          time: body.time,
          booking: res.data?._id,
          master: body.serviceDetails.masterSpecified,
          _client: body.created_by,
        };
        // api.postwithToken(
        //   `bn/admin/Timetables?filter={isHoliday:true,master:${body.serviceDetails.masterSpecified}}`,
        //   reqBody
        // );
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const getDate = (id: string, startDate: string, endDate: string) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getwithToken(
        `bn/booking/checkBookingByRange?dateStart=${startDate}&dateEnd=${endDate}&mid=${id}`
      )
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const getTime = (id: string, selectedDate: string) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getwithToken(`bn/booking/checkBooking?date=${selectedDate}&mid=${id}`)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const getBranchDetailsForSearch = () => {
  return new Promise(async (resolve, reject) => {
    return api
      .getwithToken(
        `bn/admin/masterProfiles?filter={"enabled": true}&sort={"name": 1} `
      )
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const getCustomerBookingsWithId = (
  id: string,
  page: number,
  branch: string,
  type: string,
  quary: string,
  sizePerPage: number
) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getwithToken(
        `/bn/admin/bookings?sort={"created_at":-1}&limit=${sizePerPage}&page=${page}&populate=["serviceDetails.masterSpecified","created_by"]&filter={ ${
          branch ? `"serviceDetails.masterSpecified":"${branch}",` : ""
        }  "created_by": "${id}" ${
          type ? `, "status": "${type}"` : ""
        } }&search=${quary}`
      )
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const getCustomerPackages = (
  id: string,
  page: number,
  branch: string,
  type: string,
  query: string,
  sizePerPage: number
) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getwithToken(
        `/bn/admin/ticketsPackages?filter={"paymentStatus":"paid" , "created_by": "${id}"}&populate=["created_by", "product"]&page=${page}&limit=${sizePerPage}&sort={"created_at":-1}`
      )
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const updatePackageWithId = (id: string, body: any) => {
  return new Promise(async (resolve, reject) => {
    return api
      .putwithToken(`/bn/admin/ticketsPackages/${id}`, body)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const purchaseCustomerPackage = (body: any) => {
  return new Promise(async (resolve, reject) => {
    return api
      .postwithToken(`bn/admin/ticketsPackage/addManualPurchase`, body)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const getCustomerPackagesRecords = (
  cid: string,
  pid: string,
  page: number,
  branch: string,
  type: string,
  query: string,
  sizePerPage: number
) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getwithToken(
        `/bn/admin/bookings?sort={"_timetableTime.start":-1}&limit=${sizePerPage}&page=${page}&populate=["serviceDetails.masterSpecified","serviceDetails.serviceProduct","created_by"]&filter={${
          branch ? `"serviceDetails.masterSpecified": "${branch}" , ` : ""
        }  "created_by": "${cid}", "serviceDetails.packageSpecified": "${pid}" }&search="${query}"`
      )
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};
