import api from "../common/api";

export const AuthLogin = (payload:any) => {
  return new Promise(async (resolve, reject) => {
    return api
      .post('sso/login',payload)
      .then((res:any) => {
        resolve(res);
      })
      .catch((err:any) => {
        reject(err);
      });
  });
};

export const CheckLoginStatus = () => {
  return new Promise(async (resolve, reject) => {
    return api
      .get('sso/checkLoginStatus')
      .then((res:any) => {
        resolve(res);
      })
      .catch((err:any) => {
        reject(err);
      });
  });
}