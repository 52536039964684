import React, { FC, useState, useEffect } from "react";
import SVG from "react-inlinesvg";
import {
  toAbsoluteUrl,
  headerSortingClasses,
  sortCaret,
} from "../../../helpers";
import { Card, CardBody, CardHeader } from "../../../partials/controls";
import { sortServerString } from "../../../common/table/sorter";
import { SizePerPage } from "../../../common/table/filters/SizePerPage";
import { Table } from "../../../common/table";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import Loader from "../../../common/loader";
import { FilterQuery } from "../../../common/table/filters/FilterQuery";
import { StatusFilter } from "../../../common/table/filters/StatusFilter";
import { getServices, updateServices } from "../../../service/carService";
import { PaginationLinks } from "../../../common/pagination/PaginationLinks";
import { PaginationToolbar } from "../../../common/pagination/PaginationToolbar";
import AddServiceDate from "./addListService";
import { Dropdown, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { Link, useHistory } from "react-router-dom";
import { numberFormater } from "../../../common/thousandSeperator/defaultFunction";

const ServiceList: FC = () => {
  const history = useHistory();
  const [query, setQuery] = useState<string>("");
  const [totalData, setTotalData] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [entities, setEntities] = useState<any>([]);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [type, setType] = useState<string>("true");
  const [openService, setopenService] = useState<boolean>(false);
  const [serviceId, setserviceId] = useState<string>("");
  const [sortingType, setSortingType] = useState<any>("");

  const StatusColumnFormatter = (status: any) => {
    if (status) {
      return (
        <span
          className={`label label-lg label-light-${
            status === "Active" ? `success` : `danger`
          } label-inline font-weight-normal font-size-11`}
        >
          {status}
        </span>
      );
    }
  };

  const ActionEdit = (cellContent, row, rowIndex) => {
    return (
      <>
        <Dropdown className="dropdown-inline" drop="down" alignRight>
          <Dropdown.Toggle
            id="dropdown-status"
            className="btn btn-icon btn-light btn-sm dropdown-toggle"
          >
            <span className="svg-icon svg-icon-md svg-icon-primary">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/General/Settings-1.svg")}
              ></SVG>
            </span>
          </Dropdown.Toggle>
          <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right pb-4">
            <ul className="navi navi-hover">
              <li className="navi-header pb-1">
                <span className="text-primary text-uppercase font-weight-bold font-size-base">
                  ACTION:
                </span>
              </li>
              <li className="navi-item pl-6">
                <div className="d-flex align-items-center">
                  {/* <span className="font-weight-normal font-size-base">
                    Status
                  </span> */}
                  <span className="navi-icon">
                    <FormControlLabel
                      control={
                        <Switch
                          onChange={() => onSwitchAction(row._id, row.status)}
                          color="primary"
                          id={`custom-switch-${row._id}`}
                          checked={row.status}
                        />
                      }
                      label={row.status ? "Active" : "Inactive"}
                      className="mb-0"
                    />
                  </span>
                </div>
              </li>
            </ul>
          </Dropdown.Menu>
        </Dropdown>
        <a
          title="Edit customer"
          className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
          onClick={() => openAddService(row._id)}
        >
          <span className="svg-icon svg-icon-md svg-icon-primary">
            <SVG
              src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
            />
          </span>
        </a>
      </>
    );
  };

  const rowEvents = {
    onClick: (a, row, c) => {
      openAddService(row?._id);
    },
  };

  const columns = [
    // {
    //   dataField: "_id",
    //   text: "ID",
    //   sort: true,
    //   sortCaret: sortCaret,
    //   headerSortingClasses,
    //   onSort: (field: string, order: string) =>
    //     sortServerString(field, order, setSortingType),
    // },
    {
      dataField: "name",
      text: "Service",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      onSort: (field: string, order: string) =>
        sortServerString(field, order, setSortingType),
    },
    {
      dataField: "listedPrice",
      text: "Price",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      onSort: (field: string, order: string) =>
        sortServerString(field, order, setSortingType),
    },
    {
      dataField: "discountedPrice",
      text: "Discount Price",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      onSort: (field: string, order: string) =>
        sortServerString(field, order, setSortingType),
    },
    {
      dataField: "metadata.uiTag",
      text: "Tag",
      sort: false,
    },
    {
      dataField: "enabled",
      text: "Status",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: StatusColumnFormatter,
      onSort: (field: string, order: string) =>
        sortServerString(field, order, setSortingType),
    },
    // {
    //   dataField: "action",
    //   text: "Action",
    //   formatter: ActionEdit,
    // },
  ];

  useEffect(() => {
    getServiceDetails();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getServiceDetails = (
    page = 1,
    sizePerPage = 10,
    showLoading = true
  ) => {
    const isStatus =
      type !== ""
        ? `&filter={"enabled":${type},"metadata.tag":"soft"}`
        : `&filter={"metadata.tag":"soft"}`;
    const isSortColumn =
      sortingType !== "" ? `&${sortingType}` : `&sort={"name":1}`;

    if (showLoading) {
      setLoading(true);
    }
    getServices(page, sizePerPage, query, isStatus, isSortColumn)
      .then((res: any) => {
        setLoading(false);
        let news: any = [];
        if (res?.data?.docs.length === 0 && page !== 1) {
          getServiceDetails(page - 1);
          setPage(page - 1);
          return;
        }
        res?.data?.docs.map((data, index) => {
          const Obj = {
            index: ++index + (page - 1) * sizePerPage,
            _id: data._id,
            name: data.name,
            listedPrice: `$${numberFormater(data.listedPrice)}`,
            discountedPrice: `$${numberFormater(data.discountedPrice)}`,
            metadata: data.metadata,
            enabled: data.enabled === true ? "Active" : "InActive",
            status: data.enabled,
          };
          news.push(Obj);
        });
        setEntities(news);
        setTotalData(res?.data?.total);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.response?.data?.message || "Something went wrong.");
      });
  };

  const customTotal = (from: any, to: any, size: any) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing rows {totalData === 0 ? 0 : (page - 1) * sizePerPage + 1} to {to}{" "}
      of {totalData}
    </span>
  );

  const onTableChange = async (type: any, props: any, paginationProps: any) => {
    if (type !== "sort") {
      setSizePerPage(props.sizePerPage);
      if (props.sizePerPage > paginationProps.totalSize) {
        setPage(1);
        getServiceDetails(1, props.sizePerPage);
        setPage(1);
      } else {
        setPage(props.page);
        getServiceDetails(props.page, props.sizePerPage);
      }
    }
  };

  const handleSizePerPage = (
    { page, onSizePerPageChange }: any,
    newSizePerPage: any
  ) => {
    onSizePerPageChange(newSizePerPage, page);
  };

  const openAddService = (id: any) => {
    history.push(`/service/update/${id}`);
  };

  const paginationOptions = {
    custom: true,
    totalSize: totalData,
    hideSizePerPage: true,
    showTotal: true,
    page,
    sizePerPage,
    paginationTotalRenderer: customTotal,
  };

  const getSelectRow = {
    mode: "radio",
    clickToSelect: true,
    hideSelectColumn: true,
    // onSelect: (data: ArticleEntities) =>
    //   history.push(`/service/update/${data.id}`),
  };
  useEffect(() => {
    getServiceDetails(1, 10, false);
  }, [query, type, sortingType]);

  const onSwitchAction = (id, enabled) => {
    const body = {
      enabled: !enabled,
    };
    updateServices(id, body)
      .then((res: any) => {
        if (res?.status === 200) {
          getServiceDetails(page);
          setPage(page);
          setSizePerPage(10);
        }
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || "Something went wrong.");
      });
  };
  return (
    <Card>
      {loading && <Loader />}
      <CardHeader title="Services">
        <div className="d-flex align-items-center">
          <a
            title="Edit customer"
            className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
            role="button"
            onClick={() => {
              getServiceDetails();
            }}
          >
            <span className="svg-icon svg-icon-md svg-icon-primary">
              <SVG src={toAbsoluteUrl("/media/svg/icons/reload.svg")} />
            </span>
          </a>
        </div>
      </CardHeader>
      <CardBody>
        <PaginationProvider pagination={paginationFactory(paginationOptions)}>
          {({ paginationProps, paginationTableProps }) => (
            <>
              <div className="d-flex ">
                <div className="d-flex flex-column flex-md-row flex-sm-column mb-10">
                  <StatusFilter
                    status={type}
                    setStatus={(e) => {
                      setType(e);
                      setPage(1);
                    }}
                    options={[
                      { label: "All", value: "" },
                      { label: "Active", value: "true" },
                      { label: "Inactive", value: "false" },
                    ]}
                  />
                  <FilterQuery
                    query={query}
                    setQuery={(e) => {
                      setQuery(e);
                      setPage(1);
                    }}
                  />
                </div>
                <div className="mt-4">
                  <Link className="btn btn-primary" to="/service/add">
                    New Service
                  </Link>
                </div>
              </div>

              <Table
                rowEvents={rowEvents}
                getSelectRow={getSelectRow}
                paginationProps={paginationProps}
                paginationOptions={paginationOptions}
                paginationTableProps={paginationTableProps}
                data={entities}
                columns={columns}
                remote
                onTableChange={(type: any, props: any) =>
                  onTableChange(type, props, paginationProps)
                }
              />
              <div className="d-flex justify-content-between align-items-center flex-wrap">
                <div>
                  <PaginationLinks paginationProps={paginationProps} />
                </div>
                <div className="d-flex">
                  <SizePerPage
                    paginationProps={paginationProps}
                    handleSizePerPage={handleSizePerPage}
                    entities={entities}
                  />
                  <PaginationToolbar paginationProps={paginationProps} />
                </div>
              </div>
            </>
          )}
        </PaginationProvider>
      </CardBody>
    </Card>
  );
};

export default ServiceList;
