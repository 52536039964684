import React, { useState } from "react";

import "react-quill/dist/quill.snow.css";
import { useHistory } from "react-router";
import { addBlackListData } from "../../../service/carService";
import Loader from "../../../common/loader";
import { toast } from "react-toastify";
import { Form, Modal } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";

interface Values {
  phone: string;
}
const Addblacklist = ({ id, show, onHide, getBlackLists }) => {
  const history = useHistory();
  const [loader, setLoader] = useState<boolean>(false);

  const onSubmit = (values) => {
    console.log(values, "valllll");
    setLoader(true);
    const body = {
      type: "phone",
      policy: "reject",
      phone: "+" + values.phone,
    };
    addBlackListData(body)
      .then((res: any) => {
        setLoader(false);
        if (res.status === 200) {
          toast.success("BlackList Add Successful");
          history.push("/blacklist");
          getBlackLists();
          onHide();
        }
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || "Something went wrong.");
        setLoader(false);
      });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      phone: "",
    },
    validationSchema: Yup.object({
      phone: Yup.string()
        .matches(/(85)[2-3]{1}[0-9]/, {
          message:
            "Please input Country Calling Codes(e.g 852,853)in front of phone number ",
          excludeEmptyString: false,
        })
        .required("Phone is required"),
    }),
    onSubmit: (values: Values) => {
      onSubmit(values);
    },
  });

  return (
    <div>
      {loader && <Loader />}
      <Modal
        centered
        show={show}
        onHide={onHide}
        aria-labelledby="example-modal-sizes-title-lg"
        className="service-modal"
      >
        <Modal.Body>
          <div className="d-flex justify-content-between px-10">
            <div className="modal-header-text ml-2">Add Blacklist</div>
            <div className="modal-close" onClick={onHide}>
              x
            </div>
          </div>
          <div className="row mt-4 justify-content-center">
            <div className="col-10">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  id="inputText"
                  name="phone"
                  value={formik.values.phone.replace("+", "")}
                  onChange={(e) =>
                    formik.setFieldValue(
                      "phone",
                      e.target.value.replace("+", "")
                    )
                  }
                  onBlur={formik.handleBlur}
                />
                {formik.touched.phone && formik.errors.phone ? (
                  <div className="text-danger mt-1 ml-1 mb-4 pb-4">
                    {formik.errors.phone}
                  </div>
                ) : null}
              </div>
              <div className="col-lg-10  mb-4 p-0">
                <button
                  type="submit"
                  className="btn btn-car-primary m-0"
                  onClick={() => formik.handleSubmit()}
                >
                  Create
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Addblacklist;
