import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardFooter,
} from "../../../partials/controls";
import "react-quill/dist/quill.snow.css";
import { CKModules } from "../../../common/editor/ckModules";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {
  getTermsandCondition,
  UpdateTermsandCondition,
} from "../../../service/carService";
import { toast } from "react-toastify";
import Loader from "../../../common/loader";

const TermsCondition = () => {
  const [loader, setLoader] = useState<boolean>(false);
  const [dataCN, setDataCN] = useState<any>();
  const [dataEN, setDataEN] = useState<any>();

  useEffect(() => {
    getContactUsData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getContactUsData = () => {
    setLoader(true);
    getTermsandCondition()
      .then((res: any) => {
        const defaultContent = res?.data?.content.includes("{")
          ? JSON.parse(res?.data?.content)
          : null;
        setDataCN(defaultContent?.zh || res?.data?.content);
        setDataEN(defaultContent?.en || res?.data?.content);
        setLoader(false);
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || "Something went wrong.");
        setLoader(false);
      });
  };

  const Onupdate = () => {
    setLoader(true);
    const newData = { zh: dataCN, en: dataEN };
    const body = {
      content: JSON.stringify(newData),
    };
    UpdateTermsandCondition(body)
      .then((res: any) => {
        setLoader(false);
        if (res.status === 200) {
          toast.success("Terms & Conditions Update Successful");
        }
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || "Something went wrong.");
        setLoader(false);
      });
  };

  const handleChangeCN = (e, editor) => {
    const data = editor.getData();
    setDataCN(data);
  };

  const handleChangeEN = (e, editor) => {
    const data = editor.getData();
    setDataEN(data);
  };

  return (
    <div className="row justify-content-center mt-20">
      <div className="col-8 ">
        <Card>
          <CardHeader title={"Terms & Conditions"}></CardHeader>
          {loader && <Loader />}
          <CardBody>
            <div className="row ">
              <div className="col-12">
                <div className="">
                  Chinese Version
                  <div>
                    <CKEditor
                      editor={ClassicEditor}
                      config={CKModules}
                      className="ck"
                      data={dataCN}
                      onChange={handleChangeCN}
                    />
                  </div>
                </div>
                <div></div>
              </div>
            </div>

            <div className="row mt-6">
              <div className="col-12">
                <div className="">
                  English Version
                  <div>
                    <CKEditor
                      editor={ClassicEditor}
                      config={CKModules}
                      className="ck"
                      data={dataEN}
                      onChange={handleChangeEN}
                    />
                  </div>
                </div>
                <div></div>
              </div>
            </div>
          </CardBody>
          <CardFooter>
            <div className="d-flex flex-wrap align-items-center justify-content-end">
              <div className="mt-4 mt-sm-0">
                <button
                  className="btn btn-success mr-2 mx-sm-2"
                  onClick={Onupdate}
                >
                  Submit
                </button>
              </div>
            </div>
          </CardFooter>
        </Card>
      </div>
    </div>
  );
};

export default TermsCondition;
