import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
} from "react";

import FullCalendar from "@fullcalendar/react";
import { Modal } from "react-bootstrap";
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import Datetime from "react-datetime";
import moment from "moment";
import { Link, useHistory } from "react-router-dom";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import {
  createBookings,
  getBranchsList,
  getCustomersDetails,
  getDate,
  getTime,
} from "../../../service/carService";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { getBookingDateList } from "./../../../service/carService";
import Loader from "./../../../common/loader/index";
import BookingModal from "./bookingModal";
import { useFormik } from "formik";
import * as Yup from "yup";
import Select from "react-select";

export default function Calendar() {
  const location = useLocation();
  const searchParams = new URLSearchParams(document.location.search);
  const searchBranch = searchParams.get("branch");
  const [loading, setLoading] = useState<boolean>(false);
  const [branchList, setBranchList] = useState<any>([]);
  const [branch, setBranch] = useState("");
  const [selectedDate, setSelectedDate] = useState<any>();
  const [stateEvent, setStateEvent] = useState<any>([]);
  const [singleBookingData, setSingleBookingData] = useState<any>();
  const [isOpenBookingModal, setIsOpenBookingModal] = useState<boolean>(false);
  const [selectedDateFormat, setSelectedDateFormat] = useState<any>();
  const [showAbsent, setShowAbsent] = useState<boolean>(false);
  const [timeOption, setTimeOption] = useState<any>();
  const [showCancelled, setShowCancelled] = useState<boolean>(false);
  const [newBookingAdd, setNewBookingAdd] = useState<boolean>(false);
  const [customerList, setCustomerList] = useState<any>([]);
  const [selectedCustomer, setSelectedCustomer] = useState<any>(null);
  const [customDates, setCustomDates] = useState<any>();
  const [disabledDateInput, setDisabledDateInput] = useState(true);
  const todayDateFormat = moment().format("DD-MM-YYYY");
  const [refresh, setResfresh] = useState(false);
  const history = useHistory();
  const yesterday = moment().subtract(1, "day");
  const today = moment();
  const [{ navigated, viewMode }, setState] = useState({
    navigated: 0,
    viewMode: "",
  });
  const datePickersRef = useRef<any>();

  const getBranchList = () => {
    setLoading(true);
    getBranchsList()
      .then((res: any) => {
        setLoading(false);
        setBranchList(res.data.docs);
        setBranch(searchBranch ? searchBranch : res.data.docs[0]?.id);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const getCustomerDetails = () => {
    setLoading(true);
    getCustomersDetails()
      .then((res: any) => {
        setLoading(false);
        const customerData = res.data.docs.map((customer) => {
          return {
            value: customer._id,
            label: `${customer.phone} - ${customer.name}`,
          };
        });
        setCustomerList(customerData);
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || "Something went wrong.");
        setLoading(false);
      });
  };

  useEffect(() => {
    getCustomerDetails();
    getBranchList();
    setSelectedDate(today);
    setSelectedDateFormat(todayDateFormat);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDateChange = (dateValue) => {
    setSelectedDate(dateValue);
    setSelectedDateFormat(moment(dateValue).format("YYYY-MM-DD"));
  };

  const getScheduleDateList = () => {
    setLoading(true);
    let getBranch = !branch ? branchList[0]._id : branch;
    let startDate =
      moment(selectedDate).format("YYYY-MM-DD") || today.format("YYYY-MM-DD");
    let endDate =
      moment(selectedDate)
        .add(1, "days")
        .format("YYYY-MM-DD") || today.add(1, "days").format("YYYY-MM-DD");
    let selectedBranch =
      branchList.find((selectedBranch) => selectedBranch._id === branch) ||
      branchList[0];
    let servicingCapacity = selectedBranch.servicingCapacity;
    getBookingDateList(getBranch, startDate, endDate, showAbsent, showCancelled)
      .then((res: any) => {
        const eventList: any = [];
        let item: any = res.data.docs;
        let resourcesIndex = 1;
        for (let element of item) {
          while (resourcesIndex <= servicingCapacity) {
            const eventObject = {
              resourceId:
                resourcesIndex >= 9
                  ? `0${resourcesIndex}`
                  : `00${resourcesIndex}`,
              title: element.created_by.name,
              start: element.time
                ? moment(
                    moment(element.time.start).format("HH:mm"),
                    "HH:mm"
                  ).toISOString()
                : ``,
              end: element.time
                ? moment(
                    moment(
                      element?.status === "99$completed"
                        ? element.completedAt
                        : element.time.end
                    ).format("HH:mm"),
                    "HH:mm"
                  ).toISOString()
                : ``,
              backgroundColor: "#B78989",
              borderColor: "#B78989",
              extendedProps: {
                startTime: element.time
                  ? moment(element.time.start).format("HH:mm")
                  : ``,
                status: element.status,
                late: element.late,
                phone: element.created_by.phone,
                _id: element._id,
                serviceProduct: element.serviceDetails.serviceProduct,
                _timetable: element?._timetable,
                created_by: element?.created_by,
                master: element?.serviceDetails?.masterSpecified,
              },
            };
            eventList.push(eventObject);
            resourcesIndex =
              resourcesIndex === servicingCapacity ? 1 : resourcesIndex + 1;
            break;
          }
        }
        setStateEvent(eventList);
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || "Something went wrong.");
        setLoading(false);
      });
  };

  useEffect(() => {
    if (branchList.length > 0) getScheduleDateList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branch, selectedDate, branchList, showAbsent, showCancelled, refresh]);

  const getResources = () => {
    let branchData =
      branchList.find((selectedBranch) => selectedBranch._id === branch) ||
      branchList[0];
    let titleList: any = [];
    for (let list = 1; list <= branchData?.servicingCapacity; list++) {
      if (list >= 10) {
        titleList.push({ id: `0${list}`, title: `0${list}` });
      } else {
        titleList.push({ id: `00${list}`, title: `00${list}` });
      }
    }
    return titleList;
  };

  const handleEventClick = (info) => {
    setSingleBookingData(info.event);
    setIsOpenBookingModal(true);
  };

  const customEvents = (calendarDetail) => {
    let status = "";
    if (calendarDetail.event.extendedProps.status === "0$created") {
      status = `Booked ${
        calendarDetail.event.extendedProps.late ? "(Late)" : ""
      }`;
    } else if (calendarDetail.event.extendedProps.status === "1$arrived") {
      status = `Arrived ${
        calendarDetail.event.extendedProps.late ? "(Late)" : ""
      }`;
    } else if (calendarDetail.event.extendedProps.status === "98$absent") {
      status = `Absent ${
        calendarDetail.event.extendedProps.late ? "(Late)" : ""
      }`;
    } else if (calendarDetail.event.extendedProps.status === "97$cancelled") {
      status = `Cancelled ${
        calendarDetail.event.extendedProps.late ? "(Late)" : ""
      }`;
    } else if (calendarDetail.event.extendedProps.status === "99$completed") {
      status = `Completed ${
        calendarDetail.event.extendedProps.late ? "(Late)" : ""
      }`;
    }

    const htmlContent = `
    <div>
      <span>${calendarDetail.event.extendedProps.startTime}</span>
      <strong class="float-right">${status}</strong>
    </div>
    <div>
      <strong>${calendarDetail.event.title}</strong>
    </div>
    <span>${calendarDetail.event.extendedProps.phone}</span>
    `;

    return { html: htmlContent };
  };

  const createBookingSchema = Yup.object().shape({
    customer: Yup.string().required("Customer is required"),
    branch: Yup.string().required("Branch is required"),
    carPlateNumber: Yup.string().required("carPlateNumber is required"),
    date: Yup.string().required("Booking date is required"),
    bookingTime: Yup.string().required("Service time is required"),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      customer: "",
      branch: "",
      carPlateNumber: "",
      date: "",
      bookingTime: "",
    },
    validationSchema: createBookingSchema,
    onSubmit: () => {
      createBooking();
    },
  });

  const createBooking = () => {
    const currentTime = moment(formik.values.bookingTime, "x").toISOString();
    const afterTime = moment(formik.values.bookingTime, "x")
      .add(30, "minutes")
      .toISOString();
    const body = {
      carPlate: formik.values.carPlateNumber,
      serviceDetails: {
        serviceProduct: "62318678bb5e985de91d14e7",
        masterSpecified: formik.values.branch,
      },
      status: "0$created",
      time: {
        start: currentTime,
        end: afterTime,
        duration: 1800000,
      },
      created_by: formik.values.customer,
      _timetableTime: {
        start: currentTime,
        end: afterTime,
        duration: 1800000,
      },
      master: formik.values.branch,
      needCheckCapacity: true,
    };

    createBookings(body)
      .then((res: any) => {
        setLoading(false);
        if (res.data) {
          setNewBookingAdd(false);
          setResfresh((state) => !state);
          toast.success("Booking created successfully");
        }
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || "Something went wrong.");
        setLoading(false);
      });
  };

  const handleCustomerChange = (selectedOption) => {
    if (selectedOption) {
      setSelectedCustomer(selectedOption);
      formik.setFieldValue("customer", selectedOption.value);
    } else {
      formik.setFieldValue("customer", "");
    }
  };
  const onNavigate = () =>
    setState((prevState) => ({
      ...prevState,
      navigated: prevState.navigated + 1,
    }));

  const onViewMode = useCallback((localViewMode) => {
    setState((prevState) => ({ ...prevState, viewMode: localViewMode }));
  }, []);
  const getDateByRange = () => {
    setLoading(true);
    const dateStart = moment()
      .startOf("month")
      .format("x");
    const dateEnd = moment()
      .endOf("month")
      .format("x");
    const mid = formik.values.branch;
    getDate(mid, dateStart, dateEnd)
      .then((res: any) => {
        setLoading(false);
        let dateData: any = [];
        res.data.histogram.map((data) => {
          data.full && dateData.push(moment(data.date).format("YYYY-MM-DD"));
        });
        setCustomDates(dateData);
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || "Something went wrong.");
        setLoading(false);
      });
  };
  const getTimeForSelectedDate = () => {
    setLoading(true);
    const selectedDate = moment(formik.values.date).format("x");
    const mid = formik.values.branch;
    console.log(branch, "branch");
    getTime(mid, selectedDate)
      .then((res: any) => {
        setLoading(false);
        setTimeOption(res.data.histogram);
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || "Something went wrong.");
        setLoading(false);
      });
  };
  const onChangeDate = (date) => {
    formik.setFieldValue("date", date);
  };

  const onChangeBranch = () => {
    formik.setFieldValue("date", "");
  };

  useEffect(() => {
    if (branch && branch !== "") {
      formik.setFieldValue("branch", branch);
    }
    // eslint-disable-next-line
  }, [branch]);

  useEffect(() => {
    if (searchBranch) {
      setBranch(searchBranch);
    }
    // eslint-disable-next-line
  }, [searchBranch]);

  useEffect(() => {
    if (formik.values.branch != "") {
      getDateByRange();
      setDisabledDateInput(false);
    }
  }, [formik.values.branch, refresh]);

  useEffect(() => {
    if (formik.values.date != "") {
      getTimeForSelectedDate();
    }
  }, [formik.values.date, formik.values.branch, refresh]);

  useEffect(() => {
    getCustomerDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="bg-white px-4 py-2">
      {loading && <Loader />}
      <div className="row align-items-center">
        <div className="col-9">
          <div className="d-flex align-items-center">
            <Link className="btn btn-primary mr-5" to="/booking">
              <span className="svg-icon menu-icon">
                <i className="fa fa-list-ul"></i>
              </span>
              Back to List
            </Link>
            <div className="d-flex align-items-baseline">
              <label className="mr-3 font-weight-bolder">Branch: </label>
              <div className="input-group relative d-flex align-items-center">
                <select
                  className="form-control top-select rounded"
                  name="branch"
                  value={branch}
                  onChange={(e) => {
                    window.location.href = `/booking/calendar?branch=${e.target.value}`;
                    setBranch(e.target.value);
                  }}
                  style={{ paddingRight: "3rem" }}
                >
                  {branchList.map((data) => {
                    const defaultName = data?.name?.includes("{")
                      ? JSON.parse(data?.name)
                      : null;
                    return (
                      <option value={data.id} key={data.id}>
                        {defaultName ? defaultName?.en : data.name}
                      </option>
                    );
                  })}
                </select>
                <ExpandMoreRoundedIcon
                  style={{
                    position: "absolute",
                    right: "1rem",
                    // zIndex: 10,
                  }}
                />
              </div>
            </div>
            <div className="d-flex align-items-center">
              <div className="d-flex align-items-center ml-3">
                <input
                  type="checkbox"
                  checked={showAbsent}
                  onClick={(e) => {
                    setShowAbsent(!showAbsent);
                  }}
                />
                <label style={{ marginBottom: 0, marginLeft: 5 }}>
                  Show Absent
                </label>
              </div>
              <div className="d-flex align-items-center ml-5">
                <input
                  type="checkbox"
                  checked={showCancelled}
                  onClick={(e) => {
                    setShowCancelled(!showCancelled);
                  }}
                />
                <label style={{ marginBottom: 0, marginLeft: 5 }}>
                  Show Cancelled
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="col-3 d-flex align-items-baseline">
          <label className="mr-3 font-weight-bolder">Date: </label>
          <Datetime
            value={selectedDate}
            timeFormat={false}
            dateFormat={"DD-MM-YYYY"}
            onChange={handleDateChange}
            // isValidDate={disableCustomDt}
            closeOnSelect={true}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12 mt-2">
          <FullCalendar
            droppable={true}
            scrollTime={moment()
              .subtract(30, "minutes")
              .format("HH:mm:ss")}
            schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
            timeZone="local"
            plugins={[resourceTimeGridPlugin, interactionPlugin]}
            initialView="resourceTimeGridDay"
            allDaySlot={false}
            headerToolbar={{
              left: "",
              center: "",
              right: "",
            }}
            eventDurationEditable={false}
            eventOverlap={false}
            slotDuration={"00:10:00"}
            dateClick={(e) => {
              const passDate = moment(e?.dateStr).format("YYYY-MM-DD");
              const passTime = String(
                Number(moment(e?.dateStr).format("mm")) >= 0 &&
                  Number(moment(e?.dateStr).format("mm")) < 30
                  ? `${moment(e?.dateStr).format("HH")}:00`
                  : `${moment(e?.dateStr).format("HH")}:30`
              );

              if (
                moment(selectedDate).format("YYYY-MM-DD") ===
                moment().format("YYYY-MM-DD")
              ) {
                // FOR TODAY ONLY
                if (new Date(e?.dateStr).getTime() >= new Date().getTime()) {
                  setNewBookingAdd(true);
                  formik.setFieldValue(
                    "bookingTime",
                    moment(`${passDate} ${passTime}`).valueOf()
                  );
                  formik.setFieldValue("date", selectedDate);
                }
              } else {
                setNewBookingAdd(true);
                formik.setFieldValue(
                  "bookingTime",
                  moment(
                    `${moment(selectedDate).format("YYYY-MM-DD")} ${passTime}`
                  ).valueOf()
                );
                formik.setFieldValue("date", selectedDate);
              }
            }}
            eventClick={(info) => handleEventClick(info)}
            resources={getResources()}
            events={stateEvent}
            nowIndicator={true}
            eventContent={customEvents}
          />
        </div>
      </div>
      {isOpenBookingModal && (
        <BookingModal
          show={isOpenBookingModal}
          onHide={() => setIsOpenBookingModal(false)}
          bookingData={singleBookingData}
          selecteddate={selectedDate}
          getCalenderLatestData={() => getScheduleDateList()}
        />
      )}

      <Modal
        centered
        show={newBookingAdd}
        onHide={() => {
          setNewBookingAdd(false);
          formik.setFieldValue("bookingTime", "");
        }}
        dialogClassName="modal-90w"
        aria-labelledby="example-modal-sizes-title-sm"
        className="service-modal"
      >
        <Modal.Body className="show-grid">
          <div className="d-flex justify-content-between">
            <div className="modal-header-text">New Booking</div>
            <div
              className="modal-close"
              onClick={() => {
                setNewBookingAdd(false);
                formik.setFieldValue("bookingTime", "");
              }}
            >
              x
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="col-lg-12 ws-nowrap">Customer</div>
              <div className="col-lg-12">
                <div className="input-group relative d-flex align-items-center">
                  <Select
                    name="customer"
                    options={customerList}
                    closeMenuOnSelect={true}
                    onChange={handleCustomerChange}
                    value={selectedCustomer}
                    className="basic-multi-select w-100"
                    classNamePrefix="select"
                    placeholder="Select Customer"
                  />
                </div>
                {formik.touched.customer && formik.errors.customer ? (
                  <div className="text-danger mt-1 ml-1">
                    {formik.errors.customer}
                  </div>
                ) : null}
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="col-lg-12 ws-nowrap">Branch</div>
              <div className="col-lg-12">
                <div className="input-group relative d-flex align-items-center">
                  <select
                    className="form-control top-select rounded"
                    name="branch"
                    value={branch}
                    onChange={(e) => setBranch(e.target.value)}
                    style={{ paddingRight: "3rem" }}
                  >
                    {branchList.map((data) => {
                      const defaultName = data?.name?.includes("{")
                        ? JSON.parse(data?.name)
                        : null;
                      return (
                        <option value={data.id} key={data.id}>
                          {defaultName ? defaultName?.en : data.name}
                        </option>
                      );
                    })}
                  </select>
                  <ExpandMoreRoundedIcon
                    style={{
                      position: "absolute",
                      right: "1rem",
                      // zIndex: 10,
                    }}
                  />
                </div>
                {formik.touched.branch && formik.errors.branch ? (
                  <div className="text-danger mt-1 ml-1">
                    {formik.errors.branch}
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="col-lg-12 ws-nowrap">Service Date</div>
              <div className="col-lg-12">
                <Datetime
                  className="w-100"
                  value={formik.values.date ? moment(formik.values.date) : ""}
                  timeFormat={false}
                  renderInput={(props) => {
                    return (
                      <input
                        {...props}
                        value={formik.values.date != "" ? props.value : ""}
                      />
                    );
                  }}
                  dateFormat={"DD-MM-YYYY"}
                  onChange={onChangeDate}
                  // isValidDate={disableCustomDt}
                  closeOnSelect={true}
                  onNavigateBack={() => {
                    onNavigate();
                    onChangeBranch();
                  }}
                  onNavigateForward={() => {
                    onNavigate();
                    onChangeBranch();
                  }}
                  onNavigate={onViewMode}
                  inputProps={{
                    disabled: disabledDateInput,
                    readOnly: disabledDateInput,
                  }}
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="col-lg-12 ws-nowrap">Service Time</div>
              <div className="col-lg-12">
                <div className="input-group relative d-flex align-items-center">
                  <select
                    className="form-control top-select rounded"
                    name="bookingTime"
                    value={"formik.values.bookingTime"}
                    onChange={(e) =>
                      formik.setFieldValue(
                        "bookingTime",
                        parseInt(e.target.value)
                      )
                    }
                    style={{
                      paddingRight: "3rem",
                      backgroundColor:
                        formik.values.date && formik.values.branch !== ""
                          ? "white"
                          : "#d1d3e0",
                    }}
                  >
                    {timeOption?.length ? (
                      <option value={formik.values.bookingTime}>
                        {timeOption?.filter(
                          (state) => state.time === formik.values.bookingTime
                        )[0]
                          ? moment(formik.values.bookingTime).format("HH:mm")
                          : "--- Select time ---"}
                      </option>
                    ) : (
                      <option disabled>-Not Available-</option>
                    )}
                    {timeOption?.map((time) => (
                      <option disabled={time.full} value={time.time}>
                        {moment(time.time).format("HH:mm")}
                      </option>
                    ))}
                  </select>
                  <ExpandMoreRoundedIcon
                    style={{
                      position: "absolute",
                      right: "1rem",
                      // zIndex: 10,
                    }}
                  />
                </div>
                {formik.touched.bookingTime && formik.errors.bookingTime ? (
                  <div className="text-danger mt-1 ml-1">
                    This service Time is not available
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="col-lg-12 ws-nowrap">Plate Number</div>
              <div className="col-lg-12">
                <div className="form-group">
                  <input
                    type="text"
                    value={formik.values.carPlateNumber}
                    onChange={formik.handleChange}
                    className="form-control"
                    id="title"
                    name="carPlateNumber"
                  />
                  {formik.touched.carPlateNumber &&
                  formik.errors.carPlateNumber ? (
                    <div className="text-danger mt-1 ml-1">
                      {formik.errors.carPlateNumber}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-success mr-2 mx-sm-2"
            onClick={() => formik.handleSubmit()}
          >
            Submit
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
