import React, { useState, useEffect } from "react";
import "react-quill/dist/quill.snow.css";
import {
  getPacakges,
  purchaseCustomerPackage,
} from "../../../service/carService";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import DragFileUpload from "../../../common/components/DragFileUpload";
import { useFormik } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { useParams } from "react-router-dom";
interface Values {
  packageName: any;
  customerName: any;
  imageUrl: any;
  quota: number;
  amountPayable: string;
  approve: boolean;
  reject: boolean;
  rejectReason: any;
  recieptPhotos: string[];
}

const CustomerNewPackageModal = ({ show, onHide, customerName }) => {
  const [imageSrc, setImageSrc] = useState<any>();
  const [packagesArr, setPackagesArr] = useState([]);

  useEffect(() => {
    getPacakgesDetails();
  }, []);

  const handlePackageChange = (selectedOption) => {
    if (selectedOption) {
      formik.setFieldValue("packageName", selectedOption);
    } else {
      formik.setFieldValue("packageName", "");
    }
  };

  const getPacakgesDetails = (page = 1, sizePerPage = 1000000) => {
    getPacakges(
      page,
      sizePerPage,
      "",
      `&filter={"enabled":true,"discountedPrice10":{"$ne":null}}&enabled=true`
    )
      .then((res: any) => {
        const packages = res.data.docs.map((packag) => {
          return { value: packag._id, label: packag.name };
        });
        setPackagesArr(packages);
      })
      .catch((err) => {});
  };

  const params: { id: string } = useParams();
  const handleApprove = () => {
    const values: Values = formik.values;
    const body = {
      customer: params.id,
      product: values.packageName.value,
      quota: values.quota,
      amountPayable: values.amountPayable,
      receiptPhotos: [values.recieptPhotos],
      approve: true,
    };
    purchaseCustomerPackage(body)
      .then((res) => {
        toast.success("Purchase Successfull");
        onHide();
        formik.resetForm();
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || "Something went wrong.");
      });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      recieptPhotos: [],
      packageName: "",
      customerName: "",
      imageUrl: "",
      quota: 10,
      amountPayable: "",
      approve: true,
      reject: false,
      rejectReason: "",
    },
    validationSchema: Yup.object({
      rejectReason: Yup.string().required("Reject Reason is Required."),
    }),
    onSubmit: (values: Values) => {},
  });

  const manageImageId = (id) => {
    setImageSrc(id);
  };

  useEffect(() => {
    if (formik.values.recieptPhotos) {
      manageImageId(formik.values.recieptPhotos);
    }
  }, [formik.values.recieptPhotos]);

  return (
    <div>
      <Modal
        centered
        show={show}
        onHide={onHide}
        aria-labelledby="example-modal-sizes-title-lg"
        className="service-modal lg"
      >
        <Modal.Body>
          <Modal.Title>Create Purchase</Modal.Title>
          <div className="d-flex justify-content-between">
            <div className="modal-header-text px-lg-12"></div>
            <div className="modal-close" onClick={onHide}>
              x
            </div>
          </div>
          <div className="row justify-content-center mt-2">
            <div className="col-lg-10">
              <div className="input-group relative d-flex align-items-center">
                <p className="purchase-edit-lable">Package Name</p>
                <Select
                  name="packageName"
                  options={packagesArr}
                  closeMenuOnSelect={true}
                  onChange={handlePackageChange}
                  value={formik.values.packageName}
                  className="basic-multi-select w-100"
                  classNamePrefix="select"
                  placeholder="-- Select Package Name --"
                />
              </div>
            </div>
            <div className="col-lg-10">
              {" "}
              <p className="purchase-edit-lable">Customer</p>
              <div className="input-group relative d-flex align-items-start">
                <input
                  name="customerName"
                  disabled
                  value={customerName}
                  className="form-control"
                />
              </div>
            </div>

            <div className="col-lg-10">
              {" "}
              <p className="purchase-edit-lable">Amount</p>
              <div className="input-group relative d-flex align-items-center">
                <input
                  type="number"
                  className="form-control"
                  placeholder="Add Amount"
                  name="amountPayable"
                  value={formik.values.amountPayable}
                  onChange={formik.handleChange}
                />
              </div>
            </div>

            <div className="col-lg-10 mt-4 ">
              <p className="purchase-edit-lable">Receipt</p>
              <DragFileUpload
                isMultipleFileUpload={false}
                updateFileList={(fileId: any) =>
                  formik.setFieldValue("recieptPhotos", fileId)
                }
                imageSrc={imageSrc}
                showRemoveIcon={true}
              />
            </div>

            <div className="col-lg-10 mt-2">
              <div className="input-group relative d-flex align-items-center">
                <button
                  className="btn btn-car-primary mt-3"
                  onClick={() => {
                    handleApprove();
                  }}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default CustomerNewPackageModal;
