import React, { useState, useEffect } from "react";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import "react-quill/dist/quill.snow.css";
import {
  addPurchase,
  getActiveCustomer,
  getPacakges,
} from "../../../service/carService";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import DragFileUpload from "../../../common/components/DragFileUpload";
import { useFormik } from "formik";
import * as Yup from "yup";
import Select from "react-select";
interface Values {
  packageName: any;
  customerName: any;
  imageUrl: any;
  quota: number;
  amountPayable: string;
  approve: boolean;
  reject: boolean;
  rejectReason: any;
  recieptPhotos: string[];
}

const Addapproval = ({ show, onHide, getPurchaseApproval }) => {
  const [customerArr, setCustomerArr] = useState([]);
  const [rectReason, showRejectReason] = useState(false);
  const [imageSrc, setImageSrc] = useState<any>();
  const [packagesArr, setPackagesArr] = useState([]);

  useEffect(() => {
    getPacakgesDetails();
    getCustomerList();
  }, []);

  const handlePackageChange = (selectedOption) => {
    if (selectedOption) {
      formik.setFieldValue("packageName", selectedOption);
    } else {
      formik.setFieldValue("packageName", "");
    }
  };

  const handleCustomerChange = (selectedOption) => {
    if (selectedOption) {
      formik.setFieldValue("customerName", selectedOption);
    } else {
      formik.setFieldValue("customerName", "");
    }
  };

  const getPacakgesDetails = (page = 1, sizePerPage = 1000000) => {
    getPacakges(
      page,
      sizePerPage,
      "",
      `&filter={"enabled":true,"discountedPrice10":{"$ne":null}}&enabled=true`
    )
      .then((res: any) => {
        const packages = res.data.docs.map((packag) => {
          return { value: packag._id, label: packag.name };
        });
        setPackagesArr(packages);
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || "Something went wrong.");
      });
  };

  const getCustomerList = (page = 1, sizePerPage = 10000000) => {
    getActiveCustomer(page, sizePerPage, "", "")
      .then((res: any) => {
        // setCustom(res?.data?.docs);
        const customers = res.data.docs.map((customer) => {
          return { value: customer._id, label: customer.name };
        });
        setCustomerArr(customers);
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || "Something went wrong.");
      });
  };

  const handleApprove = () => {
    const values: Values = formik.values;
    const body = {
      customer: values.customerName.value,
      product: values.packageName.value,
      quota: values.quota,
      amountPayable: values.amountPayable,
      receiptPhotos: [values.recieptPhotos],
      approve: true,
    };

    addPurchase(body)
      .then((res: any) => {
        if (res.status === 200) {
          toast.success("Purchase added.");
          onHide();
          getPurchaseApproval();
        }
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || "Something went wrong.");
      });
  };

  const handleReject = () => {
    const values: Values = formik.values;
    if (values.rejectReason) {
      const body = {
        customer: values.customerName.value,
        product: values.packageName.value,
        quota: values.quota,
        amountPayable: values.amountPayable,
        receiptPhotos: [values.recieptPhotos],
        approve: false,
        reject: {
          message: values.rejectReason,
          data: {},
        },
      };

      addPurchase(body)
        .then((res: any) => {
          if (res.status === 200) {
            toast.success("Purchase added.");
            onHide();
            getPurchaseApproval();
          }
        })
        .catch((err) => {
          toast.error(err.response?.data?.message || "Something went wrong.");
        });
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      recieptPhotos: [],
      packageName: "",
      customerName: "",
      imageUrl: "",
      quota: 10,
      amountPayable: "",
      approve: true,
      reject: false,
      rejectReason: "",
    },
    validationSchema: Yup.object({
      rejectReason: Yup.string().required("Reject Reason is Required."),
    }),
    onSubmit: (values: Values) => {
      // handleApprove(values);
      handleReject();
    },
  });

  const manageImageId = (id) => {
    setImageSrc(id);
  };

  useEffect(() => {
    if (formik.values.recieptPhotos) {
      manageImageId(formik.values.recieptPhotos);
    }
  }, [formik.values.recieptPhotos]);

  return (
    <div>
      <Modal
        centered
        show={show}
        onHide={onHide}
        aria-labelledby="example-modal-sizes-title-lg"
        className="service-modal"
      >
        <Modal.Body>
          <div className="d-flex justify-content-between">
            <div className="modal-header-text px-lg-12">Create Purchase</div>
            <div className="modal-close" onClick={onHide}>
              x
            </div>
          </div>
          <div className="row justify-content-center mt-10">
            <div className="col-lg-10">
              <p className="purchase-edit-lable">Package Name</p>
              <div className="input-group relative d-flex align-items-center">
                {/* <select
                  className="form-control top-select rounded"
                  name="packageName"
                  value={formik.values.packageName}
                  onChange={formik.handleChange}
                  style={{ paddingRight: "3rem" }}
                >
                  <option value="" disabled>
                    -- Select Package Name --
                  </option>
                  {pacakges.map((data: any) => (
                    <option value={data._id} key={data._id}>
                      {data.name}
                    </option>
                  ))}
                </select>
                <ExpandMoreRoundedIcon
                  style={{
                    position: "absolute",
                    right: "1rem",
                    // zIndex: 10,
                  }}
                /> */}
                <Select
                  name="packageName"
                  options={packagesArr}
                  closeMenuOnSelect={true}
                  onChange={handlePackageChange}
                  value={formik.values.packageName}
                  className="basic-multi-select w-100"
                  classNamePrefix="select"
                  placeholder="-- Select Package Name --"
                />
              </div>
            </div>
            <div className="col-lg-10 mt-2">
              <p className="purchase-edit-lable">Customer Name</p>{" "}
              <div className="input-group relative d-flex align-items-center">
                {/* <select
                  className="form-control top-select rounded"
                  style={{ paddingRight: "3rem" }}
                  value={formik.values.customerName}
                  onChange={formik.handleChange}
                  name="customerName"
                >
                  <option value="" disabled>
                    -- Select Cusomer Name--
                  </option>
                  {custom.map((data: any) => (
                    <option value={data._id} key={data._id}>
                      {data.name}
                    </option>
                  ))}
                </select>
                <ExpandMoreRoundedIcon
                  style={{
                    position: "absolute",
                    right: "1rem",
                    // zIndex: 10,
                  }}
                /> */}
                <Select
                  name="customerName"
                  options={customerArr}
                  closeMenuOnSelect={true}
                  onChange={handleCustomerChange}
                  value={formik.values.customerName}
                  className="basic-multi-select w-100"
                  classNamePrefix="select"
                  placeholder="-- Select Cusomer Name--"
                />
              </div>
            </div>

            <div className="col-lg-10 mt-2">
              {" "}
              <p className="purchase-edit-lable">Amount</p>
              <div className="input-group relative d-flex align-items-center">
                <input
                  type="number"
                  className="form-control"
                  placeholder="Add Amount"
                  name="amountPayable"
                  value={formik.values.amountPayable}
                  onChange={formik.handleChange}
                />
              </div>
            </div>

            <div className="col-lg-10 mt-4 ">
              <label className="purchase-edit-lable">Receipt</label>
              <DragFileUpload
                isMultipleFileUpload={false}
                updateFileList={(fileId: any) =>
                  formik.setFieldValue("recieptPhotos", fileId)
                }
                imageSrc={imageSrc}
                showRemoveIcon={true}
              />
            </div>
            {rectReason && (
              <div className="col-lg-10 mt-2">
                {" "}
                <div className="input-group relative d-flex align-items-center">
                  <input
                    type="text"
                    placeholder="Reject Reason"
                    className="form-control"
                    id="rejectReason"
                    name="rejectReason"
                    value={formik.values.rejectReason || ""}
                    onChange={formik.handleChange}
                  />
                </div>
                {formik.touched.rejectReason && formik.errors.rejectReason ? (
                  <div className="text-danger mt-1 ml-1">
                    {formik.errors.rejectReason}
                  </div>
                ) : null}
              </div>
            )}
            <div className="col-lg-10 mt-2">
              <div className="input-group relative d-flex align-items-center mt-3">
                <button
                  className="btn btn-car-primary rounded"
                  onClick={() => {
                    handleApprove();
                    onHide(false);
                  }}
                >
                  Appove
                </button>
                <button
                  className="btn btn-danger mr-2 mx-sm-2"
                  onClick={() => {
                    showRejectReason(true);
                    formik.handleSubmit();
                  }}
                  type="submit"
                >
                  Reject
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Addapproval;
