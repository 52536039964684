import React, { FC, useState, useEffect } from "react";
import SVG from "react-inlinesvg";
import {
  toAbsoluteUrl,
  headerSortingClasses,
  sortCaret,
} from "../../../helpers";
import moment from "moment";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../partials/controls";
import { getLeasetNews, updateNews } from "../../../service/carService";
import { SizePerPage } from "../../../common/table/filters/SizePerPage";
import { Table } from "../../../common/table";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import Loader from "../../../common/loader";
import { FilterQuery } from "../../../common/table/filters/FilterQuery";
import { StatusFilter } from "../../../common/table/filters/StatusFilter";
import { sortServerString } from "../../../common/table/sorter";
import { PaginationLinks } from "../../../common/pagination/PaginationLinks";
import { PaginationToolbar } from "../../../common/pagination/PaginationToolbar";
import AddLatestNews from "./addnews";
import { Dropdown, Form } from "react-bootstrap";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { toast } from "react-toastify";

const LatestNews: FC = () => {
  const [query, setQuery] = useState<string>("");
  const [totalData, setTotalData] = useState<number>(1);
  const [entities, setEntities] = useState<any>([]);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [type, setType] = useState<string>("true");
  const [loading, setLoading] = useState<boolean>(false);
  const [openNews, setopenNews] = useState<boolean>(false);
  const [newId, setnewId] = useState<any>("");
  const [sortingType, setSortingType] = useState<any>("");

  const StatusColumnFormatter = (status: any) => {
    if (status) {
      return (
        <span
          className={`label label-lg label-light-${
            status === "Active" ? `success` : `danger`
          } label-inline font-weight-normal font-size-11`}
        >
          {status}
        </span>
      );
    }
  };

  const ActionEdit = (cellContent, row, rowIndex) => {
    return (
      <>
        <Dropdown className="dropdown-inline" drop="down" alignRight>
          <Dropdown.Toggle
            id="dropdown-status"
            className="btn btn-icon btn-light btn-sm dropdown-toggle"
          >
            <span className="svg-icon svg-icon-md svg-icon-primary">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/General/Settings-1.svg")}
              ></SVG>
            </span>
          </Dropdown.Toggle>
          <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right pb-4">
            <ul className="navi navi-hover">
              <li className="navi-header pb-1">
                <span className="text-primary text-uppercase font-weight-bold font-size-base">
                  ACTION:
                </span>
              </li>
              <li className="navi-item pl-6">
                <div className="d-flex align-items-center">
                  {/* <span className="font-weight-normal font-size-base">
                    Status
                  </span> */}
                  <span className="navi-icon">
                    <FormControlLabel
                      control={
                        <Switch
                          onChange={() => onSwitchAction(row._id, row.status)}
                          color="primary"
                          id={`custom-switch-${row._id}`}
                          checked={row.status}
                        />
                      }
                      label={row.status ? "Active" : "Inactive"}
                      className="mb-0"
                    />
                  </span>
                </div>
              </li>
            </ul>
          </Dropdown.Menu>
        </Dropdown>
        <a
          title="Edit customer"
          className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
          onClick={() => openAddnews(row._id)}
        >
          <span className="svg-icon svg-icon-md svg-icon-primary">
            <SVG
              src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
            />
          </span>
        </a>
      </>
    );
  };

  const rowEvents = {
    onClick: (a, row, c) => {
      openAddnews(row?._id);
    },
  };

  const htmlFormatter = (content: any) => {
    if (content) {
      return content.replace(/<\/?[^>]+(>|$)/g, "");
    }
  };
  const onSwitchAction = (id, enabled) => {
    const body = {
      enabled: !enabled,
    };
    updateNews(id, body)
      .then((res: any) => {
        if (res?.status === 200) {
          getLatestNews(page);
          setPage(page);
          setSizePerPage(10);
        }
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || "Something went wrong.");
      });
  };
  const columns = [
    // {
    //   dataField: "_id",
    //   text: "ID",
    //   sort: true,
    //   sortCaret: sortCaret,
    //   headerSortingClasses,
    //   onSort: (field: string, order: string) =>
    //     sortServerString(field, order, setSortingType),
    // },
    {
      dataField: "subject",
      text: "Title",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      onSort: (field: string, order: string) =>
        sortServerString(field, order, setSortingType),
    },
    {
      dataField: "expires_at",
      text: "Deadline",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      onSort: (field: string, order: string) =>
        sortServerString(field, order, setSortingType),
    },
    {
      dataField: "type",
      text: "Type",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      onSort: (field: string, order: string) =>
        sortServerString(field, order, setSortingType),
    },
    {
      dataField: "content",
      text: "Content",
      formatter: htmlFormatter,
    },
    {
      dataField: "enabled",
      text: "Status",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: StatusColumnFormatter,
      onSort: (field: string, order: string) =>
        sortServerString(field, order, setSortingType),
    },
    // {
    //   dataField: "action",
    //   text: "Action",
    //   formatter: ActionEdit,
    // },
  ];

  const getLatestNews = (page = 1, sizePerPage = 10, showLoading = true) => {
    const isStatus = type !== "" ? `&filter={"enabled":${type}}` : "";
    const isSortColumn = sortingType !== "" ? `&${sortingType}` : "";

    if (showLoading) {
      setLoading(true);
    }
    getLeasetNews(page, sizePerPage, query, isStatus, isSortColumn)
      .then((res: any) => {
        setLoading(false);
        let news: any = [];
        if (res?.data?.docs.length === 0 && page !== 1) {
          getLatestNews(page - 1);
          setPage(page - 1);
          return;
        }
        res?.data?.docs.map((data, index) => {
          const Obj = {
            index: ++index + (page - 1) * sizePerPage,
            _id: data._id,
            subject: data.subject,
            expires_at: moment(data.expires_at).format("DD/MM/YYYY"),
            enabled: data.enabled === true ? "Active" : "Inactive",
            status: data.enabled,
            content: `${data.content.substring(0, 25)} ${
              data.content.length > 25 ? "....." : ""
            }`,
            type: data.type,
          };
          news.push(Obj);
        });
        setEntities(news);
        setTotalData(res?.data?.total);
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || "Something went wrong.");
        setLoading(false);
      });
  };

  useEffect(() => {
    getLatestNews();
  }, [sortingType]);

  const openAddnews = (id?) => {
    setopenNews(true);
    setnewId(id);
  };

  const customTotal = (from: any, to: any, size: any) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing rows {totalData === 0 ? 0 : (page - 1) * sizePerPage + 1} to {to}{" "}
      of {totalData}
    </span>
  );

  const onTableChange = async (type: any, props: any, paginationProps: any) => {
    if (type !== "sort") {
      setSizePerPage(props.sizePerPage);
      if (props.sizePerPage > paginationProps.totalSize) {
        setPage(1);
        getLatestNews(1, props.sizePerPage);
        setPage(1);
      } else {
        setPage(props.page);
        getLatestNews(props.page, props.sizePerPage);
      }
    }
  };

  const handleSizePerPage = (
    { page, onSizePerPageChange }: any,
    newSizePerPage: any
  ) => {
    onSizePerPageChange(newSizePerPage, page);
  };

  const paginationOptions = {
    custom: true,
    totalSize: totalData,
    hideSizePerPage: true,
    showTotal: true,
    page,
    sizePerPage,
    paginationTotalRenderer: customTotal,
  };

  const getSelectRow = {
    mode: "radio",
    clickToSelect: true,
    hideSelectColumn: true,
    // onSelect: (data: ArticleEntities) =>
    //   history.push(`/latestNews/update/${data.id}`),
  };
  useEffect(() => {
    getLatestNews(1, 10, false);
  }, [query, type]);

  const onClose = (isModalClose) => {
    if (isModalClose) {
      getLatestNews();
      setPage(1);
      setSizePerPage(10);
    }
    setopenNews(false);
  };
  return (
    <Card>
      {loading && <Loader />}
      <CardHeader title="Latest News">
        <div className="d-flex align-items-center">
          <a
            title="Edit customer"
            className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
            role="button"
            onClick={() => {
              getLatestNews();
            }}
          >
            <span className="svg-icon svg-icon-md svg-icon-primary">
              <SVG src={toAbsoluteUrl("/media/svg/icons/reload.svg")} />
            </span>
          </a>
        </div>
      </CardHeader>
      <CardBody>
        <PaginationProvider pagination={paginationFactory(paginationOptions)}>
          {({ paginationProps, paginationTableProps }) => (
            <>
              <div className="d-flex ">
                <div className="d-flex flex-column flex-md-row flex-sm-column mb-10">
                  <StatusFilter
                    status={type}
                    setStatus={(e) => {
                      setType(e);
                      setPage(1);
                    }}
                    options={[
                      { label: "All", value: "" },
                      { label: "Active", value: "true" },
                      { label: "Inactive", value: "false" },
                    ]}
                  />
                  <FilterQuery
                    query={query}
                    setQuery={(e) => {
                      setQuery(e);
                      setPage(1);
                    }}
                  />
                </div>

                <div className="mt-4">
                  <button
                    className="btn btn-primary"
                    onClick={() => openAddnews()}
                  >
                    New News
                  </button>
                </div>
              </div>
              <Table
                rowEvents={rowEvents}
                getSelectRow={getSelectRow}
                paginationProps={paginationProps}
                paginationOptions={paginationOptions}
                paginationTableProps={paginationTableProps}
                data={entities}
                columns={columns}
                remote
                onTableChange={(type: any, props: any) =>
                  onTableChange(type, props, paginationProps)
                }
              />
              <div className="d-flex justify-content-between align-items-center flex-wrap">
                <div>
                  <PaginationLinks paginationProps={paginationProps} />
                </div>
                <div className="d-flex">
                  <SizePerPage
                    paginationProps={paginationProps}
                    handleSizePerPage={handleSizePerPage}
                    entities={entities}
                  />
                  <PaginationToolbar paginationProps={paginationProps} />
                </div>
              </div>
            </>
          )}
        </PaginationProvider>
      </CardBody>
      {openNews && (
        <AddLatestNews
          show={openNews}
          onHide={(isModalClose) => onClose(isModalClose)}
          id={newId}
          getLatestNews={getLatestNews}
        />
      )}
    </Card>
  );
};

export default LatestNews;
