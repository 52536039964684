import React, { useEffect, useState } from "react";
import { TimePicker } from "antd";
import { Modal } from "react-bootstrap";
import Datetime from "react-datetime";
import moment from "moment";
import { useFormik } from "formik";
import { getBranchsList, updateBooking } from "../../../service/carService";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import Loader from "../../../common/loader";

function ChangeTimeModal({
  show,
  onHide,
  id,
  onMainHide,
  getCalenderLatestData,
  selectedBookingData,
  selectedBookingDate,
}) {
  const timetable_id =
    selectedBookingData?._def?.extendedProps?._timetable?._id;

  const [loader, setLoader] = useState<boolean>(false);
  const history = useHistory();
  const today = moment();
  const format = "HH:mm";
  const timeUpdate = () => {
    setLoader(true);
    onMainHide(true);
    onHide(true);

    const time = moment(formik.values.time).format("hh:mm a");
    const date = moment(formik.values.date).format("YYYY-MM-DD");
    const timeAndDate = date + " " + time;
    const currentTime = moment(timeAndDate).toISOString();
    const afterTime = moment(timeAndDate)
      .add(30, "minutes")
      .toISOString();
    const beforeTime = moment(timeAndDate)
      .subtract(30, "minutes")
      .toISOString();

    const body = {
      created_by: selectedBookingData?._def?.extendedProps?.created_by?._id,
      master: selectedBookingData?._def?.extendedProps?.master,
      time: {
        start: currentTime,
        end: afterTime,
        duration: 1800000,
      },
      _timetableTime: {
        start: currentTime,
        end: afterTime,
        duration: 1800000,
      },
    };

    updateBooking(body, id, timetable_id)
      .then((res: any) => {
        setLoader(false);
        if (res.data.status) {
          toast.success("Booking Updated successfully");
          onHide(true);
          getCalenderLatestData();
        }
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || "Something went wrong.");
        setLoader(false);
      });
  };

  const formik = useFormik({
    initialValues: {
      time: moment(selectedBookingData._def.extendedProps.startTime, "HH:mm"),
      date: moment(selectedBookingDate, "YYYY-MM-DD"),
    },

    onSubmit: () => {
      timeUpdate();
    },
  });

  const onChangeTime = (time, timeString) => {
    formik.setFieldValue("time", time);
  };

  const onChangeDate = (date) => {
    formik.setFieldValue("date", date);
  };

  const disableCustomDt = (current) => {
    return current.isAfter(today);
  };

  return (
    <>
      {loader && <Loader />}
      <Modal
        centered
        show={show}
        onHide={() => {
          onHide();
          onMainHide(true);
        }}
        dialogClassName="modal-90w"
        // size="sm"
        aria-labelledby="example-modal-sizes-title-sm"
        className="service-modal"
      >
        <Modal.Body>
          <div className="d-flex justify-content-end">
            <div
              className="modal-close"
              onClick={() => {
                onHide();
                onMainHide(true);
              }}
            >
              x
            </div>
          </div>

          <label className="mr-3 font-weight-bolder">Date: </label>
          <Datetime
            value={moment(formik.values.date)}
            timeFormat={false}
            dateFormat={"DD-MM-YYYY"}
            onChange={onChangeDate}
            isValidDate={disableCustomDt}
            closeOnSelect={true}
          />

          <label className="mr-3 font-weight-bolder">Time: </label>
          <TimePicker
            format={format}
            className="time_picker"
            onChange={onChangeTime}
            value={moment(formik.values.time || moment("00:00", "HH:mm"))}
          />
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex flex-wrap align-items-center justify-content-between">
            <div className="mt-4 mt-sm-0">
              <button
                className="btn btn-success mr-2 mx-sm-2"
                onClick={() => formik.handleSubmit()}
              >
                Save
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ChangeTimeModal;
