/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../helpers";
import api from "../../../../common/api";

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu &&
          "menu-item-active"} menu-item-open menu-item-not-hightlighted`
      : "";
  };

  const Logout = () => {
    // localStorage.clear();
    return new Promise(async (resolve, reject) => {
      api
        .get("sso/logout")
        .then((res) => {
          api
            .get("/sso/checkLoginStatus")
            .then((res) => {
              if (res?.data?.user === false) {
                resolve(res);
                // history.push("/auth/login");
              } else {
                resolve(res);
              }
            })
            .catch((err) => {
              reject(err);
            });
          // resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}

        <li className="py-3">
          <span className="text-uppercase Aside-header  menu-text ml-4 my-5">
            Main Service
          </span>
        </li>

        {/* ==========branches=========== */}
        <li
          className={`menu-item ${getMenuItemActive("/branches", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/branches">
            <span className="svg-icon menu-icon">
              <i className="fas fa-school p-2"></i>
            </span>
            <span className="menu-text ml-3">Branches</span>
          </NavLink>
        </li>

        {/* ===========product=============== */}
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/approval",
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="#">
            <span className="svg-icon menu-icon">
              <i className="fas fa-boxes  p-2"></i>
            </span>
            <span className="menu-text ml-3">Product</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/product/type",
                  true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link menu-toggle" to="/product/type">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Product Type</span>
                </NavLink>
              </li>
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/product/list",
                  true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link menu-toggle" to="/product/list">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Product List</span>
                </NavLink>
              </li>
              {/*end::2 Level*/}

              {/* Agent Upgrade */}
              {/*begin::2 Level*/}
            </ul>
          </div>
        </li>

        {/*===========Services===========  */}
        <li
          className={`menu-item ${getMenuItemActive("/service", false)}`}
          aria-haspopup="true"
          // data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/service">
            <span className="svg-icon menu-icon">
              <i className="flaticon-responsive p-2"></i>
            </span>
            <span className="menu-text ml-3">Services</span>
          </NavLink>
          {/* <div className="menu-submenu ">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              {/* <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/service/type",
                  true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link menu-toggle" to="/service/type">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Service Type</span>
                </NavLink>
              </li> */}
          {/* <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/service/list",
                  true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link menu-toggle" to="/service/list">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Service List</span>
                </NavLink>
              </li> */}
          {/*end::2 Level*/}

          {/* Agent Upgrade */}
          {/*begin::2 Level*/}
          {/* </ul>
          </div> */}
        </li>

        {/*==========Packages==============  */}
        <li
          className={`menu-item ${getMenuItemActive("/packages", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/packages">
            <span className="svg-icon menu-icon">
              <i className="flaticon-squares-1 p-2 ml-1"></i>
            </span>
            <span className="menu-text ml-3">Packages</span>
          </NavLink>
        </li>

        {/* ===========Booking=========== */}
        <li
          className={`menu-item ${getMenuItemActive("/booking", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/booking">
            <span className="svg-icon menu-icon ">
              <i className="far fa-calendar-alt  p-2  ml-1"></i>
            </span>
            <span className="menu-text  ml-3">Booking</span>
          </NavLink>
        </li>

        {/* =========Purchase Approval========== */}
        <li
          className={`menu-item ${getMenuItemActive(
            "/purchaseApproval",
            false
          )}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/purchaseApproval">
            <span className="svg-icon menu-icon ">
              <i className="fas fa-vote-yea p-2  ml-1"></i>
            </span>
            <span className="menu-text ml-3">Purchase Approval</span>
          </NavLink>
        </li>

        {/* ===========Order=========== */}
        <li
          className={`menu-item ${getMenuItemActive("/order", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/order">
            <span className="svg-icon menu-icon">
              <i className="fas fa-shopping-cart  p-2  ml-1"></i>
            </span>
            <span className="menu-text ml-3">Order</span>
          </NavLink>
        </li>
        <li className=" py-3">
          <span className="text-uppercase Aside-header  menu-text ml-4">
            Account
          </span>
        </li>
        {/* =============Customer=========== */}
        <li
          className={`menu-item ${getMenuItemActive("/customer", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/customer">
            <span className="svg-icon menu-icon">
              <i className="fas fa-user p-2  ml-1"></i>
            </span>
            <span className="menu-text ml-3">Customer</span>
          </NavLink>
        </li>

        {/* ===============Blacklist============ */}
        <li
          className={`menu-item ${getMenuItemActive("/blacklist", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/blacklist">
            <span className="svg-icon menu-icon">
              <i className="fas fa-user-times p-2  ml-1"></i>
            </span>
            <span className="menu-text ml-3">Blacklist</span>
          </NavLink>
        </li>

        {/* ==========Admin========= */}
        <li
          className={`menu-item ${getMenuItemActive("/admin", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/admin">
            <span className="svg-icon menu-icon">
              <i className="fas fa-user-tie p-2 ml-1"></i>
            </span>
            <span className="menu-text ml-3">Admin</span>
          </NavLink>
        </li>
        <li className="py-3">
          <span className="text-uppercase Aside-header menu-text ml-4">
            News
          </span>
        </li>
        {/*============= latestNews========== */}
        <li
          className={`menu-item ${getMenuItemActive("/latestNews", true)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/latestNews">
            <span className="svg-icon menu-icon">
              <i className="fas fa-newspaper p-2  ml-1"></i>
            </span>
            <span className="menu-text ml-3">Latest News</span>
          </NavLink>
        </li>

        {/*============Push Notification=========  */}
        <li
          className={`menu-item ${getMenuItemActive(
            "/pushNotification",
            false
          )}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/pushNotification">
            <span className="svg-icon menu-icon">
              <i className="fas fa-paper-plane p-2  ml-1 "></i>
            </span>
            <span className="menu-text  ml-3">Push Notification</span>
          </NavLink>
        </li>
        <li className="py-3">
          <span className="text-uppercase Aside-header menu-text ml-4">
            Setting
          </span>
        </li>
        {/* ===============Setting============== */}
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/approval",
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="#">
            <span className="svg-icon menu-icon">
              <i className="fas fa-tools p-2   "></i>
            </span>
            <span className="menu-text ml-3">Setting</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              {/* <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/privacypolicy",
                  true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link menu-toggle" to="/privacypolicy">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Privacy Policy</span>
                </NavLink>
              </li> */}
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/terms&conditions",
                  true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink
                  className="menu-link menu-toggle"
                  to="/terms&conditions"
                >
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Terms & Conditions</span>
                </NavLink>
              </li>
              {/* <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/aboutus",
                  true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link menu-toggle" to="/aboutus">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">About us</span>
                </NavLink>
              </li> */}
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/contactus",
                  true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link menu-toggle" to="/contactus">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Contact us</span>
                </NavLink>
              </li>
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/companyAccount",
                  true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link menu-toggle" to="/companyAccount">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Company Account</span>
                </NavLink>
              </li>

              {/* <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/poster",
                  true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link menu-toggle" to="/poster">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Poster</span>
                </NavLink>
              </li> */}
              {/*end::2 Level*/}

              {/* Agent Upgrade */}
              {/*begin::2 Level*/}
            </ul>
          </div>
        </li>

        {/* =========logout=========== */}
        <li
          className={`menu-item ${getMenuItemActive("/auth/login", false)}`}
          aria-haspopup="true"
          onClick={Logout}
        >
          <NavLink className="menu-link" to="/auth/login">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Box2.svg")} />
            </span>
            <span className="menu-text">Logout</span>
          </NavLink>
        </li>
      </ul>
      {/* end::Menu Nav */}
    </>
  );
}
