import React, { useEffect, useState } from "react";
import "react-quill/dist/quill.snow.css";
import { toAbsoluteUrl } from "../../../helpers";
import { Modal } from "react-bootstrap";
import {
  AddApprovePurchaseApproval,
  AddRejectPurchaseApproval,
  getDownloadImage,
  getOnePurchaseApprovalApi,
} from "../../../service/carService";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../../../common/loader";
import { numberFormater } from "../../../common/thousandSeperator/defaultFunction";
import Lightbox from "react-awesome-lightbox";
import "react-awesome-lightbox/build/style.css";
interface Values {
  Aid: string;
  packageName: string;
  userName: string;
  phone: string;
  receipt: any;
  amount: string;
  reason: string;
  reject: boolean;
  paymentId: string;
}
interface Params {
  id: string;
}

const Editpapproval = ({ id, show, onHide, getPurchaseApproval }) => {
  const [approvalDetail, setApprovalDetail] = useState<any>([]);
  const [loader, setLoader] = useState<boolean>(false);
  const [imageSrc, setImageSrc] = useState<string>();
  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory();

  useEffect(() => {
    getOneApproval();
  }, []);

  const getOneApproval = () => {
    getOnePurchaseApprovalApi(id)
      .then((res: any) => {
        setApprovalDetail(res?.data);
        if (
          res &&
          res.data &&
          res.data.payment &&
          res.data.payment.gateway === "manual"
        ) {
          if (
            res.data.payment.gateway_result &&
            res.data.payment.gateway_result.clientInfo &&
            res.data.payment.gateway_result.clientInfo.attachments.length
          ) {
            getDownloadImage(
              res.data.payment.gateway_result.clientInfo.attachments[0]
            )
              .then((res: any) => {
                setImageSrc(res?.config?.url);
              })
              .catch((err) => {
                setImageSrc("/media/bg/image-not-found.jpeg");
              });
          } else {
            setImageSrc("/media/bg/image-not-found.jpeg");
          }
        } else {
          setImageSrc("/media/bg/image-not-found.jpeg");
        }
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || "Something went wrong.");
      });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      Aid: id ? approvalDetail._id : "2",
      packageName: id ? approvalDetail?.product?.name : "",
      userName: id ? approvalDetail?.created_by?.name : "",
      phone: id ? approvalDetail?.created_by?.phone : "",
      receipt: "",
      amount: id ? approvalDetail?.amountPayable : "",
      reason: id ? approvalDetail?.payment?.gateway_error?.message : "",
      reject:
        id && approvalDetail?.payment?.gateway_error?.message ? true : false,
      paymentId: id ? approvalDetail?.payment?.id : "",
    },
    onSubmit: (values: Values) => {
      // params.id ? onUpdate(values) : onSubmit(values);
      handleReject(values);
    },
  });

  const handleReject = (values: any) => {
    formik.setFieldValue("reject", true);
    if (values.reason) {
      const body = {
        message: values.reason,
      };
      AddRejectPurchaseApproval(values.paymentId, body)
        .then((res: any) => {
          setLoader(false);
          if (res.status === 200) {
            toast.success("Rejected");
            onHide(false);
            history.push("/purchaseApproval");
            getPurchaseApproval();
          }
        })
        .catch((err) => {
          toast.error(err.response?.data?.message || "Something went wrong.");
          setLoader(false);
        });
    }
  };

  const handleApprove = (values: any) => {
    AddApprovePurchaseApproval(values)
      .then((res: any) => {
        setLoader(false);
        if (res.status === 200) {
          toast.success("Approved");
          history.push("/purchaseApproval");
          getPurchaseApproval();
        }
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || "Something went wrong.");
        setLoader(false);
      });
  };
  return (
    <div>
      {loader && <Loader />}
      <Modal
        centered
        show={show}
        onHide={onHide}
        aria-labelledby="example-modal-sizes-title-lg"
        className="service-modal"
      >
        <Modal.Body>
          <div className="row justify-content-between px-10">
            <div className="modal-header-text px-lg-4">Approval</div>
            <div className="modal-close" onClick={onHide}>
              x
            </div>
          </div>

          <div className="row px-10 mt-10">
            <div className="col-sm-6">
              <label>Package ID</label>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  id="id"
                  value={formik.values.Aid || ""}
                  onChange={formik.handleChange}
                  name="id"
                  disabled={true}
                />
              </div>
            </div>
            <div className="col-sm-6">
              <label>Package Name</label>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  id="packageName"
                  name="packageName"
                  value={formik.values.packageName || ""}
                  onChange={formik.handleChange}
                  disabled={true}
                />
              </div>
            </div>
          </div>

          <div className="row px-10">
            <div className="col-sm-6">
              <label>Username</label>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  id="userName"
                  name="userName"
                  value={formik.values.userName || ""}
                  onChange={formik.handleChange}
                  disabled={true}
                />
              </div>
            </div>
            <div className="col-sm-6">
              <label>Phone Number</label>
              <div className="form-group">
                <input
                  type="string"
                  className="form-control"
                  id="phone"
                  value={formik.values.phone || ""}
                  onChange={formik.handleChange}
                  name="phone"
                  disabled={true}
                />
              </div>
            </div>
          </div>

          <div className="row px-10">
            {approvalDetail.payment &&
            approvalDetail.payment.gateway === "manual" ? (
              <div className="col-sm-6">
                <label>Reciept</label>
                <div style={{ width: "160px" }} onClick={() => setIsOpen(true)}>
                  <img
                    alt="Logo"
                    className="w-100 h-100 max-h-150px"
                    src={imageSrc}
                  />
                </div>
                {isOpen && (
                  <Lightbox
                    i
                    image={imageSrc}
                    title="Reciept"
                    onClose={() => setIsOpen(false)}
                  />
                )}
              </div>
            ) : (
              ""
            )}
            <div className="col-sm-6">
              <label>Payment Method</label>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  id="service"
                  name="paymentMethod"
                  value="FPS / Bank Transfer"
                  onChange={formik.handleChange}
                  disabled={true}
                />
              </div>

              <label>Amount</label>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  id="service"
                  name="service"
                  value={`$${numberFormater(formik.values.amount)}` || ""}
                  onChange={formik.handleChange}
                  disabled={true}
                />
              </div>
            </div>

            {formik.values.reject && (
              <div className=" mt-4">
                <div className="col-12 col-sm-2 mb-4 mb-sm-0  ws-nowrap">
                  Reject Reason
                </div>
                <div className="col-12">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      id="reason"
                      name="reason"
                      value={formik.values.reason || ""}
                      onChange={formik.handleChange}
                      disabled={
                        approvalDetail.paymentStatus === "pending"
                          ? false
                          : true
                      }
                    />
                  </div>
                </div>
              </div>
            )}
          </div>

          {approvalDetail.payment?.gateway === "manual" &&
            approvalDetail.paymentStatus === "pending" && (
              <div className="d-flex flex-wrap align-items-center justify-content-start mt-10 ml-4">
                <div className="mt-4 p-0 m-0">
                  <button
                    className="btn btn-car-primary rounded"
                    onClick={() => {
                      handleApprove(approvalDetail.payment?.id);
                      onHide(false);
                    }}
                  >
                    Appove
                  </button>
                  <button
                    className="btn btn-danger mr-2 mx-sm-2"
                    onClick={() => formik.handleSubmit()}
                  >
                    Reject
                  </button>
                </div>
              </div>
            )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Editpapproval;
