import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import { MetronicLayoutProvider } from "./layout/core/MetronicLayout";
import { MetronicSplashScreenProvider } from "./layout/core/MetronicSplashScreen";
import "./assets/plugins/keenthemes-icons/font/ki.css";
import "./assets/plugins/flaticon/flaticon.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
// import "bootstrap/dist/css/bootstrap.css";
import "antd/dist/antd.css";
import "@pathofdev/react-tag-input/build/index.css";
import "font-awesome/css/font-awesome.min.css";
import "./style.css";

ReactDOM.render(
  <React.StrictMode>
    <MetronicLayoutProvider>
      <MetronicSplashScreenProvider>
        <App />
      </MetronicSplashScreenProvider>
    </MetronicLayoutProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
