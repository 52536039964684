import React from "react";
import { Link } from "react-router-dom";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
} from "../../../partials/controls";
import "react-quill/dist/quill.snow.css";

const Editblacklis = () => {
  return (
    <div>
      <Card>
        <CardHeader title={"Blacklist"}></CardHeader>
        <CardBody>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="row">
                <div className="col-lg-4 ws-nowrap">Username</div>
                <div className="col-lg-8">
                  {" "}
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      id="title"
                      disabled={true}
                      name="title"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="row">
                <div className="col-lg-4 ws-nowrap">Phone Number</div>
                <div className="col-lg-8">
                  {" "}
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      id="title"
                      disabled={true}
                      name="title"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="row">
                <div className="col-lg-4 ws-nowrap">Plate Number</div>
                <div className="col-lg-8">
                  {" "}
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      id="title"
                      disabled={true}
                      name="title"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="row">
                <div className="col-lg-4 ws-nowrap">Living District</div>
                <div className="col-lg-8">
                  {" "}
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      id="title"
                      disabled={true}
                      name="title"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-12 col-sm-2 mb-4 mb-sm-0  ws-nowrap">
              Status
            </div>
            <div className="col-4">
              <div className="input-group relative d-flex align-items-center">
                <select
                  className="form-control top-select rounded"
                  name="status"
                  style={{ paddingRight: "3rem" }}
                >
                  <option value="" disabled>
                    -- Select --
                  </option>
                  <option>Active</option>
                  <option>Inactive</option>
                </select>
                <ExpandMoreRoundedIcon
                  style={{
                    position: "absolute",
                    right: "1rem",
                    // zIndex: 10,
                  }}
                />
              </div>
            </div>
          </div>
        </CardBody>

        <CardFooter>
          <div className="d-flex flex-wrap align-items-center justify-content-between">
            <div className="mt-4 mt-sm-0">
              <button className="btn btn-success mr-2 mx-sm-2">Save</button>
            </div>
            <div className="mt-4 mt-sm-0">
              <Link className="btn btn-car-back" to="/blacklist">
                Back
              </Link>
            </div>
          </div>
        </CardFooter>
      </Card>
    </div>
  );
};

export default Editblacklis;
