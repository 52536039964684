import React, { FC, useState, useEffect, useRef } from "react";
import SVG from "react-inlinesvg";

import { Card, CardBody, CardHeader } from "../../../partials/controls";
import { toast } from "react-toastify";
import { SizePerPage } from "../../../common/table/filters/SizePerPage";
import { Table } from "../../../common/table";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { Modal, ModalFooter } from "react-bootstrap";
import {
  getBookingApi,
  getBranchDetailsForSearch,
  getBranchsList,
} from "../../../service/carService";
import { FilterQuery } from "../../../common/table/filters/FilterQuery";
import { StatusFilter } from "../../../common/table/filters/StatusFilter";
import { Link, Redirect, useHistory } from "react-router-dom";
import {
  toAbsoluteUrl,
  headerSortingClasses,
  sortCaret,
} from "../../../helpers";
import Loader from "../../../common/loader";
import moment from "moment";
import { sortServerString, sortString } from "../../../common/table/sorter";
import { PaginationLinks } from "../../../common/pagination/PaginationLinks";
import { PaginationToolbar } from "../../../common/pagination/PaginationToolbar";
import { BranchFilter } from "../../../common/table/filters/BranchFilter";
import Select from "react-select";
import { CSVLink } from "react-csv";

const MonthOptions = [
  { label: "January", value: 1, days: 31 },
  { label: "February", value: 2, days: 28 },
  { label: "March", value: 3, days: 31 },
  { label: "April", value: 4, days: 30 },
  { label: "May", value: 5, days: 31 },
  { label: "June", value: 6, days: 30 },
  { label: "July", value: 7, days: 31 },
  { label: "Augest", value: 8, days: 31 },
  { label: "September", value: 9, days: 30 },
  { label: "October", value: 10, days: 31 },
  { label: "November", value: 11, days: 30 },
  { label: "December", value: 12, days: 31 },
];

const yearOptions = [
  {
    label: new Date().getFullYear() - 1,
    value: new Date().getFullYear() - 1,
  },
  { label: new Date().getFullYear(), value: new Date().getFullYear() },
];

const Booking: FC = () => {
  const history = useHistory();
  const [query, setQuery] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [totalData, setTotalData] = useState<number>(1);

  const [entities, setEntities] = useState<any>([]);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [type, setType] = useState<string>("0$created");
  const [sortingType, setSortingType] = useState<any>("");
  const [branchList, setBranchList] = useState<any>([]);
  const [branchListFilter, setBranchListFilter] = useState([]);
  const [branch, setBranch] = useState<any>("");
  const [selectedBranch, setSelectedBranch] = useState<any>(null);
  const [filteredExportData, setFilteredExportData] = useState<any>([]);
  const [exportMonth, setExportMonth] = useState<any>(null);
  const [exportYear, setExportYear] = useState<any>(null);
  const [exportModal, setExportModal] = useState<any>(false);
  const csvRef: any = useRef();

  const statusMap: any = {
    "0$created": {
      label: "Booked",
      class: "info",
    },
    "97$cancelled": {
      label: "Cancelled",
      class: "",
    },
    "98$absent": {
      label: "Absent",
      class: "danger",
    },
    "1$arrived": {
      label: "Arrived",
      class: "warning",
    },
    "99$completed": {
      label: "Completed",
      class: "success",
    },
  };

  const StatusColumnFormatter = (status: any) => {
    if (status) {
      return (
        <span
          className={`label label-lg label-light-${statusMap[status]["class"]} label-inline font-weight-normal font-size-11`}
        >
          {statusMap[status]["label"]}
        </span>
      );
    }
  };

  const DateColumnFormatter = (date: any) => {
    if (date) {
      return moment(date).format("DD/MM/YYYY HH:mm");
    }
  };

  const openBookDetailPage = (rowData) => {
    localStorage.setItem("status", rowData.status);
    window.open(`/booking/edit/${rowData._id}`, "_blank");

    // history.push({
    //   pathname: `/booking/edit/${rowData._id}`,
    //   // state: { status: rowData.status },
    // });
  };

  const ActionEdit = (cellContent, row, rowIndex) => {
    return (
      <a
        title="Edit customer"
        className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
        onClick={(e) => openBookDetailPage(row)}
      >
        <span className="svg-icon svg-icon-md svg-icon-primary">
          <SVG
            src={toAbsoluteUrl("/media/svg/icons/Communication/arrow.svg")}
          />
        </span>
      </a>
    );
  };
  const formateUser = (cellContent, row, rowIndex) => {
    return (
      <>
        <div className="text-left">
          <div className="user-name-booking">{row?.created_by?.name}</div>
          <div className="phone-number">{row?.created_by?.phone}</div>
        </div>
      </>
    );
  };

  const columns = [
    // {
    //   dataField: "_id",
    //   text: "ID",
    //   sort: true,
    //   sortCaret: sortCaret,
    //   headerSortingClasses,
    //   onSort: (field: string, order: string) =>
    //   sortServerString(field, order, setSortingType),
    // },
    {
      dataField: "branch",
      text: "Branch",
      sort: false,
      formatter: (cell: string) => {
        const defaultName = cell?.includes("{") ? JSON.parse(cell) : null;
        return defaultName ? defaultName?.en : cell;
      },
    },
    {
      dataField: `created_by.name`,
      text: "Username",
      sort: false,
      formatter: formateUser,
    },
    // {
    //   dataField: "created_by.phone",
    //   text: "Phone",
    //   sort: true,
    // },
    {
      dataField: "created_at",
      text: "Created Date",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      onSort: (field: string, order: string) =>
        sortServerString(field, order, setSortingType),
      formatter: DateColumnFormatter,
    },
    {
      dataField: "time.start",
      text: "Service Date",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      onSort: (field: string, order: string) =>
        sortServerString(field, order, setSortingType),
      formatter: DateColumnFormatter,
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      onSort: (field: string, order: string) =>
        sortServerString(field, order, setSortingType),
      formatter: StatusColumnFormatter,
    },
    // {
    //   dataField: "action",
    //   text: "Action",
    //   formatter: ActionEdit,
    // },
  ];

  const getExportData = () => {
    const isSortColumn =
      sortingType !== "" ? `&${sortingType}` : `&sort={"created_at":-1}`;
    const BranchStatusPopulate =
      branch !== ""
        ? `&filter={"status":"99$completed", "serviceDetails.masterSpecified":"${branch}","time.start":{"$gte":"${
            exportYear?.value
          }-${exportMonth?.value}-1","$lte":"${exportYear?.value}-${
            exportMonth?.value
          }-${
            MonthOptions.filter((x) => x.value === exportMonth?.value)[0]?.days
          }"}}&populate=["serviceDetails.masterSpecified", "serviceDetails.serviceProduct", "serviceDetails.packageSpecified", "created_by", "voidedBy"]`
        : `&filter={"status":"99$completed","time.start":{"$gte":"${
            exportYear?.value
          }-${exportMonth?.value}-1","$lte":"${exportYear?.value}-${
            exportMonth?.value
          }-${
            MonthOptions.filter((x) => x.value === exportMonth?.value)[0]?.days
          }"}}&populate=["serviceDetails.masterSpecified", "serviceDetails.serviceProduct", "serviceDetails.packageSpecified", "created_by", "voidedBy"]`;
    return getBookingApi(1, 1000, query, isSortColumn, BranchStatusPopulate)
      .then((res: any) => {
        let news: any = [];
        res?.data?.docs.map((data: any, index: any) => {
          const packageName = data?.serviceDetails?.packageSpecified?._search.split(
            ";"
          );
          const Obj = {
            _id: data._id,
            serviceTime: `${moment(data?._timetableTime?.start).format(
              "YYYY-MM-DD"
            )} ${moment(data?._timetableTime?.start).format(
              "HH:mm"
            )} ~ ${moment(data?._timetableTime?.end).format("HH:mm")}`,
            discount: data?.otherDiscount?.percentageDiscount || "N/A",
            customer: data?.created_by?.name || "N/A",
            phone: data?.created_by?.phone || "N/A",
            price: data?.amountPayable || "N/A",
            service: data?.serviceDetails?.serviceProduct?.name || "N/A",
            package: data?.serviceDetails?.packageSpecified
              ? packageName[packageName.length - 1]
              : "N/A",
            carPlate: data?.carPlate,
            status: "Completed",
            // branch: data?.serviceDetails?.masterSpecified?.name
            //   ? data.serviceDetails.masterSpecified.name
            //   : "",
            // created_by: data?.created_by,
            // created_at: data?.created_at,
            // time: data._timetableTime,
            // status: data.status,
          };
          news.push(Obj);
        });
        return news;
        // setFilteredExportData(news);
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || "Something went wrong.");
      });
  };

  const getBooking = (page = 1, sizePerPage = 10, showLoading = true) => {
    // const isStatus =
    //   type !== ""
    //     ? `&filter={"status":"${type}"}&populate=["serviceDetails.masterSpecified", "created_by"]`
    //     : `&populate=["serviceDetails.masterSpecified", "created_by"]`;
    const isSortColumn =
      sortingType !== "" ? `&${sortingType}` : `&sort={"created_at":-1}`;

    const isSortBranch =
      branch !== ""
        ? `&filter={"serviceDetails.masterSpecified":"${branch}"}`
        : null;

    const isSortBranchStatus =
      type !== "" && branch !== ""
        ? `&filter={"status":"${type}", "serviceDetails.masterSpecified":"${branch}"}&populate=["serviceDetails.masterSpecified", "created_by"]`
        : type !== "" && branch == ""
        ? `&filter={"status":"${type}"}&populate=["serviceDetails.masterSpecified", "created_by"]`
        : branch !== "" && type == ""
        ? `&filter={"serviceDetails.masterSpecified":"${branch}"}&populate=["serviceDetails.masterSpecified", "created_by"]`
        : `&populate=["serviceDetails.masterSpecified", "created_by"]`;
    if (showLoading) {
      setLoading(true);
    }

    getBookingApi(page, sizePerPage, query, isSortColumn, isSortBranchStatus)
      .then((res: any) => {
        setLoading(false);
        let news: any = [];
        res?.data?.docs.map((data, index) => {
          const Obj = {
            index: ++index + (page - 1) * sizePerPage,
            _id: data._id,
            branch: data?.serviceDetails?.masterSpecified?.name
              ? data.serviceDetails.masterSpecified.name
              : "",
            created_by: data?.created_by,
            // created_at: moment(data?.created_at).format("DD/MM/YYYY HH:mm"),
            created_at: data?.created_at,
            time: data._timetableTime,
            // status: data.enabled === true ? "Active" : "Inactive",
            status: data.status,
          };
          news.push(Obj);
        });
        setEntities(news);
        setTotalData(res?.data?.total);
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || "Something went wrong.");
      });
  };

  useEffect(() => {
    getBranchList();
    getBranchsDetailsForSearch();
  }, []);

  const getBranchList = () => {
    setLoading(true);
    getBranchsList()
      .then((res: any) => {
        setLoading(false);
        setBranchList(res.data.docs);
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || "Something went wrong.");
        setLoading(false);
      });
  };

  const getBranchsDetailsForSearch = () => {
    setLoading(true);
    getBranchDetailsForSearch()
      .then((res: any) => {
        setLoading(false);
        const branchData = res.data.docs.map((branch) => {
          const defaultName = branch.name?.includes("{")
            ? JSON.parse(branch.name)
            : null;
          return {
            value: branch.id,
            label: defaultName ? defaultName?.en : branch.name,
          };
        });
        branchData.unshift({ value: "", label: "All" });
        setBranchListFilter(branchData);
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || "Something went wrong.");
        setLoading(false);
      });
  };

  const customTotal = (from: any, to: any, size: any) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing rows {totalData === 0 ? 0 : (page - 1) * sizePerPage + 1} to {to}{" "}
      of {totalData}
    </span>
  );

  const onTableChange = async (type: any, props: any, paginationProps: any) => {
    if (type !== "sort") {
      setSizePerPage(props.sizePerPage);
      if (props.sizePerPage > paginationProps.totalSize) {
        setPage(1);
        getBooking(1, props.sizePerPage);
        setPage(1);
      } else {
        setPage(props.page);
        getBooking(props.page, props.sizePerPage);
      }
    }
  };

  const paginationOptions = {
    custom: true,
    totalSize: totalData,
    hideSizePerPage: true,
    showTotal: true,
    page,
    sizePerPage,
    paginationTotalRenderer: customTotal,
  };

  const getSelectRow = {
    mode: "radio",
    clickToSelect: true,
    hideSelectColumn: true,
    // onSelect: (data: ArticleEntities) => history.push(`/booking/edit`),
  };
  const handleSizePerPage = (
    { page, onSizePerPageChange }: any,
    newSizePerPage: any
  ) => {
    onSizePerPageChange(newSizePerPage, page);
  };

  useEffect(() => {
    getBooking(1, 10, false);
  }, [query, type, sortingType, branch]);

  const handleBranchChange = (selectedOption) => {
    if (selectedOption) {
      setSelectedBranch(selectedOption);
      setBranch(selectedOption.value);
    } else {
    }
  };

  const headers = [
    { label: "Booking ID", key: "_id" },
    { label: "Service Time", key: "serviceTime" },
    // { label: "Service", key: "service" },
    { label: "Package Used", key: "package" },
    { label: "Customer Name", key: "customer" },
    { label: "Customer Phone", key: "phone" },
    { label: "Plate Number", key: "carPlate" },
    { label: "Discount Off", key: "discount" },
    { label: "Total Price", key: "price" },
    { label: "Status", key: "status" },
  ];

  const downloadReport = async () => {
    if (exportMonth && exportYear) {
      const data = await getExportData();
      setFilteredExportData(data);

      setTimeout(() => {
        csvRef.current.link.click();
      }, 200);
    }
  };

  const rowEvents = {
    onClick: (a, row, c) => {
      openBookDetailPage(row);
    },
  };

  return (
    <Card>
      {loading && <Loader />}
      <CardHeader title="Booking">
        <div className="d-flex align-items-center">
          <a
            title="Edit customer"
            className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
            role="button"
            onClick={() => {
              getBooking();
            }}
          >
            <span className="svg-icon svg-icon-md svg-icon-primary">
              <SVG src={toAbsoluteUrl("/media/svg/icons/reload.svg")} />
            </span>
          </a>
        </div>
      </CardHeader>
      <CardBody>
        <PaginationProvider pagination={paginationFactory(paginationOptions)}>
          {({ paginationProps, paginationTableProps }) => (
            <>
              <div className="d-flex ">
                <div className="d-flex flex-column flex-md-row flex-sm-column mb-10">
                  <div className="mt-4 mr-3">
                    <div
                      className={`d-flex align-items-center top-select`}
                      style={{ position: "relative", minWidth: "15rem" }}
                    >
                      <Select
                        name="branch"
                        options={branchListFilter}
                        closeMenuOnSelect={true}
                        onChange={handleBranchChange}
                        value={selectedBranch}
                        className="basic-multi-select w-100"
                        defaultValue={branchListFilter}
                        classNamePrefix="select"
                        placeholder="-- Select Branch --"
                      />
                    </div>
                    <small className="form-text text-muted">
                      <b>Filter</b> by Branch
                    </small>
                  </div>
                  <StatusFilter
                    status={type}
                    setStatus={(e) => {
                      setType(e);
                      setPage(1);
                    }}
                    options={[
                      { label: "All", value: "" },
                      { label: "Arrived", value: "1$arrived" },
                      { label: "Absent", value: "98$absent" },
                      { label: "Booked", value: "0$created" },
                      { label: "Cancelled", value: "97$cancelled" },
                      { label: "Completed", value: "99$completed" },
                    ]}
                  />

                  <FilterQuery
                    query={query}
                    setQuery={(e) => {
                      setQuery(e);
                      setPage(1);
                    }}
                  />
                </div>
                <div className="mt-4">
                  <Link className="btn btn-primary" to="/booking/create">
                    New Booking
                  </Link>
                  <Link className="btn btn-success ml-3" to="/booking/calendar">
                    Calender View
                  </Link>

                  {/* <CSVLink
                    data={exportData}
                    filename={"booking.csv"}
                    className="mr-1"
                    target="_blank"
                  > */}
                  <button
                    className="btn btn-secondary ml-3"
                    onClick={() => {
                      setExportModal(true);
                    }}
                  >
                    Export
                  </button>
                  {/* </CSVLink> */}
                </div>
              </div>
              <Table
                rowEvents={rowEvents}
                getSelectRow={getSelectRow}
                paginationProps={paginationProps}
                paginationOptions={paginationOptions}
                paginationTableProps={paginationTableProps}
                data={entities}
                columns={columns}
                remote
                onTableChange={(type: any, props: any) =>
                  onTableChange(type, props, paginationProps)
                }
              />
              <div className="d-flex justify-content-between align-items-center flex-wrap">
                <div>
                  <PaginationLinks paginationProps={paginationProps} />
                </div>
                <div className="d-flex">
                  <SizePerPage
                    paginationProps={paginationProps}
                    handleSizePerPage={handleSizePerPage}
                    entities={entities}
                  />
                  <PaginationToolbar paginationProps={paginationProps} />
                </div>
              </div>
            </>
          )}
        </PaginationProvider>
      </CardBody>

      <Modal
        centered
        show={exportModal}
        onHide={() => {
          setExportModal(false);
        }}
        dialogClassName="modal-90w"
        aria-labelledby="example-modal-sizes-title-sm"
        className="service-modal"
      >
        <Modal.Body className="show-grid">
          <div className="d-flex justify-content-between">
            <div className="modal-header-text">Export</div>
            <div
              className="modal-close"
              onClick={() => {
                setExportModal(false);
              }}
            >
              x
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-lg-12 mt-6">
              <div className="col-lg-12 ws-nowrap">Year</div>
              <div className="col-lg-12">
                <div className="input-group relative d-flex align-items-center">
                  <Select
                    name="year"
                    options={yearOptions}
                    closeMenuOnSelect={true}
                    onChange={setExportYear}
                    value={exportYear}
                    className="basic-multi-select w-100"
                    classNamePrefix="select"
                    placeholder="Select Year"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="col-lg-12 ws-nowrap">Month</div>
              <div className="col-lg-12">
                <div className="input-group relative d-flex align-items-center">
                  <Select
                    name="month"
                    options={MonthOptions}
                    closeMenuOnSelect={true}
                    onChange={setExportMonth}
                    value={exportMonth}
                    className="basic-multi-select w-100"
                    classNamePrefix="select"
                    placeholder="Select Month"
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>

        <ModalFooter>
          <button className="btn btn-primary" onClick={downloadReport}>
            Export
          </button>
          <CSVLink
            headers={headers}
            data={filteredExportData}
            filename={"booking.csv"}
            target="_blank"
            ref={csvRef}
          />
        </ModalFooter>
      </Modal>
    </Card>
  );
};

export default Booking;
