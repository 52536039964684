import React, { FC, useEffect, useState } from "react";
import SVG from "react-inlinesvg";
import {
  toAbsoluteUrl,
  headerSortingClasses,
  sortCaret,
} from "../../../helpers";
import { Card, CardBody, CardHeader } from "../../../partials/controls";
import { SizePerPage } from "../../../common/table/filters/SizePerPage";
import { Table } from "../../../common/table";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { sortServerString } from "../../../common/table/sorter";
import { PaginationLinks } from "../../../common/pagination/PaginationLinks";
import { PaginationToolbar } from "../../../common/pagination/PaginationToolbar";
import { Dropdown, Form } from "react-bootstrap";
import { FilterQuery } from "../../../common/table/filters/FilterQuery";
import { StatusFilter } from "../../../common/table/filters/StatusFilter";
import {
  getAdminListApi,
  updateAdmin,
  updateAdminData,
} from "../../../service/carService";
import moment from "moment";
import Loader from "../../../common/loader";
import { toast } from "react-toastify";
import AddAdmin from "./addAdmin";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

const Admin: FC = () => {
  const [query, setQuery] = useState<string>("");
  const [totalData, setTotalData] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);

  const StatusColumnFormatter = (status: any) => {
    if (status) {
      return (
        <span
          className={`label label-lg label-light-${
            status === "Active" ? `success` : `danger`
          } label-inline font-weight-normal font-size-11`}
        >
          {status}
        </span>
      );
    }
  };
  const [entities, setEntities] = useState<any>([]);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [type, setType] = useState<string>("true");
  const [adminDialog, setAdminDialog] = useState<Boolean>(false);
  const [adminId, setAdminId] = useState<string>("");
  const [sortingType, setSortingType] = useState<any>("");

  const onSwitchAction = (id, enabled) => {
    const body = {
      enabled: !enabled,
    };
    updateAdmin(id, body)
      .then((res: any) => {
        if (res?.status === 200) {
          getAdminList(page);
          setPage(page);
          setSizePerPage(10);
        }
      })
      .catch((err) => {
        // toast.error("You cannot disable yourself.");
        toast.error(err.response?.data?.message || "Something went wrong.");
      });
  };

  const openCreateAdmin = (id: string) => {
    setAdminDialog(true);
    setAdminId(id);
  };
  const onClose = (isModalClose) => {
    if (isModalClose) {
      getAdminList();
      setPage(1);
      setSizePerPage(10);
    }
    setAdminDialog(false);
  };
  const ActionEdit = (cellContent, row, rowIndex) => {
    return (
      <>
        <Dropdown className="dropdown-inline" drop="down" alignRight>
          <Dropdown.Toggle
            id="dropdown-status"
            className="btn btn-icon btn-light btn-sm dropdown-toggle"
          >
            <span className="svg-icon svg-icon-md svg-icon-primary">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/General/Settings-1.svg")}
              ></SVG>
            </span>
          </Dropdown.Toggle>
          <Dropdown.Menu
            className="dropdown-menu dropdown-menu-sm dropdown-menu-right pb-4"
            style={{ width: "250px" }}
          >
            <ul className="navi navi-hover">
              <li className="navi-header pb-1">
                <span className="text-primary text-uppercase font-weight-bold font-size-base">
                  ACTION:
                </span>
              </li>
              <li className="navi-item pl-6">
                <div className="d-flex align-items-center">
                  {/* <span className="font-weight-normal font-size-base mr-4">
                    Status
                  </span> */}
                  <span className="navi-icon">
                    <FormControlLabel
                      control={
                        <Switch
                          onChange={() => onSwitchAction(row.id, row.status)}
                          color="primary"
                          id={`custom-switch-${row.id}`}
                          checked={row.status}
                        />
                      }
                      label={row.status ? "Active" : "Inactive"}
                      className="mb-0"
                    />
                  </span>
                </div>
              </li>
            </ul>
          </Dropdown.Menu>
        </Dropdown>
        <a
          title="Edit customer"
          className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
          onClick={() => openCreateAdmin(row.id)}
        >
          <span className="svg-icon svg-icon-md svg-icon-primary">
            <SVG
              src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
            />
          </span>
        </a>
      </>
    );
  };

  const columns = [
    // {
    //   dataField: "index",
    //   text: "Number",
    //   sort: true,
    //   sortCaret: sortCaret,
    //   headerSortingClasses,
    //   onSort: (field: string, order: string) =>
    //     sortServerString(field, order, setSortingType),
    // },
    {
      dataField: "name",
      text: "Admin Name",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      onSort: (field: string, order: string) =>
        sortServerString(field, order, setSortingType),
    },

    {
      dataField: "role",
      text: "Type",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      onSort: (field: string, order: string) =>
        sortServerString(field, order, setSortingType),
    },
    {
      dataField: "enabled",
      text: "Status",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: StatusColumnFormatter,
      onSort: (field: string, order: string) =>
        sortServerString(field, order, setSortingType),
    },
    // {
    //   dataField: "action",
    //   text: "Action",
    //   formatter: ActionEdit,
    // },
  ];

  const customTotal = (from: any, to: any, size: any) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing rows {totalData === 0 ? 0 : (page - 1) * sizePerPage + 1} to {to}{" "}
      of {totalData}
    </span>
  );

  const onTableChange = async (type: any, props: any, paginationProps: any) => {
    if (type !== "sort") {
      setSizePerPage(props.sizePerPage);
      if (props.sizePerPage > paginationProps.totalSize) {
        setPage(1);
        getAdminList(1, props.sizePerPage);
        setPage(1);
      } else {
        setPage(props.page);
        getAdminList(props.page, props.sizePerPage);
      }
    }
  };

  const handleSizePerPage = (
    { page, onSizePerPageChange }: any,
    newSizePerPage: any
  ) => {
    onSizePerPageChange(newSizePerPage, page);
  };

  const paginationOptions = {
    custom: true,
    totalSize: totalData,
    hideSizePerPage: true,
    showTotal: true,
    page,
    sizePerPage,
    paginationTotalRenderer: customTotal,
  };

  const getAdminList = (page = 1, sizePerPage = 10, showLoading = true) => {
    const isStatus =
      type !== ""
        ? `&filter={"role": ["admin","bn.cashier"],"enabled": ${type}}`
        : `&filter={"role": ["admin","bn.cashier"]}`;
    const isSortColumn =
      sortingType !== "" ? `&${sortingType}` : `&sort={"created_at":-1}`;

    if (showLoading) {
      setLoading(true);
    }
    getAdminListApi(page, sizePerPage, query, isStatus, isSortColumn)
      .then((res: any) => {
        setLoading(false);
        let news: any = [];
        if (res?.data?.docs.length === 0 && page !== 1) {
          getAdminList(page - 1);
          setPage(page - 1);
          return;
        }
        res?.data?.docs.map((data, index) => {
          const Obj = {
            index: ++index + (page - 1) * sizePerPage,
            id: data.id,
            name: data.name,
            role: data.role,
            created_at: moment(data.created_at).format("DD/MM/YYYY HH:mm"),
            enabled: data.enabled === true ? "Active" : "Inactive",
            status: data.enabled,
          };
          news.push(Obj);
        });
        setEntities(news);
        setTotalData(res?.data?.total);
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || "Something went wrong.");
        setLoading(false);
      });
  };
  // useEffect(() => {
  //   getAdminList();
  // }, [sortingType]);

  useEffect(() => {
    getAdminList(1, 10, false);
  }, [query, type, sortingType]);

  const rowEvents = {
    onClick: (a, row, c) => {
      openCreateAdmin(row?.id);
    },
  };
  return (
    <Card>
      {loading && <Loader />}
      <CardHeader title="Admin">
        <div className="d-flex align-items-center">
          <a
            title="Edit customer"
            className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
            role="button"
            onClick={() => {
              getAdminList();
            }}
          >
            <span className="svg-icon svg-icon-md svg-icon-primary">
              <SVG src={toAbsoluteUrl("/media/svg/icons/reload.svg")} />
            </span>
          </a>
        </div>
      </CardHeader>
      <CardBody>
        <PaginationProvider pagination={paginationFactory(paginationOptions)}>
          {({ paginationProps, paginationTableProps }) => (
            <>
              <div className="d-flex ">
                <div className="d-flex flex-column flex-md-row flex-sm-column mb-10">
                  <StatusFilter
                    status={type}
                    setStatus={(e) => {
                      setType(e);
                      setPage(1);
                    }}
                    options={[
                      { label: "All", value: "" },
                      { label: "Active", value: "true" },
                      { label: "Inactive", value: "false" },
                    ]}
                  />
                  <FilterQuery
                    query={query}
                    setQuery={(e) => {
                      setQuery(e);
                      setPage(1);
                    }}
                  />
                </div>
                <div className="mt-4">
                  <button
                    className="btn btn-primary"
                    onClick={() => openCreateAdmin("")}
                  >
                    New Admin
                  </button>
                </div>
              </div>
              <Table
                rowEvents={rowEvents}
                paginationProps={paginationProps}
                paginationOptions={paginationOptions}
                paginationTableProps={paginationTableProps}
                data={entities}
                columns={columns}
                remote
                onTableChange={(type: any, props: any) =>
                  onTableChange(type, props, paginationProps)
                }
              />
              <div className="d-flex justify-content-between align-items-center flex-wrap">
                <div>
                  <PaginationLinks paginationProps={paginationProps} />
                </div>
                <div className="d-flex">
                  <SizePerPage
                    paginationProps={paginationProps}
                    handleSizePerPage={handleSizePerPage}
                    entities={entities}
                  />
                  <PaginationToolbar paginationProps={paginationProps} />
                </div>
              </div>
            </>
          )}
        </PaginationProvider>
      </CardBody>
      {adminDialog && (
        <AddAdmin
          show={adminDialog}
          onHide={(isModalClose) => onClose(isModalClose)}
          id={adminId}
        />
      )}
    </Card>
  );
};

export default Admin;
