import React, { FC, useState, useEffect } from "react";
import SVG from "react-inlinesvg";
import { Card, CardBody, CardHeader } from "../../../partials/controls";
import { toast } from "react-toastify";
import { SizePerPage } from "../../../common/table/filters/SizePerPage";
import { Table } from "../../../common/table";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import {
  getBookingApi,
  getBranchDetailsForSearch,
  getBranchsList,
  getCustomerBookingsWithId,
} from "../../../service/carService";
import { FilterQuery } from "../../../common/table/filters/FilterQuery";
import { StatusFilter } from "../../../common/table/filters/StatusFilter";
import { Link, useParams } from "react-router-dom";
import {
  toAbsoluteUrl,
  headerSortingClasses,
  sortCaret,
} from "../../../helpers";
import Loader from "../../../common/loader";
import moment from "moment";
import { sortServerString, sortString } from "../../../common/table/sorter";
import { PaginationLinks } from "../../../common/pagination/PaginationLinks";
import { PaginationToolbar } from "../../../common/pagination/PaginationToolbar";
import Select from "react-select";

const CustomerBookings = () => {
  const [query, setQuery] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [totalData, setTotalData] = useState<number>(1);

  const [entities, setEntities] = useState<any>([]);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [type, setType] = useState<string>("");
  const [sortingType, setSortingType] = useState<any>("");
  const [branchListFilter, setBranchListFilter] = useState([]);
  const [branch, setBranch] = useState<any>("");
  const [selectedBranch, setSelectedBranch] = useState<any>(null);
  const [customerBookings, setCustomerBookings] = useState([]);
  const [branchList, setBranchList] = useState<any>([]);
  const statusMap: any = {
    "0$created": {
      label: "Booked",
      class: "info",
    },
    "97$cancelled": {
      label: "Cancelled",
      class: "",
    },
    "98$absent": {
      label: "Absent",
      class: "danger",
    },
    "1$arrived": {
      label: "Arrived",
      class: "warning",
    },
    "99$completed": {
      label: "Completed",
      class: "success",
    },
  };

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      openBookDetailPage(row);
    },
  };

  const StatusColumnFormatter = (status: any) => {
    if (status) {
      return (
        <span
          className={`label label-lg label-light-${statusMap[status]["class"]} label-inline font-weight-normal font-size-11`}
        >
          {statusMap[status]["label"]}
        </span>
      );
    }
  };
  const params = useParams();
  //  @ts-ignore
  const id: any = params.id;

  const getCustomerBookingsListFunction = () => {
    let bookingList: any = [];
    if (id) {
      getCustomerBookingsWithId(id, page, branch, type, query, sizePerPage)
        .then((res: any) => {
          setTotalData(res?.data?.total);
          setPage(res?.data?.page);
          res?.data?.docs.map((data, index) => {
            const Obj = {
              index: ++index + (page - 1) * sizePerPage,
              _id: data._id,
              branch: data?.serviceDetails?.masterSpecified?.name,
              created_at: data?.created_at,
              serviceTime: {
                start: data.time.start,
                end: data.time.end,
              },
              serviceDate: data.updated_at,
              status: data.status,
            };
            bookingList.push(Obj);
          });
          setCustomerBookings(bookingList);
        })
        .catch((err) => {
          toast.error(err.response?.data?.message || "Something went wrong.");
        });
    }
  };
  useEffect(() => {
    getCustomerBookingsListFunction();
  }, [branch]);

  const DateColumnFormatter = (date: any) => {
    if (date) {
      return moment(date).format("DD-MM-YYYY");
    }
  };

  const TimeColumnFormatter = (date: any) => {
    if (date) {
      return `${moment(date.start).format("HH:mm")}-${moment(date.end).format(
        "HH:mm"
      )}`;
    }
  };

  const openBookDetailPage = (rowData) => {
    localStorage.setItem("status", rowData.status);
    window.open(`/booking/edit/${rowData._id}`, "_blank");
  };

  const ActionEdit = (cellContent, row, rowIndex) => {
    return (
      <div
        title="Edit customer"
        className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
        onClick={(e) => openBookDetailPage(row)}
      >
        <span className="svg-icon svg-icon-md svg-icon-primary">
          <SVG
            src={toAbsoluteUrl("/media/svg/icons/Communication/arrow.svg")}
          />
        </span>
      </div>
    );
  };
  const formateUser = (cellContent, row, rowIndex) => {
    return (
      <>
        <div className="text-left">
          <div className="user-name-booking">{row.created_by.name}</div>
          <div className="phone-number">{row.created_by.phone}</div>
        </div>
      </>
    );
  };

  const columns = [
    {
      dataField: "branch",
      text: "Branch",
      sort: false,
      formatter: (cell: string) => {
        const defaultName = cell?.includes("{") ? JSON.parse(cell) : null;
        return defaultName ? defaultName?.en : cell;
      },
    },
    {
      dataField: "created_at",
      text: "Created Date",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      onSort: (field: string, order: string) =>
        sortServerString(field, order, setSortingType),
      formatter: DateColumnFormatter,
    },
    {
      dataField: "serviceTime.start",
      text: "Service Date",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      onSort: (field: string, order: string) =>
        sortServerString(field, order, setSortingType),
      formatter: DateColumnFormatter,
    },
    {
      dataField: "serviceTime",
      text: "Service Time",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      onSort: (field: string, order: string) =>
        sortServerString(field, order, setSortingType),
      formatter: TimeColumnFormatter,
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      onSort: (field: string, order: string) =>
        sortServerString(field, order, setSortingType),
      formatter: StatusColumnFormatter,
    },
  ];

  const getBooking = (page = 1, sizePerPage = 10, showLoading = true) => {
    // const isStatus =
    //   type !== ""
    //     ? `&filter={"status":"${type}"}&populate=["serviceDetails.masterSpecified", "created_by"]`
    //     : `&populate=["serviceDetails.masterSpecified", "created_by"]`;
    const isSortColumn =
      sortingType !== "" ? `&${sortingType}` : `&sort={"created_at":-1}`;

    const isSortBranch =
      branch !== ""
        ? `&filter={"serviceDetails.masterSpecified":"${branch}"}`
        : null;

    const isSortBranchStatus =
      type !== "" && branch !== ""
        ? `&filter={"status":"${type}", "serviceDetails.masterSpecified":"${branch}"}&populate=["serviceDetails.masterSpecified", "created_by"]`
        : type !== "" && branch == ""
        ? `&filter={"status":"${type}"}&populate=["serviceDetails.masterSpecified", "created_by"]`
        : branch !== "" && type == ""
        ? `&filter={"serviceDetails.masterSpecified":"${branch}"}&populate=["serviceDetails.masterSpecified", "created_by"]`
        : `&populate=["serviceDetails.masterSpecified", "created_by"]`;
    if (showLoading) {
      setLoading(true);
    }

    getBookingApi(page, sizePerPage, query, isSortColumn, isSortBranchStatus)
      .then((res: any) => {
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || "Something went wrong.");
      });
  };

  const getBranchList = () => {
    setLoading(true);
    getBranchsList()
      .then((res: any) => {
        setLoading(false);
        setBranchList(res.data.docs);
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || "Something went wrong.");
        setLoading(false);
      });
  };

  const getBranchsDetailsForSearch = () => {
    setLoading(true);
    getBranchDetailsForSearch()
      .then((res: any) => {
        setLoading(false);
        const branchData = res.data.docs.map((branch) => {
          const defaultName = branch.name?.includes("{")
            ? JSON.parse(branch.name)
            : null;
          return {
            value: branch.id,
            label: defaultName ? defaultName?.en : branch.name,
          };
        });
        branchData.unshift({ value: "", label: "All" });
        setBranchListFilter(branchData);
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || "Something went wrong.");
        setLoading(false);
      });
  };

  const customTotal = (from: any, to: any, size: any) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing rows {totalData === 0 ? 0 : (page - 1) * sizePerPage + 1} to {to}{" "}
      of {totalData}
    </span>
  );

  const onTableChange = async (type: any, props: any, paginationProps: any) => {
    if (type !== "sort") {
      setSizePerPage(props.sizePerPage);
      if (props.sizePerPage > paginationProps.totalSize) {
        setPage(1);
        getBooking(1, props.sizePerPage);
        setPage(1);
      } else {
        setPage(props.page);
        getBooking(props.page, props.sizePerPage);
      }
    }
  };

  const paginationOptions = {
    custom: true,
    totalSize: totalData,
    hideSizePerPage: true,
    showTotal: true,
    page,
    sizePerPage,
    paginationTotalRenderer: customTotal,
  };

  const getSelectRow = {
    mode: "radio",
    clickToSelect: true,
    hideSelectColumn: true,
    // onSelect: (data: ArticleEntities) => history.push(`/booking/edit`),
  };
  const handleSizePerPage = (
    { page, onSizePerPageChange }: any,
    newSizePerPage: any
  ) => {
    onSizePerPageChange(newSizePerPage, page);
  };

  useEffect(() => {
    getBranchsDetailsForSearch();
    getCustomerBookingsListFunction();
    console.log("hello");
  }, [query, type, sortingType, branch, page]);

  const handleBranchChange = (selectedOption) => {
    if (selectedOption) {
      setSelectedBranch(selectedOption);
      setBranch(selectedOption.value);
    } else {
    }
  };
  return (
    <Card>
      {loading && <Loader />}
      <CardHeader title="Booking"></CardHeader>
      <CardBody>
        <PaginationProvider pagination={paginationFactory(paginationOptions)}>
          {({ paginationProps, paginationTableProps }) => (
            <>
              <div className="d-flex ">
                <div className="d-flex flex-column flex-md-row flex-sm-column mb-10">
                  <div className="mt-4 mr-3">
                    <div
                      className={`d-flex align-items-center top-select`}
                      style={{ position: "relative", minWidth: "15rem" }}
                    >
                      <Select
                        name="branch"
                        options={branchListFilter}
                        closeMenuOnSelect={true}
                        onChange={handleBranchChange}
                        value={selectedBranch}
                        className="basic-multi-select w-100"
                        defaultValue={branchListFilter}
                        classNamePrefix="select"
                        placeholder="-- Select Branch --"
                      />
                    </div>
                    <small className="form-text text-muted">
                      <b>Filter</b> by Branch
                    </small>
                  </div>
                  <StatusFilter
                    status={type}
                    setStatus={(e) => {
                      setType(e);
                      setPage(1);
                    }}
                    options={[
                      { label: "All", value: "" },
                      { label: "Arrived", value: "1$arrived" },
                      { label: "Absent", value: "98$absent" },
                      { label: "Booked", value: "0$created" },
                      { label: "Cancelled", value: "97$cancelled" },
                      { label: "Completed", value: "99$completed" },
                    ]}
                  />

                  <FilterQuery
                    query={query}
                    setQuery={(e) => {
                      setQuery(e);
                      setPage(1);
                    }}
                  />
                </div>
              </div>
              <Table
                getSelectRow={getSelectRow}
                paginationProps={paginationProps}
                rowEvents={rowEvents}
                paginationOptions={paginationOptions}
                paginationTableProps={paginationTableProps}
                data={customerBookings}
                columns={columns}
                remote
                onTableChange={(type: any, props: any) =>
                  onTableChange(type, props, paginationProps)
                }
              />
              <div className="d-flex justify-content-between align-items-center flex-wrap">
                <div>
                  <PaginationLinks paginationProps={paginationProps} />
                </div>
                <div className="d-flex">
                  <SizePerPage
                    paginationProps={paginationProps}
                    handleSizePerPage={handleSizePerPage}
                    entities={entities}
                  />
                  <PaginationToolbar paginationProps={paginationProps} />
                </div>
              </div>
            </>
          )}
        </PaginationProvider>
      </CardBody>
    </Card>
  );
};

export default CustomerBookings;
