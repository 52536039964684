import React, { useState, useEffect } from "react";
import "react-quill/dist/quill.snow.css";
import { toast } from "react-toastify";
import {
  addProductType,
  agetOneProductType,
  updateProductType,
  getAllProductType,
} from "../../../service/carService";
import { Form, Modal } from "react-bootstrap";
import Loader from "../../../common/loader";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

interface AddProductType {
  title: string;
  enabled: boolean;
}

const AddProductType = ({ id, show, onHide }) => {
  const [loader, setLoader] = useState<boolean>(false);
  const [product, setProduct] = useState<AddProductType>({
    title: "",
    enabled: false,
  });

  useEffect(() => {
    if (id) {
      getOneProductDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getOneProductDetails = () => {
    setLoader(true);
    agetOneProductType(id)
      .then((res: any) => {
        setLoader(false);
        setProduct(res?.data as AddProductType);
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || "Something went wrong.");
        setLoader(false);
      });
  };

  const onSubmit = () => {
    setLoader(true);
    addProductType(product)
      .then((res: any) => {
        setLoader(false);
        if (res.status === 200) {
          toast.success("Product Type Add Successful");
          onHide();
          getAllProductType();
        }
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || "Something went wrong.");
        setLoader(false);
      });
  };

  const onUpdate = () => {
    setLoader(true);

    updateProductType(id, product)
      .then((res: any) => {
        setLoader(false);
        if (res.status === 200) {
          toast.success("Product Type update Sucessfull");
          onHide();
          getAllProductType();
        }
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || "Something went wrong.");
        setLoader(false);
      });
  };

  const onSwitchAction = () => {
    const productTemp = { ...product };
    productTemp.enabled = !productTemp.enabled;
    setProduct(productTemp);
  };

  return (
    <div>
      {loader && <Loader />}
      <Modal
        centered
        show={show}
        onHide={onHide}
        aria-labelledby="example-modal-sizes-title-lg"
        className="service-modal"
      >
        <Modal.Body>
          <div className="d-flex justify-content-between">
            <div className="modal-header-text px-lg-12">
              {id ? "Edit" : "Create"} Product Type
            </div>
            <div className="modal-close" onClick={onHide}>
              x
            </div>
          </div>
          <div className="row justify-content-center mt-10">
            <div className="col-lg-10">
              {id && <div className="text-muted">Type Name</div>}
              <div className="input-group relative d-flex align-items-center">
                <input
                  type="text"
                  className="form-control border-0"
                  placeholder="Add Type Name"
                  name="title"
                  onChange={(e) =>
                    setProduct({ ...product, [e.target.name]: e.target.value })
                  }
                  value={product.title}
                />
              </div>
            </div>
            <div className="col-lg-10 mt-4">
              {" "}
              <div className="input-group relative d-flex align-items-center">
                <FormControlLabel
                  control={
                    <Switch
                      onChange={onSwitchAction}
                      color="primary"
                      id="custom-switch"
                      checked={product.enabled}
                    />
                  }
                  label={product.enabled ? "Active" : "Inactive"}
                />
              </div>
            </div>
            <div className="col-lg-10 mt-7 mb-4">
              <button
                className="btn btn-car-primary"
                onClick={() => (id ? onUpdate() : onSubmit())}
              >
                {id ? "Save" : "Create "}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AddProductType;
