import React, { Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import LatestNews from "../view/dashboard/latestNews";
import { LayoutSplashScreen } from "../layout";
import Branches from "../view/dashboard/branches";
import Addnews from "../view/dashboard/latestNews/addnews";
import ProductList from "../view/dashboard/product/list";
import ProductType from "../view/dashboard/product/type";
import AddProductType from "../view/dashboard/product/addtype";
import AddBranch from "../view/dashboard/branches/addbranch";
import Holidays from "../view/dashboard/branches/holidays";
import AddProductList from "../view/dashboard/product/addlist";
import ServiceList from "../view/dashboard/service/list";
import AddListService from "../view/dashboard/service/addListService";
import Packages from "../view/dashboard/packages";
import AddPackage from "../view/dashboard/packages/addpackage";
import PurchaseApproval from "../view/dashboard/purchaseApproval";
import Booking from "../view/dashboard/booking";
import Customer from "../view/dashboard/customer";
import CustomerEdit from "../view/dashboard/customer/customerEdit";
import Blacklist from "../view/dashboard/blacklist";
import Addblacklist from "../view/dashboard/blacklist/addblacklist";
import Editblacklis from "../view/dashboard/blacklist/editblacklis";
import Admin from "../view/dashboard/admin";
import AddAdmin from "../view/dashboard/admin/addAdmin";
import Order from "../view/dashboard/order";
import OrderEdit from "../view/dashboard/order/orderEdit";
import Addpapproval from "../view/dashboard/purchaseApproval/addpapproval";
import Editpapproval from "../view/dashboard/purchaseApproval/editpapproval";
import BookingEdit from "../view/dashboard/booking/bookingEdit";
import PushNotification from "../view/dashboard/pushnotification";
import Addnotification from "../view/dashboard/pushnotification/addnotification";
import PusHistory from "../view/dashboard/pushnotification/pusHistory";
import Privacypolicy from "../view/dashboard/setting/privacypolicy";
import TermsCondition from "../view/dashboard/setting/terms&conditions";
import Aboutus from "../view/dashboard/setting/aboutus";
import ContactUs from "../view/dashboard/setting/contactus";
import CompanyAccount from "../view/dashboard/setting/companyAccount";
import Poster from "../view/dashboard/setting/poster";
import Calendar from "../view/dashboard/booking/calendar";
import CreateBooking from "../view/dashboard/booking/createBooking";
import CreateCustomer from "../view/dashboard/booking/createCustomer";
import CustomerPackageRecord from "../view/dashboard/customer/CustomerPackageRecord";
export default function BasePage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {/* {
          <Redirect exact from="/" to="/article" />
        }
        {
          <Redirect exact from="/dashboard" to="/article" />
        } */}
        <Redirect exact from="/" to="/booking" />
        <Route exact path="/latestNews" component={LatestNews} />
        <Route exact path="/latestNews/add" component={Addnews} />
        <Route exact path="/latestNews/update/:id" component={Addnews} />
        <Route exact path="/branches" component={Branches} />
        <Route exact path="/branches/add" component={AddBranch} />
        <Route exact path="/branches/update/:id" component={AddBranch} />
        <Route exact path="/branches/holiday/:id/:name" component={Holidays} />
        <Route exact path="/product/list" component={ProductList} />
        <Route exact path="/product/list/add" component={AddProductList} />
        <Route
          exact
          path="/product/list/update/:id"
          component={AddProductList}
        />
        <Route exact path="/product/type" component={ProductType} />
        <Route exact path="/product/type/add" component={AddProductType} />
        <Route
          exact
          path="/product/type/update/:id"
          component={AddProductType}
        />
        <Route exact path="/service" component={ServiceList} />
        <Route exact path="/service/update/:id" component={AddListService} />
        <Route exact path="/service/add" component={AddListService} />
        <Route
          exact
          path="/service/type/update/:id"
          component={AddListService}
        />
        <Route exact path="/packages" component={Packages} />
        <Route exact path="/packages/add" component={AddPackage} />
        <Route exact path="/packages/edit/:id" component={AddPackage} />
        <Route exact path="/purchaseApproval" component={PurchaseApproval} />
        <Route exact path="/purchaseApproval/add" component={Addpapproval} />
        <Route exact path="/purchaseApproval/edit" component={Editpapproval} />
        <Route exact path="/booking" component={Booking} />
        <Route exact path="/booking/calendar" component={Calendar} />
        <Route exact path="/booking/create" component={CreateBooking} />
        <Route exact path="/booking/edit/:id" component={BookingEdit} />
        <Route exact path="/customer" component={Customer} />
        <Route exact path="/customer/create" component={CreateCustomer} />
        <Route exact path="/customer/edit/:id" component={CustomerEdit} />
        <Route
          exact
          path="/customer/packagerecord/:cid/:pid"
          component={CustomerPackageRecord}
        />
        <Route exact path="/blacklist" component={Blacklist} />
        <Route exact path="/blacklist/add" component={Addblacklist} />
        <Route exact path="/blacklist/edit" component={Editblacklis} />
        <Route exact path="/admin" component={Admin} />
        <Route exact path="/admin/add" component={AddAdmin} />
        <Route exact path="/pushNotification" component={PushNotification} />
        <Route exact path="/pushNotification/add" component={Addnotification} />
        <Route
          exact
          path="/pushNotification/history/:id"
          component={PusHistory}
        />
        <Route exact path="/order" component={Order} />
        <Route exact path="/order/edit/:id" component={OrderEdit} />
        <Route exact path="/privacypolicy" component={Privacypolicy} />
        <Route exact path="/terms&conditions" component={TermsCondition} />
        <Route exact path="/aboutus" component={Aboutus} />
        <Route exact path="/contactus" component={ContactUs} />
        <Route exact path="/companyAccount" component={CompanyAccount} />
        <Route exact path="/poster" component={Poster} />
      </Switch>
    </Suspense>
  );
}
