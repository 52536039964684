import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
} from "../../../partials/controls";
import "react-quill/dist/quill.snow.css";
import { Switch } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import DragFileUpload from "../../../common/components/DragFileUpload";
import * as Yup from "yup";
import { useFormik } from "formik";
import Loader from "../../../common/loader";
import { useHistory, useParams } from "react-router";
import { toast } from "react-toastify";
import {
  AddPackageData,
  getOnePackageDetails,
  updatePackage,
} from "../../../service/carService";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import { numberFormater } from "../../../common/thousandSeperator/defaultFunction";

interface Values {
  discountedPrice10?: number | undefined;
  listedPrice?: number;
  name: string;
  tag: string;
  tagColor: string;
  detailCN: string;
  detailEN: string;
  featuredImage: string;
  images: string[];
}
interface Params {
  id: string;
}
const AddPackage = () => {
  const history = useHistory();
  const [isSwitchOn, setIsSwitchOn] = useState(true);
  const [loader, setLoader] = useState<boolean>(false);
  const [packages, setpacakges] = useState<any>([]);
  const [imageSrc, setImageSrc] = useState<string[]>([]);
  const [packagePrice, setPackagePrice] = useState<any>(0);

  const params: Params = useParams();

  const onSwitchAction = () => {
    setIsSwitchOn(!isSwitchOn);
  };

  const onUpdate = (values: any) => {
    const newDetails = {
      zh: formik.values.detailCN,
      en: formik.values.detailEN,
    };

    const body = {
      enabled: isSwitchOn,
      discountedPrice10: values?.discountedPrice10 / 100,
      listedPrice: values.listedPrice,
      name: values.name,
      metadata: {
        tag: values.tag,
        detail: JSON.stringify(newDetails),
        tagColor: values.tagColor,
      },
      images: values.images,
      featuredImage: values.featuredImage,
    };
    updatePackage(params.id, body)
      .then((res: any) => {
        setLoader(false);
        if (res.status === 200) {
          toast.success("Package Update Succesfull");
          history.push("/packages");
        }
      })
      .catch((err) => {
        setLoader(false);
        toast.error(err.response?.data?.message || "Something went wrong.");
      });
  };
  const onSubmit = (values: any) => {
    const newDetails = {
      zh: formik.values.detailCN,
      en: formik.values.detailEN,
    };

    const body = {
      enabled: isSwitchOn,
      discountedPrice10: values.discountedPrice10 / 100,
      listedPrice: values.listedPrice,
      name: values.name,
      metadata: {
        tag: values.tag,
        detail: JSON.stringify(newDetails),
        tagColor: values.tagColor,
      },
      images: values.images,
      featuredImage: values.featuredImage,
    };
    setLoader(true);
    AddPackageData(body)
      .then((res: any) => {
        setLoader(false);
        if (res.status === 200) {
          toast.success("Package add Sucessfull");
          history.push("/packages");
        }
      })
      .catch((err) => {
        setLoader(false);
        toast.error(err.response?.data?.message || "Something went wrong.");
      });
  };
  const getOnePackageDetail = (id) => {
    getOnePackageDetails(id)
      .then((res: any) => {
        setpacakges(res?.data);
        setIsSwitchOn(res?.data?.enabled);
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || "Something went wrong.");
      });
  };
  const getImagePreview = (id: any) => {
    setImageSrc(id);
  };

  useEffect(() => {
    if (params.id) {
      getOnePackageDetail(params.id);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const defaultDetail =
    params.id && packages?.metadata?.detail?.includes("{")
      ? JSON.parse(packages?.metadata?.detail)
      : null;

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      images: params.id ? packages?.images : [],
      discountedPrice10: params.id ? packages?.discountedPrice10 * 100 : 0,
      listedPrice: params.id ? packages?.listedPrice : undefined,
      name: params.id ? packages?.name : "",
      tag: params.id ? packages?.metadata?.tag : "",
      tagColor: params.id ? packages?.metadata?.tagColor : "",
      detailCN: params.id
        ? defaultDetail
          ? defaultDetail?.zh
          : packages?.metadata?.detail
        : "",
      detailEN: params.id
        ? defaultDetail
          ? defaultDetail?.en
          : packages?.metadata?.detail
        : "",
      featuredImage: params.id ? packages?.featuredImage : "",
    },
    validationSchema: Yup.object({
      discountedPrice10: Yup.number().required("Discount Price is required"),
      listedPrice: Yup.number().required("Latest Price is required"),
      name: Yup.string().required("Name is required"),
      tag: Yup.string().required("Tag is required"),
      tagColor: Yup.string().required("Tag color is required"),
      detailCN: Yup.string().required("Detail is required"),
      detailEN: Yup.string().required("Detail is required"),
      featuredImage: Yup.string().required("Please select at least one image"),
    }),
    onSubmit: (values: Values) => {
      if (packagePrice >= 10) {
        params.id ? onUpdate(values) : onSubmit(values);
      }
    },
  });

  useEffect(() => {
    if (params.id && formik.values.images) {
      getImagePreview(formik.values.images);
    }
  }, [formik.values.images]);

  const manageImageIds = (id: any) => {
    formik.setFieldValue("featuredImage", id[0]);
    formik.setFieldValue("images", id);
  };

  useEffect(() => {
    setPackagePrice(
      (formik.values?.listedPrice || 0) *
        10 *
        ((formik.values?.discountedPrice10 || 0) / 100) || 0
    );
    // eslint-disable-next-line
  }, [formik.values.listedPrice, formik.values.discountedPrice10]);

  return (
    <div>
      <Card>
        {loader && <Loader />}
        <CardHeader
          title={params.id ? "Edit Package" : "Create Package"}
        ></CardHeader>
        <CardBody>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="col-lg-4 ws-nowrap">Package Name</div>
              <div className="col-lg-8">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.name && formik.errors.name ? (
                    <div className="text-danger mt-1 ml-1">
                      {formik.errors.name}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="col-lg-4 ws-nowrap">Tag</div>
              <div className="col-lg-8">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    id="tag"
                    name="tag"
                    value={formik.values.tag}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.tag && formik.errors.tag ? (
                    <div className="text-danger mt-1 ml-1">
                      {formik.errors.tag}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="col-lg-4 ws-nowrap">Tag Color</div>
              <div className="col-lg-8">
                <div className="input-group relative d-flex align-items-center">
                  <select
                    name="tagColor"
                    className="form-control"
                    value={formik.values.tagColor}
                    onChange={formik.handleChange}
                  >
                    <option disabled selected value="">
                      Select Tag Color
                    </option>
                    <option value="#000000">Black</option>
                    <option value="#D70000">Red</option>
                  </select>
                  <ExpandMoreRoundedIcon
                    style={{
                      position: "absolute",
                      right: "1rem",
                      // zIndex: 10,
                    }}
                  />
                </div>
                {formik.touched.tagColor && formik.errors.tagColor ? (
                  <div className="text-danger mt-1 ml-1">
                    {formik.errors.tagColor}
                  </div>
                ) : null}
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="col-lg-4 ws-nowrap">Price (Single)</div>
              <div className="col-lg-8">
                <div className="form-group">
                  <input
                    type="number"
                    className="form-control"
                    id="listedPrice"
                    name="listedPrice"
                    value={formik.values.listedPrice}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.listedPrice && formik.errors.listedPrice ? (
                    <div className="text-danger mt-1 ml-1">
                      {formik.errors.listedPrice}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="col-lg-4 ws-nowrap">Package Discount</div>
              <div className="col-lg-8 ">
                <div className="form-group d-flex">
                  <input
                    type="number"
                    className="form-control"
                    id="discountedPrice10"
                    name="discountedPrice10"
                    min={0}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.discountedPrice10}
                  />
                  <span className="input-group-text" id="basic-addon1">
                    %
                  </span>
                </div>
                {formik.touched.discountedPrice10 &&
                formik.errors.discountedPrice10 ? (
                  <div className="text-danger mt-1 ml-1">
                    {formik.errors.discountedPrice10}
                  </div>
                ) : null}
              </div>
            </div>

            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="col-lg-4 ws-nowrap">Package Price</div>
              <div className="col-lg-8">
                <div className="form-group">
                  <input
                    type="number"
                    disabled
                    className={
                      packagePrice < 10
                        ? "form-control text-danger"
                        : "form-control"
                    }
                    id="listedPrice"
                    name="listedPrice"
                    value={packagePrice}
                  />

                  <div className="text-danger mt-1 ml-1">
                    {packagePrice < 10
                      ? "Package price must be at least $10.00 HKD"
                      : ""}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="">
            <div className="col-12 col-sm-2 mb-4 mb-sm-0  ws-nowrap">
              Detail (Chinese)
            </div>
            <div className="col-10">
              <div className="form-group">
                <textarea
                  className="form-control"
                  id="detail"
                  name="detailCN"
                  value={formik.values.detailCN}
                  onChange={formik.handleChange}
                />
                {formik.touched.detailCN && formik.errors.detailCN ? (
                  <div className="text-danger mt-1 ml-1">
                    {formik.errors.detailCN}
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          <div className="">
            <div className="col-12 col-sm-2 mb-4 mb-sm-0  ws-nowrap">
              Detail (English)
            </div>
            <div className="col-10">
              <div className="form-group">
                <textarea
                  className="form-control"
                  id="detail"
                  name="detailEN"
                  value={formik.values.detailEN}
                  onChange={formik.handleChange}
                />
                {formik.touched.detailEN && formik.errors.detailEN ? (
                  <div className="text-danger mt-1 ml-1">
                    {formik.errors.detailEN}
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="col-lg-4 ws-nowrap">Upload Photo</div>
              <div className="col-lg-8">
                <DragFileUpload
                  isMultipleFileUpload={true}
                  updateFileList={(fileId: any) => manageImageIds(fileId)}
                  imageSrc={imageSrc}
                  showRemoveIcon={false}
                />
                {formik.touched.featuredImage ? (
                  <div className="text-danger mt-1 ml-1">
                    {formik.errors.featuredImage}
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          <div className="row" style={{ marginLeft: "0.5px" }}>
            <div className="mt-4 col-12 col-sm-3 mb-4 mb-sm-0 ws-nowrap d-flex justify-content-between align-items-center">
              <span>Status</span>
              <FormControlLabel
                control={
                  <Switch
                    checked={isSwitchOn}
                    onChange={onSwitchAction}
                    color="primary"
                    name="status"
                  />
                }
                className="mb-0"
                label={isSwitchOn ? "Active" : "Inactive"}
              />
            </div>
          </div>
        </CardBody>
        <CardFooter>
          <div className="row">
            <div className="col-lg-10">
              <div className="d-flex flex-wrap align-items-center justify-content-end">
                <div className="mt-4 mt-sm-0">
                  <button
                    className="btn btn-success mr-2 mx-sm-2"
                    onClick={() => {
                      formik.handleSubmit();
                      formik.setFieldTouched("featuredImage");
                    }}
                  >
                    Submit
                  </button>
                </div>
                <div className="mt-4 mt-sm-0">
                  <Link className="btn btn-car-back" to="/packages">
                    Back
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </CardFooter>
      </Card>
    </div>
  );
};

export default AddPackage;
