import React, { FC, useState, useEffect } from "react";
import SVG from "react-inlinesvg";
import {
  toAbsoluteUrl,
  headerSortingClasses,
  sortCaret,
} from "../../../helpers";
import { toast } from "react-toastify";
import { Card, CardBody, CardHeader } from "../../../partials/controls";
import { SizePerPage } from "../../../common/table/filters/SizePerPage";
import { Table } from "../../../common/table";
import moment from "moment";
import { sortServerString } from "../../../common/table/sorter";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { getCustomer } from "../../../service/carService";
import { FilterQuery } from "../../../common/table/filters/FilterQuery";
import { StatusFilter } from "../../../common/table/filters/StatusFilter";
import Loader from "../../../common/loader";
import { PaginationLinks } from "../../../common/pagination/PaginationLinks";
import { PaginationToolbar } from "../../../common/pagination/PaginationToolbar";
import CustomerEdit from "./customerEdit";
import { Link, useHistory } from "react-router-dom";

const Customer: FC = () => {
  const [query, setQuery] = useState<string>("");
  const [totalData, setTotalData] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [entities, setEntities] = useState<any>([]);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [type, setType] = useState<string>("true");
  const [editCustomerModal, setEditCustomerModal] = useState<boolean>(false);
  const [customerId, setCustomerId] = useState<string>("");
  const [sortingType, setSortingType] = useState<any>("");
  const history = useHistory();
  const StatusColumnFormatter = (status: any) => {
    if (status) {
      return (
        <span
          className={`label label-lg label-light-${
            status === "Active" ? `success` : `danger`
          } label-inline font-weight-normal font-size-11`}
        >
          {status}
        </span>
      );
    }
  };

  const ActionEdit = (cellContent, row, rowIndex) => {
    return (
      <div
        title="Edit customer"
        className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
        onClick={() => history.push(`/customer/edit/${row._id}`)}
      >
        <span className="svg-icon svg-icon-md svg-icon-primary">
          <SVG
            src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
          />
        </span>
      </div>
    );
  };
  const columns = [
    {
      dataField: "name",
      text: "Username",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      onSort: (field: string, order: string) =>
        sortServerString(field, order, setSortingType),
    },
    {
      dataField: "phone",
      text: "Phone Number",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      onSort: (field: string, order: string) =>
        sortServerString(field, order, setSortingType),
    },
    {
      dataField: "homeDistrict",
      text: "Living District",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      onSort: (field: string, order: string) =>
        sortServerString(field, order, setSortingType),
    },
    {
      dataField: "created_at",
      text: "Created Date",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      onSort: (field: string, order: string) =>
        sortServerString(field, order, setSortingType),
    },
    {
      dataField: "enabled",
      text: "Status",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: StatusColumnFormatter,
      onSort: (field: string, order: string) =>
        sortServerString(field, order, setSortingType),
    },
    // {
    //   dataField: "action",
    //   text: "Action",
    //   formatter: ActionEdit,
    // },
  ];

  const getCustomerList = (page = 1, sizePerPage = 10, showLoading = true) => {
    if (showLoading) {
      setLoading(true);
    }

    const isStatus = type !== "" ? `&filter={"enabled":${type}}` : "";
    const isSortColumn =
      sortingType !== "" ? `&${sortingType}` : `&sort={"created_at":-1}`;

    getCustomer(page, sizePerPage, query, isStatus, isSortColumn)
      .then((res: any) => {
        setLoading(false);
        let news: any = [];
        res?.data?.docs.map((data, index) => {
          const Obj = {
            index: ++index + (page - 1) * sizePerPage,
            _id: data._id,
            name: data.name,
            phone: data.phone && data.phone.replace("+852", ""),
            homeDistrict: data.homeDistrict,
            created_at: moment(data.created_at).format("DD/MM/YYYY HH:mm"),
            enabled: data.enabled === true ? "Active" : "Inactive",
          };
          news.push(Obj);
        });
        setEntities(news);
        setTotalData(res?.data?.total);
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || "Something went wrong.");
        setLoading(false);
      });
  };

  // useEffect(() => {
  //   getCustomerList(1, 10, false);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [sortingType]);

  const customTotal = (from: any, to: any, size: any) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing rows {totalData === 0 ? 0 : (page - 1) * sizePerPage + 1} to {to}{" "}
      of {totalData}
    </span>
  );

  const onTableChange = async (type: any, props: any, paginationProps: any) => {
    if (type !== "sort") {
      setSizePerPage(props.sizePerPage);
      if (props.sizePerPage > paginationProps.totalSize) {
        // setPage(1);
        getCustomerList(1, props.sizePerPage);
        setPage(1);
      } else {
        setPage(props.page);
        getCustomerList(props.page, props.sizePerPage);
      }
    }
  };

  const handleSizePerPage = (
    { page, onSizePerPageChange }: any,
    newSizePerPage: any
  ) => {
    onSizePerPageChange(newSizePerPage, page);
  };

  const paginationOptions = {
    custom: true,
    totalSize: totalData,
    hideSizePerPage: true,
    showTotal: true,
    page,
    sizePerPage,
    paginationTotalRenderer: customTotal,
  };

  const getSelectRow = {
    mode: "radio",
    clickToSelect: true,
    hideSelectColumn: true,
    // onSelect: (data: ArticleEntities) =>
    //   history.push(`/customer/edit/${data.id}`),
  };

  useEffect(() => {
    getCustomerList(1, 10, false);
  }, [query, type, sortingType]);

  const openEditCustomerDialog = (id) => {
    setEditCustomerModal(true);
    setCustomerId(id);
  };

  const onClose = (isEditClick) => {
    if (isEditClick) {
      getCustomerList();
      setPage(1);
      setSizePerPage(10);
    }
    setEditCustomerModal(false);
  };

  const rowEvents = {
    onClick: (a, row, c) => {
      history.push(`/customer/edit/${row._id}`);
    },
  };

  return (
    <Card>
      {loading && <Loader />}
      <CardHeader title="Customer">
        <div className="d-flex align-items-center">
          <a
            title="Edit customer"
            className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
            role="button"
            onClick={() => {
              getCustomerList();
            }}
          >
            <span className="svg-icon svg-icon-md svg-icon-primary">
              <SVG src={toAbsoluteUrl("/media/svg/icons/reload.svg")} />
            </span>
          </a>
        </div>
      </CardHeader>
      <CardBody>
        <PaginationProvider pagination={paginationFactory(paginationOptions)}>
          {({ paginationProps, paginationTableProps }) => (
            <>
              <div className="d-flex justify-content-between">
                <div className="d-flex flex-column flex-md-row flex-sm-column mb-10">
                  <StatusFilter
                    status={type}
                    setStatus={(e) => {
                      setType(e);
                      setPage(1);
                    }}
                    options={[
                      { label: "All", value: "" },
                      { label: "Active", value: "true" },
                      { label: "Inactive", value: "false" },
                    ]}
                  />
                  <FilterQuery
                    query={query}
                    setQuery={(e) => {
                      setQuery(e);
                      setPage(1);
                    }}
                  />
                  <div className="mt-4">
                    <Link to={"/customer/create"}>
                      <button className="btn btn-primary ">New Customer</button>
                    </Link>
                  </div>
                </div>
              </div>

              <Table
                rowEvents={rowEvents}
                getSelectRow={getSelectRow}
                paginationProps={paginationProps}
                paginationOptions={paginationOptions}
                paginationTableProps={paginationTableProps}
                data={entities}
                columns={columns}
                remote
                onTableChange={(type: any, props: any) =>
                  onTableChange(type, props, paginationProps)
                }
              />
              <div className="mt-5 p-3 d-flex justify-content-between align-items-center flex-wrap">
                <div>
                  <PaginationLinks paginationProps={paginationProps} />
                </div>
                <div className="d-flex">
                  <SizePerPage
                    paginationProps={paginationProps}
                    handleSizePerPage={handleSizePerPage}
                    entities={entities}
                  />
                  <PaginationToolbar paginationProps={paginationProps} />
                </div>
              </div>
            </>
          )}
        </PaginationProvider>
      </CardBody>
      {/* {
        editCustomerModal && (
          <CustomerEdit
            show={editCustomerModal}
            onHide={(isEditClick) => onClose(isEditClick)}
            id={customerId}
          />
        )
      } */}
    </Card>
  );
};

export default Customer;
