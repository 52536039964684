/* eslint-disable no-unused-vars */
import React from "react";
import { PaginationTotalStandalone } from "react-bootstrap-table2-paginator";

export function PaginationToolbar(props) {
  const { paginationProps } = props;

  return (
    <div className="d-flex align-items-center py-3">
      <PaginationTotalStandalone className="text-muted" {...paginationProps} />
    </div>
  );
}
