import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Login from "../view/auth/login";
import BasePage from "./BasePage";
import { Layout } from "../layout";
import { PrivateRoute } from "./private";

export function Routes() {
  return (
    <>
      <Router>
        <Switch>
          <Route exact path="/auth/login" component={Login} />
          <Redirect from="/auth" to="/auth/login" />
          <Layout>
            <PrivateRoute path="/" component={BasePage} />
          </Layout>
        </Switch>
      </Router>
    </>
  );
}
