import React, { useRef } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardFooter,
} from "../../../partials/controls";
import "react-quill/dist/quill.snow.css";
import { IconButton } from "@material-ui/core";
import { toAbsoluteUrl } from "../../../helpers";
import SVG from "react-inlinesvg";

const Poster = () => {
  const inputFile = useRef<HTMLInputElement | null>(null);
  return (
    <div>
      <Card>
        <CardHeader title={"Poster"}></CardHeader>
        <CardBody>
          <div className="form-group row">
            <div className="col-sm-6">
              <div className="p-1 upload-image mt-4 ">
                <input
                  className="w-50 h-100"
                  type="file"
                  hidden
                  ref={inputFile}
                  name="upload-image"
                  id="upload-image"
                  accept="image/jpg, image/png, image/jpeg"
                />
                <IconButton
                  // className={classes.icon}
                  disableRipple
                  disableFocusRipple
                  onClick={() => {
                    let input = document.getElementById(
                      "upload-image"
                    )! as HTMLInputElement;
                    input.value = "";
                    inputFile.current?.click();
                  }}
                  className="image-add-icon"
                >
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/Files/File-plus.svg")}
                  />
                </IconButton>
              </div>
            </div>
          </div>
        </CardBody>
        <CardFooter>
          <div className="d-flex flex-wrap align-items-center justify-content-between">
            <div className="mt-4 mt-sm-0">
              <button className="btn btn-success mr-2 mx-sm-2">Save</button>
            </div>
          </div>
        </CardFooter>
      </Card>
    </div>
  );
};

export default Poster;
