import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
} from "../../../partials/controls";
import "react-quill/dist/quill.snow.css";
import * as Yup from "yup";
import {
  editSocialUrlDetails,
  getcontactUs,
  getSocialUrl,
  UpdatecontactUs,
} from "../../../service/carService";
import { toast } from "react-toastify";
import Loader from "../../../common/loader";
import { useFormik } from "formik";

interface Values {
  Facebook: string;
  Instagram: string;
  phone: string;
  addressCN: string;
  addressEN: string;
}

const ContactUs = () => {
  const [loader, setLoader] = useState<boolean>(false);
  const [phone, setPhone] = useState<any>();
  const [address, setAddress] = useState<any>();
  const [url, setUrl] = useState<any>();
  const [contactUs, setContactUs] = useState<any>();

  useEffect(() => {
    getContactUsData();
    getSocialUrlDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getContactUsData = () => {
    setLoader(true);
    getcontactUs()
      .then((res: any) => {
        setContactUs(res?.data?.value);
        setLoader(false);
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || "Something went wrong.");
        setLoader(false);
      });
  };

  const getSocialUrlDetails = () => {
    setLoader(true);
    getSocialUrl()
      .then((res: any) => {
        setUrl(res.data.socialUrl);
        setLoader(false);
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || "Something went wrong.");
        setLoader(false);
      });
  };

  const Onupdate = () => {
    setLoader(true);
    const newAddress = {
      zh: formik.values.addressCN,
      en: formik.values.addressEN,
    };
    const body = {
      phone: formik.values.phone,
      address: JSON.stringify(newAddress),
    };
    UpdatecontactUs(body)
      .then((res: any) => {
        setLoader(true);
        if (res.status === 200) {
          // toast.success("ContactUs Update Successful");
          console.log("phone call");
        }
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || "Something went wrong.");
        setLoader(true);
      });
  };

  const onUpdateUrl = (values: Values) => {
    setLoader(true);
    const body = {
      socialUrl: {
        fb: values.Facebook,
        ins: values.Instagram,
      },
    };
    editSocialUrlDetails(body)
      .then((res: any) => {
        setLoader(false);
        if (res.status === 200) {
          toast.success("ContactUs Update Successful");
        }
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || "Something went wrong.");
        setLoader(false);
      });
  };

  const defaultAddress = contactUs?.address.includes("{")
    ? JSON.parse(contactUs?.address)
    : null;

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      Facebook: url?.fb ? (url?.fb as any) : "",
      Instagram: url?.ins ? (url?.ins as any) : "",
      phone: contactUs?.phone,
      addressCN: defaultAddress?.zh || contactUs?.address,
      addressEN: defaultAddress?.en || contactUs?.address,
    },
    validationSchema: Yup.object({
      Facebook: Yup.string().required("FacebookURL is required"),
      Instagram: Yup.string().required("Instagram is required"),
      addressCN: Yup.string().required("Address (Chinese) is required"),
      addressEN: Yup.string().required("Address (English) is required"),
      phone: Yup.string().required("Phone number is required"),
    }),
    onSubmit: (values: Values) => {
      Onupdate();
      onUpdateUrl(values);
    },
  });

  return (
    <div className="row justify-content-center mt-20">
      <div className="col-8 ">
        <Card>
          {loader && <Loader />}
          <CardHeader title={"Contact Us"}></CardHeader>
          <CardBody>
            <div className=" mt-4">
              <div className="col-12  ws-nowrap">Phone Number</div>
              <div className="col-6 mt-1">
                <div className="form-group">
                  <input
                    type="number"
                    className="form-control"
                    id="phone"
                    value={formik.values.phone}
                    name="phone"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.phone && formik.errors.phone ? (
                    <div className="text-danger mt-1 ml-1">
                      please enter valid number
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className=" mt-4">
              <div className="col-12 ws-nowrap">Address (Chinese)</div>
              <div className="col-10 mt-1">
                <div className="form-group">
                  <textarea
                    className="form-control"
                    id="addressCN"
                    name="addressCN"
                    onChange={formik.handleChange}
                    value={formik.values.addressCN}
                    rows={5}
                    cols={5}
                  />
                  {formik.touched.addressCN && formik.errors.addressCN ? (
                    <div className="text-danger mt-1 ml-1">
                      {formik.errors.addressCN}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className=" mt-4">
              <div className="col-12 ws-nowrap">Address (English)</div>
              <div className="col-10 mt-1">
                <div className="form-group">
                  <textarea
                    className="form-control"
                    id="addressEN"
                    name="addressEN"
                    onChange={formik.handleChange}
                    value={formik.values.addressEN}
                    rows={5}
                    cols={5}
                  />
                  {formik.touched.addressEN && formik.errors.addressEN ? (
                    <div className="text-danger mt-1 ml-1">
                      {formik.errors.addressEN}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="col-lg-12 ws-nowrap font-label-color">
                  Facebook
                </div>
                <div className="col-lg-12 mt-1">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      value={formik.values.Facebook}
                      onChange={formik.handleChange}
                      name="Facebook"
                    />
                    {formik.touched.Facebook && formik.errors.Facebook ? (
                      <div className="text-danger mt-1 ml-1">
                        {formik.errors.Facebook}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-2">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="col-lg-12 ws-nowrap font-label-color">
                  Instagram
                </div>
                <div className="col-lg-12 mt-1">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      value={formik.values.Instagram}
                      onChange={formik.handleChange}
                      name="Instagram"
                    />
                    {formik.touched.Instagram && formik.errors.Instagram ? (
                      <div className="text-danger mt-1 ml-1">
                        {formik.errors.Instagram}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="row mt-4">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="row">
                <div className="col-lg-4 ws-nowrap">Photo</div>
                <div className="col-lg-8">
                  {" "}
                  <img
                    alt="Logo"
                    className="max-h-120px"
                    src={toAbsoluteUrl("/media/image/recipi.png")}
                  />
                </div>
              </div>
            </div>
          </div> */}
          </CardBody>

          <CardFooter>
            <div className="row">
              <div className="col-lg-10">
                <div className="d-flex flex-wrap align-items-center justify-content-end">
                  <div className="mt-4 mt-sm-0">
                    <button
                      className="btn btn-success mr-2 mx-sm-2"
                      onClick={() => formik.handleSubmit()}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </CardFooter>
        </Card>
      </div>
    </div>
  );
};

export default ContactUs;
