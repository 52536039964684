import React, { useRef } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardFooter,
} from "../../../partials/controls";
import "react-quill/dist/quill.snow.css";
import { CKModules } from "../../../common/editor/ckModules";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { IconButton } from "@material-ui/core";
import { toAbsoluteUrl } from "../../../helpers";
import SVG from "react-inlinesvg";

const Aboutus = () => {
  const inputFile = useRef<HTMLInputElement | null>(null);
  return (
    <div>
      <Card>
        <CardHeader title={"Aboutus"}></CardHeader>
        <CardBody>
          <div className="row ">
            <div className="col-12 ">
              <div className="">
                <div>
                  <CKEditor
                    editor={ClassicEditor}
                    config={CKModules}
                    className="ck"
                    data={"Aboutus"}
                  />
                </div>
              </div>
              <div></div>
            </div>
          </div>
          <div className="form-group row">
            <label
              htmlFor="inputText"
              className="col-sm-4 col-md-2 col-lg-2 col-form-label"
            >
              Photo
            </label>
            <div className="col-sm-6">
              <div className="p-1 upload-image mt-4 ">
                <input
                  className="w-50 h-100"
                  type="file"
                  hidden
                  ref={inputFile}
                  name="upload-image"
                  id="upload-image"
                  accept="image/jpg, image/png, image/jpeg"
                />
                <IconButton
                  // className={classes.icon}
                  disableRipple
                  disableFocusRipple
                  onClick={() => {
                    let input = document.getElementById(
                      "upload-image"
                    )! as HTMLInputElement;
                    input.value = "";
                    inputFile.current?.click();
                  }}
                  className="image-add-icon"
                >
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/Files/File-plus.svg")}
                  />
                </IconButton>
              </div>
            </div>
          </div>
        </CardBody>
        <CardFooter>
          <div className="d-flex flex-wrap align-items-center justify-content-between">
            <div className="mt-4 mt-sm-0">
              <button className="btn btn-success mr-2 mx-sm-2">Update</button>
            </div>
          </div>
        </CardFooter>
      </Card>
    </div>
  );
};

export default Aboutus;
