import React from 'react'
import { Button, Modal } from "react-bootstrap";

export interface modalProps {
    show: boolean;
    onHide: (value: any) => void;
    functionToExecute: (value: any) => void;
    funcVal?: any;
    message: string;
    title?: string;
}
function CommonModal(props: modalProps) {
    const { show, onHide, functionToExecute, message, title, funcVal } = props;

    return (
        <Modal show={show} centered size="lg">
            <Modal.Header closeButton>
                <Modal.Title> {title || "Do You Confirm ? "}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {message}
            </Modal.Body>
            <Modal.Footer>
                <Button className="btn btn-secondary" onClick={() => onHide(false)} >
                    Close
                </Button>
                <Button
                    className="btn btn-danger"
                    onClick={() => {
                        functionToExecute(funcVal)
                        onHide(false)
                    }} >
                    Confirm
                </Button>
            </Modal.Footer>
        </Modal >
    )
}

export default CommonModal