import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useHistory } from "react-router";
import { updateArrivedBranchDetail } from "../../../service/carService";
import { toast } from "react-toastify";
import ChangeTimeModal from "./changeTimeModal";
import ChangeBranchModal from "./changeBranchModal";
import ChangeRemarksModal from "./changeRemarksModal";
import ChangeStatusToCancelModal from "./changeStatutsToCancelModal";
import ChangeMarkLateModal from "./changeStatusToLate";
import ChangeStatusToAbsentModal from "./changeStatusToAbsentModal";

const BookingModal = ({
  bookingData,
  show,
  onHide,
  selecteddate,
  getCalenderLatestData,
}) => {
  const history = useHistory();
  const [isOpenChangeTimeModal, setIsChangeTimeModal] = useState<boolean>(
    false
  );
  const [isOpenChangeBranchModal, setIsChangeBranchModal] = useState<boolean>(
    false
  );
  const [isOpenChangeRemarksModal, setIsChangeRemarksModal] = useState<boolean>(
    false
  );
  const [isOpenCancelStatusModal, setIsChangeStatusToCancelModal] = useState<
    boolean
  >(false);
  const [isOpenLateStatusModal, setIsChangeStatusToLateModal] = useState<
    boolean
  >(false);
  const [isOpenAbsentStatusModal, setIsChangeStatusToAbsentModal] = useState<
    boolean
  >(false);

  const [mainModalShow, setMainModalShow] = useState<boolean>(true);

  const markArrived = () => {
    updateArrivedBranchDetail(bookingData._def.extendedProps._id, "arrived")
      .then((res: any) => {
        onHide(true);
        getCalenderLatestData();
        toast.success("Status is updated");
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || "Something went wrong.");
      });
  };

  const goToDetailPage = () => {
    onHide(true);
    history.push({
      pathname: `/booking/edit/${bookingData._def.extendedProps._id}`,
      state: { status: bookingData._def.extendedProps.status },
    });
  };

  const handleModalOpen = () => {
    setIsChangeTimeModal(true);
    setMainModalShow(false);
  };

  const handleModalOpenRemarks = () => {
    setIsChangeRemarksModal(true);
    setMainModalShow(false);
  };

  const handleChangeBranchModalOpen = () => {
    setIsChangeBranchModal(true);
    setMainModalShow(false);
  };

  const handleModalOpenToChangeStatusToCancel = () => {
    setIsChangeStatusToCancelModal(true);
    setMainModalShow(false);
  };

  const handleModalOpenToChangeStatusToLate = () => {
    setIsChangeStatusToLateModal(true);
    setMainModalShow(false);
  };

  const handleModalOpenToChangeStatusToAbsent = () => {
    setIsChangeStatusToAbsentModal(true);
    setMainModalShow(false);
  };

  console.log(mainModalShow, "mainModalShow");

  return (
    <>
      <div>
        <Modal
          centered
          show={show}
          onHide={onHide}
          dialogClassName="modal-90w"
          aria-labelledby="example-modal-sizes-title-sm"
          className={
            mainModalShow == false ? "d-none service-modal" : "service-modal"
          }
        >
          <Modal.Body className="show-grid">
            <div className="d-flex justify-content-between">
              <div className="modal-header-text">Booking</div>
              <div className="modal-close" onClick={onHide}>
                x
              </div>
            </div>

            <div className="row">
              <div className="col-6">
                <button
                  className="w-100 btn mt-10 rounded-lg booking-modal-arrived-btn"
                  disabled={
                    bookingData._def.extendedProps.status !== "0$created"
                  }
                  onClick={markArrived}
                >
                  Mark Arrived
                </button>
              </div>

              <div className="col-6">
                <button
                  className="w-100 btn mt-10 rounded-lg booking-modal-arrived-btn"
                  onClick={handleModalOpen}
                  disabled={
                    bookingData._def.extendedProps.status === "98$absent" ||
                    bookingData._def.extendedProps.status === "99$completed" ||
                    bookingData._def.extendedProps.status === "97$cancelled"
                  }
                >
                  Change Time Schedule
                </button>
              </div>
              <div className="col-6">
                <button
                  className="w-100 btn mt-10 rounded-lg booking-modal-arrived-btn"
                  onClick={handleModalOpenToChangeStatusToLate}
                  disabled={
                    bookingData._def.extendedProps.status === "98$absent" ||
                    bookingData._def.extendedProps.status === "99$completed" ||
                    bookingData._def.extendedProps.status === "97$cancelled"
                  }
                >
                  Mark Late
                </button>
              </div>
              <div className="col-6">
                <button
                  className="w-100 btn mt-10 rounded-lg booking-modal-arrived-btn"
                  onClick={handleChangeBranchModalOpen}
                  disabled={
                    bookingData._def.extendedProps.status === "98$absent" ||
                    bookingData._def.extendedProps.status === "99$completed" ||
                    bookingData._def.extendedProps.status === "97$cancelled"
                  }
                >
                  Change Branch
                </button>
              </div>
              <div className="col-6">
                <button
                  className="w-100 btn mt-10 rounded-lg booking-modal-arrived-btn"
                  onClick={handleModalOpenToChangeStatusToAbsent}
                  disabled={
                    bookingData._def.extendedProps.status === "98$absent" ||
                    bookingData._def.extendedProps.status === "99$completed" ||
                    bookingData._def.extendedProps.status === "1$arrived" ||
                    bookingData._def.extendedProps.status === "97$cancelled"
                  }
                >
                  Absent
                </button>
              </div>
              <div className="col-6">
                <button
                  className="w-100 btn mt-10 btn-success rounded-lg"
                  onClick={goToDetailPage}
                >
                  View Detail
                </button>
              </div>

              <div className="col-6">
                <button
                  className="w-100 btn mt-10 rounded-lg booking-modal-arrived-btn"
                  onClick={handleModalOpenRemarks}
                  disabled={
                    bookingData._def.extendedProps.status === "98$absent" ||
                    bookingData._def.extendedProps.status === "99$completed" ||
                    bookingData._def.extendedProps.status === "97$cancelled"
                  }
                >
                  Remark
                </button>
              </div>
              <div className="col-6">
                <button
                  className="w-100 btn btn-danger mt-10 rounded-lg booking-modal-arrived-btn"
                  onClick={handleModalOpenToChangeStatusToCancel}
                  disabled={
                    bookingData._def.extendedProps.status === "98$absent" ||
                    bookingData._def.extendedProps.status === "99$completed" ||
                    bookingData._def.extendedProps.status === "97$cancelled"
                  }
                >
                  Cancel Booking
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>

      {isOpenChangeTimeModal && (
        <ChangeTimeModal
          show={isOpenChangeTimeModal}
          onHide={() => setIsChangeTimeModal(false)}
          id={bookingData._def.extendedProps._id}
          onMainHide={onHide}
          selectedBookingData={bookingData}
          selectedBookingDate={selecteddate}
          getCalenderLatestData={getCalenderLatestData}
        />
      )}

      {isOpenChangeBranchModal && (
        <ChangeBranchModal
          show={isOpenChangeBranchModal}
          onHide={() => setIsChangeBranchModal(false)}
          onMainHide={onHide}
          serviceProduct={bookingData._def.extendedProps.serviceProduct}
          id={bookingData._def.extendedProps._id}
          getCalenderLatestData={getCalenderLatestData}
        />
      )}

      {isOpenChangeRemarksModal && (
        <ChangeRemarksModal
          show={isOpenChangeRemarksModal}
          onHide={() => setIsChangeRemarksModal(false)}
          onMainHide={onHide}
          // serviceProduct={bookingData._def.extendedProps.serviceProduct}
          id={bookingData._def.extendedProps._id}
          getCalenderLatestData={getCalenderLatestData}
        />
      )}

      {isOpenCancelStatusModal && (
        <ChangeStatusToCancelModal
          show={isOpenCancelStatusModal}
          onHide={() => setIsChangeStatusToCancelModal(false)}
          onMainHide={onHide}
          // serviceProduct={bookingData._def.extendedProps.serviceProduct}
          id={bookingData._def.extendedProps._id}
          getCalenderLatestData={getCalenderLatestData}
        />
      )}

      {isOpenLateStatusModal && (
        <ChangeMarkLateModal
          show={isOpenLateStatusModal}
          onHide={() => setIsChangeStatusToLateModal(false)}
          onMainHide={onHide}
          // serviceProduct={bookingData._def.extendedProps.serviceProduct}
          id={bookingData._def.extendedProps._id}
          getCalenderLatestData={getCalenderLatestData}
        />
      )}

      {isOpenAbsentStatusModal && (
        <ChangeStatusToAbsentModal
          show={isOpenAbsentStatusModal}
          onHide={() => setIsChangeStatusToAbsentModal(false)}
          onMainHide={onHide}
          // serviceProduct={bookingData._def.extendedProps.serviceProduct}
          id={bookingData._def.extendedProps._id}
          getCalenderLatestData={getCalenderLatestData}
        />
      )}
    </>
  );
};

export default BookingModal;
