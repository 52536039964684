import React, { useEffect, useState } from "react";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
} from "../../../partials/controls";
import "react-quill/dist/quill.snow.css";
import {
  getOnePushNotificationHistoryApi,
  getTableDataById,
} from "../../../service/carService";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { Table } from "../../../common/table";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { PaginationToolbar } from "../../../common/pagination/PaginationToolbar";
import { SizePerPage } from "../../../common/table/filters/SizePerPage";
import { PaginationLinks } from "../../../common/pagination/PaginationLinks";
import { toast } from "react-toastify";

interface Params {
  id?: string;
}

const PusHistory = () => {
  const params: Params = useParams();
  const [notificationData, setNotificationData] = useState<any>([]);
  const [tableData, setTableData] = useState<any>([]);
  const [totalData, setTotalData] = useState<number>(1);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);

  useEffect(() => {
    if (params.id) {
      getnotification(params.id);
      getTableData();
    }
  }, []);

  const getnotification = (id) => {
    getOnePushNotificationHistoryApi(id)
      .then((res: any) => {
        setNotificationData(res?.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const getTableData = (page = 1, sizePerPage = 10) => {
    getTableDataById(params.id, page, sizePerPage)
      .then((res: any) => {
        const entities = res?.data?.docs.map((d: any, index) => {
          {
            console.log(d?.profile.length, "d");
          }
          return {
            index: ++index + (page - 1) * sizePerPage,
            name: d?.profile?.name,
            phone: d?.profile?.phone,
            status: d?.read ? "Read" : "Pushed",
          };
        });
        setTableData(entities);
        setTotalData(res?.data.total);
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || "Something went wrong.");
        console.log("err", err);
      });
  };
  const columns = [
    {
      dataField: "name",
      text: "Receiver Users",
      sort: true,
    },
    {
      dataField: "phone",
      text: "Phone Number",
      sort: true,
    },
    {
      dataField: "status",
      text: "Push Status",
      sort: true,
    },
  ];
  const customTotal = (from: any, to: any, size: any) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing rows {totalData === 0 ? 0 : (page - 1) * sizePerPage + 1} to {to}{" "}
      of {totalData}
    </span>
  );
  const onTableChange = async (type: any, props: any, paginationProps: any) => {
    if (type !== "sort") {
      setSizePerPage(props.sizePerPage);
      if (props.sizePerPage > paginationProps.totalSize) {
        setPage(1);
        getTableData(1, props.sizePerPage);
        setPage(1);
      } else {
        setPage(props.page);
        getTableData(props.page, props.sizePerPage);
      }
    }
  };
  const paginationOptions = {
    custom: true,
    totalSize: totalData,
    hideSizePerPage: true,
    showTotal: true,
    page,
    sizePerPage,
    paginationTotalRenderer: customTotal,
  };
  const handleSizePerPage = (
    { page, onSizePerPageChange }: any,
    newSizePerPage: any
  ) => {
    onSizePerPageChange(newSizePerPage, page);
  };
  return (
    <div>
      <Card>
        <CardHeader title={"Push Detail"}></CardHeader>
        <CardBody>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="col-lg-4 ws-nowrap">Type</div>
              <div className="col-lg-8">
                <div className="input-group relative d-flex align-items-center">
                  <select
                    className="form-control top-select rounded"
                    name="type"
                    style={{ paddingRight: "3rem" }}
                    disabled={true}
                    value={notificationData?.transport || ""}
                  >
                    <option value="" disabled>
                      -- Select --
                    </option>
                    <option value="push">APP</option>
                    <option value="sms">SMS</option>
                  </select>
                  <ExpandMoreRoundedIcon
                    style={{
                      position: "absolute",
                      right: "1rem",
                      // zIndex: 10,
                    }}
                  />
                </div>
              </div>
            </div>
            {notificationData.transport !== "sms" && (
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="col-lg-4 ws-nowrap">Title</div>
                <div className="col-lg-8">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      id="title"
                      placeholder="Add title"
                      name="title"
                      disabled={true}
                      value={notificationData?.subject || ""}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="">
            <div className="col-12 col-sm-2 mb-4 mb-sm-0  ws-nowrap">
              Customer Tag
            </div>
            <div className="col-10">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  id="tag"
                  name="tag"
                  disabled={true}
                  value={notificationData?.labels || ""}
                />
              </div>
            </div>
          </div>

          <div className="">
            <div className="col-12 col-sm-2 mb-4 mb-sm-0  ws-nowrap">
              Content
            </div>
            <div className="col-10">
              <div className="form-group">
                <textarea
                  className="form-control"
                  id="content"
                  name="content"
                  rows={5}
                  value={notificationData?.content || ""}
                  disabled={true}
                />
              </div>
            </div>
          </div>
          <PaginationProvider pagination={paginationFactory(paginationOptions)}>
            {({ paginationProps, paginationTableProps }) => (
              <>
                <Table
                  paginationProps={paginationProps}
                  paginationOptions={paginationOptions}
                  paginationTableProps={paginationTableProps}
                  data={tableData}
                  columns={columns}
                  remote
                  onTableChange={(type: any, props: any) =>
                    onTableChange(type, props, paginationProps)
                  }
                />
                <div className="d-flex justify-content-between align-items-center flex-wrap">
                  <div>
                    <PaginationLinks paginationProps={paginationProps} />
                  </div>
                  <div className="d-flex">
                    <SizePerPage
                      paginationProps={paginationProps}
                      handleSizePerPage={handleSizePerPage}
                      entities={tableData}
                    />
                    <PaginationToolbar paginationProps={paginationProps} />
                  </div>
                </div>
              </>
            )}
          </PaginationProvider>
        </CardBody>
        <CardFooter>
          <div className="d-flex flex-wrap align-items-center justify-content-end">
            <div className="mt-4 mt-sm-0">
              <Link className="btn btn-car-back" to="/pushNotification">
                Back
              </Link>
            </div>
          </div>
        </CardFooter>
      </Card>
    </div>
  );
};

export default PusHistory;
