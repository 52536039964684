import React, { FC } from "react";
import { BrowserRouter } from "react-router-dom";
import { MaterialThemeProvider } from "./layout/core/MaterialThemeProvider";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Routes } from "./router";

const App: FC = () => {
  return (
    <BrowserRouter>
      {/*This library only returns the location that has been active before the recent location change in the current window lifetime.*/}
      <MaterialThemeProvider>
        <div className="App h-100">
          <Routes />
          <ToastContainer
            position="bottom-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss={false}
            draggable
            pauseOnHover
          />
        </div>
      </MaterialThemeProvider>
    </BrowserRouter>
  );
};

export default App;
