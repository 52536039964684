import React, { useState } from "react";
import { useParams } from "react-router-dom";
// import "bootstrap/dist/css/bootstrap.min.css";
import { DatePicker } from "antd";
import { addHolidays } from "../../../service/carService";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";

const { RangePicker } = DatePicker;

interface Params {
  id: string;
}

const AddHoliday = ({ id, show, onHide, getHolidayList }) => {
  const [name, setName] = useState<string>();
  const [date, setDate] = useState<any>();
  const [dateError, setDateError] = useState<any>(false);
  const params: Params = useParams();
  const startDate = date ? date[0] : "";
  const endDate = date ? date[1] : "";

  const handleDatePickerChange = (date: any, datastring: any) => {
    setDate(datastring);
  };

  const onChangeName = (e) => {
    setName(e.target.value);
    setDateError(false);
  };

  const onSubmit = () => {
    const body = {
      time: {
        start: new Date(startDate).toISOString(),
        end: new Date(endDate).toISOString(),
      },
      remarks: name,
      isRest: true,
      master: params.id,
    };
    if (dateError === false) {
      setDateError(false);
      addHolidays(body)
        .then((res: any) => {
          if (res.status === 200) {
            toast.success("Holiday Add Successful");
            onHide();
            getHolidayList();
          }
        })
        .catch((err) => {
          onHide();
          toast.error(err.response?.data?.message || "Something went wrong.");
        });
    } else {
      setDateError(true);
    }
  };

  return (
    <Modal
      centered
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
      className="service-modal"
    >
      <Modal.Body>
        <div className="d-flex justify-content-between">
          <div className="modal-header-text">Create Holiday</div>
          <div className="modal-close" onClick={onHide}>
            x
          </div>
        </div>
        <div className="row justify-content-start mt-10">
          <div className="col-lg-8">
            {" "}
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                id="title"
                placeholder="Holiday Name"
                name="title"
                onChange={(e) => onChangeName(e)}
              />
            </div>
          </div>
          <div className="col-lg-8 mt-2">
            {" "}
            <RangePicker
              style={{ width: "100%" }}
              onChange={(date, dataSting) =>
                handleDatePickerChange(date, dataSting)
              }
            />
            {dateError && <div style={{ color: "red" }}>Select Date</div>}
          </div>
        </div>
        <button className="btn btn-car-primary mr-2 mt-7" onClick={onSubmit}>
          Create
        </button>
      </Modal.Body>
    </Modal>
  );
};

export default AddHoliday;
