import React, { FC } from "react";
import { Select } from "../../components/Select";
interface Select {
  value: string;
  label: string;
}

interface Props {
  status: string;
  setStatus: any;
  options: Select[];
}

export const StatusFilter: FC<Props> = (props) => {
  const { status, setStatus, options, } = props;
  return (
    <div className="mt-4 mr-3">
      <div className=" d-flex flex-row">
        <Select
          value={status}
          setValue={setStatus}
          options={options}
          name="status"
          className="top-select"
        />
      </div>
    </div>
  );
};
