import clsx from "clsx";
import React from "react";

const Loader = () => {
  return (
    <div
      className={clsx(
        "position-fixed top-0 bottom-0 left-0 right-0  bg-dark opacity-75 d-flex align-items-center justify-content-center  zindex-5"
      )}
    >
      <img
        src="/media/logos/logo.png"
        alt="Loading icon.."
        className="width-5 pulse"
      />
    </div>
  );
};

export default Loader;
