import React, { FC, useState, useEffect } from "react";
import { toAbsoluteUrl } from "../../../helpers";
import { Card, CardBody, CardHeader } from "../../../partials/controls";
import { SizePerPage } from "../../../common/table/filters/SizePerPage";
import { Table } from "../../../common/table";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { FilterQuery } from "../../../common/table/filters/FilterQuery";
import AddHoliday from "./addholiday";
import { useParams } from "react-router";
import { getHolidays, deleteHolidays } from "../../../service/carService";
import Loader from "../../../common/loader";
import moment from "moment";
import { toast } from "react-toastify";
import { PaginationLinks } from "../../../common/pagination/PaginationLinks";
import { PaginationToolbar } from "../../../common/pagination/PaginationToolbar";

interface Params {
  id?: string;
  name?: string;
}

const Holiday: FC = () => {
  const [query, setQuery] = useState<string>("");
  const [totalData, setTotalData] = useState<number>(1);
  const [isOpen, setisopen] = useState(false);
  const params: Params = useParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [entities, setEntities] = useState<any>([]);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);

  const columns = [
    {
      dataField: "index",
      text: "ID",
      sort: true,
    },
    {
      dataField: "holidayName",
      text: "Holiday Name",
      sort: true,
    },
    {
      dataField: "date",
      text: "Date",
      sort: true,
    },
    {
      dataField: "action",
      text: "Action",
      // formatter: ActionDelete,
    },
  ];

  const deleteHoliday = (e, data?) => {
    deleteHolidays(data._id)
      .then((res: any) => {
        if (res.data) {
          toast.success("Holiday Delete Successful");
          getHolidayList();
        }
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || "Something went wrong.");
      });
  };

  const getHolidayList = (page = 1, sizePerPage = 10, showLoading = true) => {
    if (showLoading) {
      setLoading(true);
    }
    getHolidays(params.id, page, sizePerPage, query)
      .then((res: any) => {
        setLoading(false);
        let news: any = [];
        res?.data?.docs.map((data, index) => {
          const Obj = {
            holidayName: data.remarks,
            index: ++index + (page - 1) * sizePerPage,
            id: data._id,
            date: moment(data?.time?.start).format("DD/MM/YYYY"),
            action: (
              <div onClick={(e) => deleteHoliday(e, data)}>
                <img
                  alt="Logo"
                  className="max-h-30px"
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Communication/trash.svg"
                  )}
                />
              </div>
            ),
          };
          news.push(Obj);
        });
        setEntities(news);
        setTotalData(res?.data?.total);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.response?.data?.message || "Something went wrong.");
      });
  };

  useEffect(() => {
    getHolidayList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const customTotal = (from: any, to: any, size: any) => (
    <span className="react-bootstrap-table-pagination-total">
      {totalData} records ({totalData === 0 ? 0 : (page - 1) * sizePerPage + 1}{" "}
      to {to})
    </span>
  );

  const onTableChange = async (type: any, props: any, paginationProps: any) => {
    if (type !== "sort") {
      setSizePerPage(props.sizePerPage);
      if (props.sizePerPage > paginationProps.totalSize) {
        setPage(1);
        getHolidayList(1, props.sizePerPage);
        setPage(1);
      } else {
        setPage(props.page);
        getHolidayList(props.page, props.sizePerPage);
      }
    }
  };

  const handleSizePerPage = (
    { page, onSizePerPageChange }: any,
    newSizePerPage: any
  ) => {
    onSizePerPageChange(newSizePerPage, page);
  };

  const paginationOptions = {
    custom: true,
    totalSize: totalData,
    hideSizePerPage: true,
    showTotal: true,
    page,
    sizePerPage,
    paginationTotalRenderer: customTotal,
  };

  const onAddHoliday = () => {
    setisopen(true);
  };

  useEffect(() => {
    getHolidayList(1, 10, false);
  }, [query]);

  const defaultName = params?.name?.includes("{")
    ? JSON.parse(params?.name)
    : null;
  return (
    <>
      <Card>
        {loading && <Loader />}
        <CardHeader
          title={`Branches > ${
            defaultName ? defaultName?.en : params?.name
          } > Holiday List`}
        ></CardHeader>
        <CardBody>
          <PaginationProvider pagination={paginationFactory(paginationOptions)}>
            {({ paginationProps, paginationTableProps }) => (
              <>
                <div className="d-flex ">
                  <div className="d-flex flex-column flex-md-row flex-sm-column mb-10">
                    <FilterQuery
                      query={query}
                      setQuery={(e) => {
                        setQuery(e);
                        setPage(1);
                      }}
                    />
                  </div>
                  <div className="mt-4">
                    <button className="btn btn-primary" onClick={onAddHoliday}>
                      Add Holidays
                    </button>
                  </div>
                </div>
                <Table
                  paginationProps={paginationProps}
                  paginationOptions={paginationOptions}
                  paginationTableProps={paginationTableProps}
                  data={entities}
                  columns={columns}
                  remote
                  onTableChange={(type: any, props: any) =>
                    onTableChange(type, props, paginationProps)
                  }
                />
                <div className="d-flex justify-content-between align-items-center flex-wrap">
                  <div>
                    <PaginationLinks paginationProps={paginationProps} />
                  </div>
                  <div className="d-flex">
                    <SizePerPage
                      paginationProps={paginationProps}
                      handleSizePerPage={handleSizePerPage}
                      entities={entities}
                    />
                    <PaginationToolbar paginationProps={paginationProps} />
                  </div>
                </div>
              </>
            )}
          </PaginationProvider>
        </CardBody>
      </Card>
      <AddHoliday
        getHolidayList={getHolidayList}
        show={isOpen}
        onHide={() => setisopen(false)}
        id={""}
      />
    </>
  );
};

export default Holiday;
