import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardFooter,
} from "../../../partials/controls";
import "react-quill/dist/quill.snow.css";
import { CKModules } from "../../../common/editor/ckModules";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {
  getPrivacyPolicy,
  UpdatePrivacyPolicy,
} from "../../../service/carService";
import { toast } from "react-toastify";
import Loader from "../../../common/loader";

const Privacypolicy = () => {
  const [loader, setLoader] = useState<boolean>(false);
  const [data, setData] = useState<any>();

  useEffect(() => {
    getPolicyData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getPolicyData = () => {
    setLoader(true);
    getPrivacyPolicy()
      .then((res: any) => {
        setData(res?.data?.content);
        setLoader(false);
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || "Something went wrong.");
        setLoader(false);
      });
  };

  const Onupdate = () => {
    setLoader(true);
    const body = {
      content: data,
    };
    UpdatePrivacyPolicy(body)
      .then((res: any) => {
        setLoader(false);
        if (res.status === 200) {
          toast.success("Privacy Policy Update Successful");
        }
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || "Something went wrong.");
        setLoader(false);
      });
  };

  const handleChange = (e, editor) => {
    const data = editor.getData();
    setData(data);
  };

  return (
    <div>
      <Card>
        <CardHeader title={"Privacy Policy"}></CardHeader>
        {loader && <Loader />}
        <CardBody>
          <div className="row ">
            <div className="col-12 ">
              <div className="">
                <div>
                  <CKEditor
                    editor={ClassicEditor}
                    config={CKModules}
                    className="ck"
                    data={data}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div></div>
            </div>
          </div>
        </CardBody>
        <CardFooter>
          <div className="d-flex flex-wrap align-items-center justify-content-between">
            <div className="mt-4 mt-sm-0">
              <button
                className="btn btn-success mr-2 mx-sm-2"
                onClick={Onupdate}
              >
                Update
              </button>
            </div>
          </div>
        </CardFooter>
      </Card>
    </div>
  );
};

export default Privacypolicy;
